.spectrum {
  --spectrum-clearbutton-medium-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-clearbutton-medium-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-clearbutton-medium-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-clearbutton-medium-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-clearbutton-medium-background-color-mouse-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-icon-color-mouse-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-clearbutton-medium-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-clearbutton-medium-icon-size: var(--spectrum-global-dimension-size-100);
  --spectrum-clearbutton-medium-border-radius: var(--spectrum-global-dimension-static-size-0);
  --spectrum-clearbutton-medium-width: var(--spectrum-alias-single-line-height);
  --spectrum-clearbutton-medium-height: var(--spectrum-alias-single-line-height);
  --spectrum-clearbutton-medium-over-background-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-over-background-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-clearbutton-medium-over-background-background-color-hover: rgba(255,255,255,0.1);
  --spectrum-clearbutton-medium-over-background-icon-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-clearbutton-medium-over-background-background-color-down: rgba(255,255,255,0.15);
  --spectrum-clearbutton-medium-over-background-icon-color-down: var(--spectrum-global-color-static-white);
  --spectrum-clearbutton-medium-over-background-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-over-background-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-clearbutton-medium-over-background-background-color-mouse-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-over-background-icon-color-mouse-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-clearbutton-medium-over-background-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-medium-over-background-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-clearbutton-medium-over-background-icon-size: var(--spectrum-global-dimension-size-100);
  --spectrum-clearbutton-medium-over-background-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-clearbutton-medium-over-background-width: var(--spectrum-alias-single-line-height);
  --spectrum-clearbutton-medium-over-background-height: var(--spectrum-alias-single-line-height);
  --spectrum-clearbutton-small-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-small-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-clearbutton-small-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-small-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-clearbutton-small-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-small-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-clearbutton-small-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-small-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-clearbutton-small-background-color-mouse-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-small-icon-color-mouse-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-clearbutton-small-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-clearbutton-small-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-clearbutton-small-icon-size: var(--spectrum-global-dimension-size-100);
  --spectrum-clearbutton-small-border-radius: var(--spectrum-global-dimension-static-size-0);
  --spectrum-clearbutton-small-width: var(--spectrum-global-dimension-size-300);
  --spectrum-clearbutton-small-height: var(--spectrum-global-dimension-size-300);
}
