.spectrum {
  --spectrum-sidenav-item-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-sidenav-item-text-color: var(--spectrum-alias-text-color);
  --spectrum-sidenav-header-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-sidenav-heading-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-sidenav-item-background-color-hover: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-item-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-item-background-color-key-focus: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-item-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-item-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-sidenav-item-background-color-down: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-item-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-sidenav-item-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-sidenav-item-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-sidenav-item-background-color-selected: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-item-text-color-selected: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-item-background-color-selected-hover: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-item-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-item-background-color-selected-down: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-item-text-color-selected-down: var(--spectrum-alias-text-color-down);
  --spectrum-sidenav-item-background-color-selected-key-focus: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-item-border-color-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-sidenav-item-text-color-selected-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-min-width: var(--spectrum-global-dimension-size-3000);
  --spectrum-sidenav-width: var(--spectrum-global-dimension-size-3000);
  --spectrum-sidenav-item-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-sidenav-item-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-sidenav-item-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-sidenav-item-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-sidenav-item-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-sidenav-item-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-item-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-item-height: var(--spectrum-alias-single-line-height);
  --spectrum-sidenav-item-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-item-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-sidenav-item-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-sidenav-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-sidenav-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-sidenav-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-sidenav-header-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-sidenav-header-font-weight: var(--spectrum-global-font-weight-medium);
  --spectrum-sidenav-header-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-sidenav-header-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-sidenav-header-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-header-height: var(--spectrum-alias-single-line-height);
  --spectrum-sidenav-header-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-header-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-sidenav-header-gap-top: var(--spectrum-global-dimension-size-200);
  --spectrum-sidenav-header-gap-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-sidenav-heading-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-sidenav-heading-font-weight: var(--spectrum-global-font-weight-medium);
  --spectrum-sidenav-heading-text-font-weight: var(--spectrum-global-font-weight-medium);
  --spectrum-sidenav-heading-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-sidenav-heading-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-sidenav-heading-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-sidenav-heading-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-sidenav-heading-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-heading-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-heading-height: var(--spectrum-alias-single-line-height);
  --spectrum-sidenav-heading-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-heading-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-sidenav-heading-gap-top: var(--spectrum-global-dimension-size-200);
  --spectrum-sidenav-heading-gap-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-sidenav-item-touch-hit-top: var(--spectrum-global-dimension-static-size-25);
  --spectrum-sidenav-multilevel-item-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-sidenav-multilevel-item-text-color: var(--spectrum-alias-text-color);
  --spectrum-sidenav-multilevel-header-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-sidenav-multilevel-heading-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-sidenav-multilevel-main-item-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-sidenav-multilevel-main-item-text-color: var(--spectrum-alias-text-color);
  --spectrum-sidenav-multilevel-item-background-color-hover: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-item-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-main-item-background-color-hover: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-main-item-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-item-background-color-key-focus: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-item-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-item-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-sidenav-multilevel-main-item-background-color-key-focus: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-main-item-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-main-item-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-sidenav-multilevel-item-background-color-down: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-item-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-sidenav-multilevel-main-item-background-color-down: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-main-item-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-sidenav-multilevel-item-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-sidenav-multilevel-item-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-sidenav-multilevel-main-item-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-sidenav-multilevel-main-item-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-sidenav-multilevel-item-background-color-selected: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-item-text-color-selected: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-main-item-background-color-selected: var(--spectrum-alias-background-color-transparent);
  --spectrum-sidenav-multilevel-main-item-text-color-selected: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-item-background-color-selected-hover: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-item-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-main-item-background-color-selected-hover: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-main-item-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-item-background-color-selected-down: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-item-text-color-selected-down: var(--spectrum-alias-text-color-down);
  --spectrum-sidenav-multilevel-main-item-background-color-selected-down: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-main-item-text-color-selected-down: var(--spectrum-alias-text-color-down);
  --spectrum-sidenav-multilevel-item-background-color-selected-key-focus: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-item-border-color-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-sidenav-multilevel-item-text-color-selected-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-main-item-background-color-selected-key-focus: var(--spectrum-alias-highlight-hover);
  --spectrum-sidenav-multilevel-main-item-border-color-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-sidenav-multilevel-main-item-text-color-selected-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-sidenav-multilevel-min-width: var(--spectrum-global-dimension-size-3000);
  --spectrum-sidenav-multilevel-width: var(--spectrum-global-dimension-size-3000);
  --spectrum-sidenav-multilevel-item-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-sidenav-multilevel-item-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-sidenav-multilevel-item-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-sidenav-multilevel-item-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-sidenav-multilevel-item-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-sidenav-multilevel-item-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-item-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-item-height: var(--spectrum-alias-single-line-height);
  --spectrum-sidenav-multilevel-item-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-item-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-sidenav-multilevel-item-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-sidenav-multilevel-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-sidenav-multilevel-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-sidenav-multilevel-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-sidenav-multilevel-header-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-sidenav-multilevel-header-font-weight: var(--spectrum-global-font-weight-medium);
  --spectrum-sidenav-multilevel-header-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-sidenav-multilevel-header-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-sidenav-multilevel-header-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-header-height: var(--spectrum-alias-single-line-height);
  --spectrum-sidenav-multilevel-header-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-header-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-sidenav-multilevel-header-gap-top: var(--spectrum-global-dimension-size-200);
  --spectrum-sidenav-multilevel-header-gap-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-sidenav-multilevel-heading-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-sidenav-multilevel-heading-font-weight: var(--spectrum-global-font-weight-medium);
  --spectrum-sidenav-multilevel-heading-text-font-weight: var(--spectrum-global-font-weight-medium);
  --spectrum-sidenav-multilevel-heading-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-sidenav-multilevel-heading-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-sidenav-multilevel-heading-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-sidenav-multilevel-heading-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-sidenav-multilevel-heading-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-heading-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-heading-height: var(--spectrum-alias-single-line-height);
  --spectrum-sidenav-multilevel-heading-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-heading-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-sidenav-multilevel-heading-gap-top: var(--spectrum-global-dimension-size-200);
  --spectrum-sidenav-multilevel-heading-gap-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-sidenav-multilevel-item-touch-hit-top: var(--spectrum-global-dimension-static-size-25);
  --spectrum-sidenav-multilevel-main-item-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-sidenav-multilevel-item-indentation: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-item-indentation-level1: var(--spectrum-global-dimension-size-150);
  --spectrum-sidenav-multilevel-item-indentation-level2: var(--spectrum-global-dimension-size-300);
  --spectrum-sidenav-multilevel-item-indentation-level3: var(--spectrum-global-dimension-size-450);
}
