.spectrum {
  --spectrum-colorwheel-fill-color: none;
  --spectrum-colorwheel-fill-color-hover: none;
  --spectrum-colorwheel-fill-color-down: none;
  --spectrum-colorwheel-fill-color-key-focus: none;
  --spectrum-colorwheel-border-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-colorwheel-fill-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-colorwheel-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-colorwheel-default-size: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorwheel-track-width: var(--spectrum-global-dimension-size-300);
  --spectrum-colorwheel-colorarea-margin: var(--spectrum-global-dimension-size-150);
  --spectrum-colorwheel-cursor-hit-outer: var(--spectrum-global-dimension-size-0);
  --spectrum-colorwheel-cursor-hit-inner: var(--spectrum-global-dimension-size-0);
  --spectrum-colorwheel-loupe-entry-animation-delay: 0ms;
  --spectrum-colorwheel-loupe-entry-animation-duration: var(--spectrum-alias-loupe-entry-animation-duration);
  --spectrum-colorwheel-loupe-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-colorwheel-loupe-entry-animation-properties: opacity;
  --spectrum-colorwheel-loupe-exit-animation-delay: 0ms;
  --spectrum-colorwheel-loupe-exit-animation-duration: var(--spectrum-alias-loupe-exit-animation-duration);
  --spectrum-colorwheel-loupe-exit-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-colorwheel-loupe-exit-animation-properties: opacity;
}
