/*
Copyright 2019 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

/* Default */
.spectrum-ToggleSwitch-switch {
  background-color: var(--spectrum-switch-emphasized-track-color);
  /* :after is the focus ring */
  /* :before is used for the handle of the switch */
  &:before {
    background-color: var(--spectrum-switch-emphasized-handle-background-color);
    border-color: var(--spectrum-switch-emphasized-handle-border-color);
  }
}
.spectrum-ToggleSwitch-input ~ .spectrum-ToggleSwitch-label {
  color: var(--spectrum-switch-emphasized-text-color);
}
/* Selected */
.spectrum-ToggleSwitch-input {
  &:checked + .spectrum-ToggleSwitch-switch {
    background-color: var(--spectrum-switch-emphasized-track-color-selected);
    &:before {
      border-color: var(--spectrum-switch-emphasized-handle-border-color-selected);
    }
  }
}
/* Hover */
.spectrum-ToggleSwitch:hover {
  .spectrum-ToggleSwitch-input {
    & + .spectrum-ToggleSwitch-switch {
      &:before {
        border-color: var(--spectrum-switch-emphasized-handle-border-color-hover);
        box-shadow: none;
      }
    }
    & ~ .spectrum-ToggleSwitch-label {
      color: var(--spectrum-switch-emphasized-text-color-hover);
    }
    /* Selected Hover */
    &:checked:enabled + .spectrum-ToggleSwitch-switch {
      background-color: var(--spectrum-switch-emphasized-track-color-selected-hover);
      &:before {
        border-color: var(--spectrum-switch-emphasized-handle-border-color-selected-hover);
      }
    }
  }
}
/* Down */
.spectrum-ToggleSwitch:active {
  .spectrum-ToggleSwitch-input {
    & + .spectrum-ToggleSwitch-switch {
      &:before {
        border-color: var(--spectrum-switch-emphasized-handle-border-color-down);
      }
    }
    & ~ .spectrum-ToggleSwitch-label {
      color: var(--spectrum-switch-emphasized-text-color-down);
    }
    /* Selected Down */
    &:checked:enabled + .spectrum-ToggleSwitch-switch {
      background-color: var(--spectrum-switch-emphasized-track-color-selected-down);
      &:before {
        border-color: var(--spectrum-switch-emphasized-handle-border-color-selected-down);
      }
    }
  }
}
/* Disabled */
.spectrum-ToggleSwitch .spectrum-ToggleSwitch-input:disabled {
  & + .spectrum-ToggleSwitch-switch {
    background-color: var(--spectrum-switch-emphasized-track-color-disabled);
    &:before {
      border-color: var(--spectrum-switch-emphasized-handle-border-color-disabled);
    }
  }
  & ~ .spectrum-ToggleSwitch-label {
    color: var(--spectrum-switch-emphasized-text-color-disabled);
  }
  /* Selected Disabled */
  &:checked {
    & + .spectrum-ToggleSwitch-switch {
      background-color: var(--spectrum-switch-emphasized-track-color-selected-disabled);
      &:before {
        border-color: var(--spectrum-switch-emphasized-handle-border-color-selected-disabled);
      }
    }
    & ~ .spectrum-ToggleSwitch-label {
      color: var(--spectrum-switch-emphasized-text-color-selected-disabled);
    }
  }
}
/* Quiet */
/* Quiet Selected */
.spectrum-ToggleSwitch--quiet {
  .spectrum-ToggleSwitch-input:checked + .spectrum-ToggleSwitch-switch {
    background-color: var(--spectrum-switch-quiet-track-color-selected);
    &:before {
      border-color: var(--spectrum-switch-quiet-handle-border-color-selected);
    }
  }
}
/* Quiet Selected Hover */
.spectrum-ToggleSwitch--quiet:hover {
  .spectrum-ToggleSwitch-input:checked:enabled + .spectrum-ToggleSwitch-switch {
    background-color: var(--spectrum-switch-quiet-track-color-selected-hover);
    &:before {
      border-color: var(--spectrum-switch-quiet-handle-border-color-selected-hover);
    }
  }
}
/* Quiet Selected Down */
.spectrum-ToggleSwitch.spectrum-ToggleSwitch--quiet:active {
  .spectrum-ToggleSwitch-input:checked:enabled + .spectrum-ToggleSwitch-switch {
    background-color: var(--spectrum-switch-quiet-track-color-selected-down);
    &:before {
      border-color: var(--spectrum-switch-quiet-handle-border-color-selected-down);
    }
  }
}
/* Key Focus */
.spectrum-ToggleSwitch,
.spectrum-ToggleSwitch:hover {
  .spectrum-ToggleSwitch-input:focus-ring {
    & + .spectrum-ToggleSwitch-switch {
      &:after {
        box-shadow: 0 0 0 var(--spectrum-switch-focus-ring-size-key-focus) var(--spectrum-switch-focus-ring-color-key-focus);
      }
      &:before {
        border-color: var(--spectrum-switch-emphasized-handle-border-color-key-focus);
      }
    }
    /* Selected Key Focus */
    &:checked {
      & + .spectrum-ToggleSwitch-switch {
        background-color: var(--spectrum-switch-emphasized-track-color-selected-key-focus);
        &:before {
          border-color: var(--spectrum-switch-emphasized-handle-border-color-selected-key-focus);
        }
      }
    }
  }
}

.spectrum-ToggleSwitch--quiet,
.spectrum-ToggleSwitch--quiet:hover {
  .spectrum-ToggleSwitch-input:focus-ring {
    /* Selected Key Focus */
    &:checked {
      & + .spectrum-ToggleSwitch-switch {
        background-color: var(--spectrum-switch-quiet-track-color-selected-key-focus);
        &:before {
          border-color: var(--spectrum-switch-quiet-handle-border-color-selected-key-focus);
        }
      }
    }
  }
}

.spectrum-ToggleSwitch--ab {
  .spectrum-ToggleSwitch-input {
    &:checked + .spectrum-ToggleSwitch-switch {
      background-color: var(--spectrum-switch-track-color);
    }

    &:checked + .spectrum-ToggleSwitch-switch {
      &:before {
        /* Don't be blue */
        border-color: var(--spectrum-switch-handle-border-color);
      }
    }

    &:focus-ring {
      &:checked + .spectrum-ToggleSwitch-switch {
        background-color: var(--spectrum-switch-track-color);

        &:before {
          border-color: var(--spectrum-switch-handle-border-color-selected);
        }
      }
    }

    &:disabled {
      &:checked + .spectrum-ToggleSwitch-switch {
        background-color: var(--spectrum-switch-emphasized-track-color-disabled);
      }

      & + .spectrum-ToggleSwitch-switch {
        background-color: var(--spectrum-switch-emphasized-track-color-disabled);

        &:before {
          border-color: var(--spectrum-switch-emphasized-handle-border-color-disabled);
        }
      }
    }
  }

  &:hover {
    .spectrum-ToggleSwitch-input {
      &:checked:enabled + .spectrum-ToggleSwitch-switch {
        background-color: var(--spectrum-switch-emphasized-track-color);
        &:before {
          border-color: var(--spectrum-switch-emphasized-handle-border-color-hover);
        }
      }
    }
  }

  &:active {
    .spectrum-ToggleSwitch-input {
      &:checked:enabled + .spectrum-ToggleSwitch-switch {
        background-color: var(--spectrum-switch-emphasized-track-color);
        &:before {
          border-color: var(--spectrum-switch-emphasized-handle-border-color-down);
        }
      }
    }
  }
}
