.spectrum {
  --spectrum-combobox-textfield-border-top-right-radius: 0;
  --spectrum-combobox-textfield-border-bottom-right-radius: 0;
  --spectrum-combobox-fieldbutton-border-top-left-radius: 0;
  --spectrum-combobox-fieldbutton-border-bottom-left-radius: 0;
  --spectrum-combobox-min-width: var(--spectrum-global-dimension-size-1000);
  --spectrum-combobox-popover-offset-y: var(--spectrum-global-dimension-size-75);
  --spectrum-combobox-popover-offset-x: 0px;
  --spectrum-combobox-quiet-textfield-border-top-right-radius: 0;
  --spectrum-combobox-quiet-textfield-border-bottom-right-radius: 0;
  --spectrum-combobox-quiet-fieldbutton-border-top-left-radius: 0;
  --spectrum-combobox-quiet-fieldbutton-border-bottom-left-radius: 0;
  --spectrum-combobox-quiet-min-width: var(--spectrum-global-dimension-size-800);
  --spectrum-combobox-quiet-popover-offset-y: var(--spectrum-global-dimension-size-75);
  --spectrum-combobox-quiet-popover-offset-x: var(--spectrum-global-dimension-size-150);
}
