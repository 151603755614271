.spectrum {
  --spectrum-code-l-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-l-margin-top: 0px;
  --spectrum-code-l-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-l-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-l-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-l-text-transform: none;
  --spectrum-code-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-l-margin-bottom-multiplier: 0em;
  --spectrum-code-l-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-code-l-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-l-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-l-emphasis-margin-top: 0px;
  --spectrum-code-l-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-l-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-l-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-l-emphasis-text-transform: none;
  --spectrum-code-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-l-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-code-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-l-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-l-emphasis-strong-margin-top: 0px;
  --spectrum-code-l-emphasis-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-l-emphasis-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-l-emphasis-strong-text-transform: none;
  --spectrum-code-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-l-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-code-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-l-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-l-strong-margin-top: 0px;
  --spectrum-code-l-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-l-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-l-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-l-strong-text-transform: none;
  --spectrum-code-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-l-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-l-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-code-l-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-m-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-m-margin-top: 0px;
  --spectrum-code-m-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-m-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-m-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-m-text-transform: none;
  --spectrum-code-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-m-margin-bottom-multiplier: 0em;
  --spectrum-code-m-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-code-m-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-m-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-m-emphasis-margin-top: 0px;
  --spectrum-code-m-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-m-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-m-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-m-emphasis-text-transform: none;
  --spectrum-code-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-m-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-code-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-m-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-m-emphasis-strong-margin-top: 0px;
  --spectrum-code-m-emphasis-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-m-emphasis-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-m-emphasis-strong-text-transform: none;
  --spectrum-code-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-m-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-code-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-m-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-m-strong-margin-top: 0px;
  --spectrum-code-m-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-m-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-m-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-m-strong-text-transform: none;
  --spectrum-code-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-m-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-m-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-code-m-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-s-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-s-margin-top: 0px;
  --spectrum-code-s-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-s-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-s-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-s-text-transform: none;
  --spectrum-code-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-s-margin-bottom-multiplier: 0em;
  --spectrum-code-s-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-code-s-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-s-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-s-emphasis-margin-top: 0px;
  --spectrum-code-s-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-s-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-s-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-s-emphasis-text-transform: none;
  --spectrum-code-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-s-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-s-emphasis-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-code-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-s-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-s-emphasis-strong-margin-top: 0px;
  --spectrum-code-s-emphasis-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-s-emphasis-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-s-emphasis-strong-text-transform: none;
  --spectrum-code-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-s-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-s-emphasis-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-code-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-s-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-s-strong-margin-top: 0px;
  --spectrum-code-s-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-s-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-s-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-s-strong-text-transform: none;
  --spectrum-code-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-s-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-s-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-code-s-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-xl-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-xl-margin-top: 0px;
  --spectrum-code-xl-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-xl-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-xl-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-xl-text-transform: none;
  --spectrum-code-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-xl-margin-bottom-multiplier: 0em;
  --spectrum-code-xl-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-code-xl-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-xl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-xl-emphasis-margin-top: 0px;
  --spectrum-code-xl-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-xl-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-xl-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-xl-emphasis-text-transform: none;
  --spectrum-code-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-xl-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-code-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-xl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-xl-emphasis-strong-margin-top: 0px;
  --spectrum-code-xl-emphasis-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-xl-emphasis-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-xl-emphasis-strong-text-transform: none;
  --spectrum-code-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-xl-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-code-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-xl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-xl-strong-margin-top: 0px;
  --spectrum-code-xl-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-xl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-xl-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-xl-strong-text-transform: none;
  --spectrum-code-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-xl-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-xl-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-code-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-xs-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-xs-margin-top: 0px;
  --spectrum-code-xs-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-xs-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-xs-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-xs-text-transform: none;
  --spectrum-code-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-xs-margin-bottom-multiplier: 0em;
  --spectrum-code-xs-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-code-xs-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-xs-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-xs-emphasis-margin-top: 0px;
  --spectrum-code-xs-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-xs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-xs-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-code-xs-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-xs-emphasis-text-transform: none;
  --spectrum-code-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-xs-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-xs-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-code-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-xs-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-xs-emphasis-strong-margin-top: 0px;
  --spectrum-code-xs-emphasis-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-code-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-xs-emphasis-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-xs-emphasis-strong-text-transform: none;
  --spectrum-code-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-xs-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-xs-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-code-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-xs-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-xs-strong-margin-top: 0px;
  --spectrum-code-xs-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-code-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-xs-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-code-xs-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-code-xs-strong-text-transform: none;
  --spectrum-code-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-code-xs-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-xs-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-code-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-l-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-l-margin-top: 0px;
  --spectrum-code-han-l-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-l-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-code-han-l-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-l-text-transform: none;
  --spectrum-code-han-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-l-margin-bottom-multiplier: 0em;
  --spectrum-code-han-l-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-code-han-l-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-l-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-l-emphasis-margin-top: 0px;
  --spectrum-code-han-l-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-l-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-l-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-code-han-l-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-l-emphasis-text-transform: none;
  --spectrum-code-han-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-l-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-han-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-code-han-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-l-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-l-emphasis-strong-margin-top: 0px;
  --spectrum-code-han-l-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-l-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-l-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-l-emphasis-strong-text-transform: none;
  --spectrum-code-han-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-l-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-code-han-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-l-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-l-strong-margin-top: 0px;
  --spectrum-code-han-l-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-l-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-l-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-l-strong-text-transform: none;
  --spectrum-code-han-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-l-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-l-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-code-han-l-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-m-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-m-margin-top: 0px;
  --spectrum-code-han-m-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-m-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-code-han-m-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-m-text-transform: none;
  --spectrum-code-han-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-m-margin-bottom-multiplier: 0em;
  --spectrum-code-han-m-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-code-han-m-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-m-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-m-emphasis-margin-top: 0px;
  --spectrum-code-han-m-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-m-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-m-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-code-han-m-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-m-emphasis-text-transform: none;
  --spectrum-code-han-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-m-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-han-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-code-han-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-m-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-m-emphasis-strong-margin-top: 0px;
  --spectrum-code-han-m-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-m-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-m-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-m-emphasis-strong-text-transform: none;
  --spectrum-code-han-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-m-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-code-han-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-m-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-m-strong-margin-top: 0px;
  --spectrum-code-han-m-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-m-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-m-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-m-strong-text-transform: none;
  --spectrum-code-han-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-m-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-m-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-code-han-m-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-s-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-s-margin-top: 0px;
  --spectrum-code-han-s-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-s-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-code-han-s-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-s-text-transform: none;
  --spectrum-code-han-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-s-margin-bottom-multiplier: 0em;
  --spectrum-code-han-s-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-code-han-s-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-s-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-s-emphasis-margin-top: 0px;
  --spectrum-code-han-s-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-s-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-s-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-code-han-s-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-s-emphasis-text-transform: none;
  --spectrum-code-han-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-s-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-han-s-emphasis-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-code-han-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-s-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-s-emphasis-strong-margin-top: 0px;
  --spectrum-code-han-s-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-s-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-s-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-s-emphasis-strong-text-transform: none;
  --spectrum-code-han-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-s-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-s-emphasis-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-code-han-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-s-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-s-strong-margin-top: 0px;
  --spectrum-code-han-s-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-s-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-s-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-s-strong-text-transform: none;
  --spectrum-code-han-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-s-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-s-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-code-han-s-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-xl-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-xl-margin-top: 0px;
  --spectrum-code-han-xl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-xl-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-code-han-xl-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-xl-text-transform: none;
  --spectrum-code-han-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-xl-margin-bottom-multiplier: 0em;
  --spectrum-code-han-xl-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-code-han-xl-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-xl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-xl-emphasis-margin-top: 0px;
  --spectrum-code-han-xl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-xl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-xl-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-code-han-xl-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-xl-emphasis-text-transform: none;
  --spectrum-code-han-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-xl-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-han-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-code-han-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-xl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-xl-emphasis-strong-margin-top: 0px;
  --spectrum-code-han-xl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-xl-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-xl-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-xl-emphasis-strong-text-transform: none;
  --spectrum-code-han-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-xl-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-code-han-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-xl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-xl-strong-margin-top: 0px;
  --spectrum-code-han-xl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-xl-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-xl-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-xl-strong-text-transform: none;
  --spectrum-code-han-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-xl-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-xl-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-code-han-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-xs-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-xs-margin-top: 0px;
  --spectrum-code-han-xs-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-xs-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-code-han-xs-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-xs-text-transform: none;
  --spectrum-code-han-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-xs-margin-bottom-multiplier: 0em;
  --spectrum-code-han-xs-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-code-han-xs-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-xs-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-xs-emphasis-margin-top: 0px;
  --spectrum-code-han-xs-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-xs-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-xs-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-code-han-xs-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-xs-emphasis-text-transform: none;
  --spectrum-code-han-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-xs-emphasis-margin-bottom-multiplier: 0em;
  --spectrum-code-han-xs-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-code-han-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-xs-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-xs-emphasis-strong-margin-top: 0px;
  --spectrum-code-han-xs-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-xs-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-xs-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-xs-emphasis-strong-text-transform: none;
  --spectrum-code-han-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-xs-emphasis-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-xs-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-code-han-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
  --spectrum-code-han-xs-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-code-han-xs-strong-margin-top: 0px;
  --spectrum-code-han-xs-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-code-han-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-code-han-xs-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-code-han-xs-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-code-han-xs-strong-text-transform: none;
  --spectrum-code-han-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-code-han-xs-strong-margin-bottom-multiplier: 0em;
  --spectrum-code-han-xs-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-code-han-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-0);
}
