.spectrum {
  --spectrum-progresscircle-medium-indeterminate-text-color: var(--spectrum-alias-text-color);
  --spectrum-progresscircle-medium-indeterminate-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-progresscircle-medium-indeterminate-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-progresscircle-medium-indeterminate-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-medium-indeterminate-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-medium-indeterminate-height: var(--spectrum-global-dimension-size-400);
  --spectrum-progresscircle-medium-indeterminate-width: var(--spectrum-global-dimension-size-400);
  --spectrum-progresscircle-medium-indeterminate-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-progresscircle-medium-indeterminate-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
}
