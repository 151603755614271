.spectrum {
  --spectrum-table-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-table-border-color: var(--spectrum-alias-border-color-mid);
  --spectrum-table-divider-border-color: var(--spectrum-alias-border-color-mid);
  --spectrum-table-row-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-table-header-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-table-header-sort-icon-color: var(--spectrum-global-color-gray-600);
  --spectrum-table-header-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-table-cell-text-color: var(--spectrum-alias-text-color);
  --spectrum-table-cell-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-table-cell-border-color: var(--spectrum-global-color-gray-300);
  --spectrum-table-row-background-color-hover: var(--spectrum-alias-highlight-hover);
  --spectrum-table-header-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-table-header-sort-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-table-cell-background-color-hover: var(--spectrum-global-color-gray-100);
  --spectrum-table-row-background-color-down: var(--spectrum-alias-highlight-active);
  --spectrum-table-header-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-table-header-sort-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-table-cell-background-color-down: var(--spectrum-global-color-gray-200);
  --spectrum-table-header-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-table-header-sort-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-table-row-background-color-key-focus: var(--spectrum-alias-highlight-active);
  --spectrum-table-header-border-radius-key-focus: var(--spectrum-alias-border-radius-regular);
  --spectrum-table-header-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-table-header-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-table-cell-border-radius-key-focus: var(--spectrum-alias-border-radius-regular);
  --spectrum-table-cell-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-table-cell-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-table-row-background-color-selected: var(--spectrum-alias-highlight-selected);
  --spectrum-table-cell-background-color-selected: var(--spectrum-global-color-gray-100);
  --spectrum-table-row-background-color-selected-hover: var(--spectrum-alias-highlight-selected-hover);
  --spectrum-table-cell-background-color-selected-hover: var(--spectrum-global-color-gray-200);
  --spectrum-table-row-background-color-selected-key-focus: var(--spectrum-alias-highlight-selected-hover);
  --spectrum-table-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-table-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-table-border-y-size: var(--spectrum-alias-border-size-thin);
  --spectrum-table-border-x-size: var(--spectrum-alias-border-size-thin);
  --spectrum-table-divider-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-table-header-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-table-header-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-table-header-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-table-header-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-table-header-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-table-header-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-table-header-padding-y: var(--spectrum-global-dimension-static-size-125);
  --spectrum-table-header-sort-icon-gap: var(--spectrum-global-dimension-size-125);
  --spectrum-table-header-sort-icon-width: var(--spectrum-global-dimension-size-125);
  --spectrum-table-header-sort-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-table-header-min-height: var(--spectrum-global-dimension-size-150);
  --spectrum-table-header-border-radius: 0px;
  --spectrum-table-cell-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-table-cell-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-table-cell-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-table-cell-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-table-cell-padding-y: var(--spectrum-global-dimension-size-175);
  --spectrum-table-cell-checkbox-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-table-cell-checkbox-vertical-alignment: middle;
  --spectrum-table-cell-min-height: var(--spectrum-global-dimension-size-600);
  --spectrum-table-cell-vertical-alignment: top;
  --spectrum-table-cell-border-radius: 0px;
  --spectrum-table-cell-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-table-cell-padding: var(--spectrum-global-dimension-static-size-200);
  --spectrum-table-header-height: var(--spectrum-global-dimension-size-150);
  --spectrum-table-header-sort-icon-margin-left: var(--spectrum-global-dimension-static-size-50);
  --spectrum-table-cell-padding-vertical: var(--spectrum-global-dimension-static-size-150);
  --spectrum-table-quiet-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-table-quiet-border-color: var(--spectrum-alias-border-color-mid);
  --spectrum-table-quiet-divider-border-color: var(--spectrum-alias-border-color-mid);
  --spectrum-table-quiet-row-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-table-quiet-header-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-table-quiet-header-sort-icon-color: var(--spectrum-global-color-gray-600);
  --spectrum-table-quiet-header-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-table-quiet-cell-text-color: var(--spectrum-alias-text-color);
  --spectrum-table-quiet-cell-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-table-quiet-row-background-color-hover: var(--spectrum-alias-highlight-hover);
  --spectrum-table-quiet-header-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-table-quiet-header-sort-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-table-quiet-cell-background-color-hover: var(--spectrum-global-color-gray-200);
  --spectrum-table-quiet-row-background-color-down: var(--spectrum-alias-highlight-active);
  --spectrum-table-quiet-header-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-table-quiet-header-sort-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-table-quiet-cell-background-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-table-quiet-header-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-table-quiet-header-sort-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-table-quiet-row-background-color-key-focus: var(--spectrum-alias-highlight-active);
  --spectrum-table-quiet-header-border-radius-key-focus: var(--spectrum-alias-border-radius-regular);
  --spectrum-table-quiet-header-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-table-quiet-header-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-table-quiet-cell-border-radius-key-focus: var(--spectrum-alias-border-radius-regular);
  --spectrum-table-quiet-cell-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-table-quiet-cell-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-table-quiet-row-background-color-selected: var(--spectrum-alias-highlight-selected);
  --spectrum-table-quiet-cell-background-color-selected: var(--spectrum-global-color-gray-200);
  --spectrum-table-quiet-row-background-color-selected-hover: var(--spectrum-alias-highlight-selected-hover);
  --spectrum-table-quiet-cell-background-color-selected-hover: var(--spectrum-global-color-gray-300);
  --spectrum-table-quiet-row-background-color-selected-key-focus: var(--spectrum-alias-highlight-selected-hover);
  --spectrum-table-quiet-border-radius: 0px;
  --spectrum-table-quiet-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-table-quiet-border-y-size: 0px;
  --spectrum-table-quiet-border-x-size: var(--spectrum-alias-border-size-thin);
  --spectrum-table-quiet-divider-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-table-quiet-header-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-table-quiet-header-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-table-quiet-header-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-table-quiet-header-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-table-quiet-header-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-table-quiet-header-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-table-quiet-header-padding-y: var(--spectrum-global-dimension-static-size-125);
  --spectrum-table-quiet-header-sort-icon-gap: var(--spectrum-global-dimension-size-125);
  --spectrum-table-quiet-header-sort-icon-width: var(--spectrum-global-dimension-size-125);
  --spectrum-table-quiet-header-sort-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-table-quiet-header-min-height: var(--spectrum-global-dimension-size-150);
  --spectrum-table-quiet-header-border-radius: 0px;
  --spectrum-table-quiet-cell-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-table-quiet-cell-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-table-quiet-cell-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-table-quiet-cell-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-table-quiet-cell-padding-y: var(--spectrum-global-dimension-size-175);
  --spectrum-table-quiet-cell-checkbox-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-table-quiet-cell-checkbox-vertical-alignment: middle;
  --spectrum-table-quiet-cell-min-height: var(--spectrum-global-dimension-size-600);
  --spectrum-table-quiet-cell-vertical-alignment: top;
  --spectrum-table-quiet-cell-border-radius: 0px;
}
