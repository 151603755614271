.spectrum {
  --spectrum-slider-color-handle-outset-border-color: var(--spectrum-alias-border-color-translucent-dark);
  --spectrum-slider-color-handle-inset-border-color: var(--spectrum-alias-border-color-translucent-dark);
  --spectrum-slider-color-handle-border-color: var(--spectrum-global-color-gray-50);
  --spectrum-slider-color-handle-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-slider-color-track-background-color-gradient-start: var(--spectrum-global-color-blue-400);
  --spectrum-slider-color-track-background-color-gradient-end: var(--spectrum-global-color-blue-700);
  --spectrum-slider-color-track-border-color: var(--spectrum-alias-border-color-translucent-dark);
  --spectrum-slider-color-handle-outset-border-color-key-focus: var(--spectrum-alias-border-color-translucent-dark);
  --spectrum-slider-color-track-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-slider-color-handle-border-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-slider-color-handle-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-slider-color-handle-outset-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-slider-color-handle-inset-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-slider-color-track-height: var(--spectrum-global-dimension-static-size-300);
  --spectrum-slider-color-track-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-slider-handle-border-color: var(--spectrum-global-color-gray-700);
  --spectrum-slider-handle-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-track-color: var(--spectrum-global-color-gray-300);
  --spectrum-slider-handle-border-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-slider-handle-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-track-color-hover: var(--spectrum-global-color-gray-300);
  --spectrum-slider-handle-border-size-down: var(--spectrum-global-dimension-size-75);
  --spectrum-slider-handle-border-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-slider-handle-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-track-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-slider-radial-reaction-color-down: var(--spectrum-alias-radial-reaction-color-default);
  --spectrum-slider-handle-border-size-key-focus: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-handle-border-color-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-slider-handle-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-track-color-key-focus: var(--spectrum-global-color-gray-300);
  --spectrum-slider-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-slider-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-slider-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-slider-handle-border-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-slider-handle-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-track-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-slider-track-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-slider-track-height: var(--spectrum-alias-border-size-thick);
  --spectrum-slider-handle-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-slider-handle-gap: var(--spectrum-alias-border-size-thicker);
  --spectrum-slider-label-text-alignment: left;
  --spectrum-slider-label-min-width: var(--spectrum-global-dimension-size-600);
  --spectrum-slider-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-slider-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-slider-handle-width: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-handle-height: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-handle-border-radius: var(--spectrum-alias-border-radius-medium);
  --spectrum-slider-height: var(--spectrum-alias-single-line-height);
  --spectrum-slider-min-width: var(--spectrum-global-dimension-size-1250);
  --spectrum-slider-label-gap-y: 0px;
  --spectrum-slider-label-gap-x: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-text-baseline: var(--spectrum-global-dimension-font-size-75);
  --spectrum-slider-track-cursor-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-track-cursor-hit-y: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-hot-text-cursor-hit-y: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-track-touch-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-radial-reaction-size: var(--spectrum-alias-radial-reaction-size-default);
  --spectrum-slider-editable-handle-border-color: var(--spectrum-global-color-gray-700);
  --spectrum-slider-editable-handle-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-editable-track-color: var(--spectrum-global-color-gray-300);
  --spectrum-slider-editable-handle-border-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-slider-editable-handle-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-editable-track-color-hover: var(--spectrum-global-color-gray-300);
  --spectrum-slider-editable-handle-border-size-down: var(--spectrum-global-dimension-size-75);
  --spectrum-slider-editable-handle-border-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-slider-editable-handle-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-editable-track-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-slider-editable-radial-reaction-color-down: var(--spectrum-alias-radial-reaction-color-default);
  --spectrum-slider-editable-handle-border-size-key-focus: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-editable-handle-border-color-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-slider-editable-handle-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-editable-track-color-key-focus: var(--spectrum-global-color-gray-300);
  --spectrum-slider-editable-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-slider-editable-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-slider-editable-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-slider-editable-handle-border-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-slider-editable-handle-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-editable-track-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-slider-editable-track-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-slider-editable-track-height: var(--spectrum-alias-border-size-thick);
  --spectrum-slider-editable-handle-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-slider-editable-handle-gap: var(--spectrum-alias-border-size-thicker);
  --spectrum-slider-editable-label-text-alignment: left;
  --spectrum-slider-editable-label-min-width: var(--spectrum-global-dimension-size-600);
  --spectrum-slider-editable-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-slider-editable-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-slider-editable-handle-width: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-editable-handle-height: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-editable-handle-border-radius: var(--spectrum-alias-border-radius-medium);
  --spectrum-slider-editable-height: var(--spectrum-alias-single-line-height);
  --spectrum-slider-editable-min-width: var(--spectrum-global-dimension-size-1250);
  --spectrum-slider-editable-label-gap-y: 0px;
  --spectrum-slider-editable-label-gap-x: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-editable-text-baseline: var(--spectrum-global-dimension-font-size-75);
  --spectrum-slider-editable-track-cursor-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-editable-track-cursor-hit-y: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-editable-hot-text-cursor-hit-y: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-editable-track-touch-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-editable-radial-reaction-size: var(--spectrum-alias-radial-reaction-size-default);
  --spectrum-slider-editable-textfield-min-width: var(--spectrum-global-dimension-size-600);
  --spectrum-slider-fill-handle-border-color: var(--spectrum-global-color-gray-700);
  --spectrum-slider-fill-handle-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-fill-track-color: var(--spectrum-global-color-gray-700);
  --spectrum-slider-fill-handle-border-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-slider-fill-handle-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-fill-track-color-hover: var(--spectrum-global-color-gray-700);
  --spectrum-slider-fill-handle-border-size-down: var(--spectrum-global-dimension-size-75);
  --spectrum-slider-fill-handle-border-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-slider-fill-handle-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-fill-track-color-down: var(--spectrum-global-color-gray-700);
  --spectrum-slider-fill-radial-reaction-color-down: var(--spectrum-alias-radial-reaction-color-default);
  --spectrum-slider-fill-handle-border-size-key-focus: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-fill-handle-border-color-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-slider-fill-handle-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-fill-track-color-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-slider-fill-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-slider-fill-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-slider-fill-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-slider-fill-handle-border-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-slider-fill-handle-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-slider-fill-track-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-slider-fill-track-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-slider-fill-track-height: var(--spectrum-alias-border-size-thick);
  --spectrum-slider-fill-handle-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-slider-fill-handle-gap: var(--spectrum-alias-border-size-thicker);
  --spectrum-slider-fill-label-text-alignment: left;
  --spectrum-slider-fill-label-min-width: var(--spectrum-global-dimension-size-600);
  --spectrum-slider-fill-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-slider-fill-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-slider-fill-handle-width: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-fill-handle-height: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-fill-handle-border-radius: var(--spectrum-alias-border-radius-medium);
  --spectrum-slider-fill-height: var(--spectrum-alias-single-line-height);
  --spectrum-slider-fill-min-width: var(--spectrum-global-dimension-size-1250);
  --spectrum-slider-fill-label-gap-y: 0px;
  --spectrum-slider-fill-label-gap-x: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-fill-text-baseline: var(--spectrum-global-dimension-font-size-75);
  --spectrum-slider-fill-track-cursor-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-fill-track-cursor-hit-y: var(--spectrum-global-dimension-size-200);
  --spectrum-slider-fill-hot-text-cursor-hit-y: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-fill-track-touch-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-slider-fill-radial-reaction-size: var(--spectrum-alias-radial-reaction-size-default);
  --spectrum-slider-player-track-buffer-color: var(--spectrum-global-color-gray-500);
  --spectrum-slider-player-track-buffer-color-disabled: var(--spectrum-alias-track-color-default);
  --spectrum-slider-ramp-track-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-slider-ramp-track-height: var(--spectrum-global-dimension-static-size-200);
  --spectrum-slider-tick-mark-color: var(--spectrum-alias-track-color-default);
  --spectrum-slider-tick-mark-margin-x: 1px;
  --spectrum-slider-tick-mark-height: 10px;
  --spectrum-slider-tick-mark-width: var(--spectrum-alias-border-size-thick);
  --spectrum-slider-tick-mark-border-radius: var(--spectrum-alias-border-radius-xsmall);
}
