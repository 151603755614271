.spectrum {
  --spectrum-toast-background-color: var(--spectrum-global-color-static-gray-700);
  --spectrum-toast-text-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-rule-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-toast-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-toast-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-toast-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-toast-text-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-toast-min-height: var(--spectrum-global-dimension-size-600);
  --spectrum-toast-max-width: var(--spectrum-global-dimension-size-6000);
  --spectrum-toast-border-radius: var(--spectrum-global-dimension-static-size-50);
  --spectrum-toast-padding-left: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-margin-x: var(--spectrum-global-dimension-static-size-100);
  --spectrum-toast-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-toast-icon-padding-right: var(--spectrum-global-dimension-size-150);
  --spectrum-toast-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-toast-button-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-button-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-button-gap-y: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-button-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-rule-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-toast-rule-height: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-content-padding-right: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-content-padding-top: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-content-padding-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-content-padding-bottom-action: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-dismiss-duration: 5000ms;
  --spectrum-toast-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-entry-animation-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-entry-animation-opacity: 0%;
  --spectrum-toast-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-toast-entry-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-entry-animation-properties: opacity, offset;
  --spectrum-toast-entry-animation-keyframe-0-opacity: 0%;
  --spectrum-toast-entry-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-entry-animation-keyframe-66-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-entry-animation-keyframe-66-offset-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-toast-entry-animation-keyframe-100-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-entry-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-exit-animation-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-exit-animation-opacity: 0%;
  --spectrum-toast-exit-animation-duration: var(--spectrum-global-animation-duration-400);
  --spectrum-toast-exit-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-exit-animation-keyframe-0-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-exit-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-exit-animation-keyframe-100-opacity: 0%;
  --spectrum-toast-exit-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-error-background-color: var(--spectrum-global-color-static-red-700);
  --spectrum-toast-info-background-color: var(--spectrum-global-color-static-blue-700);
  --spectrum-toast-info-text-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-info-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-info-rule-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-toast-info-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-toast-info-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-toast-info-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-toast-info-text-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-toast-info-min-height: var(--spectrum-global-dimension-size-600);
  --spectrum-toast-info-max-width: var(--spectrum-global-dimension-size-6000);
  --spectrum-toast-info-border-radius: var(--spectrum-global-dimension-static-size-50);
  --spectrum-toast-info-padding-left: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-info-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-info-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-info-margin-x: var(--spectrum-global-dimension-static-size-100);
  --spectrum-toast-info-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-toast-info-icon-padding-right: var(--spectrum-global-dimension-size-150);
  --spectrum-toast-info-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-toast-info-button-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-info-button-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-info-button-gap-y: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-info-button-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-info-rule-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-toast-info-rule-height: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-info-content-padding-right: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-info-content-padding-top: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-info-content-padding-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-info-content-padding-bottom-action: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-info-dismiss-duration: 5000ms;
  --spectrum-toast-info-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-info-entry-animation-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-info-entry-animation-opacity: 0%;
  --spectrum-toast-info-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-toast-info-entry-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-info-entry-animation-properties: opacity, offset;
  --spectrum-toast-info-entry-animation-keyframe-0-opacity: 0%;
  --spectrum-toast-info-entry-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-info-entry-animation-keyframe-66-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-info-entry-animation-keyframe-66-offset-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-toast-info-entry-animation-keyframe-100-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-info-entry-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-info-exit-animation-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-info-exit-animation-opacity: 0%;
  --spectrum-toast-info-exit-animation-duration: var(--spectrum-global-animation-duration-400);
  --spectrum-toast-info-exit-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-info-exit-animation-keyframe-0-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-info-exit-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-info-exit-animation-keyframe-100-opacity: 0%;
  --spectrum-toast-info-exit-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-negative-background-color: var(--spectrum-global-color-static-red-700);
  --spectrum-toast-negative-text-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-negative-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-negative-rule-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-toast-negative-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-toast-negative-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-toast-negative-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-toast-negative-text-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-toast-negative-min-height: var(--spectrum-global-dimension-size-600);
  --spectrum-toast-negative-max-width: var(--spectrum-global-dimension-size-6000);
  --spectrum-toast-negative-border-radius: var(--spectrum-global-dimension-static-size-50);
  --spectrum-toast-negative-padding-left: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-negative-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-negative-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-negative-margin-x: var(--spectrum-global-dimension-static-size-100);
  --spectrum-toast-negative-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-toast-negative-icon-padding-right: var(--spectrum-global-dimension-size-150);
  --spectrum-toast-negative-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-toast-negative-button-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-negative-button-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-negative-button-gap-y: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-negative-button-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-negative-rule-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-toast-negative-rule-height: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-negative-content-padding-right: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-negative-content-padding-top: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-negative-content-padding-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-negative-content-padding-bottom-action: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-negative-dismiss-duration: 5000ms;
  --spectrum-toast-negative-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-negative-entry-animation-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-negative-entry-animation-opacity: 0%;
  --spectrum-toast-negative-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-toast-negative-entry-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-negative-entry-animation-properties: opacity, offset;
  --spectrum-toast-negative-entry-animation-keyframe-0-opacity: 0%;
  --spectrum-toast-negative-entry-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-negative-entry-animation-keyframe-66-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-negative-entry-animation-keyframe-66-offset-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-toast-negative-entry-animation-keyframe-100-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-negative-entry-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-negative-exit-animation-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-negative-exit-animation-opacity: 0%;
  --spectrum-toast-negative-exit-animation-duration: var(--spectrum-global-animation-duration-400);
  --spectrum-toast-negative-exit-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-negative-exit-animation-keyframe-0-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-negative-exit-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-negative-exit-animation-keyframe-100-opacity: 0%;
  --spectrum-toast-negative-exit-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-neutral-background-color: var(--spectrum-global-color-static-gray-700);
  --spectrum-toast-neutral-text-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-neutral-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-neutral-rule-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-toast-neutral-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-toast-neutral-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-toast-neutral-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-toast-neutral-text-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-toast-neutral-min-height: var(--spectrum-global-dimension-size-600);
  --spectrum-toast-neutral-max-width: var(--spectrum-global-dimension-size-6000);
  --spectrum-toast-neutral-border-radius: var(--spectrum-global-dimension-static-size-50);
  --spectrum-toast-neutral-padding-left: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-neutral-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-neutral-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-neutral-margin-x: var(--spectrum-global-dimension-static-size-100);
  --spectrum-toast-neutral-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-toast-neutral-icon-padding-right: var(--spectrum-global-dimension-size-150);
  --spectrum-toast-neutral-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-toast-neutral-button-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-neutral-button-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-neutral-button-gap-y: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-neutral-button-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-neutral-rule-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-toast-neutral-rule-height: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-neutral-content-padding-right: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-neutral-content-padding-top: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-neutral-content-padding-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-neutral-content-padding-bottom-action: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-neutral-dismiss-duration: 5000ms;
  --spectrum-toast-neutral-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-neutral-entry-animation-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-neutral-entry-animation-opacity: 0%;
  --spectrum-toast-neutral-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-toast-neutral-entry-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-neutral-entry-animation-properties: opacity, offset;
  --spectrum-toast-neutral-entry-animation-keyframe-0-opacity: 0%;
  --spectrum-toast-neutral-entry-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-neutral-entry-animation-keyframe-66-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-neutral-entry-animation-keyframe-66-offset-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-toast-neutral-entry-animation-keyframe-100-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-neutral-entry-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-neutral-exit-animation-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-neutral-exit-animation-opacity: 0%;
  --spectrum-toast-neutral-exit-animation-duration: var(--spectrum-global-animation-duration-400);
  --spectrum-toast-neutral-exit-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-neutral-exit-animation-keyframe-0-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-neutral-exit-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-neutral-exit-animation-keyframe-100-opacity: 0%;
  --spectrum-toast-neutral-exit-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-positive-background-color: var(--spectrum-global-color-static-green-700);
  --spectrum-toast-positive-text-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-positive-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-toast-positive-rule-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-toast-positive-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-toast-positive-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-toast-positive-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-toast-positive-text-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-toast-positive-min-height: var(--spectrum-global-dimension-size-600);
  --spectrum-toast-positive-max-width: var(--spectrum-global-dimension-size-6000);
  --spectrum-toast-positive-border-radius: var(--spectrum-global-dimension-static-size-50);
  --spectrum-toast-positive-padding-left: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-positive-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-positive-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-positive-margin-x: var(--spectrum-global-dimension-static-size-100);
  --spectrum-toast-positive-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-toast-positive-icon-padding-right: var(--spectrum-global-dimension-size-150);
  --spectrum-toast-positive-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-toast-positive-button-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-positive-button-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-positive-button-gap-y: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-positive-button-padding-y: var(--spectrum-global-dimension-size-100);
  --spectrum-toast-positive-rule-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-toast-positive-rule-height: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-positive-content-padding-right: var(--spectrum-global-dimension-size-200);
  --spectrum-toast-positive-content-padding-top: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-positive-content-padding-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-toast-positive-content-padding-bottom-action: var(--spectrum-global-dimension-size-50);
  --spectrum-toast-positive-dismiss-duration: 5000ms;
  --spectrum-toast-positive-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-positive-entry-animation-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-positive-entry-animation-opacity: 0%;
  --spectrum-toast-positive-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-toast-positive-entry-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-positive-entry-animation-properties: opacity, offset;
  --spectrum-toast-positive-entry-animation-keyframe-0-opacity: 0%;
  --spectrum-toast-positive-entry-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-positive-entry-animation-keyframe-66-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-positive-entry-animation-keyframe-66-offset-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-toast-positive-entry-animation-keyframe-100-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-positive-entry-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-positive-exit-animation-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-positive-exit-animation-opacity: 0%;
  --spectrum-toast-positive-exit-animation-duration: var(--spectrum-global-animation-duration-400);
  --spectrum-toast-positive-exit-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-toast-positive-exit-animation-keyframe-0-opacity: var(--spectrum-global-dimension-static-percent-100);
  --spectrum-toast-positive-exit-animation-keyframe-0-offset-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-toast-positive-exit-animation-keyframe-100-opacity: 0%;
  --spectrum-toast-positive-exit-animation-keyframe-100-offset-y: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-toast-success-background-color: var(--spectrum-global-color-static-green-700);
  --spectrum-toast-warning-background-color: var(--spectrum-global-color-static-orange-700);
}
