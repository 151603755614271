.spectrum {
  --spectrum-display-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-1-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-1-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-display-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-1-text-transform: none;
  --spectrum-display-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-1-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-1-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-1-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-2-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-2-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-display-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-2-text-transform: none;
  --spectrum-display-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-2-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-2-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-2-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-article-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-article-1-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-article-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-article-1-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-display-article-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-article-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-article-1-text-transform: none;
  --spectrum-display-article-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-article-1-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-article-1-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-article-1-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-article-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-article-1-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-article-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-article-1-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-display-article-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-article-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-article-1-emphasis-text-transform: none;
  --spectrum-display-article-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-article-1-emphasis-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-article-1-emphasis-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-article-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-article-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-article-1-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-article-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-article-1-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-display-article-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-article-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-article-1-strong-text-transform: none;
  --spectrum-display-article-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-article-1-strong-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-article-1-strong-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-article-1-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-article-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-article-2-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-article-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-article-2-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-display-article-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-article-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-article-2-text-transform: none;
  --spectrum-display-article-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-article-2-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-article-2-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-article-2-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-article-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-article-2-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-article-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-article-2-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-display-article-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-article-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-article-2-emphasis-text-transform: none;
  --spectrum-display-article-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-article-2-emphasis-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-article-2-emphasis-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-article-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-article-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-article-2-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-article-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-article-2-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-display-article-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-article-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-article-2-strong-text-transform: none;
  --spectrum-display-article-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-article-2-strong-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-article-2-strong-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-article-2-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-quiet-article-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-article-1-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-quiet-article-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-article-1-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet);
  --spectrum-display-quiet-article-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-article-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-quiet-article-1-text-transform: none;
  --spectrum-display-quiet-article-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-article-1-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-quiet-article-1-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-quiet-article-1-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-quiet-article-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-article-1-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-quiet-article-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-quiet-article-1-emphasis-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet);
  --spectrum-display-quiet-article-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-article-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-quiet-article-1-emphasis-text-transform: none;
  --spectrum-display-quiet-article-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-article-1-emphasis-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-quiet-article-1-emphasis-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-quiet-article-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-quiet-article-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-article-1-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-quiet-article-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-article-1-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet-strong);
  --spectrum-display-quiet-article-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-article-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-quiet-article-1-strong-text-transform: none;
  --spectrum-display-quiet-article-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-article-1-strong-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-quiet-article-1-strong-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-quiet-article-1-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-quiet-article-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-article-2-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-quiet-article-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-article-2-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet);
  --spectrum-display-quiet-article-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-article-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-quiet-article-2-text-transform: none;
  --spectrum-display-quiet-article-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-article-2-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-quiet-article-2-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-quiet-article-2-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-quiet-article-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-article-2-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-quiet-article-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-quiet-article-2-emphasis-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet);
  --spectrum-display-quiet-article-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-article-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-quiet-article-2-emphasis-text-transform: none;
  --spectrum-display-quiet-article-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-article-2-emphasis-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-quiet-article-2-emphasis-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-quiet-article-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-quiet-article-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-article-2-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-display-quiet-article-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-article-2-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet-strong);
  --spectrum-display-quiet-article-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-article-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-display-quiet-article-2-strong-text-transform: none;
  --spectrum-display-quiet-article-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-article-2-strong-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-quiet-article-2-strong-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-quiet-article-2-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-1-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-1-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-display-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-1-emphasis-text-transform: none;
  --spectrum-display-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-1-emphasis-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-1-emphasis-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-1-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-1-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular-strong);
  --spectrum-display-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-1-strong-text-transform: none;
  --spectrum-display-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-1-strong-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-1-strong-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-1-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-2-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-2-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-display-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-2-emphasis-text-transform: none;
  --spectrum-display-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-2-emphasis-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-2-emphasis-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-2-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-2-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular-strong);
  --spectrum-display-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-2-strong-text-transform: none;
  --spectrum-display-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-2-strong-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-2-strong-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-2-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-quiet-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-1-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-quiet-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-1-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  --spectrum-display-quiet-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-quiet-1-text-transform: none;
  --spectrum-display-quiet-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-1-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-quiet-1-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-quiet-1-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-quiet-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-1-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-quiet-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-quiet-1-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  --spectrum-display-quiet-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-quiet-1-emphasis-text-transform: none;
  --spectrum-display-quiet-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-1-emphasis-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-quiet-1-emphasis-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-quiet-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-quiet-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-1-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-quiet-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-1-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet-strong);
  --spectrum-display-quiet-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-quiet-1-strong-text-transform: none;
  --spectrum-display-quiet-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-1-strong-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-quiet-1-strong-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-quiet-1-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-quiet-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-2-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-quiet-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-2-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  --spectrum-display-quiet-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-quiet-2-text-transform: none;
  --spectrum-display-quiet-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-2-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-quiet-2-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-quiet-2-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-quiet-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-2-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-quiet-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-quiet-2-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  --spectrum-display-quiet-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-quiet-2-emphasis-text-transform: none;
  --spectrum-display-quiet-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-2-emphasis-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-quiet-2-emphasis-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-quiet-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-quiet-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-2-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-quiet-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-2-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet-strong);
  --spectrum-display-quiet-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-quiet-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-quiet-2-strong-text-transform: none;
  --spectrum-display-quiet-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-2-strong-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-quiet-2-strong-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-quiet-2-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-strong-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-1-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-strong-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-1-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong);
  --spectrum-display-strong-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-strong-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-strong-1-text-transform: none;
  --spectrum-display-strong-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-1-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-strong-1-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-strong-1-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-strong-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-1-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-strong-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-strong-1-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong);
  --spectrum-display-strong-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-strong-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-strong-1-emphasis-text-transform: none;
  --spectrum-display-strong-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-1-emphasis-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-strong-1-emphasis-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-strong-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-strong-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-1-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-strong-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-1-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong-strong);
  --spectrum-display-strong-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-strong-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-strong-1-strong-text-transform: none;
  --spectrum-display-strong-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-1-strong-text-size: var(--spectrum-alias-heading-display1-text-size);
  --spectrum-display-strong-1-strong-margin-top: var(--spectrum-alias-heading-display1-margin-top);
  --spectrum-display-strong-1-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-display-strong-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-2-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-strong-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-2-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong);
  --spectrum-display-strong-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-strong-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-strong-2-text-transform: none;
  --spectrum-display-strong-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-2-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-strong-2-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-strong-2-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-strong-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-2-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-strong-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-display-strong-2-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong);
  --spectrum-display-strong-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-strong-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-strong-2-emphasis-text-transform: none;
  --spectrum-display-strong-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-2-emphasis-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-strong-2-emphasis-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-strong-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-strong-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-2-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-display-strong-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-2-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong-strong);
  --spectrum-display-strong-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-display-strong-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-display-strong-2-strong-text-transform: none;
  --spectrum-display-strong-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-2-strong-text-size: var(--spectrum-alias-heading-display2-text-size);
  --spectrum-display-strong-2-strong-margin-top: var(--spectrum-alias-heading-display2-margin-top);
  --spectrum-display-strong-2-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-han-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-han-1-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-han-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-han-1-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-display-han-1-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-han-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-han-1-text-transform: none;
  --spectrum-display-han-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-han-1-text-size: var(--spectrum-alias-heading-han-display1-text-size);
  --spectrum-display-han-1-margin-top: var(--spectrum-alias-heading-han-display1-margin-top);
  --spectrum-display-han-1-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-han-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-han-1-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-han-1-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-han-1-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-display-han-1-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-han-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-han-1-emphasis-text-transform: none;
  --spectrum-display-han-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-han-1-emphasis-text-size: var(--spectrum-alias-heading-han-display1-text-size);
  --spectrum-display-han-1-emphasis-margin-top: var(--spectrum-alias-heading-han-display1-margin-top);
  --spectrum-display-han-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-han-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-han-1-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-han-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-han-1-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-strong);
  --spectrum-display-han-1-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-han-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-han-1-strong-text-transform: none;
  --spectrum-display-han-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-han-1-strong-text-size: var(--spectrum-alias-heading-han-display1-text-size);
  --spectrum-display-han-1-strong-margin-top: var(--spectrum-alias-heading-han-display1-margin-top);
  --spectrum-display-han-1-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-han-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-han-2-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-han-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-han-2-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-display-han-2-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-han-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-han-2-text-transform: none;
  --spectrum-display-han-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-han-2-text-size: var(--spectrum-alias-heading-han-display2-text-size);
  --spectrum-display-han-2-margin-top: var(--spectrum-alias-heading-han-display2-margin-top);
  --spectrum-display-han-2-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-display-han-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-han-2-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-han-2-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-han-2-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-display-han-2-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-han-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-han-2-emphasis-text-transform: none;
  --spectrum-display-han-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-han-2-emphasis-text-size: var(--spectrum-alias-heading-han-display2-text-size);
  --spectrum-display-han-2-emphasis-margin-top: var(--spectrum-alias-heading-han-display2-margin-top);
  --spectrum-display-han-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-display-han-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-han-2-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-han-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-han-2-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-strong);
  --spectrum-display-han-2-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-han-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-han-2-strong-text-transform: none;
  --spectrum-display-han-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-han-2-strong-text-size: var(--spectrum-alias-heading-han-display2-text-size);
  --spectrum-display-han-2-strong-margin-top: var(--spectrum-alias-heading-han-display2-margin-top);
  --spectrum-display-han-2-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-display-quiet-han-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-han-1-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-quiet-han-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-han-1-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet);
  --spectrum-display-quiet-han-1-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-quiet-han-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-quiet-han-1-text-transform: none;
  --spectrum-display-quiet-han-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-han-1-text-size: var(--spectrum-alias-heading-han-display1-text-size);
  --spectrum-display-quiet-han-1-margin-top: var(--spectrum-alias-heading-han-display1-margin-top);
  --spectrum-display-quiet-han-1-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-quiet-han-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-han-1-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-quiet-han-1-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-han-1-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet-emphasis);
  --spectrum-display-quiet-han-1-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-quiet-han-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-quiet-han-1-emphasis-text-transform: none;
  --spectrum-display-quiet-han-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-han-1-emphasis-text-size: var(--spectrum-alias-heading-han-display1-text-size);
  --spectrum-display-quiet-han-1-emphasis-margin-top: var(--spectrum-alias-heading-han-display1-margin-top);
  --spectrum-display-quiet-han-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-quiet-han-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-han-1-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-quiet-han-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-han-1-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet-strong);
  --spectrum-display-quiet-han-1-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-quiet-han-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-quiet-han-1-strong-text-transform: none;
  --spectrum-display-quiet-han-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-han-1-strong-text-size: var(--spectrum-alias-heading-han-display1-text-size);
  --spectrum-display-quiet-han-1-strong-margin-top: var(--spectrum-alias-heading-han-display1-margin-top);
  --spectrum-display-quiet-han-1-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-quiet-han-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-han-2-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-quiet-han-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-han-2-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet);
  --spectrum-display-quiet-han-2-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-quiet-han-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-quiet-han-2-text-transform: none;
  --spectrum-display-quiet-han-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-han-2-text-size: var(--spectrum-alias-heading-han-display2-text-size);
  --spectrum-display-quiet-han-2-margin-top: var(--spectrum-alias-heading-han-display2-margin-top);
  --spectrum-display-quiet-han-2-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-display-quiet-han-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-han-2-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-quiet-han-2-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-han-2-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet-emphasis);
  --spectrum-display-quiet-han-2-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-quiet-han-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-quiet-han-2-emphasis-text-transform: none;
  --spectrum-display-quiet-han-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-han-2-emphasis-text-size: var(--spectrum-alias-heading-han-display2-text-size);
  --spectrum-display-quiet-han-2-emphasis-margin-top: var(--spectrum-alias-heading-han-display2-margin-top);
  --spectrum-display-quiet-han-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-display-quiet-han-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-quiet-han-2-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-quiet-han-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-quiet-han-2-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet-strong);
  --spectrum-display-quiet-han-2-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-quiet-han-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-quiet-han-2-strong-text-transform: none;
  --spectrum-display-quiet-han-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-quiet-han-2-strong-text-size: var(--spectrum-alias-heading-han-display2-text-size);
  --spectrum-display-quiet-han-2-strong-margin-top: var(--spectrum-alias-heading-han-display2-margin-top);
  --spectrum-display-quiet-han-2-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-display-strong-han-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-han-1-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-strong-han-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-han-1-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong);
  --spectrum-display-strong-han-1-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-strong-han-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-strong-han-1-text-transform: none;
  --spectrum-display-strong-han-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-han-1-text-size: var(--spectrum-alias-heading-han-display1-text-size);
  --spectrum-display-strong-han-1-margin-top: var(--spectrum-alias-heading-han-display1-margin-top);
  --spectrum-display-strong-han-1-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-strong-han-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-han-1-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-strong-han-1-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-han-1-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong-emphasis);
  --spectrum-display-strong-han-1-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-strong-han-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-strong-han-1-emphasis-text-transform: none;
  --spectrum-display-strong-han-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-han-1-emphasis-text-size: var(--spectrum-alias-heading-han-display1-text-size);
  --spectrum-display-strong-han-1-emphasis-margin-top: var(--spectrum-alias-heading-han-display1-margin-top);
  --spectrum-display-strong-han-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-strong-han-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-han-1-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-strong-han-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-han-1-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong-strong);
  --spectrum-display-strong-han-1-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-strong-han-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-strong-han-1-strong-text-transform: none;
  --spectrum-display-strong-han-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-han-1-strong-text-size: var(--spectrum-alias-heading-han-display1-text-size);
  --spectrum-display-strong-han-1-strong-margin-top: var(--spectrum-alias-heading-han-display1-margin-top);
  --spectrum-display-strong-han-1-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-display-strong-han-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-han-2-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-strong-han-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-han-2-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong);
  --spectrum-display-strong-han-2-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-strong-han-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-strong-han-2-text-transform: none;
  --spectrum-display-strong-han-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-han-2-text-size: var(--spectrum-alias-heading-han-display2-text-size);
  --spectrum-display-strong-han-2-margin-top: var(--spectrum-alias-heading-han-display2-margin-top);
  --spectrum-display-strong-han-2-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-display-strong-han-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-han-2-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-strong-han-2-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-han-2-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong-emphasis);
  --spectrum-display-strong-han-2-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-strong-han-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-strong-han-2-emphasis-text-transform: none;
  --spectrum-display-strong-han-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-han-2-emphasis-text-size: var(--spectrum-alias-heading-han-display2-text-size);
  --spectrum-display-strong-han-2-emphasis-margin-top: var(--spectrum-alias-heading-han-display2-margin-top);
  --spectrum-display-strong-han-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-display-strong-han-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-display-strong-han-2-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-display-strong-han-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-display-strong-han-2-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong-strong);
  --spectrum-display-strong-han-2-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-display-strong-han-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-display-strong-han-2-strong-text-transform: none;
  --spectrum-display-strong-han-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-display-strong-han-2-strong-text-size: var(--spectrum-alias-heading-han-display2-text-size);
  --spectrum-display-strong-han-2-strong-margin-top: var(--spectrum-alias-heading-han-display2-margin-top);
  --spectrum-display-strong-han-2-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
}
