.spectrum {
  --spectrum-button-cta-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-button-cta-background-color: var(--spectrum-semantic-cta-color-background-default);
  --spectrum-button-cta-border-color: var(--spectrum-semantic-cta-color-background-default);
  --spectrum-button-cta-text-color: var(--spectrum-global-color-static-white);
  --spectrum-button-cta-icon-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-button-cta-background-color-hover: var(--spectrum-semantic-cta-color-background-hover);
  --spectrum-button-cta-border-color-hover: var(--spectrum-semantic-cta-color-background-hover);
  --spectrum-button-cta-text-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-button-cta-icon-color-down: var(--spectrum-global-color-static-white);
  --spectrum-button-cta-background-color-down: var(--spectrum-semantic-cta-color-background-down);
  --spectrum-button-cta-border-color-down: var(--spectrum-semantic-cta-color-background-down);
  --spectrum-button-cta-text-color-down: var(--spectrum-global-color-static-white);
  --spectrum-button-cta-icon-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-button-cta-border-size-increase-key-focus: 1px;
  --spectrum-button-cta-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-button-cta-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-button-cta-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-button-cta-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-size-250);
  --spectrum-button-cta-background-color-key-focus: var(--spectrum-semantic-cta-color-background-hover);
  --spectrum-button-cta-border-color-key-focus: var(--spectrum-semantic-cta-color-background-hover);
  --spectrum-button-cta-text-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-button-cta-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-button-cta-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-button-cta-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-button-cta-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-button-cta-height: var(--spectrum-alias-single-line-height);
  --spectrum-button-cta-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-cta-text-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-cta-text-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-button-cta-medium-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-cta-large-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-cta-icon-padding-left: var(--spectrum-global-dimension-size-175);
  --spectrum-button-cta-icon-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-button-cta-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-button-cta-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-button-cta-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-button-cta-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-button-cta-text-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-button-cta-text-size: var(--spectrum-alias-pill-button-text-size);
  --spectrum-button-cta-text-baseline: var(--spectrum-alias-pill-button-text-baseline);
  --spectrum-button-cta-text-line-height: var(--spectrum-alias-button-text-line-height);
  --spectrum-button-over-background-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-over-background-border-color: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-icon-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-button-over-background-background-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-border-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-icon-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-button-over-background-background-color-down: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-border-color-down: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-icon-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-button-over-background-border-size-increase-key-focus: 1px;
  --spectrum-button-over-background-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-button-over-background-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-button-over-background-focus-ring-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-size-250);
  --spectrum-button-over-background-background-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-border-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-button-over-background-icon-color-disabled: rgba(255,255,255,0.35);
  --spectrum-button-over-background-background-color-disabled: rgba(255,255,255,0.1);
  --spectrum-button-over-background-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-over-background-text-color-disabled: rgba(255,255,255,0.35);
  --spectrum-button-over-background-height: var(--spectrum-alias-single-line-height);
  --spectrum-button-over-background-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-over-background-text-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-over-background-text-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-button-over-background-medium-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-over-background-large-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-over-background-icon-padding-left: var(--spectrum-global-dimension-size-175);
  --spectrum-button-over-background-icon-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-button-over-background-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-button-over-background-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-button-over-background-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-button-over-background-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-button-over-background-text-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-button-over-background-text-size: var(--spectrum-alias-pill-button-text-size);
  --spectrum-button-over-background-text-baseline: var(--spectrum-alias-pill-button-text-baseline);
  --spectrum-button-over-background-text-line-height: var(--spectrum-alias-button-text-line-height);
  --spectrum-button-primary-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-button-primary-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-primary-border-color: var(--spectrum-global-color-gray-800);
  --spectrum-button-primary-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-button-primary-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-button-primary-background-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-button-primary-border-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-button-primary-text-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-button-primary-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-button-primary-background-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-button-primary-border-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-button-primary-text-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-button-primary-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-button-primary-border-size-increase-key-focus: 1px;
  --spectrum-button-primary-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-button-primary-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-button-primary-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-button-primary-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-size-250);
  --spectrum-button-primary-background-color-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-button-primary-border-color-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-button-primary-text-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-button-primary-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-button-primary-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-button-primary-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-button-primary-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-button-primary-height: var(--spectrum-alias-single-line-height);
  --spectrum-button-primary-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-primary-text-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-primary-text-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-button-primary-medium-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-primary-large-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-primary-icon-padding-left: var(--spectrum-global-dimension-size-175);
  --spectrum-button-primary-icon-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-button-primary-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-button-primary-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-button-primary-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-button-primary-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-button-primary-text-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-button-primary-text-size: var(--spectrum-alias-pill-button-text-size);
  --spectrum-button-primary-text-baseline: var(--spectrum-alias-pill-button-text-baseline);
  --spectrum-button-primary-text-line-height: var(--spectrum-alias-button-text-line-height);
  --spectrum-button-primary-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-button-quiet-over-background-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-button-quiet-over-background-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-quiet-over-background-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-button-quiet-over-background-icon-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-button-quiet-over-background-background-color-hover: rgba(255,255,255,0.1);
  --spectrum-button-quiet-over-background-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-over-background-text-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-button-quiet-over-background-icon-color-down: var(--spectrum-global-color-static-white);
  --spectrum-button-quiet-over-background-background-color-down: rgba(255,255,255,0.15);
  --spectrum-button-quiet-over-background-border-color-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-over-background-text-color-down: var(--spectrum-global-color-static-white);
  --spectrum-button-quiet-over-background-icon-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-button-quiet-over-background-border-size-increase-key-focus: 1px;
  --spectrum-button-quiet-over-background-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-button-quiet-over-background-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-button-quiet-over-background-focus-ring-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-button-quiet-over-background-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-size-250);
  --spectrum-button-quiet-over-background-background-color-key-focus: rgba(255,255,255,0.1);
  --spectrum-button-quiet-over-background-border-color-key-focus: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-over-background-text-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-button-quiet-over-background-icon-color-disabled: rgba(255,255,255,0.15);
  --spectrum-button-quiet-over-background-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-quiet-over-background-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-over-background-text-color-disabled: rgba(255,255,255,0.15);
  --spectrum-button-quiet-over-background-height: var(--spectrum-alias-single-line-height);
  --spectrum-button-quiet-over-background-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-quiet-over-background-text-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-quiet-over-background-text-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-button-quiet-over-background-medium-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-over-background-large-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-over-background-icon-padding-left: var(--spectrum-global-dimension-size-175);
  --spectrum-button-quiet-over-background-icon-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-over-background-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-over-background-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-button-quiet-over-background-border-size: 0;
  --spectrum-button-quiet-over-background-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-button-quiet-over-background-text-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-over-background-text-size: var(--spectrum-alias-pill-button-text-size);
  --spectrum-button-quiet-over-background-text-baseline: var(--spectrum-alias-pill-button-text-baseline);
  --spectrum-button-quiet-over-background-text-line-height: var(--spectrum-alias-button-text-line-height);
  --spectrum-button-quiet-primary-icon-color: var(--spectrum-global-color-gray-800);
  --spectrum-button-quiet-primary-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-quiet-primary-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-primary-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-button-quiet-primary-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-button-quiet-primary-background-color-hover: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-primary-border-color-hover: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-primary-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-button-quiet-primary-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-button-quiet-primary-background-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-button-quiet-primary-border-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-button-quiet-primary-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-button-quiet-primary-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-button-quiet-primary-border-size-increase-key-focus: 1px;
  --spectrum-button-quiet-primary-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-button-quiet-primary-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-button-quiet-primary-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-button-quiet-primary-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-size-250);
  --spectrum-button-quiet-primary-background-color-key-focus: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-primary-border-color-key-focus: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-primary-text-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-button-quiet-primary-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-button-quiet-primary-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-quiet-primary-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-primary-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-button-quiet-primary-height: var(--spectrum-alias-single-line-height);
  --spectrum-button-quiet-primary-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-quiet-primary-text-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-quiet-primary-text-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-button-quiet-primary-medium-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-primary-large-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-primary-icon-padding-left: var(--spectrum-global-dimension-size-175);
  --spectrum-button-quiet-primary-icon-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-primary-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-primary-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-button-quiet-primary-border-size: 0;
  --spectrum-button-quiet-primary-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-button-quiet-primary-text-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-primary-text-size: var(--spectrum-alias-pill-button-text-size);
  --spectrum-button-quiet-primary-text-baseline: var(--spectrum-alias-pill-button-text-baseline);
  --spectrum-button-quiet-primary-text-line-height: var(--spectrum-alias-button-text-line-height);
  --spectrum-button-quiet-primary-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-button-quiet-secondary-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-button-quiet-secondary-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-quiet-secondary-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-secondary-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-button-quiet-secondary-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-button-quiet-secondary-background-color-hover: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-secondary-border-color-hover: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-secondary-text-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-button-quiet-secondary-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-button-quiet-secondary-background-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-button-quiet-secondary-border-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-button-quiet-secondary-text-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-button-quiet-secondary-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-button-quiet-secondary-border-size-increase-key-focus: 1px;
  --spectrum-button-quiet-secondary-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-button-quiet-secondary-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-button-quiet-secondary-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-button-quiet-secondary-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-size-250);
  --spectrum-button-quiet-secondary-background-color-key-focus: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-secondary-border-color-key-focus: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-secondary-text-color-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-button-quiet-secondary-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-button-quiet-secondary-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-quiet-secondary-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-secondary-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-button-quiet-secondary-height: var(--spectrum-alias-single-line-height);
  --spectrum-button-quiet-secondary-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-quiet-secondary-text-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-quiet-secondary-text-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-button-quiet-secondary-medium-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-secondary-large-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-secondary-icon-padding-left: var(--spectrum-global-dimension-size-175);
  --spectrum-button-quiet-secondary-icon-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-secondary-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-secondary-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-button-quiet-secondary-border-size: 0;
  --spectrum-button-quiet-secondary-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-button-quiet-secondary-text-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-secondary-text-size: var(--spectrum-alias-pill-button-text-size);
  --spectrum-button-quiet-secondary-text-baseline: var(--spectrum-alias-pill-button-text-baseline);
  --spectrum-button-quiet-secondary-text-line-height: var(--spectrum-alias-button-text-line-height);
  --spectrum-button-quiet-secondary-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-button-quiet-warning-icon-color: var(--spectrum-semantic-negative-color-text-small);
  --spectrum-button-quiet-warning-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-quiet-warning-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-warning-text-color: var(--spectrum-semantic-negative-color-text-small);
  --spectrum-button-quiet-warning-icon-color-hover: var(--spectrum-global-color-red-700);
  --spectrum-button-quiet-warning-background-color-hover: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-warning-border-color-hover: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-warning-text-color-hover: var(--spectrum-global-color-red-700);
  --spectrum-button-quiet-warning-icon-color-down: var(--spectrum-global-color-red-700);
  --spectrum-button-quiet-warning-background-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-button-quiet-warning-border-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-button-quiet-warning-text-color-down: var(--spectrum-global-color-red-700);
  --spectrum-button-quiet-warning-icon-color-key-focus: var(--spectrum-global-color-red-700);
  --spectrum-button-quiet-warning-border-size-increase-key-focus: 1px;
  --spectrum-button-quiet-warning-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-button-quiet-warning-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-button-quiet-warning-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-button-quiet-warning-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-size-250);
  --spectrum-button-quiet-warning-background-color-key-focus: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-warning-border-color-key-focus: var(--spectrum-global-color-gray-200);
  --spectrum-button-quiet-warning-text-color-key-focus: var(--spectrum-global-color-red-700);
  --spectrum-button-quiet-warning-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-button-quiet-warning-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-quiet-warning-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-button-quiet-warning-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-button-quiet-warning-height: var(--spectrum-alias-single-line-height);
  --spectrum-button-quiet-warning-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-quiet-warning-text-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-quiet-warning-text-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-button-quiet-warning-medium-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-warning-large-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-warning-icon-padding-left: var(--spectrum-global-dimension-size-175);
  --spectrum-button-quiet-warning-icon-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-warning-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-warning-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-button-quiet-warning-border-size: 0;
  --spectrum-button-quiet-warning-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-button-quiet-warning-text-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-warning-text-size: var(--spectrum-alias-pill-button-text-size);
  --spectrum-button-quiet-warning-text-baseline: var(--spectrum-alias-pill-button-text-baseline);
  --spectrum-button-quiet-warning-text-line-height: var(--spectrum-alias-button-text-line-height);
  --spectrum-button-quiet-warning-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-button-secondary-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-button-secondary-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-secondary-border-color: var(--spectrum-global-color-gray-700);
  --spectrum-button-secondary-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-button-secondary-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-button-secondary-background-color-hover: var(--spectrum-global-color-gray-700);
  --spectrum-button-secondary-border-color-hover: var(--spectrum-global-color-gray-700);
  --spectrum-button-secondary-text-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-button-secondary-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-button-secondary-background-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-button-secondary-border-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-button-secondary-text-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-button-secondary-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-button-secondary-border-size-increase-key-focus: 1px;
  --spectrum-button-secondary-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-button-secondary-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-button-secondary-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-button-secondary-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-size-250);
  --spectrum-button-secondary-background-color-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-button-secondary-border-color-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-button-secondary-text-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-button-secondary-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-button-secondary-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-button-secondary-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-button-secondary-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-button-secondary-height: var(--spectrum-alias-single-line-height);
  --spectrum-button-secondary-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-secondary-text-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-secondary-text-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-button-secondary-medium-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-secondary-large-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-secondary-icon-padding-left: var(--spectrum-global-dimension-size-175);
  --spectrum-button-secondary-icon-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-button-secondary-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-button-secondary-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-button-secondary-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-button-secondary-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-button-secondary-text-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-button-secondary-text-size: var(--spectrum-alias-pill-button-text-size);
  --spectrum-button-secondary-text-baseline: var(--spectrum-alias-pill-button-text-baseline);
  --spectrum-button-secondary-text-line-height: var(--spectrum-alias-button-text-line-height);
  --spectrum-button-secondary-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-button-warning-icon-color: var(--spectrum-semantic-negative-color-text-small);
  --spectrum-button-warning-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-button-warning-border-color: var(--spectrum-semantic-negative-color-text-small);
  --spectrum-button-warning-text-color: var(--spectrum-semantic-negative-color-text-small);
  --spectrum-button-warning-icon-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-button-warning-background-color-hover: var(--spectrum-semantic-negative-color-text-small);
  --spectrum-button-warning-border-color-hover: var(--spectrum-semantic-negative-color-text-small);
  --spectrum-button-warning-text-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-button-warning-icon-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-button-warning-background-color-down: var(--spectrum-global-color-red-700);
  --spectrum-button-warning-border-color-down: var(--spectrum-global-color-red-700);
  --spectrum-button-warning-text-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-button-warning-icon-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-button-warning-border-size-increase-key-focus: 1px;
  --spectrum-button-warning-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-button-warning-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-button-warning-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-button-warning-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-size-250);
  --spectrum-button-warning-background-color-key-focus: var(--spectrum-semantic-negative-color-text-small);
  --spectrum-button-warning-border-color-key-focus: var(--spectrum-semantic-negative-color-text-small);
  --spectrum-button-warning-text-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-button-warning-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-button-warning-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-button-warning-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-button-warning-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-button-warning-height: var(--spectrum-alias-single-line-height);
  --spectrum-button-warning-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-warning-text-padding-x: var(--spectrum-global-dimension-size-200);
  --spectrum-button-warning-text-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-button-warning-medium-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-warning-large-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-warning-icon-padding-left: var(--spectrum-global-dimension-size-175);
  --spectrum-button-warning-icon-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-button-warning-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-button-warning-border-radius: var(--spectrum-alias-border-radius-large);
  --spectrum-button-warning-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-button-warning-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-button-warning-text-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-button-warning-text-size: var(--spectrum-alias-pill-button-text-size);
  --spectrum-button-warning-text-baseline: var(--spectrum-alias-pill-button-text-baseline);
  --spectrum-button-warning-text-line-height: var(--spectrum-alias-button-text-line-height);
  --spectrum-button-warning-icon-size: var(--spectrum-alias-workflow-icon-size);
}
