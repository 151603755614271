.spectrum {
  --spectrum-treeview-item-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-treeview-item-group-background-color-hover: var(--spectrum-global-color-gray-75);
  --spectrum-treeview-item-group-border-color-hover: var(--spectrum-global-color-gray-500);
  --spectrum-treeview-row-border-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-treeview-item-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-treeview-item-background-color-hover: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-treeview-item-border-color-key-focus: var(--spectrum-alias-focus-color);
  --spectrum-treeview-item-border-size-key-focus: var(--spectrum-global-dimension-static-size-25);
  --spectrum-treeview-item-background-color-key-focus: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-treeview-width: 304px;
  --spectrum-treeview-item-height: var(--spectrum-global-dimension-size-450);
  --spectrum-treeview-padding-x: 6px;
  --spectrum-treeview-item-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-treeview-item-border-size: var(--spectrum-global-dimension-static-size-25);
  --spectrum-treeview-item-padding-y: var(--spectrum-global-dimension-static-size-100);
  --spectrum-treeview-item-margin-y: var(--spectrum-global-dimension-static-size-100);
  --spectrum-treeview-item-padding-x: 30px;
  --spectrum-treeview-item-indent: var(--spectrum-global-dimension-size-350);
  --spectrum-treeview-item-icon-gap: var(--spectrum-global-dimension-size-100);
}
