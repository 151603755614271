.spectrum {
  --spectrum-progresscircle-large-text-color: var(--spectrum-alias-text-color);
  --spectrum-progresscircle-large-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-progresscircle-large-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-progresscircle-large-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-large-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-large-height: var(--spectrum-global-dimension-size-800);
  --spectrum-progresscircle-large-width: var(--spectrum-global-dimension-size-800);
  --spectrum-progresscircle-large-border-size: var(--spectrum-global-dimension-size-50);
  --spectrum-progresscircle-large-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-progresscircle-large-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-progresscircle-large-indeterminate-text-color: var(--spectrum-alias-text-color);
  --spectrum-progresscircle-large-indeterminate-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-progresscircle-large-indeterminate-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-progresscircle-large-indeterminate-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-large-indeterminate-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-large-indeterminate-height: var(--spectrum-global-dimension-size-800);
  --spectrum-progresscircle-large-indeterminate-width: var(--spectrum-global-dimension-size-800);
  --spectrum-progresscircle-large-indeterminate-border-size: var(--spectrum-global-dimension-size-50);
  --spectrum-progresscircle-large-indeterminate-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-progresscircle-large-indeterminate-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-progresscircle-large-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-progresscircle-large-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-progresscircle-large-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-progresscircle-large-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-progresscircle-large-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-progresscircle-large-over-background-height: var(--spectrum-global-dimension-size-800);
  --spectrum-progresscircle-large-over-background-width: var(--spectrum-global-dimension-size-800);
  --spectrum-progresscircle-large-over-background-border-size: var(--spectrum-global-dimension-size-50);
  --spectrum-progresscircle-large-over-background-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-progresscircle-large-over-background-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-progresscircle-medium-text-color: var(--spectrum-alias-text-color);
  --spectrum-progresscircle-medium-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-progresscircle-medium-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-progresscircle-medium-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-medium-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-medium-height: var(--spectrum-global-dimension-size-400);
  --spectrum-progresscircle-medium-width: var(--spectrum-global-dimension-size-400);
  --spectrum-progresscircle-medium-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-progresscircle-medium-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-progresscircle-medium-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-progresscircle-medium-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-progresscircle-medium-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-progresscircle-medium-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-progresscircle-medium-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-progresscircle-medium-over-background-height: var(--spectrum-global-dimension-size-400);
  --spectrum-progresscircle-medium-over-background-width: var(--spectrum-global-dimension-size-400);
  --spectrum-progresscircle-medium-over-background-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-progresscircle-medium-over-background-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-progresscircle-small-text-color: var(--spectrum-alias-text-color);
  --spectrum-progresscircle-small-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-progresscircle-small-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-progresscircle-small-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-small-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-small-height: var(--spectrum-global-dimension-size-200);
  --spectrum-progresscircle-small-width: var(--spectrum-global-dimension-size-200);
  --spectrum-progresscircle-small-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-progresscircle-small-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-progresscircle-small-indeterminate-text-color: var(--spectrum-alias-text-color);
  --spectrum-progresscircle-small-indeterminate-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-progresscircle-small-indeterminate-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-progresscircle-small-indeterminate-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-small-indeterminate-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-progresscircle-small-indeterminate-height: var(--spectrum-global-dimension-size-200);
  --spectrum-progresscircle-small-indeterminate-width: var(--spectrum-global-dimension-size-200);
  --spectrum-progresscircle-small-indeterminate-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-progresscircle-small-indeterminate-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-progresscircle-small-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-progresscircle-small-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-progresscircle-small-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-progresscircle-small-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-progresscircle-small-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-progresscircle-small-over-background-height: var(--spectrum-global-dimension-size-200);
  --spectrum-progresscircle-small-over-background-width: var(--spectrum-global-dimension-size-200);
  --spectrum-progresscircle-small-over-background-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-progresscircle-small-over-background-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
}
