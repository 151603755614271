.spectrum {
  --spectrum-divider-large-background-color: var(--spectrum-global-color-gray-800);
  --spectrum-divider-large-height: var(--spectrum-global-dimension-static-size-50);
  --spectrum-divider-large-border-radius: var(--spectrum-global-dimension-static-size-25);
  --spectrum-divider-large-vertical-background-color: var(--spectrum-global-color-gray-800);
  --spectrum-divider-large-vertical-width: var(--spectrum-global-dimension-static-size-50);
  --spectrum-divider-large-vertical-border-radius: var(--spectrum-global-dimension-static-size-25);
  --spectrum-divider-medium-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-divider-medium-height: var(--spectrum-global-dimension-static-size-25);
  --spectrum-divider-medium-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-divider-medium-vertical-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-divider-medium-vertical-width: var(--spectrum-global-dimension-static-size-25);
  --spectrum-divider-medium-vertical-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-divider-small-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-divider-small-height: var(--spectrum-global-dimension-static-size-10);
  --spectrum-divider-small-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-divider-small-vertical-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-divider-small-vertical-width: var(--spectrum-global-dimension-static-size-10);
  --spectrum-divider-small-vertical-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-divider-small-veritcal-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-divider-small-veritcal-width: var(--spectrum-global-dimension-static-size-10);
  --spectrum-divider-small-veritcal-border-radius: var(--spectrum-global-dimension-static-size-10);
}
