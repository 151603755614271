.spectrum {
  --spectrum-card-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-card-border-color: var(--spectrum-global-color-gray-200);
  --spectrum-card-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-card-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-card-border-color-hover: var(--spectrum-global-color-gray-400);
  --spectrum-card-coverphoto-background-color: var(--spectrum-global-color-gray-200);
  --spectrum-card-coverphoto-border-color: var(--spectrum-global-color-gray-200);
  --spectrum-card-description-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-card-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-card-min-width: var(--spectrum-global-dimension-size-3000);
  --spectrum-card-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-card-coverphoto-height: var(--spectrum-global-dimension-size-1700);
  --spectrum-card-coverphoto-border-bottom-size: var(--spectrum-alias-border-size-thin);
  --spectrum-card-body-padding-top: var(--spectrum-global-dimension-size-250);
  --spectrum-card-body-padding-right: var(--spectrum-global-dimension-size-300);
  --spectrum-card-body-padding-left: var(--spectrum-global-dimension-size-300);
  --spectrum-card-body-padding-bottom: var(--spectrum-global-dimension-size-250);
  --spectrum-card-checkbox-margin: var(--spectrum-global-dimension-size-200);
  --spectrum-card-body-header-height: var(--spectrum-global-dimension-size-225);
  --spectrum-card-body-content-height: var(--spectrum-global-dimension-size-175);
  --spectrum-card-body-content-margin-top: var(--spectrum-global-dimension-size-75);
  --spectrum-card-title-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-card-title-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-card-subtitle-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-card-subtitle-padding-top: var(--spectrum-global-dimension-size-50);
  --spectrum-card-subtitle-padding-right: var(--spectrum-global-dimension-size-100);
  --spectrum-card-subtitle-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-card-actions-margin: var(--spectrum-global-dimension-size-125);
  --spectrum-card-footer-padding-top: var(--spectrum-global-dimension-size-175);
  --spectrum-card-footer-border-top-size: var(--spectrum-global-dimension-size-10);
  --spectrum-card-quiet-preview-background-color: var(--spectrum-global-color-gray-200);
  --spectrum-card-quiet-preview-background-color-hover: var(--spectrum-global-color-gray-300);
  --spectrum-card-quiet-border-color-selected: var(--spectrum-global-color-blue-500);
  --spectrum-card-quiet-title-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-card-quiet-subtitle-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-card-quiet-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-card-quiet-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-card-quiet-min-size: var(--spectrum-global-dimension-size-1700);
  --spectrum-card-quiet-checkbox-margin: var(--spectrum-global-dimension-size-200);
  --spectrum-card-quiet-preview-padding: var(--spectrum-global-dimension-size-250);
  --spectrum-card-quiet-body-margin-top: var(--spectrum-global-dimension-size-175);
  --spectrum-card-quiet-body-header-height: var(--spectrum-global-dimension-size-225);
  --spectrum-card-quiet-body-header-margin-top: var(--spectrum-global-dimension-size-175);
  --spectrum-card-quiet-small-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-card-quiet-small-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-card-quiet-small-body-header-height: var(--spectrum-global-dimension-size-150);
  --spectrum-card-quiet-small-body-margin-top: var(--spectrum-global-dimension-size-100);
  --spectrum-card-quiet-small-preview-padding: var(--spectrum-global-dimension-size-150);
  --spectrum-card-quiet-small-checkbox-margin: var(--spectrum-global-dimension-size-125);
  --spectrum-card-quiet-small-min-size: var(--spectrum-global-dimension-size-900);
  --spectrum-card-quiet-small-title-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-card-quiet-small-body-header-margin-top: var(--spectrum-global-dimension-size-175);
}
