.spectrum {
  --spectrum-heading-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-1-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-1-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-1-text-transform: none;
  --spectrum-heading-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-1-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-1-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-1-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-2-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-2-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-2-text-transform: none;
  --spectrum-heading-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-2-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-2-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-2-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-3-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-3-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-3-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-3-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-3-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-3-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-3-text-transform: none;
  --spectrum-heading-3-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-3-text-size: var(--spectrum-alias-heading3-text-size);
  --spectrum-heading-3-margin-top: var(--spectrum-alias-heading3-margin-top);
  --spectrum-heading-3-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-4-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-4-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-4-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-4-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-4-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-4-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-4-text-transform: none;
  --spectrum-heading-4-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-4-text-size: var(--spectrum-alias-heading4-text-size);
  --spectrum-heading-4-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-4-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-5-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-5-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-5-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-5-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-5-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-5-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-5-text-transform: none;
  --spectrum-heading-5-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-5-text-size: var(--spectrum-alias-heading5-text-size);
  --spectrum-heading-5-margin-top: var(--spectrum-alias-heading5-margin-top);
  --spectrum-heading-5-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-6-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-6-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-6-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-6-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-6-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-6-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-6-text-transform: none;
  --spectrum-heading-6-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-6-text-size: var(--spectrum-alias-heading6-text-size);
  --spectrum-heading-6-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-6-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-article-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-1-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-1-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-1-text-transform: none;
  --spectrum-heading-article-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-1-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-article-1-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-article-1-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-article-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-1-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-article-1-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-1-emphasis-text-transform: none;
  --spectrum-heading-article-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-1-emphasis-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-article-1-emphasis-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-article-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-article-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-1-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-1-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-heading-article-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-1-strong-text-transform: none;
  --spectrum-heading-article-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-1-strong-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-article-1-strong-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-article-1-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-article-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-2-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-2-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-2-text-transform: none;
  --spectrum-heading-article-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-2-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-article-2-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-article-2-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-article-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-2-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-article-2-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-2-emphasis-text-transform: none;
  --spectrum-heading-article-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-2-emphasis-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-article-2-emphasis-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-article-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-article-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-2-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-2-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-heading-article-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-2-strong-text-transform: none;
  --spectrum-heading-article-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-2-strong-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-article-2-strong-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-article-2-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-article-3-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-3-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-3-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-3-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-3-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-3-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-3-text-transform: none;
  --spectrum-heading-article-3-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-3-text-size: var(--spectrum-alias-heading3-text-size);
  --spectrum-heading-article-3-margin-top: var(--spectrum-alias-heading3-margin-top);
  --spectrum-heading-article-3-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-article-3-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-3-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-3-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-article-3-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-3-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-3-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-3-emphasis-text-transform: none;
  --spectrum-heading-article-3-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-3-emphasis-text-size: var(--spectrum-alias-heading3-text-size);
  --spectrum-heading-article-3-emphasis-margin-top: var(--spectrum-alias-heading3-margin-top);
  --spectrum-heading-article-3-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-article-3-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-3-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-3-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-3-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-heading-article-3-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-3-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-3-strong-text-transform: none;
  --spectrum-heading-article-3-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-3-strong-text-size: var(--spectrum-alias-heading3-text-size);
  --spectrum-heading-article-3-strong-margin-top: var(--spectrum-alias-heading3-margin-top);
  --spectrum-heading-article-3-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-article-4-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-4-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-4-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-4-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-4-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-4-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-4-text-transform: none;
  --spectrum-heading-article-4-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-4-text-size: var(--spectrum-alias-heading4-text-size);
  --spectrum-heading-article-4-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-article-4-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-article-4-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-4-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-4-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-article-4-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-4-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-4-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-4-emphasis-text-transform: none;
  --spectrum-heading-article-4-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-4-emphasis-text-size: var(--spectrum-alias-heading4-text-size);
  --spectrum-heading-article-4-emphasis-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-article-4-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-article-4-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-4-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-4-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-4-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-heading-article-4-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-4-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-4-strong-text-transform: none;
  --spectrum-heading-article-4-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-4-strong-text-size: var(--spectrum-alias-heading4-text-size);
  --spectrum-heading-article-4-strong-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-article-4-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-article-5-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-5-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-5-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-5-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-5-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-5-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-5-text-transform: none;
  --spectrum-heading-article-5-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-5-text-size: var(--spectrum-alias-heading5-text-size);
  --spectrum-heading-article-5-margin-top: var(--spectrum-alias-heading5-margin-top);
  --spectrum-heading-article-5-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-article-5-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-5-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-5-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-article-5-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-5-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-5-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-5-emphasis-text-transform: none;
  --spectrum-heading-article-5-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-5-emphasis-text-size: var(--spectrum-alias-heading5-text-size);
  --spectrum-heading-article-5-emphasis-margin-top: var(--spectrum-alias-heading5-margin-top);
  --spectrum-heading-article-5-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-article-5-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-5-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-5-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-5-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-heading-article-5-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-5-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-5-strong-text-transform: none;
  --spectrum-heading-article-5-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-5-strong-text-size: var(--spectrum-alias-heading5-text-size);
  --spectrum-heading-article-5-strong-margin-top: var(--spectrum-alias-heading5-margin-top);
  --spectrum-heading-article-5-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-article-6-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-6-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-6-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-6-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-6-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-6-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-6-text-transform: none;
  --spectrum-heading-article-6-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-6-text-size: var(--spectrum-alias-heading6-text-size);
  --spectrum-heading-article-6-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-article-6-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-article-6-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-6-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-6-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-article-6-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-article-6-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-6-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-6-emphasis-text-transform: none;
  --spectrum-heading-article-6-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-6-emphasis-text-size: var(--spectrum-alias-heading6-text-size);
  --spectrum-heading-article-6-emphasis-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-article-6-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-article-6-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-article-6-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-article-6-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-article-6-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-heading-article-6-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-article-6-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-article-6-strong-text-transform: none;
  --spectrum-heading-article-6-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-article-6-strong-text-size: var(--spectrum-alias-heading6-text-size);
  --spectrum-heading-article-6-strong-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-article-6-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-quiet-article-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-article-1-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-quiet-article-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-article-1-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-article-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-article-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-quiet-article-1-text-transform: none;
  --spectrum-heading-quiet-article-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-article-1-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-quiet-article-1-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-quiet-article-1-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-quiet-article-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-article-1-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-quiet-article-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-quiet-article-1-emphasis-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-article-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-article-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-quiet-article-1-emphasis-text-transform: none;
  --spectrum-heading-quiet-article-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-article-1-emphasis-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-quiet-article-1-emphasis-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-quiet-article-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-quiet-article-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-article-1-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-quiet-article-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-article-1-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-heading-quiet-article-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-article-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-quiet-article-1-strong-text-transform: none;
  --spectrum-heading-quiet-article-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-article-1-strong-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-quiet-article-1-strong-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-quiet-article-1-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-quiet-article-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-article-2-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-quiet-article-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-article-2-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-article-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-article-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-quiet-article-2-text-transform: none;
  --spectrum-heading-quiet-article-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-article-2-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-quiet-article-2-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-quiet-article-2-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-quiet-article-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-article-2-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-quiet-article-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-quiet-article-2-emphasis-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-article-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-article-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-quiet-article-2-emphasis-text-transform: none;
  --spectrum-heading-quiet-article-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-article-2-emphasis-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-quiet-article-2-emphasis-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-quiet-article-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-quiet-article-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-article-2-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-heading-quiet-article-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-article-2-strong-text-font-weight: var(--spectrum-alias-article-heading-text-font-weight-strong);
  --spectrum-heading-quiet-article-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-article-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-heading-quiet-article-2-strong-text-transform: none;
  --spectrum-heading-quiet-article-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-article-2-strong-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-quiet-article-2-strong-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-quiet-article-2-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-1-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-1-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-1-emphasis-text-transform: none;
  --spectrum-heading-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-1-emphasis-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-1-emphasis-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-1-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-1-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular-strong);
  --spectrum-heading-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-1-strong-text-transform: none;
  --spectrum-heading-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-1-strong-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-1-strong-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-1-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-2-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-2-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-2-emphasis-text-transform: none;
  --spectrum-heading-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-2-emphasis-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-2-emphasis-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-2-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-2-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular-strong);
  --spectrum-heading-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-2-strong-text-transform: none;
  --spectrum-heading-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-2-strong-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-2-strong-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-2-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-3-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-3-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-3-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-3-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-3-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-3-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-3-emphasis-text-transform: none;
  --spectrum-heading-3-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-3-emphasis-text-size: var(--spectrum-alias-heading3-text-size);
  --spectrum-heading-3-emphasis-margin-top: var(--spectrum-alias-heading3-margin-top);
  --spectrum-heading-3-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-3-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-3-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-3-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-3-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular-strong);
  --spectrum-heading-3-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-3-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-3-strong-text-transform: none;
  --spectrum-heading-3-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-3-strong-text-size: var(--spectrum-alias-heading3-text-size);
  --spectrum-heading-3-strong-margin-top: var(--spectrum-alias-heading3-margin-top);
  --spectrum-heading-3-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-4-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-4-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-4-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-4-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-4-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-4-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-4-emphasis-text-transform: none;
  --spectrum-heading-4-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-4-emphasis-text-size: var(--spectrum-alias-heading4-text-size);
  --spectrum-heading-4-emphasis-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-4-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-4-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-4-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-4-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-4-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular-strong);
  --spectrum-heading-4-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-4-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-4-strong-text-transform: none;
  --spectrum-heading-4-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-4-strong-text-size: var(--spectrum-alias-heading4-text-size);
  --spectrum-heading-4-strong-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-4-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-5-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-5-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-5-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-5-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-5-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-5-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-5-emphasis-text-transform: none;
  --spectrum-heading-5-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-5-emphasis-text-size: var(--spectrum-alias-heading5-text-size);
  --spectrum-heading-5-emphasis-margin-top: var(--spectrum-alias-heading5-margin-top);
  --spectrum-heading-5-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-5-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-5-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-5-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-5-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular-strong);
  --spectrum-heading-5-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-5-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-5-strong-text-transform: none;
  --spectrum-heading-5-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-5-strong-text-size: var(--spectrum-alias-heading5-text-size);
  --spectrum-heading-5-strong-margin-top: var(--spectrum-alias-heading5-margin-top);
  --spectrum-heading-5-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-6-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-6-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-6-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-6-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-6-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-6-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-6-emphasis-text-transform: none;
  --spectrum-heading-6-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-6-emphasis-text-size: var(--spectrum-alias-heading6-text-size);
  --spectrum-heading-6-emphasis-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-6-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-6-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-6-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-6-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-6-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular-strong);
  --spectrum-heading-6-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-6-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-6-strong-text-transform: none;
  --spectrum-heading-6-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-6-strong-text-size: var(--spectrum-alias-heading6-text-size);
  --spectrum-heading-6-strong-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-6-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-quiet-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-1-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-quiet-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-1-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-quiet-1-text-transform: none;
  --spectrum-heading-quiet-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-1-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-quiet-1-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-quiet-1-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-quiet-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-1-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-quiet-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-quiet-1-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-quiet-1-emphasis-text-transform: none;
  --spectrum-heading-quiet-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-1-emphasis-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-quiet-1-emphasis-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-quiet-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-quiet-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-1-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-quiet-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-1-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet-strong);
  --spectrum-heading-quiet-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-quiet-1-strong-text-transform: none;
  --spectrum-heading-quiet-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-1-strong-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-quiet-1-strong-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-quiet-1-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-quiet-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-2-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-quiet-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-2-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-quiet-2-text-transform: none;
  --spectrum-heading-quiet-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-2-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-quiet-2-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-quiet-2-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-quiet-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-2-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-quiet-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-quiet-2-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-quiet-2-emphasis-text-transform: none;
  --spectrum-heading-quiet-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-2-emphasis-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-quiet-2-emphasis-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-quiet-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-quiet-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-2-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-quiet-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-2-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-quiet-strong);
  --spectrum-heading-quiet-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-quiet-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-quiet-2-strong-text-transform: none;
  --spectrum-heading-quiet-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-2-strong-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-quiet-2-strong-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-quiet-2-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-strong-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-1-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-strong-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-1-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong);
  --spectrum-heading-strong-1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-strong-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-strong-1-text-transform: none;
  --spectrum-heading-strong-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-1-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-strong-1-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-strong-1-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-strong-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-1-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-strong-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-strong-1-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong);
  --spectrum-heading-strong-1-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-strong-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-strong-1-emphasis-text-transform: none;
  --spectrum-heading-strong-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-1-emphasis-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-strong-1-emphasis-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-strong-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-strong-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-1-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-strong-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-1-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong-strong);
  --spectrum-heading-strong-1-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-strong-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-strong-1-strong-text-transform: none;
  --spectrum-heading-strong-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-1-strong-text-size: var(--spectrum-alias-heading1-text-size);
  --spectrum-heading-strong-1-strong-margin-top: var(--spectrum-alias-heading1-margin-top);
  --spectrum-heading-strong-1-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-strong-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-2-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-strong-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-2-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong);
  --spectrum-heading-strong-2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-strong-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-strong-2-text-transform: none;
  --spectrum-heading-strong-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-2-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-strong-2-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-strong-2-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-strong-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-2-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-strong-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-strong-2-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong);
  --spectrum-heading-strong-2-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-strong-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-strong-2-emphasis-text-transform: none;
  --spectrum-heading-strong-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-2-emphasis-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-strong-2-emphasis-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-strong-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-strong-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-2-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-strong-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-2-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-strong-strong);
  --spectrum-heading-strong-2-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-strong-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-strong-2-strong-text-transform: none;
  --spectrum-heading-strong-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-2-strong-text-size: var(--spectrum-alias-heading2-text-size);
  --spectrum-heading-strong-2-strong-margin-top: var(--spectrum-alias-heading2-margin-top);
  --spectrum-heading-strong-2-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-han-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-1-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-1-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-1-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-1-text-transform: none;
  --spectrum-heading-han-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-1-text-size: var(--spectrum-alias-heading1-han-text-size);
  --spectrum-heading-han-1-margin-top: var(--spectrum-alias-heading1-han-margin-top);
  --spectrum-heading-han-1-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-1-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-1-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-1-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-1-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-1-emphasis-text-transform: none;
  --spectrum-heading-han-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-1-emphasis-text-size: var(--spectrum-alias-heading1-han-text-size);
  --spectrum-heading-han-1-emphasis-margin-top: var(--spectrum-alias-heading1-han-margin-top);
  --spectrum-heading-han-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-1-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-1-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-strong);
  --spectrum-heading-han-1-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-1-strong-text-transform: none;
  --spectrum-heading-han-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-1-strong-text-size: var(--spectrum-alias-heading1-han-text-size);
  --spectrum-heading-han-1-strong-margin-top: var(--spectrum-alias-heading1-han-margin-top);
  --spectrum-heading-han-1-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-2-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-2-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-2-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-2-text-transform: none;
  --spectrum-heading-han-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-2-text-size: var(--spectrum-alias-heading2-han-text-size);
  --spectrum-heading-han-2-margin-top: var(--spectrum-alias-heading2-han-margin-top);
  --spectrum-heading-han-2-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-2-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-2-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-2-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-2-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-2-emphasis-text-transform: none;
  --spectrum-heading-han-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-2-emphasis-text-size: var(--spectrum-alias-heading2-han-text-size);
  --spectrum-heading-han-2-emphasis-margin-top: var(--spectrum-alias-heading2-han-margin-top);
  --spectrum-heading-han-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-2-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-2-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-strong);
  --spectrum-heading-han-2-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-2-strong-text-transform: none;
  --spectrum-heading-han-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-2-strong-text-size: var(--spectrum-alias-heading2-han-text-size);
  --spectrum-heading-han-2-strong-margin-top: var(--spectrum-alias-heading2-han-margin-top);
  --spectrum-heading-han-2-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-3-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-3-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-3-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-3-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-3-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-3-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-3-text-transform: none;
  --spectrum-heading-han-3-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-3-text-size: var(--spectrum-alias-heading3-han-text-size);
  --spectrum-heading-han-3-margin-top: var(--spectrum-alias-heading3-han-margin-top);
  --spectrum-heading-han-3-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-3-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-3-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-3-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-3-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-3-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-3-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-3-emphasis-text-transform: none;
  --spectrum-heading-han-3-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-3-emphasis-text-size: var(--spectrum-alias-heading3-han-text-size);
  --spectrum-heading-han-3-emphasis-margin-top: var(--spectrum-alias-heading3-han-margin-top);
  --spectrum-heading-han-3-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-3-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-3-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-3-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-3-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-strong);
  --spectrum-heading-han-3-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-3-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-3-strong-text-transform: none;
  --spectrum-heading-han-3-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-3-strong-text-size: var(--spectrum-alias-heading3-han-text-size);
  --spectrum-heading-han-3-strong-margin-top: var(--spectrum-alias-heading3-han-margin-top);
  --spectrum-heading-han-3-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-4-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-4-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-4-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-4-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-4-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-4-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-4-text-transform: none;
  --spectrum-heading-han-4-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-4-text-size: var(--spectrum-alias-heading4-text-size);
  --spectrum-heading-han-4-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-han-4-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-4-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-4-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-4-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-4-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-4-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-4-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-4-emphasis-text-transform: none;
  --spectrum-heading-han-4-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-4-emphasis-text-size: var(--spectrum-alias-heading4-text-size);
  --spectrum-heading-han-4-emphasis-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-han-4-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-4-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-4-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-4-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-4-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-strong);
  --spectrum-heading-han-4-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-4-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-4-strong-text-transform: none;
  --spectrum-heading-han-4-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-4-strong-text-size: var(--spectrum-alias-heading4-text-size);
  --spectrum-heading-han-4-strong-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-han-4-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-5-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-5-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-5-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-5-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-5-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-5-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-5-text-transform: none;
  --spectrum-heading-han-5-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-5-text-size: var(--spectrum-alias-heading5-text-size);
  --spectrum-heading-han-5-margin-top: var(--spectrum-alias-heading5-margin-top);
  --spectrum-heading-han-5-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-5-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-5-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-5-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-5-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-5-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-5-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-5-emphasis-text-transform: none;
  --spectrum-heading-han-5-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-5-emphasis-text-size: var(--spectrum-alias-heading5-text-size);
  --spectrum-heading-han-5-emphasis-margin-top: var(--spectrum-alias-heading5-margin-top);
  --spectrum-heading-han-5-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-5-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-5-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-5-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-5-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-strong);
  --spectrum-heading-han-5-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-5-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-5-strong-text-transform: none;
  --spectrum-heading-han-5-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-5-strong-text-size: var(--spectrum-alias-heading5-text-size);
  --spectrum-heading-han-5-strong-margin-top: var(--spectrum-alias-heading5-margin-top);
  --spectrum-heading-han-5-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-6-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-6-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-6-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-6-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-6-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-6-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-6-text-transform: none;
  --spectrum-heading-han-6-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-6-text-size: var(--spectrum-alias-heading6-text-size);
  --spectrum-heading-han-6-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-han-6-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-6-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-6-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-6-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-6-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-6-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-6-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-6-emphasis-text-transform: none;
  --spectrum-heading-han-6-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-6-emphasis-text-size: var(--spectrum-alias-heading6-text-size);
  --spectrum-heading-han-6-emphasis-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-han-6-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-6-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-6-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-6-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-6-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-strong);
  --spectrum-heading-han-6-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-6-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-6-strong-text-transform: none;
  --spectrum-heading-han-6-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-6-strong-text-size: var(--spectrum-alias-heading6-text-size);
  --spectrum-heading-han-6-strong-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-han-6-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-quiet-han-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-han-1-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-quiet-han-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-han-1-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-han-1-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-quiet-han-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-quiet-han-1-text-transform: none;
  --spectrum-heading-quiet-han-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-han-1-text-size: var(--spectrum-alias-heading1-han-text-size);
  --spectrum-heading-quiet-han-1-margin-top: var(--spectrum-alias-heading1-han-margin-top);
  --spectrum-heading-quiet-han-1-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-quiet-han-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-han-1-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-quiet-han-1-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-han-1-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet-emphasis);
  --spectrum-heading-quiet-han-1-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-quiet-han-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-quiet-han-1-emphasis-text-transform: none;
  --spectrum-heading-quiet-han-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-han-1-emphasis-text-size: var(--spectrum-alias-heading1-han-text-size);
  --spectrum-heading-quiet-han-1-emphasis-margin-top: var(--spectrum-alias-heading1-han-margin-top);
  --spectrum-heading-quiet-han-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-quiet-han-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-han-1-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-quiet-han-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-han-1-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet-strong);
  --spectrum-heading-quiet-han-1-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-quiet-han-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-quiet-han-1-strong-text-transform: none;
  --spectrum-heading-quiet-han-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-han-1-strong-text-size: var(--spectrum-alias-heading1-han-text-size);
  --spectrum-heading-quiet-han-1-strong-margin-top: var(--spectrum-alias-heading1-han-margin-top);
  --spectrum-heading-quiet-han-1-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-quiet-han-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-han-2-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-quiet-han-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-han-2-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet);
  --spectrum-heading-quiet-han-2-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-quiet-han-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-quiet-han-2-text-transform: none;
  --spectrum-heading-quiet-han-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-han-2-text-size: var(--spectrum-alias-heading2-han-text-size);
  --spectrum-heading-quiet-han-2-margin-top: var(--spectrum-alias-heading2-han-margin-top);
  --spectrum-heading-quiet-han-2-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-quiet-han-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-han-2-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-quiet-han-2-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-han-2-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet-emphasis);
  --spectrum-heading-quiet-han-2-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-quiet-han-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-quiet-han-2-emphasis-text-transform: none;
  --spectrum-heading-quiet-han-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-han-2-emphasis-text-size: var(--spectrum-alias-heading2-han-text-size);
  --spectrum-heading-quiet-han-2-emphasis-margin-top: var(--spectrum-alias-heading2-han-margin-top);
  --spectrum-heading-quiet-han-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-quiet-han-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-quiet-han-2-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-quiet-han-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-quiet-han-2-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-quiet-strong);
  --spectrum-heading-quiet-han-2-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-quiet-han-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-quiet-han-2-strong-text-transform: none;
  --spectrum-heading-quiet-han-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-quiet-han-2-strong-text-size: var(--spectrum-alias-heading2-han-text-size);
  --spectrum-heading-quiet-han-2-strong-margin-top: var(--spectrum-alias-heading2-han-margin-top);
  --spectrum-heading-quiet-han-2-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-strong-han-1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-han-1-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-strong-han-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-han-1-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong);
  --spectrum-heading-strong-han-1-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-strong-han-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-strong-han-1-text-transform: none;
  --spectrum-heading-strong-han-1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-han-1-text-size: var(--spectrum-alias-heading1-han-text-size);
  --spectrum-heading-strong-han-1-margin-top: var(--spectrum-alias-heading1-han-margin-top);
  --spectrum-heading-strong-han-1-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-strong-han-1-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-han-1-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-strong-han-1-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-han-1-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong-emphasis);
  --spectrum-heading-strong-han-1-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-strong-han-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-strong-han-1-emphasis-text-transform: none;
  --spectrum-heading-strong-han-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-han-1-emphasis-text-size: var(--spectrum-alias-heading1-han-text-size);
  --spectrum-heading-strong-han-1-emphasis-margin-top: var(--spectrum-alias-heading1-han-margin-top);
  --spectrum-heading-strong-han-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-strong-han-1-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-han-1-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-strong-han-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-han-1-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong-strong);
  --spectrum-heading-strong-han-1-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-strong-han-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-strong-han-1-strong-text-transform: none;
  --spectrum-heading-strong-han-1-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-han-1-strong-text-size: var(--spectrum-alias-heading1-han-text-size);
  --spectrum-heading-strong-han-1-strong-margin-top: var(--spectrum-alias-heading1-han-margin-top);
  --spectrum-heading-strong-han-1-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-strong-han-2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-han-2-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-strong-han-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-han-2-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong);
  --spectrum-heading-strong-han-2-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-strong-han-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-strong-han-2-text-transform: none;
  --spectrum-heading-strong-han-2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-han-2-text-size: var(--spectrum-alias-heading2-han-text-size);
  --spectrum-heading-strong-han-2-margin-top: var(--spectrum-alias-heading2-han-margin-top);
  --spectrum-heading-strong-han-2-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-strong-han-2-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-han-2-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-strong-han-2-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-han-2-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong-emphasis);
  --spectrum-heading-strong-han-2-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-strong-han-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-strong-han-2-emphasis-text-transform: none;
  --spectrum-heading-strong-han-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-han-2-emphasis-text-size: var(--spectrum-alias-heading2-han-text-size);
  --spectrum-heading-strong-han-2-emphasis-margin-top: var(--spectrum-alias-heading2-han-margin-top);
  --spectrum-heading-strong-han-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-strong-han-2-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-strong-han-2-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-strong-han-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-strong-han-2-strong-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-strong-strong);
  --spectrum-heading-strong-han-2-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-strong-han-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-strong-han-2-strong-text-transform: none;
  --spectrum-heading-strong-han-2-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-strong-han-2-strong-text-size: var(--spectrum-alias-heading2-han-text-size);
  --spectrum-heading-strong-han-2-strong-margin-top: var(--spectrum-alias-heading2-han-margin-top);
  --spectrum-heading-strong-han-2-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-display-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-display-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-display-text-size: var(--spectrum-global-dimension-font-size-1000);
  --spectrum-heading-display-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-page-title-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-heading-page-title-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-page-title-text-size: var(--spectrum-global-dimension-font-size-700);
  --spectrum-heading-page-title-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-subtitle1-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-subtitle1-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-subtitle1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-subtitle1-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-subtitle1-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-subtitle1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-subtitle1-text-transform: none;
  --spectrum-heading-subtitle1-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-subtitle1-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-heading-subtitle1-margin-top: var(--spectrum-alias-heading4-margin-top);
  --spectrum-heading-subtitle1-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-subtitle1-font-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-heading-subtitle2-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-subtitle2-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-subtitle2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-subtitle2-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-subtitle2-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-subtitle2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-subtitle2-text-transform: none;
  --spectrum-heading-subtitle2-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-subtitle2-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-heading-subtitle2-margin-top: var(--spectrum-alias-heading6-margin-top);
  --spectrum-heading-subtitle2-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-subtitle2-font-line-height: var(--spectrum-global-font-line-height-medium);
  --spectrum-heading-subtitle3-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-heading-subtitle3-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-subtitle3-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-subtitle3-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-subtitle3-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-subtitle3-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-heading-subtitle3-text-transform: uppercase;
  --spectrum-heading-subtitle3-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-subtitle3-font-line-height: var(--spectrum-global-font-line-height-small);
  --spectrum-heading-subtitle3-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-heading-subtitle3-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-heading-subtitle3-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-xs-xstrong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xs-xstrong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-xs-xstrong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xs-xstrong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xs-xstrong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xs-xstrong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xs-xstrong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xs-xstrong-text-transform: none;
  --spectrum-heading-han-xs-xstrong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xs-xstrong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-xs-xstrong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-xxs-xxstrong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxs-xxstrong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-xxs-xxstrong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxs-xxstrong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxs-xxstrong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xxs-xxstrong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxs-xxstrong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxs-xxstrong-text-transform: none;
  --spectrum-heading-han-xxs-xxstrong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxs-xxstrong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-xxs-xxstrong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-xs-xstrong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xs-xstrong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-xs-xstrong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xs-xstrong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xs-xstrong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xs-xstrong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xs-xstrong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xs-xstrong-text-transform: none;
  --spectrum-heading-serif-xs-xstrong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xs-xstrong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-xs-xstrong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-xxs-xxstrong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxs-xxstrong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-xxs-xxstrong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxs-xxstrong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xxs-xxstrong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xxs-xxstrong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxs-xxstrong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxs-xxstrong-text-transform: none;
  --spectrum-heading-serif-xxs-xxstrong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxs-xxstrong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-xxs-xxstrong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-xs-xstrong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xs-xstrong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-xs-xstrong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xs-xstrong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xs-xstrong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xs-xstrong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xs-xstrong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xs-xstrong-text-transform: none;
  --spectrum-heading-xs-xstrong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xs-xstrong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-xs-xstrong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-xxs-xxstrong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxs-xxstrong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-xxs-xxstrong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxs-xxstrong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xxs-xxstrong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xxs-xxstrong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxs-xxstrong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxs-xxstrong-text-transform: none;
  --spectrum-heading-xxs-xxstrong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxs-xxstrong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-xxs-xxstrong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-heavy-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-l-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-heavy-l-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-l-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-l-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-l-text-transform: none;
  --spectrum-heading-han-heavy-l-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-l-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-heavy-l-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-heavy-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-l-emphasis-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-heavy-l-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-l-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-l-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-heavy-emphasis);
  --spectrum-heading-han-heavy-l-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-l-emphasis-text-transform: none;
  --spectrum-heading-han-heavy-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-l-emphasis-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-heavy-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-heavy-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-l-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-heavy-l-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-l-emphasis-strong-text-transform: none;
  --spectrum-heading-han-heavy-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-l-emphasis-strong-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-heavy-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-heavy-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-l-strong-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-heavy-l-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-l-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-l-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-l-strong-text-transform: none;
  --spectrum-heading-han-heavy-l-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-l-strong-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-heavy-l-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-heavy-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-m-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-heavy-m-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-m-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-m-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-m-text-transform: none;
  --spectrum-heading-han-heavy-m-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-m-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-heavy-m-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-heavy-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-m-emphasis-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-heavy-m-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-m-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-m-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-heavy-emphasis);
  --spectrum-heading-han-heavy-m-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-m-emphasis-text-transform: none;
  --spectrum-heading-han-heavy-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-m-emphasis-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-heavy-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-heavy-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-m-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-heavy-m-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-m-emphasis-strong-text-transform: none;
  --spectrum-heading-han-heavy-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-m-emphasis-strong-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-heavy-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-heavy-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-m-strong-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-heavy-m-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-m-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-m-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-m-strong-text-transform: none;
  --spectrum-heading-han-heavy-m-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-m-strong-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-heavy-m-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-heavy-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-s-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-heavy-s-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-s-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-s-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-s-text-transform: none;
  --spectrum-heading-han-heavy-s-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-s-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-heavy-s-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-heavy-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-s-emphasis-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-heavy-s-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-s-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-s-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-heavy-emphasis);
  --spectrum-heading-han-heavy-s-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-s-emphasis-text-transform: none;
  --spectrum-heading-han-heavy-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-s-emphasis-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-heavy-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-heavy-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-s-emphasis-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-heavy-s-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-s-emphasis-strong-text-transform: none;
  --spectrum-heading-han-heavy-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-s-emphasis-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-heavy-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-heavy-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-s-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-heavy-s-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-s-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-s-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-s-strong-text-transform: none;
  --spectrum-heading-han-heavy-s-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-s-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-heavy-s-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-heavy-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xl-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-heavy-xl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xl-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xl-text-transform: none;
  --spectrum-heading-han-heavy-xl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xl-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-heavy-xl-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-heavy-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xl-emphasis-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-heavy-xl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-heavy-emphasis);
  --spectrum-heading-han-heavy-xl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xl-emphasis-text-transform: none;
  --spectrum-heading-han-heavy-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xl-emphasis-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-heavy-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-heavy-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xl-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-heavy-xl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xl-emphasis-strong-text-transform: none;
  --spectrum-heading-han-heavy-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xl-emphasis-strong-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-heavy-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-heavy-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xl-strong-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-heavy-xl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xl-strong-text-transform: none;
  --spectrum-heading-han-heavy-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xl-strong-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-heavy-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-heavy-xs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xs-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-heavy-xs-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xs-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xs-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xs-text-transform: none;
  --spectrum-heading-han-heavy-xs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xs-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-heavy-xs-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-heavy-xs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xs-emphasis-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-heavy-xs-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xs-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xs-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-heavy-emphasis);
  --spectrum-heading-han-heavy-xs-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xs-emphasis-text-transform: none;
  --spectrum-heading-han-heavy-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xs-emphasis-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-heavy-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-heavy-xs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-heavy-xs-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xs-emphasis-strong-text-transform: none;
  --spectrum-heading-han-heavy-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xs-emphasis-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-heavy-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-heavy-xs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xs-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-heavy-xs-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xs-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xs-strong-text-transform: none;
  --spectrum-heading-han-heavy-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xs-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-heavy-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-heavy-xxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxl-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-heavy-xxl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxl-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xxl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxl-text-transform: none;
  --spectrum-heading-han-heavy-xxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxl-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-heavy-xxl-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-heavy-xxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxl-emphasis-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-heavy-xxl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-heavy-emphasis);
  --spectrum-heading-han-heavy-xxl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxl-emphasis-text-transform: none;
  --spectrum-heading-han-heavy-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxl-emphasis-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-heavy-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-text-transform: none;
  --spectrum-heading-han-heavy-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-heavy-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-heavy-xxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxl-strong-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-heavy-xxl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xxl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxl-strong-text-transform: none;
  --spectrum-heading-han-heavy-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxl-strong-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-heavy-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-heavy-xxs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxs-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-heavy-xxs-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxs-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xxs-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxs-text-transform: none;
  --spectrum-heading-han-heavy-xxs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxs-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-heavy-xxs-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-heavy-xxs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxs-emphasis-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-heavy-xxs-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxs-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxs-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-heavy-emphasis);
  --spectrum-heading-han-heavy-xxs-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxs-emphasis-text-transform: none;
  --spectrum-heading-han-heavy-xxs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxs-emphasis-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-heavy-xxs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-text-transform: none;
  --spectrum-heading-han-heavy-xxs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-heavy-xxs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-heavy-xxs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxs-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-heavy-xxs-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xxs-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxs-strong-text-transform: none;
  --spectrum-heading-han-heavy-xxs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxs-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-heavy-xxs-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-heavy-xxxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxxl-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-heavy-xxxl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxxl-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xxxl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxxl-text-transform: none;
  --spectrum-heading-han-heavy-xxxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxxl-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-heavy-xxxl-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-heavy-xxxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxxl-emphasis-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-heavy-xxxl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxxl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-heavy-emphasis);
  --spectrum-heading-han-heavy-xxxl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxxl-emphasis-text-transform: none;
  --spectrum-heading-han-heavy-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxxl-emphasis-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-heavy-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-text-transform: none;
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-heavy-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-heavy-xxxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-heavy-xxxl-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-heavy-xxxl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-heavy-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-heavy-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-heavy-xxxl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-heavy-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-heavy-xxxl-strong-text-transform: none;
  --spectrum-heading-han-heavy-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-heavy-xxxl-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-heavy-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-l-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-l-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-l-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-l-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-l-text-transform: none;
  --spectrum-heading-han-l-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-l-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-l-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-l-emphasis-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-l-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-l-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-l-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-l-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-l-emphasis-text-transform: none;
  --spectrum-heading-han-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-l-emphasis-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-l-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-l-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-l-emphasis-strong-text-transform: none;
  --spectrum-heading-han-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-l-emphasis-strong-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-light-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-l-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-light-l-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-l-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-han-light-l-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-l-text-transform: none;
  --spectrum-heading-han-light-l-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-l-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-light-l-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-light-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-l-emphasis-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-light-l-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-l-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-l-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-heading-han-light-l-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-l-emphasis-text-transform: none;
  --spectrum-heading-han-light-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-l-emphasis-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-light-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-light-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-l-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-light-l-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-light-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-l-emphasis-strong-text-transform: none;
  --spectrum-heading-han-light-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-l-emphasis-strong-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-light-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-light-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-l-strong-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-light-l-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-l-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-han-light-l-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-l-strong-text-transform: none;
  --spectrum-heading-han-light-l-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-l-strong-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-light-l-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-light-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-m-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-light-m-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-m-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-han-light-m-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-m-text-transform: none;
  --spectrum-heading-han-light-m-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-m-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-light-m-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-light-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-m-emphasis-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-light-m-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-m-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-m-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-heading-han-light-m-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-m-emphasis-text-transform: none;
  --spectrum-heading-han-light-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-m-emphasis-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-light-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-light-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-m-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-light-m-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-light-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-m-emphasis-strong-text-transform: none;
  --spectrum-heading-han-light-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-m-emphasis-strong-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-light-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-light-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-m-strong-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-light-m-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-m-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-han-light-m-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-m-strong-text-transform: none;
  --spectrum-heading-han-light-m-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-m-strong-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-light-m-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-light-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-s-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-light-s-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-s-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-han-light-s-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-s-text-transform: none;
  --spectrum-heading-han-light-s-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-s-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-light-s-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-light-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-s-emphasis-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-light-s-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-s-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-s-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-heading-han-light-s-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-s-emphasis-text-transform: none;
  --spectrum-heading-han-light-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-s-emphasis-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-light-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-light-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-s-emphasis-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-light-s-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-light-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-s-emphasis-strong-text-transform: none;
  --spectrum-heading-han-light-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-s-emphasis-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-light-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-light-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-s-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-light-s-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-s-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-han-light-s-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-s-strong-text-transform: none;
  --spectrum-heading-han-light-s-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-s-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-light-s-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-light-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xl-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-light-xl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xl-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-han-light-xl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xl-text-transform: none;
  --spectrum-heading-han-light-xl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xl-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-light-xl-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-light-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xl-emphasis-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-light-xl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-heading-han-light-xl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xl-emphasis-text-transform: none;
  --spectrum-heading-han-light-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xl-emphasis-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-light-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-light-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xl-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-light-xl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-light-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xl-emphasis-strong-text-transform: none;
  --spectrum-heading-han-light-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xl-emphasis-strong-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-light-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-light-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xl-strong-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-light-xl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-han-light-xl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xl-strong-text-transform: none;
  --spectrum-heading-han-light-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xl-strong-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-light-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-light-xs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xs-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-light-xs-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xs-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-han-light-xs-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xs-text-transform: none;
  --spectrum-heading-han-light-xs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xs-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-light-xs-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-light-xs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xs-emphasis-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-light-xs-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xs-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xs-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-heading-han-light-xs-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xs-emphasis-text-transform: none;
  --spectrum-heading-han-light-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xs-emphasis-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-light-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-light-xs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-light-xs-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-light-xs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xs-emphasis-strong-text-transform: none;
  --spectrum-heading-han-light-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xs-emphasis-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-light-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-light-xs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xs-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-light-xs-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xs-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-han-light-xs-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xs-strong-text-transform: none;
  --spectrum-heading-han-light-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xs-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-light-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-light-xxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxl-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-light-xxl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxl-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-han-light-xxl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxl-text-transform: none;
  --spectrum-heading-han-light-xxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxl-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-light-xxl-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-light-xxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxl-emphasis-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-light-xxl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-heading-han-light-xxl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxl-emphasis-text-transform: none;
  --spectrum-heading-han-light-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxl-emphasis-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-light-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-light-xxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-light-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-light-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxl-emphasis-strong-text-transform: none;
  --spectrum-heading-han-light-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxl-emphasis-strong-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-light-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-light-xxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxl-strong-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-light-xxl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-han-light-xxl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxl-strong-text-transform: none;
  --spectrum-heading-han-light-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxl-strong-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-light-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-light-xxs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxs-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-light-xxs-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxs-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-han-light-xxs-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxs-text-transform: none;
  --spectrum-heading-han-light-xxs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxs-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-light-xxs-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-light-xxs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxs-emphasis-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-light-xxs-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxs-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxs-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-heading-han-light-xxs-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxs-emphasis-text-transform: none;
  --spectrum-heading-han-light-xxs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxs-emphasis-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-light-xxs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-light-xxs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-light-xxs-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-light-xxs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxs-emphasis-strong-text-transform: none;
  --spectrum-heading-han-light-xxs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxs-emphasis-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-light-xxs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-light-xxs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxs-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-light-xxs-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxs-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-han-light-xxs-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxs-strong-text-transform: none;
  --spectrum-heading-han-light-xxs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxs-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-light-xxs-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-light-xxxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxxl-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-light-xxxl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxxl-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-han-light-xxxl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxxl-text-transform: none;
  --spectrum-heading-han-light-xxxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxxl-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-light-xxxl-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-light-xxxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxxl-emphasis-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-light-xxxl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxxl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-heading-han-light-xxxl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxxl-emphasis-text-transform: none;
  --spectrum-heading-han-light-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxxl-emphasis-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-light-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-light-xxxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-light-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-light-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxxl-emphasis-strong-text-transform: none;
  --spectrum-heading-han-light-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-light-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-light-xxxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-light-xxxl-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-light-xxxl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-light-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-light-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-han-light-xxxl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-light-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-light-xxxl-strong-text-transform: none;
  --spectrum-heading-han-light-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-light-xxxl-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-light-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-l-strong-margin-top: var(--spectrum-alias-heading-han-l-margin-top);
  --spectrum-heading-han-l-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-l-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-l-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-l-strong-text-transform: none;
  --spectrum-heading-han-l-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-l-strong-text-size: var(--spectrum-alias-heading-han-l-text-size);
  --spectrum-heading-han-l-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-han-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-m-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-m-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-m-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-m-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-m-text-transform: none;
  --spectrum-heading-han-m-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-m-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-m-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-m-emphasis-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-m-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-m-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-m-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-m-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-m-emphasis-text-transform: none;
  --spectrum-heading-han-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-m-emphasis-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-m-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-m-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-m-emphasis-strong-text-transform: none;
  --spectrum-heading-han-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-m-emphasis-strong-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-m-strong-margin-top: var(--spectrum-alias-heading-han-m-margin-top);
  --spectrum-heading-han-m-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-m-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-m-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-m-strong-text-transform: none;
  --spectrum-heading-han-m-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-m-strong-text-size: var(--spectrum-alias-heading-han-m-text-size);
  --spectrum-heading-han-m-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-s-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-s-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-s-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-s-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-s-text-transform: none;
  --spectrum-heading-han-s-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-s-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-s-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-s-emphasis-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-s-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-s-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-s-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-s-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-s-emphasis-text-transform: none;
  --spectrum-heading-han-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-s-emphasis-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-s-emphasis-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-s-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-s-emphasis-strong-text-transform: none;
  --spectrum-heading-han-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-s-emphasis-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-s-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-han-s-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-s-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-s-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-s-strong-text-transform: none;
  --spectrum-heading-han-s-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-s-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-han-s-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-han-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xl-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-xl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xl-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-xl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xl-text-transform: none;
  --spectrum-heading-han-xl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xl-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-xl-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xl-emphasis-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-xl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-xl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xl-emphasis-text-transform: none;
  --spectrum-heading-han-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xl-emphasis-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xl-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-xl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xl-emphasis-strong-text-transform: none;
  --spectrum-heading-han-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xl-emphasis-strong-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xl-strong-margin-top: var(--spectrum-alias-heading-han-xl-margin-top);
  --spectrum-heading-han-xl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xl-strong-text-transform: none;
  --spectrum-heading-han-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xl-strong-text-size: var(--spectrum-alias-heading-han-xl-text-size);
  --spectrum-heading-han-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-heading-han-xs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xs-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-xs-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xs-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-xs-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xs-text-transform: none;
  --spectrum-heading-han-xs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xs-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-xs-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-xs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xs-emphasis-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-xs-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xs-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xs-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-xs-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xs-emphasis-text-transform: none;
  --spectrum-heading-han-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xs-emphasis-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-xs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-xs-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xs-emphasis-strong-text-transform: none;
  --spectrum-heading-han-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xs-emphasis-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-xs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xs-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-han-xs-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xs-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xs-strong-text-transform: none;
  --spectrum-heading-han-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xs-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-han-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-han-xxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxl-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-xxl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxl-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-xxl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxl-text-transform: none;
  --spectrum-heading-han-xxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxl-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-xxl-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-xxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxl-emphasis-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-xxl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-xxl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxl-emphasis-text-transform: none;
  --spectrum-heading-han-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxl-emphasis-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-xxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxl-emphasis-strong-text-transform: none;
  --spectrum-heading-han-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxl-emphasis-strong-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-xxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxl-strong-margin-top: var(--spectrum-alias-heading-han-xxl-margin-top);
  --spectrum-heading-han-xxl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xxl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxl-strong-text-transform: none;
  --spectrum-heading-han-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxl-strong-text-size: var(--spectrum-alias-heading-han-xxl-text-size);
  --spectrum-heading-han-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-han-xxs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxs-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-xxs-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxs-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-xxs-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxs-text-transform: none;
  --spectrum-heading-han-xxs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxs-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-xxs-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-xxs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxs-emphasis-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-xxs-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxs-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxs-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-xxs-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxs-emphasis-text-transform: none;
  --spectrum-heading-han-xxs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxs-emphasis-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-xxs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-xxs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-xxs-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xxs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxs-emphasis-strong-text-transform: none;
  --spectrum-heading-han-xxs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxs-emphasis-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-xxs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-xxs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxs-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-han-xxs-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xxs-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxs-strong-text-transform: none;
  --spectrum-heading-han-xxs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxs-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-han-xxs-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-han-xxxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxxl-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-xxxl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxxl-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-heading-han-xxxl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxxl-text-transform: none;
  --spectrum-heading-han-xxxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxxl-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-xxxl-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-xxxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxxl-emphasis-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-xxxl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxxl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-heading-han-xxxl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxxl-emphasis-text-transform: none;
  --spectrum-heading-han-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxxl-emphasis-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-xxxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxxl-emphasis-strong-text-transform: none;
  --spectrum-heading-han-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-han-xxxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-han-xxxl-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-han-xxxl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-heading-han-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-han-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-han-xxxl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-heading-han-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-heading-han-xxxl-strong-text-transform: none;
  --spectrum-heading-han-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-han-xxxl-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-han-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-base-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-base-margin-top: 0px;
  --spectrum-heading-base-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-base-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-base-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-base-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-base-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-base-text-transform: none;
  --spectrum-heading-base-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-l-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-heavy-l-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-l-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-l-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-l-text-transform: none;
  --spectrum-heading-heavy-l-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-l-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-heavy-l-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-heavy-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-l-emphasis-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-heavy-l-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-l-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-l-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-l-emphasis-text-transform: none;
  --spectrum-heading-heavy-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-l-emphasis-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-heavy-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-heavy-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-l-emphasis-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-heavy-l-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-l-emphasis-strong-text-transform: none;
  --spectrum-heading-heavy-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-l-emphasis-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-heavy-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-heavy-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-l-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-heavy-l-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-l-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-l-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-l-strong-text-transform: none;
  --spectrum-heading-heavy-l-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-l-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-heavy-l-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-heavy-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-m-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-heavy-m-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-m-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-m-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-m-text-transform: none;
  --spectrum-heading-heavy-m-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-m-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-heavy-m-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-heavy-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-m-emphasis-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-heavy-m-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-m-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-m-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-m-emphasis-text-transform: none;
  --spectrum-heading-heavy-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-m-emphasis-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-heavy-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-heavy-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-m-emphasis-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-heavy-m-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-m-emphasis-strong-text-transform: none;
  --spectrum-heading-heavy-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-m-emphasis-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-heavy-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-heavy-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-m-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-heavy-m-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-m-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-m-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-m-strong-text-transform: none;
  --spectrum-heading-heavy-m-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-m-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-heavy-m-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-heavy-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-s-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-heavy-s-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-s-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-s-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-s-text-transform: none;
  --spectrum-heading-heavy-s-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-s-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-heavy-s-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-heavy-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-s-emphasis-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-heavy-s-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-s-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-s-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-s-emphasis-text-transform: none;
  --spectrum-heading-heavy-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-s-emphasis-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-heavy-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-heavy-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-s-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-heavy-s-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-s-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-s-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-s-strong-text-transform: none;
  --spectrum-heading-heavy-s-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-s-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-heavy-s-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-heavy-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xl-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-heavy-xl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xl-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xl-text-transform: none;
  --spectrum-heading-heavy-xl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xl-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-heavy-xl-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-heavy-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xl-emphasis-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-heavy-xl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-xl-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xl-emphasis-text-transform: none;
  --spectrum-heading-heavy-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xl-emphasis-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-heavy-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-heavy-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-heavy-xl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xl-emphasis-strong-text-transform: none;
  --spectrum-heading-heavy-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xl-emphasis-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-heavy-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-heavy-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xl-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-heavy-xl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xl-strong-text-transform: none;
  --spectrum-heading-heavy-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xl-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-heavy-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-heavy-xs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xs-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-heavy-xs-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xs-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xs-text-transform: none;
  --spectrum-heading-heavy-xs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xs-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-heavy-xs-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-heavy-xs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xs-emphasis-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-heavy-xs-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-xs-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xs-emphasis-text-transform: none;
  --spectrum-heading-heavy-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xs-emphasis-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-heavy-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-heavy-xs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-heavy-xs-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xs-emphasis-strong-text-transform: none;
  --spectrum-heading-heavy-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xs-emphasis-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-heavy-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-heavy-xs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xs-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-heavy-xs-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xs-strong-text-transform: none;
  --spectrum-heading-heavy-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xs-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-heavy-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-heavy-xxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxl-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-heavy-xxl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xxl-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxl-text-transform: none;
  --spectrum-heading-heavy-xxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxl-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-heavy-xxl-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-heavy-xxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxl-emphasis-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-heavy-xxl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-xxl-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxl-emphasis-text-transform: none;
  --spectrum-heading-heavy-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxl-emphasis-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-heavy-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-heavy-xxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-heavy-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxl-emphasis-strong-text-transform: none;
  --spectrum-heading-heavy-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-heavy-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-heavy-xxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxl-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-heavy-xxl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxl-strong-text-transform: none;
  --spectrum-heading-heavy-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxl-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-heavy-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-heavy-xxs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxs-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-heavy-xxs-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xxs-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxs-text-transform: none;
  --spectrum-heading-heavy-xxs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxs-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-heavy-xxs-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-heavy-xxs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxs-emphasis-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-heavy-xxs-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-xxs-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxs-emphasis-text-transform: none;
  --spectrum-heading-heavy-xxs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxs-emphasis-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-heavy-xxs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-heavy-xxs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxs-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-heavy-xxs-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xxs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxs-strong-text-transform: none;
  --spectrum-heading-heavy-xxs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxs-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-heavy-xxs-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-heavy-xxxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxxl-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-heavy-xxxl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xxxl-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxxl-text-transform: none;
  --spectrum-heading-heavy-xxxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxxl-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-heavy-xxxl-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-heavy-xxxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxxl-emphasis-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-heavy-xxxl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-xxxl-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxxl-emphasis-text-transform: none;
  --spectrum-heading-heavy-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxxl-emphasis-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-heavy-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-heavy-xxxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-heavy-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-heavy-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxxl-emphasis-strong-text-transform: none;
  --spectrum-heading-heavy-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-heavy-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-heavy-xxxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-heavy-xxxl-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-heavy-xxxl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-heavy-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-heavy-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-heavy-xxxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-heavy-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-heavy-xxxl-strong-text-transform: none;
  --spectrum-heading-heavy-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-heavy-xxxl-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-heavy-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-l-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-l-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-l-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-l-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-l-text-transform: none;
  --spectrum-heading-l-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-l-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-l-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-l-emphasis-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-l-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-l-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-l-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-l-emphasis-text-transform: none;
  --spectrum-heading-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-l-emphasis-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-l-emphasis-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-l-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-l-emphasis-strong-text-transform: none;
  --spectrum-heading-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-l-emphasis-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-light-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-l-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-light-l-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-l-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-l-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-l-text-transform: none;
  --spectrum-heading-light-l-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-l-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-light-l-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-light-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-l-emphasis-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-light-l-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-l-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-l-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-l-emphasis-text-transform: none;
  --spectrum-heading-light-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-l-emphasis-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-light-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-light-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-l-emphasis-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-light-l-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-l-emphasis-strong-text-transform: none;
  --spectrum-heading-light-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-l-emphasis-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-light-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-light-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-l-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-light-l-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-l-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-l-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-l-strong-text-transform: none;
  --spectrum-heading-light-l-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-l-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-light-l-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-light-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-m-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-light-m-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-m-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-m-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-m-text-transform: none;
  --spectrum-heading-light-m-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-m-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-light-m-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-light-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-m-emphasis-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-light-m-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-m-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-m-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-m-emphasis-text-transform: none;
  --spectrum-heading-light-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-m-emphasis-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-light-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-light-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-m-emphasis-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-light-m-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-m-emphasis-strong-text-transform: none;
  --spectrum-heading-light-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-m-emphasis-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-light-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-light-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-m-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-light-m-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-m-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-m-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-m-strong-text-transform: none;
  --spectrum-heading-light-m-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-m-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-light-m-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-light-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-s-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-light-s-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-s-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-s-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-s-text-transform: none;
  --spectrum-heading-light-s-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-s-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-light-s-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-light-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-s-emphasis-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-light-s-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-s-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-s-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-s-emphasis-text-transform: none;
  --spectrum-heading-light-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-s-emphasis-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-light-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-light-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-s-emphasis-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-light-s-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-s-emphasis-strong-text-transform: none;
  --spectrum-heading-light-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-s-emphasis-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-light-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-light-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-s-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-light-s-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-s-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-s-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-s-strong-text-transform: none;
  --spectrum-heading-light-s-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-s-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-light-s-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-light-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xl-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-light-xl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xl-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xl-text-transform: none;
  --spectrum-heading-light-xl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xl-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-light-xl-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-light-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xl-emphasis-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-light-xl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xl-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xl-emphasis-text-transform: none;
  --spectrum-heading-light-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xl-emphasis-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-light-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-light-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-light-xl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xl-emphasis-strong-text-transform: none;
  --spectrum-heading-light-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xl-emphasis-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-light-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-light-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xl-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-light-xl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xl-strong-text-transform: none;
  --spectrum-heading-light-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xl-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-light-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-light-xs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xs-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-light-xs-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xs-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xs-text-transform: none;
  --spectrum-heading-light-xs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xs-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-light-xs-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-light-xs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xs-emphasis-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-light-xs-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xs-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xs-emphasis-text-transform: none;
  --spectrum-heading-light-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xs-emphasis-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-light-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-light-xs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-light-xs-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xs-emphasis-strong-text-transform: none;
  --spectrum-heading-light-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xs-emphasis-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-light-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-light-xs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xs-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-light-xs-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xs-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xs-strong-text-transform: none;
  --spectrum-heading-light-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xs-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-light-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-light-xxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxl-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-light-xxl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xxl-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxl-text-transform: none;
  --spectrum-heading-light-xxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxl-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-light-xxl-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-light-xxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxl-emphasis-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-light-xxl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xxl-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxl-emphasis-text-transform: none;
  --spectrum-heading-light-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxl-emphasis-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-light-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-light-xxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-light-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxl-emphasis-strong-text-transform: none;
  --spectrum-heading-light-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-light-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-light-xxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxl-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-light-xxl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxl-strong-text-transform: none;
  --spectrum-heading-light-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxl-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-light-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-light-xxs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxs-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-light-xxs-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xxs-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xxs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxs-text-transform: none;
  --spectrum-heading-light-xxs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxs-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-light-xxs-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-light-xxs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxs-emphasis-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-light-xxs-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xxs-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xxs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxs-emphasis-text-transform: none;
  --spectrum-heading-light-xxs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxs-emphasis-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-light-xxs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-light-xxs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-light-xxs-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xxs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xxs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxs-emphasis-strong-text-transform: none;
  --spectrum-heading-light-xxs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxs-emphasis-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-light-xxs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-light-xxs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxs-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-light-xxs-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xxs-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xxs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxs-strong-text-transform: none;
  --spectrum-heading-light-xxs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxs-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-light-xxs-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-light-xxxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxxl-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-light-xxxl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xxxl-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xxxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxxl-text-transform: none;
  --spectrum-heading-light-xxxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxxl-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-light-xxxl-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-light-xxxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxxl-emphasis-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-light-xxxl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xxxl-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-light-xxxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxxl-emphasis-text-transform: none;
  --spectrum-heading-light-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxxl-emphasis-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-light-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-light-xxxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-light-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-light-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxxl-emphasis-strong-text-transform: none;
  --spectrum-heading-light-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-light-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-light-xxxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-light-xxxl-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-light-xxxl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-light-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-light-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-light-xxxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-light-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-light-xxxl-strong-text-transform: none;
  --spectrum-heading-light-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-light-xxxl-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-light-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-l-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-l-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-l-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-l-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-l-strong-text-transform: none;
  --spectrum-heading-l-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-l-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-l-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-m-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-m-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-m-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-m-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-m-text-transform: none;
  --spectrum-heading-m-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-m-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-m-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-m-emphasis-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-m-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-m-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-m-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-m-emphasis-text-transform: none;
  --spectrum-heading-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-m-emphasis-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-m-emphasis-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-m-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-m-emphasis-strong-text-transform: none;
  --spectrum-heading-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-m-emphasis-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-m-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-m-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-m-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-m-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-m-strong-text-transform: none;
  --spectrum-heading-m-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-m-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-m-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-s-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-s-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-s-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-s-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-s-text-transform: none;
  --spectrum-heading-s-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-s-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-s-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-s-emphasis-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-s-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-s-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-s-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-s-emphasis-text-transform: none;
  --spectrum-heading-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-s-emphasis-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-s-emphasis-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-s-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-s-emphasis-strong-text-transform: none;
  --spectrum-heading-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-s-emphasis-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-s-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-s-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-s-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-s-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-s-strong-text-transform: none;
  --spectrum-heading-s-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-s-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-s-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xl-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-xl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xl-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xl-text-transform: none;
  --spectrum-heading-xl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xl-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-xl-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xl-emphasis-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-xl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xl-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xl-emphasis-text-transform: none;
  --spectrum-heading-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xl-emphasis-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-xl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xl-emphasis-strong-text-transform: none;
  --spectrum-heading-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xl-emphasis-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xl-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-xl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xl-strong-text-transform: none;
  --spectrum-heading-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xl-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-xs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xs-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-xs-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xs-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xs-text-transform: none;
  --spectrum-heading-xs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xs-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-xs-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-xs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xs-emphasis-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-xs-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xs-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xs-emphasis-text-transform: none;
  --spectrum-heading-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xs-emphasis-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-xs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-xs-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xs-emphasis-strong-text-transform: none;
  --spectrum-heading-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xs-emphasis-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-xs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xs-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-xs-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xs-strong-text-transform: none;
  --spectrum-heading-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xs-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-xxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxl-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-xxl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xxl-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxl-text-transform: none;
  --spectrum-heading-xxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxl-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-xxl-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-xxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxl-emphasis-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-xxl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xxl-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxl-emphasis-text-transform: none;
  --spectrum-heading-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxl-emphasis-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-xxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxl-emphasis-strong-text-transform: none;
  --spectrum-heading-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-xxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxl-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-xxl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxl-strong-text-transform: none;
  --spectrum-heading-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxl-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-xxs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxs-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-xxs-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xxs-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xxs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxs-text-transform: none;
  --spectrum-heading-xxs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxs-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-xxs-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-xxs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxs-emphasis-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-xxs-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xxs-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xxs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxs-emphasis-text-transform: none;
  --spectrum-heading-xxs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxs-emphasis-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-xxs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-xxs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-xxs-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xxs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xxs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxs-emphasis-strong-text-transform: none;
  --spectrum-heading-xxs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxs-emphasis-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-xxs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-xxs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxs-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-xxs-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xxs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xxs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxs-strong-text-transform: none;
  --spectrum-heading-xxs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxs-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-xxs-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-xxxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxxl-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-xxxl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xxxl-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xxxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxxl-text-transform: none;
  --spectrum-heading-xxxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxxl-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-xxxl-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-xxxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxxl-emphasis-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-xxxl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xxxl-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-xxxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxxl-emphasis-text-transform: none;
  --spectrum-heading-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxxl-emphasis-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-xxxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxxl-emphasis-strong-text-transform: none;
  --spectrum-heading-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-xxxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-xxxl-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-xxxl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-heading-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-xxxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-xxxl-strong-text-transform: none;
  --spectrum-heading-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-xxxl-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-heavy-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-l-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-heavy-l-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-l-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-l-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-l-text-transform: none;
  --spectrum-heading-serif-heavy-l-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-l-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-heavy-l-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-heavy-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-l-emphasis-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-heavy-l-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-l-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-l-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-l-emphasis-text-transform: none;
  --spectrum-heading-serif-heavy-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-l-emphasis-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-heavy-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-heavy-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-l-emphasis-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-heavy-l-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-l-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-heavy-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-l-emphasis-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-heavy-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-heavy-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-l-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-heavy-l-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-l-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-l-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-l-strong-text-transform: none;
  --spectrum-heading-serif-heavy-l-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-l-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-heavy-l-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-heavy-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-m-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-heavy-m-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-m-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-m-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-m-text-transform: none;
  --spectrum-heading-serif-heavy-m-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-m-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-heavy-m-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-heavy-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-m-emphasis-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-heavy-m-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-m-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-m-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-m-emphasis-text-transform: none;
  --spectrum-heading-serif-heavy-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-m-emphasis-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-heavy-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-heavy-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-m-emphasis-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-heavy-m-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-m-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-heavy-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-m-emphasis-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-heavy-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-heavy-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-m-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-heavy-m-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-m-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-m-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-m-strong-text-transform: none;
  --spectrum-heading-serif-heavy-m-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-m-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-heavy-m-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-heavy-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-s-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-heavy-s-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-s-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-s-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-s-text-transform: none;
  --spectrum-heading-serif-heavy-s-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-s-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-heavy-s-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-heavy-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-s-emphasis-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-heavy-s-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-s-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-s-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-s-emphasis-text-transform: none;
  --spectrum-heading-serif-heavy-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-s-emphasis-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-heavy-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-heavy-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-s-emphasis-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-heavy-s-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-s-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-heavy-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-s-emphasis-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-heavy-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-heavy-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-s-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-heavy-s-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-s-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-s-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-s-strong-text-transform: none;
  --spectrum-heading-serif-heavy-s-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-s-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-heavy-s-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-heavy-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xl-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-heavy-xl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xl-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xl-text-transform: none;
  --spectrum-heading-serif-heavy-xl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xl-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-heavy-xl-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-heavy-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xl-emphasis-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-heavy-xl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xl-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xl-emphasis-text-transform: none;
  --spectrum-heading-serif-heavy-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xl-emphasis-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-heavy-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-heavy-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-heavy-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xl-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-heavy-xl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xl-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xl-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-heavy-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-heavy-xs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xs-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-heavy-xs-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xs-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xs-text-transform: none;
  --spectrum-heading-serif-heavy-xs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xs-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-heavy-xs-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-heavy-xs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xs-emphasis-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-heavy-xs-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xs-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xs-emphasis-text-transform: none;
  --spectrum-heading-serif-heavy-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xs-emphasis-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-heavy-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-heavy-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-heavy-xs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xs-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-heavy-xs-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xs-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xs-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-heavy-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-heavy-xxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxl-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-heavy-xxl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xxl-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxl-text-transform: none;
  --spectrum-heading-serif-heavy-xxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxl-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-heavy-xxl-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-heavy-xxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxl-emphasis-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-heavy-xxl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xxl-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxl-emphasis-text-transform: none;
  --spectrum-heading-serif-heavy-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxl-emphasis-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-heavy-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-heavy-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-heavy-xxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxl-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-heavy-xxl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxl-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxl-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-heavy-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-heavy-xxs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxs-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-heavy-xxs-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xxs-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxs-text-transform: none;
  --spectrum-heading-serif-heavy-xxs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxs-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-heavy-xxs-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-heavy-xxs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxs-emphasis-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-heavy-xxs-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xxs-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxs-emphasis-text-transform: none;
  --spectrum-heading-serif-heavy-xxs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxs-emphasis-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-heavy-xxs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-heavy-xxs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-heavy-xxs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxs-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-heavy-xxs-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xxs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxs-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xxs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxs-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-heavy-xxs-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-heavy-xxxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxxl-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-heavy-xxxl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xxxl-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxxl-text-transform: none;
  --spectrum-heading-serif-heavy-xxxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxxl-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-heavy-xxxl-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-heavy-xxxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxxl-emphasis-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-heavy-xxxl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xxxl-emphasis-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxxl-emphasis-text-transform: none;
  --spectrum-heading-serif-heavy-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxxl-emphasis-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-heavy-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-heavy-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-heavy-xxxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-heavy-xxxl-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-heavy-xxxl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-heavy-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-heavy-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-heavy-xxxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-heavy-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-heavy-xxxl-strong-text-transform: none;
  --spectrum-heading-serif-heavy-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-heavy-xxxl-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-heavy-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-l-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-l-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-l-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-l-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-l-text-transform: none;
  --spectrum-heading-serif-l-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-l-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-l-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-l-emphasis-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-l-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-l-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-l-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-l-emphasis-text-transform: none;
  --spectrum-heading-serif-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-l-emphasis-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-l-emphasis-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-l-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-l-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-l-emphasis-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-light-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-l-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-light-l-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-l-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-l-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-l-text-transform: none;
  --spectrum-heading-serif-light-l-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-l-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-light-l-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-light-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-l-emphasis-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-light-l-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-l-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-l-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-l-emphasis-text-transform: none;
  --spectrum-heading-serif-light-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-l-emphasis-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-light-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-light-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-l-emphasis-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-light-l-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-l-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-light-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-l-emphasis-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-light-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-light-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-l-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-light-l-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-l-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-l-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-l-strong-text-transform: none;
  --spectrum-heading-serif-light-l-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-l-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-light-l-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-light-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-m-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-light-m-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-m-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-m-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-m-text-transform: none;
  --spectrum-heading-serif-light-m-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-m-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-light-m-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-light-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-m-emphasis-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-light-m-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-m-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-m-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-m-emphasis-text-transform: none;
  --spectrum-heading-serif-light-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-m-emphasis-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-light-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-light-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-m-emphasis-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-light-m-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-m-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-light-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-m-emphasis-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-light-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-light-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-m-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-light-m-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-m-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-m-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-m-strong-text-transform: none;
  --spectrum-heading-serif-light-m-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-m-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-light-m-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-light-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-s-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-light-s-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-s-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-s-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-s-text-transform: none;
  --spectrum-heading-serif-light-s-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-s-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-light-s-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-light-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-s-emphasis-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-light-s-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-s-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-s-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-s-emphasis-text-transform: none;
  --spectrum-heading-serif-light-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-s-emphasis-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-light-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-light-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-s-emphasis-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-light-s-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-s-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-light-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-s-emphasis-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-light-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-light-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-s-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-light-s-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-s-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-s-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-s-strong-text-transform: none;
  --spectrum-heading-serif-light-s-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-s-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-light-s-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-light-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xl-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-light-xl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xl-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xl-text-transform: none;
  --spectrum-heading-serif-light-xl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xl-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-light-xl-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-light-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xl-emphasis-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-light-xl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xl-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xl-emphasis-text-transform: none;
  --spectrum-heading-serif-light-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xl-emphasis-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-light-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-light-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-light-xl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xl-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-light-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xl-emphasis-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-light-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-light-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xl-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-light-xl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xl-strong-text-transform: none;
  --spectrum-heading-serif-light-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xl-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-light-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-light-xs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xs-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-light-xs-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xs-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xs-text-transform: none;
  --spectrum-heading-serif-light-xs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xs-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-light-xs-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-light-xs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xs-emphasis-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-light-xs-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xs-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xs-emphasis-text-transform: none;
  --spectrum-heading-serif-light-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xs-emphasis-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-light-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-light-xs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-light-xs-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xs-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-light-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xs-emphasis-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-light-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-light-xs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xs-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-light-xs-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xs-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xs-strong-text-transform: none;
  --spectrum-heading-serif-light-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xs-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-light-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-light-xxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxl-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-light-xxl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xxl-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxl-text-transform: none;
  --spectrum-heading-serif-light-xxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxl-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-light-xxl-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-light-xxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxl-emphasis-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-light-xxl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xxl-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxl-emphasis-text-transform: none;
  --spectrum-heading-serif-light-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxl-emphasis-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-light-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-light-xxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-light-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxl-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-light-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-light-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-light-xxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxl-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-light-xxl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxl-strong-text-transform: none;
  --spectrum-heading-serif-light-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxl-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-light-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-light-xxs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxs-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-light-xxs-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xxs-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xxs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxs-text-transform: none;
  --spectrum-heading-serif-light-xxs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxs-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-light-xxs-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-light-xxs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxs-emphasis-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-light-xxs-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xxs-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xxs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxs-emphasis-text-transform: none;
  --spectrum-heading-serif-light-xxs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxs-emphasis-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-light-xxs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-light-xxs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-light-xxs-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xxs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xxs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxs-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-light-xxs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxs-emphasis-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-light-xxs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-light-xxs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxs-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-light-xxs-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xxs-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xxs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxs-strong-text-transform: none;
  --spectrum-heading-serif-light-xxs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxs-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-light-xxs-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-light-xxxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxxl-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-light-xxxl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xxxl-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xxxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxxl-text-transform: none;
  --spectrum-heading-serif-light-xxxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxxl-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-light-xxxl-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-light-xxxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxxl-emphasis-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-light-xxxl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xxxl-emphasis-text-font-weight: var(--spectrum-global-font-weight-light);
  --spectrum-heading-serif-light-xxxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxxl-emphasis-text-transform: none;
  --spectrum-heading-serif-light-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxxl-emphasis-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-light-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-light-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-light-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-light-xxxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-light-xxxl-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-light-xxxl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-light-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-light-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-heading-serif-light-xxxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-light-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-light-xxxl-strong-text-transform: none;
  --spectrum-heading-serif-light-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-light-xxxl-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-light-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-l-strong-margin-top: var(--spectrum-alias-heading-l-margin-top);
  --spectrum-heading-serif-l-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-l-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-l-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-l-strong-text-transform: none;
  --spectrum-heading-serif-l-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-l-strong-text-size: var(--spectrum-alias-heading-l-text-size);
  --spectrum-heading-serif-l-strong-margin-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-heading-serif-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-m-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-m-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-m-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-m-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-m-text-transform: none;
  --spectrum-heading-serif-m-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-m-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-m-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-m-emphasis-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-m-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-m-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-m-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-m-emphasis-text-transform: none;
  --spectrum-heading-serif-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-m-emphasis-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-m-emphasis-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-m-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-m-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-m-emphasis-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-m-strong-margin-top: var(--spectrum-alias-heading-m-margin-top);
  --spectrum-heading-serif-m-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-m-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-m-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-m-strong-text-transform: none;
  --spectrum-heading-serif-m-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-m-strong-text-size: var(--spectrum-alias-heading-m-text-size);
  --spectrum-heading-serif-m-strong-margin-bottom: var(--spectrum-global-dimension-size-75);
  --spectrum-heading-serif-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-s-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-s-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-s-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-s-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-s-text-transform: none;
  --spectrum-heading-serif-s-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-s-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-s-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-s-emphasis-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-s-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-s-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-s-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-s-emphasis-text-transform: none;
  --spectrum-heading-serif-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-s-emphasis-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-s-emphasis-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-s-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-s-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-s-emphasis-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-s-strong-margin-top: var(--spectrum-alias-heading-s-margin-top);
  --spectrum-heading-serif-s-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-s-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-s-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-s-strong-text-transform: none;
  --spectrum-heading-serif-s-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-s-strong-text-size: var(--spectrum-alias-heading-s-text-size);
  --spectrum-heading-serif-s-strong-margin-bottom: var(--spectrum-global-dimension-size-65);
  --spectrum-heading-serif-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xl-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-xl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xl-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xl-text-transform: none;
  --spectrum-heading-serif-xl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xl-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-xl-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xl-emphasis-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-xl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xl-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xl-emphasis-text-transform: none;
  --spectrum-heading-serif-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xl-emphasis-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-xl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xl-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xl-emphasis-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xl-strong-margin-top: var(--spectrum-alias-heading-xl-margin-top);
  --spectrum-heading-serif-xl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xl-strong-text-transform: none;
  --spectrum-heading-serif-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xl-strong-text-size: var(--spectrum-alias-heading-xl-text-size);
  --spectrum-heading-serif-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-heading-serif-xs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xs-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-xs-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xs-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xs-text-transform: none;
  --spectrum-heading-serif-xs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xs-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-xs-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-xs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xs-emphasis-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-xs-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xs-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xs-emphasis-text-transform: none;
  --spectrum-heading-serif-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xs-emphasis-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-xs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-xs-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xs-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xs-emphasis-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-xs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xs-strong-margin-top: var(--spectrum-alias-heading-xs-margin-top);
  --spectrum-heading-serif-xs-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xs-strong-text-transform: none;
  --spectrum-heading-serif-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xs-strong-text-size: var(--spectrum-alias-heading-xs-text-size);
  --spectrum-heading-serif-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-50);
  --spectrum-heading-serif-xxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxl-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-xxl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xxl-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxl-text-transform: none;
  --spectrum-heading-serif-xxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxl-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-xxl-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-xxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxl-emphasis-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-xxl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xxl-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxl-emphasis-text-transform: none;
  --spectrum-heading-serif-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxl-emphasis-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-xxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxl-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-xxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxl-strong-margin-top: var(--spectrum-alias-heading-xxl-margin-top);
  --spectrum-heading-serif-xxl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxl-strong-text-transform: none;
  --spectrum-heading-serif-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxl-strong-text-size: var(--spectrum-alias-heading-xxl-text-size);
  --spectrum-heading-serif-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-heading-serif-xxs-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxs-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-xxs-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xxs-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xxs-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxs-text-transform: none;
  --spectrum-heading-serif-xxs-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxs-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-xxs-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-xxs-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxs-emphasis-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-xxs-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xxs-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xxs-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxs-emphasis-text-transform: none;
  --spectrum-heading-serif-xxs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxs-emphasis-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-xxs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-xxs-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxs-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-xxs-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xxs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xxs-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxs-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-xxs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxs-emphasis-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-xxs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-xxs-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxs-strong-margin-top: var(--spectrum-alias-heading-xxs-margin-top);
  --spectrum-heading-serif-xxs-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xxs-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xxs-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxs-strong-text-transform: none;
  --spectrum-heading-serif-xxs-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxs-strong-text-size: var(--spectrum-alias-heading-xxs-text-size);
  --spectrum-heading-serif-xxs-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-heading-serif-xxxl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxxl-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-xxxl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xxxl-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xxxl-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxxl-text-transform: none;
  --spectrum-heading-serif-xxxl-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxxl-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-xxxl-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-xxxl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxxl-emphasis-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-xxxl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xxxl-emphasis-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-heading-serif-xxxl-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxxl-emphasis-text-transform: none;
  --spectrum-heading-serif-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxxl-emphasis-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-xxxl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxxl-emphasis-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-heading-serif-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxxl-emphasis-strong-text-transform: none;
  --spectrum-heading-serif-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxxl-emphasis-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
  --spectrum-heading-serif-xxxl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-heading-serif-xxxl-strong-margin-top: var(--spectrum-alias-heading-xxxl-margin-top);
  --spectrum-heading-serif-xxxl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-heading-serif-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-heading-serif-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-heading-serif-xxxl-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-heading-serif-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-heading-serif-xxxl-strong-text-transform: none;
  --spectrum-heading-serif-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-heading-serif-xxxl-strong-text-size: var(--spectrum-alias-heading-xxxl-text-size);
  --spectrum-heading-serif-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-130);
}
