.spectrum {
  --spectrum-tabs-compact-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-compact-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-compact-ui-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-compact-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-compact-rule-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-tabs-compact-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-compact-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-compact-ui-icon-color-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-compact-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-compact-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-compact-ui-icon-color-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-compact-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-compact-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-compact-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-compact-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-compact-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-compact-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-ui-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-compact-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-compact-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-compact-ui-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-compact-text-color-selected-hover: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-compact-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-compact-ui-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-compact-text-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-compact-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-compact-ui-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-compact-text-color-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-compact-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-compact-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-compact-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-compact-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-compact-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-compact-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-compact-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-compact-text-transform: none;
  --spectrum-tabs-compact-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-compact-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-compact-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-compact-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-compact-item-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-compact-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-compact-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-compact-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-compact-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-compact-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-compact-padding-x: 0px;
  --spectrum-tabs-compact-item-gap: var(--spectrum-global-dimension-size-300);
  --spectrum-tabs-compact-rule-height: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-compact-focus-area-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-compact-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-compact-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-compact-vertical-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-compact-vertical-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-compact-vertical-ui-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-compact-vertical-selection-indicator-color: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-compact-vertical-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-compact-vertical-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-compact-vertical-ui-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-compact-vertical-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-compact-vertical-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-compact-vertical-ui-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-compact-vertical-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-compact-vertical-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-compact-vertical-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-compact-vertical-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-compact-vertical-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-compact-vertical-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-vertical-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-vertical-ui-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-text-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-ui-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-text-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-ui-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-text-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-ui-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-text-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-compact-vertical-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-vertical-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-compact-vertical-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-vertical-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-compact-vertical-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-compact-vertical-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-compact-vertical-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-compact-vertical-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-compact-vertical-text-transform: none;
  --spectrum-tabs-compact-vertical-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-compact-vertical-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-compact-vertical-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-compact-vertical-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-compact-vertical-item-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-compact-vertical-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-compact-vertical-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-compact-vertical-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-compact-vertical-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-compact-vertical-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-compact-vertical-padding-x: var(--spectrum-global-dimension-size-0);
  --spectrum-tabs-compact-vertical-item-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-tabs-compact-vertical-item-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-compact-vertical-rule-width: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-compact-vertical-margin-left: 0px;
  --spectrum-tabs-compact-vertical-margin-right: 0px;
  --spectrum-tabs-compact-vertical-focus-area-gap: var(--spectrum-global-dimension-size-200);
  --spectrum-tabs-compact-vertical-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-compact-vertical-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-compact-vertical-emphasized-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-compact-vertical-emphasized-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-compact-vertical-emphasized-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-compact-vertical-emphasized-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-compact-vertical-emphasized-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-compact-vertical-emphasized-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-compact-vertical-emphasized-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-compact-vertical-emphasized-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-compact-vertical-emphasized-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-compact-vertical-emphasized-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-compact-vertical-emphasized-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-compact-vertical-emphasized-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-compact-vertical-emphasized-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-compact-vertical-emphasized-text-color-selected-hover: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-compact-vertical-emphasized-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-compact-vertical-emphasized-text-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-compact-vertical-emphasized-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-compact-vertical-emphasized-text-color-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-compact-vertical-emphasized-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-compact-vertical-emphasized-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-vertical-emphasized-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-compact-vertical-emphasized-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-compact-vertical-emphasized-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-compact-vertical-emphasized-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-compact-vertical-emphasized-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-compact-vertical-emphasized-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-compact-vertical-emphasized-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-compact-vertical-emphasized-text-transform: none;
  --spectrum-tabs-compact-vertical-emphasized-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-compact-vertical-emphasized-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-compact-vertical-emphasized-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-compact-vertical-emphasized-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-compact-vertical-emphasized-item-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-compact-vertical-emphasized-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-compact-vertical-emphasized-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-compact-vertical-emphasized-padding-x: var(--spectrum-global-dimension-size-0);
  --spectrum-tabs-compact-vertical-emphasized-item-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-tabs-compact-vertical-emphasized-item-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-compact-vertical-emphasized-rule-width: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-compact-vertical-emphasized-margin-left: 0px;
  --spectrum-tabs-compact-vertical-emphasized-margin-right: 0px;
  --spectrum-tabs-compact-vertical-emphasized-focus-area-gap: var(--spectrum-global-dimension-size-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-ui-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-selection-indicator-color: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-ui-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-ui-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-ui-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-text-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-ui-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-text-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-ui-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-text-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-ui-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-text-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-text-transform: none;
  --spectrum-tabs-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-item-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-padding-x: 0px;
  --spectrum-tabs-item-gap: var(--spectrum-global-dimension-size-300);
  --spectrum-tabs-rule-height: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-focus-area-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-emphasized-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-emphasized-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-emphasized-ui-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-emphasized-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-emphasized-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-emphasized-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-emphasized-ui-icon-color-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-emphasized-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-emphasized-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-emphasized-ui-icon-color-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-emphasized-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-emphasized-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-emphasized-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-emphasized-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-emphasized-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-emphasized-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-emphasized-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-emphasized-ui-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-emphasized-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-emphasized-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-emphasized-ui-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-emphasized-text-color-selected-hover: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-emphasized-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-emphasized-ui-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-emphasized-text-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-emphasized-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-emphasized-ui-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-emphasized-text-color-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-emphasized-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-emphasized-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-emphasized-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-emphasized-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-emphasized-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-emphasized-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-emphasized-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-emphasized-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-emphasized-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-emphasized-text-transform: none;
  --spectrum-tabs-emphasized-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-emphasized-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-emphasized-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-emphasized-item-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-emphasized-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-emphasized-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-emphasized-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-emphasized-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-emphasized-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-emphasized-padding-x: 0px;
  --spectrum-tabs-emphasized-item-gap: var(--spectrum-global-dimension-size-300);
  --spectrum-tabs-emphasized-rule-height: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-emphasized-focus-area-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-emphasized-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-emphasized-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-quiet-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-quiet-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-ui-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-selection-indicator-color: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-quiet-rule-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-tabs-quiet-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-ui-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-quiet-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-ui-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-ui-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-text-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-ui-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-text-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-ui-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-text-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-ui-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-text-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-quiet-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-quiet-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-quiet-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-quiet-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-quiet-text-transform: none;
  --spectrum-tabs-quiet-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-quiet-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-quiet-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-quiet-item-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-quiet-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-quiet-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-quiet-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-padding-x: 0px;
  --spectrum-tabs-quiet-item-gap: var(--spectrum-global-dimension-size-300);
  --spectrum-tabs-quiet-rule-height: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-focus-area-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-quiet-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-quiet-compact-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-quiet-compact-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-compact-ui-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-compact-selection-indicator-color: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-quiet-compact-rule-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-tabs-quiet-compact-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-compact-ui-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-compact-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-quiet-compact-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-compact-ui-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-compact-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-compact-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-compact-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-compact-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-ui-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-text-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-ui-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-text-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-ui-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-text-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-ui-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-text-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-compact-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-quiet-compact-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-quiet-compact-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-quiet-compact-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-quiet-compact-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-quiet-compact-text-transform: none;
  --spectrum-tabs-quiet-compact-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-quiet-compact-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-quiet-compact-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-quiet-compact-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-quiet-compact-item-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-quiet-compact-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-quiet-compact-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-quiet-compact-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-compact-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-padding-x: 0px;
  --spectrum-tabs-quiet-compact-item-gap: var(--spectrum-global-dimension-size-300);
  --spectrum-tabs-quiet-compact-rule-height: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-compact-focus-area-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-quiet-compact-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-quiet-compact-emphasized-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-quiet-compact-emphasized-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-quiet-compact-emphasized-rule-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-tabs-quiet-compact-emphasized-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-emphasized-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-compact-emphasized-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-quiet-compact-emphasized-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-compact-emphasized-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-emphasized-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-compact-emphasized-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-emphasized-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-compact-emphasized-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-compact-emphasized-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-quiet-compact-emphasized-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-compact-emphasized-text-color-selected-hover: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-quiet-compact-emphasized-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-compact-emphasized-text-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-quiet-compact-emphasized-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-compact-emphasized-text-color-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-quiet-compact-emphasized-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-compact-emphasized-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-emphasized-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-compact-emphasized-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-emphasized-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-quiet-compact-emphasized-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-quiet-compact-emphasized-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-quiet-compact-emphasized-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-quiet-compact-emphasized-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-quiet-compact-emphasized-text-transform: none;
  --spectrum-tabs-quiet-compact-emphasized-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-quiet-compact-emphasized-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-quiet-compact-emphasized-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-quiet-compact-emphasized-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-quiet-compact-emphasized-item-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-quiet-compact-emphasized-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-emphasized-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-emphasized-padding-x: 0px;
  --spectrum-tabs-quiet-compact-emphasized-item-gap: var(--spectrum-global-dimension-size-300);
  --spectrum-tabs-quiet-compact-emphasized-rule-height: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-compact-emphasized-focus-area-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-quiet-compact-vertical-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-quiet-compact-vertical-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-compact-vertical-selection-indicator-color: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-quiet-compact-vertical-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-vertical-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-compact-vertical-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-quiet-compact-vertical-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-compact-vertical-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-vertical-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-compact-vertical-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-vertical-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-text-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-text-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-text-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-text-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-compact-vertical-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-rule-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-tabs-quiet-compact-vertical-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-quiet-compact-vertical-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-quiet-compact-vertical-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-quiet-compact-vertical-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-quiet-compact-vertical-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-quiet-compact-vertical-text-transform: none;
  --spectrum-tabs-quiet-compact-vertical-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-quiet-compact-vertical-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-quiet-compact-vertical-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-quiet-compact-vertical-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-quiet-compact-vertical-item-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-quiet-compact-vertical-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-vertical-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-quiet-compact-vertical-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-quiet-compact-vertical-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-compact-vertical-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-vertical-padding-x: var(--spectrum-global-dimension-size-0);
  --spectrum-tabs-quiet-compact-vertical-item-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-tabs-quiet-compact-vertical-item-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-quiet-compact-vertical-rule-width: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-compact-vertical-margin-left: 0px;
  --spectrum-tabs-quiet-compact-vertical-margin-right: 0px;
  --spectrum-tabs-quiet-compact-vertical-focus-area-gap: var(--spectrum-global-dimension-size-200);
  --spectrum-tabs-quiet-compact-vertical-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-quiet-compact-vertical-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-selected-hover: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-compact-vertical-emphasized-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-compact-vertical-emphasized-rule-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-transform: none;
  --spectrum-tabs-quiet-compact-vertical-emphasized-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-quiet-compact-vertical-emphasized-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-quiet-compact-vertical-emphasized-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-quiet-compact-vertical-emphasized-item-height: var(--spectrum-global-dimension-size-400);
  --spectrum-tabs-quiet-compact-vertical-emphasized-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-vertical-emphasized-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-compact-vertical-emphasized-padding-x: var(--spectrum-global-dimension-size-0);
  --spectrum-tabs-quiet-compact-vertical-emphasized-item-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-tabs-quiet-compact-vertical-emphasized-item-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-quiet-compact-vertical-emphasized-rule-width: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-compact-vertical-emphasized-margin-left: 0px;
  --spectrum-tabs-quiet-compact-vertical-emphasized-margin-right: 0px;
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-area-gap: var(--spectrum-global-dimension-size-200);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-quiet-emphasized-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-quiet-emphasized-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-emphasized-ui-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-emphasized-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-quiet-emphasized-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-emphasized-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-emphasized-ui-icon-color-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-emphasized-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-quiet-emphasized-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-emphasized-ui-icon-color-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-emphasized-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-emphasized-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-emphasized-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-emphasized-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-emphasized-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-emphasized-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-emphasized-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-emphasized-ui-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-emphasized-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-quiet-emphasized-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-emphasized-ui-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-emphasized-text-color-selected-hover: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-quiet-emphasized-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-emphasized-ui-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-emphasized-text-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-quiet-emphasized-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-emphasized-ui-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-emphasized-text-color-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-quiet-emphasized-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-emphasized-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-emphasized-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-emphasized-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-quiet-emphasized-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-quiet-emphasized-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-quiet-emphasized-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-quiet-emphasized-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-quiet-emphasized-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-quiet-emphasized-text-transform: none;
  --spectrum-tabs-quiet-emphasized-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-quiet-emphasized-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-quiet-emphasized-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-quiet-emphasized-item-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-quiet-emphasized-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-emphasized-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-quiet-emphasized-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-quiet-emphasized-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-emphasized-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-emphasized-padding-x: 0px;
  --spectrum-tabs-quiet-emphasized-item-gap: var(--spectrum-global-dimension-size-300);
  --spectrum-tabs-quiet-emphasized-rule-height: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-emphasized-focus-area-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-emphasized-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-quiet-emphasized-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-quiet-vertical-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-quiet-vertical-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-vertical-ui-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-vertical-selection-indicator-color: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-quiet-vertical-rule-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-tabs-quiet-vertical-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-vertical-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-vertical-ui-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-vertical-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-quiet-vertical-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-vertical-ui-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-vertical-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-vertical-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-vertical-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-vertical-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-vertical-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-vertical-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-vertical-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-vertical-ui-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-text-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-ui-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-text-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-ui-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-text-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-ui-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-text-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-quiet-vertical-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-vertical-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-vertical-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-vertical-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-quiet-vertical-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-quiet-vertical-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-quiet-vertical-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-quiet-vertical-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-quiet-vertical-text-transform: none;
  --spectrum-tabs-quiet-vertical-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-quiet-vertical-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-quiet-vertical-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-quiet-vertical-item-height: var(--spectrum-global-dimension-size-550);
  --spectrum-tabs-quiet-vertical-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-vertical-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-quiet-vertical-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-quiet-vertical-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-vertical-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-vertical-padding-x: var(--spectrum-global-dimension-size-0);
  --spectrum-tabs-quiet-vertical-item-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-tabs-quiet-vertical-item-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-quiet-vertical-rule-width: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-vertical-margin-left: 0px;
  --spectrum-tabs-quiet-vertical-margin-right: 0px;
  --spectrum-tabs-quiet-vertical-focus-area-gap: var(--spectrum-global-dimension-size-200);
  --spectrum-tabs-quiet-vertical-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-quiet-vertical-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-quiet-vertical-emphasized-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-quiet-vertical-emphasized-rule-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-selected-hover: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-tabs-quiet-vertical-emphasized-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-vertical-emphasized-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-quiet-vertical-emphasized-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-quiet-vertical-emphasized-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-quiet-vertical-emphasized-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-quiet-vertical-emphasized-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-quiet-vertical-emphasized-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-quiet-vertical-emphasized-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-quiet-vertical-emphasized-text-transform: none;
  --spectrum-tabs-quiet-vertical-emphasized-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-quiet-vertical-emphasized-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-quiet-vertical-emphasized-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-quiet-vertical-emphasized-item-height: var(--spectrum-global-dimension-size-550);
  --spectrum-tabs-quiet-vertical-emphasized-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-vertical-emphasized-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-quiet-vertical-emphasized-padding-x: var(--spectrum-global-dimension-size-0);
  --spectrum-tabs-quiet-vertical-emphasized-item-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-tabs-quiet-vertical-emphasized-item-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-quiet-vertical-emphasized-rule-width: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-quiet-vertical-emphasized-margin-left: 0px;
  --spectrum-tabs-quiet-vertical-emphasized-margin-right: 0px;
  --spectrum-tabs-quiet-vertical-emphasized-focus-area-gap: var(--spectrum-global-dimension-size-200);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-tabs-vertical-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-tabs-vertical-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-vertical-ui-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-tabs-vertical-selection-indicator-color: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-focus-ring-color: var(--spectrum-alias-border-color-focus);
  --spectrum-tabs-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-vertical-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-vertical-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-vertical-ui-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-tabs-vertical-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-tabs-vertical-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-vertical-ui-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-tabs-vertical-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-vertical-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-vertical-ui-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-tabs-vertical-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-tabs-vertical-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-vertical-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-vertical-ui-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-vertical-ui-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-text-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-icon-color-selected: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-ui-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-text-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-icon-color-selected-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-ui-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-text-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-icon-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-ui-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-text-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-icon-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tabs-vertical-ui-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-vertical-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-tabs-vertical-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-tabs-vertical-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-tabs-vertical-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tabs-vertical-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-tabs-vertical-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-tabs-vertical-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-tabs-vertical-text-transform: none;
  --spectrum-tabs-vertical-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-tabs-vertical-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-tabs-vertical-height: var(--spectrum-global-dimension-size-600);
  --spectrum-tabs-vertical-item-height: var(--spectrum-global-dimension-size-550);
  --spectrum-tabs-vertical-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-vertical-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-tabs-vertical-focus-ring-height: var(--spectrum-alias-single-line-height);
  --spectrum-tabs-vertical-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-vertical-focus-ring-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tabs-vertical-padding-x: var(--spectrum-global-dimension-size-0);
  --spectrum-tabs-vertical-item-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-tabs-vertical-item-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-tabs-vertical-rule-width: var(--spectrum-alias-border-size-thick);
  --spectrum-tabs-vertical-margin-left: 0px;
  --spectrum-tabs-vertical-margin-right: 0px;
  --spectrum-tabs-vertical-focus-area-gap: var(--spectrum-global-dimension-size-200);
  --spectrum-tabs-vertical-selection-indicator-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-tabs-vertical-selection-indicator-animation-ease: var(--spectrum-global-animation-ease-in-out);
}
