.spectrum {
  --spectrum-body-1-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-1-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-1-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-1-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-1-text-transform: none;
  --spectrum-body-1-margin-top: 0px;
  --spectrum-body-1-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-1-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-1-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-2-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-2-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-2-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-2-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-2-text-transform: none;
  --spectrum-body-2-margin-top: 0px;
  --spectrum-body-2-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-2-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-2-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-3-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-3-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-3-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-3-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-3-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-3-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-3-text-transform: none;
  --spectrum-body-3-margin-top: 0px;
  --spectrum-body-3-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-3-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-3-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-4-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-4-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-4-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-4-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-4-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-4-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-4-text-transform: none;
  --spectrum-body-4-margin-top: 0px;
  --spectrum-body-4-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-4-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-4-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-5-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-5-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-5-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-5-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-5-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-5-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-5-text-transform: none;
  --spectrum-body-5-margin-top: 0px;
  --spectrum-body-5-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-5-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-5-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-article-1-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-1-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-1-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-1-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-1-text-transform: none;
  --spectrum-body-article-1-margin-top: 0px;
  --spectrum-body-article-1-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-1-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-article-1-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-article-1-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-1-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-article-1-emphasis-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-1-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-1-emphasis-text-transform: none;
  --spectrum-body-article-1-emphasis-margin-top: 0px;
  --spectrum-body-article-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-1-emphasis-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-article-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-article-1-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-1-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-1-strong-text-font-weight: var(--spectrum-alias-article-body-text-font-weight-strong);
  --spectrum-body-article-1-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-1-strong-text-transform: none;
  --spectrum-body-article-1-strong-margin-top: 0px;
  --spectrum-body-article-1-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-1-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-article-1-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-article-2-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-2-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-2-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-2-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-2-text-transform: none;
  --spectrum-body-article-2-margin-top: 0px;
  --spectrum-body-article-2-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-2-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-article-2-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-article-2-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-2-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-article-2-emphasis-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-2-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-2-emphasis-text-transform: none;
  --spectrum-body-article-2-emphasis-margin-top: 0px;
  --spectrum-body-article-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-2-emphasis-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-article-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-article-2-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-2-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-2-strong-text-font-weight: var(--spectrum-alias-article-body-text-font-weight-strong);
  --spectrum-body-article-2-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-2-strong-text-transform: none;
  --spectrum-body-article-2-strong-margin-top: 0px;
  --spectrum-body-article-2-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-2-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-article-2-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-article-3-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-3-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-3-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-3-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-3-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-3-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-3-text-transform: none;
  --spectrum-body-article-3-margin-top: 0px;
  --spectrum-body-article-3-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-3-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-article-3-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-article-3-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-3-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-3-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-article-3-emphasis-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-3-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-3-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-3-emphasis-text-transform: none;
  --spectrum-body-article-3-emphasis-margin-top: 0px;
  --spectrum-body-article-3-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-3-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-article-3-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-article-3-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-3-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-3-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-3-strong-text-font-weight: var(--spectrum-alias-article-body-text-font-weight-strong);
  --spectrum-body-article-3-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-3-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-3-strong-text-transform: none;
  --spectrum-body-article-3-strong-margin-top: 0px;
  --spectrum-body-article-3-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-3-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-article-3-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-article-4-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-4-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-4-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-4-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-4-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-4-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-4-text-transform: none;
  --spectrum-body-article-4-margin-top: 0px;
  --spectrum-body-article-4-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-4-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-article-4-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-article-4-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-4-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-4-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-article-4-emphasis-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-4-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-4-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-4-emphasis-text-transform: none;
  --spectrum-body-article-4-emphasis-margin-top: 0px;
  --spectrum-body-article-4-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-4-emphasis-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-article-4-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-article-4-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-4-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-4-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-4-strong-text-font-weight: var(--spectrum-alias-article-body-text-font-weight-strong);
  --spectrum-body-article-4-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-4-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-4-strong-text-transform: none;
  --spectrum-body-article-4-strong-margin-top: 0px;
  --spectrum-body-article-4-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-4-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-article-4-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-article-5-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-5-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-5-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-5-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-5-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-5-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-5-text-transform: none;
  --spectrum-body-article-5-margin-top: 0px;
  --spectrum-body-article-5-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-5-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-article-5-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-article-5-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-5-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-5-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-article-5-emphasis-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-body-article-5-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-5-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-5-emphasis-text-transform: none;
  --spectrum-body-article-5-emphasis-margin-top: 0px;
  --spectrum-body-article-5-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-5-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-article-5-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-article-5-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-article-5-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-body-article-5-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-article-5-strong-text-font-weight: var(--spectrum-alias-article-body-text-font-weight-strong);
  --spectrum-body-article-5-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-article-5-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-article-5-strong-text-transform: none;
  --spectrum-body-article-5-strong-margin-top: 0px;
  --spectrum-body-article-5-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-article-5-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-article-5-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-code-1-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-1-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-1-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-1-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-1-text-transform: none;
  --spectrum-body-code-1-margin-top: 0px;
  --spectrum-body-code-1-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-1-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-code-1-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-code-1-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-1-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-code-1-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-1-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-1-emphasis-text-transform: none;
  --spectrum-body-code-1-emphasis-margin-top: 0px;
  --spectrum-body-code-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-1-emphasis-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-code-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-code-1-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-1-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-1-strong-text-font-weight: var(--spectrum-alias-code-text-font-weight-strong);
  --spectrum-body-code-1-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-1-strong-text-transform: none;
  --spectrum-body-code-1-strong-margin-top: 0px;
  --spectrum-body-code-1-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-1-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-code-1-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-code-2-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-2-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-2-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-2-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-2-text-transform: none;
  --spectrum-body-code-2-margin-top: 0px;
  --spectrum-body-code-2-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-2-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-code-2-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-code-2-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-2-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-code-2-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-2-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-2-emphasis-text-transform: none;
  --spectrum-body-code-2-emphasis-margin-top: 0px;
  --spectrum-body-code-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-2-emphasis-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-code-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-code-2-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-2-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-2-strong-text-font-weight: var(--spectrum-alias-code-text-font-weight-strong);
  --spectrum-body-code-2-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-2-strong-text-transform: none;
  --spectrum-body-code-2-strong-margin-top: 0px;
  --spectrum-body-code-2-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-2-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-code-2-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-code-3-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-3-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-3-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-3-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-3-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-3-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-3-text-transform: none;
  --spectrum-body-code-3-margin-top: 0px;
  --spectrum-body-code-3-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-3-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-code-3-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-code-3-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-3-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-3-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-code-3-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-3-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-3-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-3-emphasis-text-transform: none;
  --spectrum-body-code-3-emphasis-margin-top: 0px;
  --spectrum-body-code-3-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-3-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-code-3-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-code-3-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-3-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-3-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-3-strong-text-font-weight: var(--spectrum-alias-code-text-font-weight-strong);
  --spectrum-body-code-3-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-3-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-3-strong-text-transform: none;
  --spectrum-body-code-3-strong-margin-top: 0px;
  --spectrum-body-code-3-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-3-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-code-3-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-code-4-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-4-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-4-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-4-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-4-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-4-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-4-text-transform: none;
  --spectrum-body-code-4-margin-top: 0px;
  --spectrum-body-code-4-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-4-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-code-4-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-code-4-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-4-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-4-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-code-4-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-4-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-4-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-4-emphasis-text-transform: none;
  --spectrum-body-code-4-emphasis-margin-top: 0px;
  --spectrum-body-code-4-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-4-emphasis-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-code-4-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-code-4-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-4-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-4-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-4-strong-text-font-weight: var(--spectrum-alias-code-text-font-weight-strong);
  --spectrum-body-code-4-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-4-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-4-strong-text-transform: none;
  --spectrum-body-code-4-strong-margin-top: 0px;
  --spectrum-body-code-4-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-4-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-code-4-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-code-5-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-5-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-5-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-5-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-5-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-5-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-5-text-transform: none;
  --spectrum-body-code-5-margin-top: 0px;
  --spectrum-body-code-5-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-5-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-code-5-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-code-5-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-5-emphasis-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-5-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-code-5-emphasis-text-font-weight: var(--spectrum-alias-code-text-font-weight-regular);
  --spectrum-body-code-5-emphasis-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-5-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-5-emphasis-text-transform: none;
  --spectrum-body-code-5-emphasis-margin-top: 0px;
  --spectrum-body-code-5-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-5-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-code-5-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-code-5-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-code-5-strong-text-font-family: var(--spectrum-alias-code-text-font-family);
  --spectrum-body-code-5-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-code-5-strong-text-font-weight: var(--spectrum-alias-code-text-font-weight-strong);
  --spectrum-body-code-5-strong-text-line-height: var(--spectrum-alias-code-text-line-height);
  --spectrum-body-code-5-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-code-5-strong-text-transform: none;
  --spectrum-body-code-5-strong-margin-top: 0px;
  --spectrum-body-code-5-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-code-5-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-code-5-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-1-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-1-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-1-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-1-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-1-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-1-emphasis-text-transform: none;
  --spectrum-body-1-emphasis-margin-top: 0px;
  --spectrum-body-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-1-emphasis-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-1-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-1-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-1-strong-text-font-weight: var(--spectrum-alias-body-text-font-weight-strong);
  --spectrum-body-1-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-1-strong-text-transform: none;
  --spectrum-body-1-strong-margin-top: 0px;
  --spectrum-body-1-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-1-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-1-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-2-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-2-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-2-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-2-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-2-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-2-emphasis-text-transform: none;
  --spectrum-body-2-emphasis-margin-top: 0px;
  --spectrum-body-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-2-emphasis-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-2-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-2-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-2-strong-text-font-weight: var(--spectrum-alias-body-text-font-weight-strong);
  --spectrum-body-2-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-2-strong-text-transform: none;
  --spectrum-body-2-strong-margin-top: 0px;
  --spectrum-body-2-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-2-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-2-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-3-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-3-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-3-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-3-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-3-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-3-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-3-emphasis-text-transform: none;
  --spectrum-body-3-emphasis-margin-top: 0px;
  --spectrum-body-3-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-3-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-3-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-3-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-3-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-3-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-3-strong-text-font-weight: var(--spectrum-alias-body-text-font-weight-strong);
  --spectrum-body-3-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-3-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-3-strong-text-transform: none;
  --spectrum-body-3-strong-margin-top: 0px;
  --spectrum-body-3-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-3-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-3-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-4-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-4-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-4-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-4-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-4-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-4-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-4-emphasis-text-transform: none;
  --spectrum-body-4-emphasis-margin-top: 0px;
  --spectrum-body-4-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-4-emphasis-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-4-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-4-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-4-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-4-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-4-strong-text-font-weight: var(--spectrum-alias-body-text-font-weight-strong);
  --spectrum-body-4-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-4-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-4-strong-text-transform: none;
  --spectrum-body-4-strong-margin-top: 0px;
  --spectrum-body-4-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-4-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-4-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-5-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-5-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-5-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-5-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-5-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-5-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-5-emphasis-text-transform: none;
  --spectrum-body-5-emphasis-margin-top: 0px;
  --spectrum-body-5-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-5-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-5-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-5-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-5-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-5-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-5-strong-text-font-weight: var(--spectrum-alias-body-text-font-weight-strong);
  --spectrum-body-5-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-5-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-5-strong-text-transform: none;
  --spectrum-body-5-strong-margin-top: 0px;
  --spectrum-body-5-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-5-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-5-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-han-1-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-1-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-1-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-1-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-1-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-1-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-1-text-transform: none;
  --spectrum-body-han-1-margin-top: 0px;
  --spectrum-body-han-1-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-1-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-han-1-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-han-1-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-1-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-1-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-1-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-1-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-1-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-1-emphasis-text-transform: none;
  --spectrum-body-han-1-emphasis-margin-top: 0px;
  --spectrum-body-han-1-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-1-emphasis-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-han-1-emphasis-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-han-1-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-1-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-1-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-1-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-1-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-1-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-1-strong-text-transform: none;
  --spectrum-body-han-1-strong-margin-top: 0px;
  --spectrum-body-han-1-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-1-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-han-1-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-han-2-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-2-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-2-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-2-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-2-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-2-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-2-text-transform: none;
  --spectrum-body-han-2-margin-top: 0px;
  --spectrum-body-han-2-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-2-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-han-2-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-han-2-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-2-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-2-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-2-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-2-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-2-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-2-emphasis-text-transform: none;
  --spectrum-body-han-2-emphasis-margin-top: 0px;
  --spectrum-body-han-2-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-2-emphasis-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-han-2-emphasis-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-han-2-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-2-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-2-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-2-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-2-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-2-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-2-strong-text-transform: none;
  --spectrum-body-han-2-strong-margin-top: 0px;
  --spectrum-body-han-2-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-2-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-han-2-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-han-3-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-3-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-3-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-3-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-3-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-3-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-3-text-transform: none;
  --spectrum-body-han-3-margin-top: 0px;
  --spectrum-body-han-3-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-3-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-han-3-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-han-3-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-3-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-3-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-3-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-3-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-3-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-3-emphasis-text-transform: none;
  --spectrum-body-han-3-emphasis-margin-top: 0px;
  --spectrum-body-han-3-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-3-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-han-3-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-han-3-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-3-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-3-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-3-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-3-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-3-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-3-strong-text-transform: none;
  --spectrum-body-han-3-strong-margin-top: 0px;
  --spectrum-body-han-3-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-3-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-han-3-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-han-4-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-4-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-4-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-4-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-4-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-4-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-4-text-transform: none;
  --spectrum-body-han-4-margin-top: 0px;
  --spectrum-body-han-4-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-4-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-han-4-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-han-4-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-4-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-4-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-4-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-4-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-4-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-4-emphasis-text-transform: none;
  --spectrum-body-han-4-emphasis-margin-top: 0px;
  --spectrum-body-han-4-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-4-emphasis-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-han-4-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-han-4-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-4-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-4-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-4-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-4-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-4-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-4-strong-text-transform: none;
  --spectrum-body-han-4-strong-margin-top: 0px;
  --spectrum-body-han-4-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-4-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-han-4-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-han-5-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-5-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-5-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-5-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-5-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-5-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-5-text-transform: none;
  --spectrum-body-han-5-margin-top: 0px;
  --spectrum-body-han-5-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-5-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-han-5-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-han-5-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-5-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-5-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-5-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-5-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-5-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-5-emphasis-text-transform: none;
  --spectrum-body-han-5-emphasis-margin-top: 0px;
  --spectrum-body-han-5-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-5-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-han-5-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-han-5-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-5-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-5-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-5-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-5-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-5-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-5-strong-text-transform: none;
  --spectrum-body-han-5-strong-margin-top: 0px;
  --spectrum-body-han-5-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-5-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-han-5-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-text-transform: none;
  --spectrum-body-font-line-height: var(--spectrum-alias-line-height-medium);
  --spectrum-body-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-large-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-large-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-large-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-large-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-large-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-large-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-large-text-transform: none;
  --spectrum-body-large-font-line-height: var(--spectrum-alias-line-height-large);
  --spectrum-body-large-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-large-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-secondary-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-body-secondary-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-secondary-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-secondary-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-secondary-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-secondary-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-secondary-text-transform: none;
  --spectrum-body-secondary-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-secondary-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-small-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-small-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-small-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-small-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-small-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-small-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-small-text-transform: none;
  --spectrum-body-small-font-line-height: var(--spectrum-alias-line-height-small);
  --spectrum-body-small-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-small-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-body-han-l-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-l-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-l-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-l-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-l-text-transform: none;
  --spectrum-body-han-l-margin-top: 0px;
  --spectrum-body-han-l-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-l-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-han-l-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-han-l-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-l-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-l-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-l-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-l-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-l-emphasis-text-transform: none;
  --spectrum-body-han-l-emphasis-margin-top: 0px;
  --spectrum-body-han-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-han-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-han-l-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-l-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-l-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-l-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-l-emphasis-strong-text-transform: none;
  --spectrum-body-han-l-emphasis-strong-margin-top: 0px;
  --spectrum-body-han-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-han-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-han-l-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-l-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-l-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-l-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-l-strong-text-transform: none;
  --spectrum-body-han-l-strong-margin-top: 0px;
  --spectrum-body-han-l-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-l-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-han-l-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-han-m-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-m-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-m-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-m-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-m-text-transform: none;
  --spectrum-body-han-m-margin-top: 0px;
  --spectrum-body-han-m-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-m-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-han-m-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-han-m-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-m-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-m-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-m-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-m-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-m-emphasis-text-transform: none;
  --spectrum-body-han-m-emphasis-margin-top: 0px;
  --spectrum-body-han-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-han-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-han-m-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-m-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-m-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-m-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-m-emphasis-strong-text-transform: none;
  --spectrum-body-han-m-emphasis-strong-margin-top: 0px;
  --spectrum-body-han-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-han-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-han-m-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-m-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-m-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-m-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-m-strong-text-transform: none;
  --spectrum-body-han-m-strong-margin-top: 0px;
  --spectrum-body-han-m-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-m-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-han-m-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-han-s-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-s-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-s-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-s-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-s-text-transform: none;
  --spectrum-body-han-s-margin-top: 0px;
  --spectrum-body-han-s-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-s-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-han-s-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-han-s-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-s-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-s-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-s-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-s-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-s-emphasis-text-transform: none;
  --spectrum-body-han-s-emphasis-margin-top: 0px;
  --spectrum-body-han-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-s-emphasis-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-han-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-han-s-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-s-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-s-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-s-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-s-emphasis-strong-text-transform: none;
  --spectrum-body-han-s-emphasis-strong-margin-top: 0px;
  --spectrum-body-han-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-s-emphasis-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-han-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-han-s-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-s-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-s-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-s-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-s-strong-text-transform: none;
  --spectrum-body-han-s-strong-margin-top: 0px;
  --spectrum-body-han-s-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-s-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-han-s-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-han-xl-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xl-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-xl-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xl-text-transform: none;
  --spectrum-body-han-xl-margin-top: 0px;
  --spectrum-body-han-xl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xl-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-han-xl-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-han-xl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xl-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-xl-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xl-emphasis-text-transform: none;
  --spectrum-body-han-xl-emphasis-margin-top: 0px;
  --spectrum-body-han-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-han-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-han-xl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xl-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-xl-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xl-emphasis-strong-text-transform: none;
  --spectrum-body-han-xl-emphasis-strong-margin-top: 0px;
  --spectrum-body-han-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-han-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-han-xl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xl-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-xl-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xl-strong-text-transform: none;
  --spectrum-body-han-xl-strong-margin-top: 0px;
  --spectrum-body-han-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xl-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-han-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-han-xs-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xs-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xs-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-xs-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xs-text-transform: none;
  --spectrum-body-han-xs-margin-top: 0px;
  --spectrum-body-han-xs-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xs-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-han-xs-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-han-xs-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xs-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xs-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xs-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-xs-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xs-emphasis-text-transform: none;
  --spectrum-body-han-xs-emphasis-margin-top: 0px;
  --spectrum-body-han-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xs-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-han-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-han-xs-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xs-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xs-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-xs-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xs-emphasis-strong-text-transform: none;
  --spectrum-body-han-xs-emphasis-strong-margin-top: 0px;
  --spectrum-body-han-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xs-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-han-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-han-xs-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xs-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xs-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-xs-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xs-strong-text-transform: none;
  --spectrum-body-han-xs-strong-margin-top: 0px;
  --spectrum-body-han-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xs-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-han-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-han-xxl-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xxl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xxl-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-xxl-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xxl-text-transform: none;
  --spectrum-body-han-xxl-margin-top: 0px;
  --spectrum-body-han-xxl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xxl-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-han-xxl-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-han-xxl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xxl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xxl-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-xxl-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xxl-emphasis-text-transform: none;
  --spectrum-body-han-xxl-emphasis-margin-top: 0px;
  --spectrum-body-han-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xxl-emphasis-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-han-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-han-xxl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xxl-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xxl-emphasis-strong-text-transform: none;
  --spectrum-body-han-xxl-emphasis-strong-margin-top: 0px;
  --spectrum-body-han-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xxl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-han-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-han-xxl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xxl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xxl-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-xxl-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xxl-strong-text-transform: none;
  --spectrum-body-han-xxl-strong-margin-top: 0px;
  --spectrum-body-han-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xxl-strong-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-han-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-han-xxxl-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xxxl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xxxl-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-regular);
  --spectrum-body-han-xxxl-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xxxl-text-transform: none;
  --spectrum-body-han-xxxl-margin-top: 0px;
  --spectrum-body-han-xxxl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xxxl-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-han-xxxl-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-han-xxxl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xxxl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xxxl-emphasis-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-emphasis);
  --spectrum-body-han-xxxl-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xxxl-emphasis-text-transform: none;
  --spectrum-body-han-xxxl-emphasis-margin-top: 0px;
  --spectrum-body-han-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xxxl-emphasis-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-han-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-han-xxxl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xxxl-emphasis-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xxxl-emphasis-strong-text-transform: none;
  --spectrum-body-han-xxxl-emphasis-strong-margin-top: 0px;
  --spectrum-body-han-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xxxl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-han-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-han-xxxl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-han-xxxl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-body-han-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-han-xxxl-strong-text-font-weight: var(--spectrum-alias-han-body-text-font-weight-strong);
  --spectrum-body-han-xxxl-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-body-han-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-body-han-xxxl-strong-text-transform: none;
  --spectrum-body-han-xxxl-strong-margin-top: 0px;
  --spectrum-body-han-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-han-xxxl-strong-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-han-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-base-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-base-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-base-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-base-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-base-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-base-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-base-text-transform: none;
  --spectrum-body-base-margin-top: 0px;
  --spectrum-body-base-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-l-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-l-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-l-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-l-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-l-text-transform: none;
  --spectrum-body-l-margin-top: 0px;
  --spectrum-body-l-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-l-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-l-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-l-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-l-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-l-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-l-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-l-emphasis-text-transform: none;
  --spectrum-body-l-emphasis-margin-top: 0px;
  --spectrum-body-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-l-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-l-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-l-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-l-emphasis-strong-text-transform: none;
  --spectrum-body-l-emphasis-strong-margin-top: 0px;
  --spectrum-body-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-l-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-l-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-l-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-l-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-l-strong-text-transform: none;
  --spectrum-body-l-strong-margin-top: 0px;
  --spectrum-body-l-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-l-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-l-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-m-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-m-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-m-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-m-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-m-text-transform: none;
  --spectrum-body-m-margin-top: 0px;
  --spectrum-body-m-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-m-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-m-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-m-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-m-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-m-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-m-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-m-emphasis-text-transform: none;
  --spectrum-body-m-emphasis-margin-top: 0px;
  --spectrum-body-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-m-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-m-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-m-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-m-emphasis-strong-text-transform: none;
  --spectrum-body-m-emphasis-strong-margin-top: 0px;
  --spectrum-body-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-m-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-m-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-m-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-m-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-m-strong-text-transform: none;
  --spectrum-body-m-strong-margin-top: 0px;
  --spectrum-body-m-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-m-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-m-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-s-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-s-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-s-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-s-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-s-text-transform: none;
  --spectrum-body-s-margin-top: 0px;
  --spectrum-body-s-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-s-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-s-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-s-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-s-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-s-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-s-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-s-emphasis-text-transform: none;
  --spectrum-body-s-emphasis-margin-top: 0px;
  --spectrum-body-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-s-emphasis-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-s-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-s-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-s-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-s-emphasis-strong-text-transform: none;
  --spectrum-body-s-emphasis-strong-margin-top: 0px;
  --spectrum-body-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-s-emphasis-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-s-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-s-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-s-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-s-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-s-strong-text-transform: none;
  --spectrum-body-s-strong-margin-top: 0px;
  --spectrum-body-s-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-s-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-s-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-xl-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-xl-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-xl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xl-text-transform: none;
  --spectrum-body-xl-margin-top: 0px;
  --spectrum-body-xl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xl-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-xl-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-xl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-xl-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-xl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xl-emphasis-text-transform: none;
  --spectrum-body-xl-emphasis-margin-top: 0px;
  --spectrum-body-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-xl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-xl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xl-emphasis-strong-text-transform: none;
  --spectrum-body-xl-emphasis-strong-margin-top: 0px;
  --spectrum-body-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-xl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-xl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-xl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xl-strong-text-transform: none;
  --spectrum-body-xl-strong-margin-top: 0px;
  --spectrum-body-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xl-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-xs-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xs-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-xs-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-xs-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xs-text-transform: none;
  --spectrum-body-xs-margin-top: 0px;
  --spectrum-body-xs-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xs-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-xs-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-xs-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xs-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-xs-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-xs-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xs-emphasis-text-transform: none;
  --spectrum-body-xs-emphasis-margin-top: 0px;
  --spectrum-body-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xs-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-xs-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xs-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-xs-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xs-emphasis-strong-text-transform: none;
  --spectrum-body-xs-emphasis-strong-margin-top: 0px;
  --spectrum-body-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xs-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-xs-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xs-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-xs-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-xs-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xs-strong-text-transform: none;
  --spectrum-body-xs-strong-margin-top: 0px;
  --spectrum-body-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xs-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-xxl-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xxl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-xxl-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-xxl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xxl-text-transform: none;
  --spectrum-body-xxl-margin-top: 0px;
  --spectrum-body-xxl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xxl-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-xxl-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-xxl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xxl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-xxl-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-xxl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xxl-emphasis-text-transform: none;
  --spectrum-body-xxl-emphasis-margin-top: 0px;
  --spectrum-body-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xxl-emphasis-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-xxl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xxl-emphasis-strong-text-transform: none;
  --spectrum-body-xxl-emphasis-strong-margin-top: 0px;
  --spectrum-body-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xxl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-xxl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xxl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-xxl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xxl-strong-text-transform: none;
  --spectrum-body-xxl-strong-margin-top: 0px;
  --spectrum-body-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xxl-strong-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-xxxl-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xxxl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-xxxl-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-xxxl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xxxl-text-transform: none;
  --spectrum-body-xxxl-margin-top: 0px;
  --spectrum-body-xxxl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xxxl-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-xxxl-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-xxxl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xxxl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-xxxl-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-xxxl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xxxl-emphasis-text-transform: none;
  --spectrum-body-xxxl-emphasis-margin-top: 0px;
  --spectrum-body-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xxxl-emphasis-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-xxxl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xxxl-emphasis-strong-text-transform: none;
  --spectrum-body-xxxl-emphasis-strong-margin-top: 0px;
  --spectrum-body-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xxxl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-xxxl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-xxxl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-body-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-xxxl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-xxxl-strong-text-transform: none;
  --spectrum-body-xxxl-strong-margin-top: 0px;
  --spectrum-body-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-xxxl-strong-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-serif-l-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-l-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-l-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-l-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-l-text-transform: none;
  --spectrum-body-serif-l-margin-top: 0px;
  --spectrum-body-serif-l-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-l-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-serif-l-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-serif-l-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-l-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-l-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-l-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-l-emphasis-text-transform: none;
  --spectrum-body-serif-l-emphasis-margin-top: 0px;
  --spectrum-body-serif-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-serif-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-serif-l-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-l-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-l-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-l-emphasis-strong-text-transform: none;
  --spectrum-body-serif-l-emphasis-strong-margin-top: 0px;
  --spectrum-body-serif-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-serif-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-serif-l-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-l-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-l-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-l-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-l-strong-text-transform: none;
  --spectrum-body-serif-l-strong-margin-top: 0px;
  --spectrum-body-serif-l-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-l-strong-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-body-serif-l-strong-margin-bottom: var(--spectrum-global-dimension-size-160);
  --spectrum-body-serif-m-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-m-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-m-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-m-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-m-text-transform: none;
  --spectrum-body-serif-m-margin-top: 0px;
  --spectrum-body-serif-m-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-m-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-serif-m-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-serif-m-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-m-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-m-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-m-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-m-emphasis-text-transform: none;
  --spectrum-body-serif-m-emphasis-margin-top: 0px;
  --spectrum-body-serif-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-serif-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-serif-m-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-m-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-m-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-m-emphasis-strong-text-transform: none;
  --spectrum-body-serif-m-emphasis-strong-margin-top: 0px;
  --spectrum-body-serif-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-serif-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-serif-m-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-m-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-m-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-m-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-m-strong-text-transform: none;
  --spectrum-body-serif-m-strong-margin-top: 0px;
  --spectrum-body-serif-m-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-m-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-body-serif-m-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-body-serif-s-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-s-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-s-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-s-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-s-text-transform: none;
  --spectrum-body-serif-s-margin-top: 0px;
  --spectrum-body-serif-s-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-s-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-serif-s-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-serif-s-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-s-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-s-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-s-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-s-emphasis-text-transform: none;
  --spectrum-body-serif-s-emphasis-margin-top: 0px;
  --spectrum-body-serif-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-s-emphasis-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-serif-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-serif-s-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-s-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-s-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-s-emphasis-strong-text-transform: none;
  --spectrum-body-serif-s-emphasis-strong-margin-top: 0px;
  --spectrum-body-serif-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-s-emphasis-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-serif-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-serif-s-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-s-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-s-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-s-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-s-strong-text-transform: none;
  --spectrum-body-serif-s-strong-margin-top: 0px;
  --spectrum-body-serif-s-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-s-strong-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-body-serif-s-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-body-serif-xl-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-xl-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-xl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xl-text-transform: none;
  --spectrum-body-serif-xl-margin-top: 0px;
  --spectrum-body-serif-xl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xl-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-serif-xl-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-serif-xl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-xl-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-xl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xl-emphasis-text-transform: none;
  --spectrum-body-serif-xl-emphasis-margin-top: 0px;
  --spectrum-body-serif-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-serif-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-serif-xl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-xl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xl-emphasis-strong-text-transform: none;
  --spectrum-body-serif-xl-emphasis-strong-margin-top: 0px;
  --spectrum-body-serif-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-serif-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-serif-xl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-xl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-xl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xl-strong-text-transform: none;
  --spectrum-body-serif-xl-strong-margin-top: 0px;
  --spectrum-body-serif-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xl-strong-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-body-serif-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-body-serif-xs-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xs-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xs-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-xs-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-xs-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xs-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xs-text-transform: none;
  --spectrum-body-serif-xs-margin-top: 0px;
  --spectrum-body-serif-xs-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xs-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-serif-xs-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-serif-xs-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xs-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xs-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-xs-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-xs-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xs-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xs-emphasis-text-transform: none;
  --spectrum-body-serif-xs-emphasis-margin-top: 0px;
  --spectrum-body-serif-xs-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xs-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-serif-xs-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-serif-xs-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xs-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xs-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-xs-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-xs-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xs-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xs-emphasis-strong-text-transform: none;
  --spectrum-body-serif-xs-emphasis-strong-margin-top: 0px;
  --spectrum-body-serif-xs-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xs-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-serif-xs-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-serif-xs-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xs-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xs-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-xs-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-xs-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xs-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xs-strong-text-transform: none;
  --spectrum-body-serif-xs-strong-margin-top: 0px;
  --spectrum-body-serif-xs-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xs-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-body-serif-xs-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-body-serif-xxl-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xxl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-xxl-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-xxl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xxl-text-transform: none;
  --spectrum-body-serif-xxl-margin-top: 0px;
  --spectrum-body-serif-xxl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xxl-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-serif-xxl-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-serif-xxl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xxl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-xxl-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-xxl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xxl-emphasis-text-transform: none;
  --spectrum-body-serif-xxl-emphasis-margin-top: 0px;
  --spectrum-body-serif-xxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xxl-emphasis-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-serif-xxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-serif-xxl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xxl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-xxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-xxl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xxl-emphasis-strong-text-transform: none;
  --spectrum-body-serif-xxl-emphasis-strong-margin-top: 0px;
  --spectrum-body-serif-xxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xxl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-serif-xxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-serif-xxl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xxl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-xxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-xxl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xxl-strong-text-transform: none;
  --spectrum-body-serif-xxl-strong-margin-top: 0px;
  --spectrum-body-serif-xxl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xxl-strong-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-body-serif-xxl-strong-margin-bottom: var(--spectrum-global-dimension-size-300);
  --spectrum-body-serif-xxxl-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xxxl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xxxl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-xxxl-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-xxxl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xxxl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xxxl-text-transform: none;
  --spectrum-body-serif-xxxl-margin-top: 0px;
  --spectrum-body-serif-xxxl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xxxl-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-serif-xxxl-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-serif-xxxl-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xxxl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xxxl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-xxxl-emphasis-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-body-serif-xxxl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xxxl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xxxl-emphasis-text-transform: none;
  --spectrum-body-serif-xxxl-emphasis-margin-top: 0px;
  --spectrum-body-serif-xxxl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xxxl-emphasis-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-serif-xxxl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-serif-xxxl-emphasis-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xxxl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xxxl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-body-serif-xxxl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-xxxl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xxxl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xxxl-emphasis-strong-text-transform: none;
  --spectrum-body-serif-xxxl-emphasis-strong-margin-top: 0px;
  --spectrum-body-serif-xxxl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xxxl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-serif-xxxl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-400);
  --spectrum-body-serif-xxxl-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-body-serif-xxxl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-body-serif-xxxl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-body-serif-xxxl-strong-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-body-serif-xxxl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-body-serif-xxxl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-body-serif-xxxl-strong-text-transform: none;
  --spectrum-body-serif-xxxl-strong-margin-top: 0px;
  --spectrum-body-serif-xxxl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-body-serif-xxxl-strong-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-body-serif-xxxl-strong-margin-bottom: var(--spectrum-global-dimension-size-400);
}
