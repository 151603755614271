.spectrum {
  --spectrum-alert-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-alert-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alert-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-alert-border-color: var(--spectrum-global-color-gray-600);
  --spectrum-alert-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-alert-content-margin-top: var(--spectrum-global-dimension-static-size-100);
  --spectrum-alert-error-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-alert-error-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alert-error-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-alert-error-border-color: var(--spectrum-semantic-negative-color-border);
  --spectrum-alert-error-icon-color: var(--spectrum-semantic-negative-color-icon);
  --spectrum-alert-error-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-error-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-error-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-alert-error-content-margin-top: var(--spectrum-global-dimension-static-size-100);
  --spectrum-alert-info-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-alert-info-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alert-info-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-alert-info-border-color: var(--spectrum-semantic-informative-color-border);
  --spectrum-alert-info-icon-color: var(--spectrum-semantic-informative-color-icon);
  --spectrum-alert-info-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-info-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-info-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-alert-info-content-margin-top: var(--spectrum-global-dimension-static-size-100);
  --spectrum-alert-success-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-alert-success-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alert-success-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-alert-success-border-color: var(--spectrum-semantic-positive-color-border);
  --spectrum-alert-success-icon-color: var(--spectrum-semantic-positive-color-icon);
  --spectrum-alert-success-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-success-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-success-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-alert-success-content-margin-top: var(--spectrum-global-dimension-static-size-100);
  --spectrum-alert-warning-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-alert-warning-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alert-warning-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-alert-warning-border-color: var(--spectrum-semantic-notice-color-border);
  --spectrum-alert-warning-icon-color: var(--spectrum-semantic-notice-color-icon);
  --spectrum-alert-warning-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-warning-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-alert-warning-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-alert-warning-content-margin-top: var(--spectrum-global-dimension-static-size-100);
}
