.spectrum {
  --spectrum-miller-column-ui-icon-color: var(--spectrum-global-color-gray-600);
  --spectrum-miller-column-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-miller-column-thumbnail-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-miller-column-item-background-color-hover: var(--spectrum-global-color-gray-200);
  --spectrum-miller-column-ui-icon-color-hover: var(--spectrum-global-color-gray-700);
  --spectrum-miller-column-item-background-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-miller-column-ui-icon-color-down: var(--spectrum-alias-icon-color);
  --spectrum-miller-column-item-text-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-miller-column-item-text-color-key-focus: var(--spectrum-alias-focus-color);
  --spectrum-miller-column-item-text-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-miller-column-thumbnail-background-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-miller-column-ui-icon-color-selected: var(--spectrum-alias-icon-color);
  --spectrum-miller-column-ui-icon-color-selected-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-miller-column-item-height: var(--spectrum-global-dimension-static-size-500);
  --spectrum-miller-column-ui-icon-width: var(--spectrum-global-dimension-size-75);
  --spectrum-miller-column-ui-icon-height: var(--spectrum-global-dimension-size-125);
  --spectrum-miller-column-thumbnail-width: var(--spectrum-global-dimension-static-size-300);
  --spectrum-miller-column-thumbnail-height: var(--spectrum-global-dimension-static-size-300);
  --spectrum-miller-column-thumbnail-margin-left: var(--spectrum-global-dimension-static-size-100);
  --spectrum-miller-column-item-label-padding-left: var(--spectrum-global-dimension-static-size-100);
  --spectrum-miller-column-item-margin-bottom: var(--spectrum-global-dimension-static-size-50);
  --spectrum-miller-column-width: var(--spectrum-global-dimension-static-size-3400);
  --spectrum-miller-column-item-border-radius: var(--spectrum-global-dimension-static-size-50);
  --spectrum-miller-column-item-padding-left: var(--spectrum-global-dimension-static-size-200);
  --spectrum-miller-column-item-padding-right: var(--spectrum-global-dimension-static-size-200);
}
