.spectrum {
  --spectrum-circleloader-large-text-color: var(--spectrum-alias-text-color);
  --spectrum-circleloader-large-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-circleloader-large-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-circleloader-large-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-circleloader-large-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-circleloader-large-height: var(--spectrum-global-dimension-size-800);
  --spectrum-circleloader-large-width: var(--spectrum-global-dimension-size-800);
  --spectrum-circleloader-large-border-size: var(--spectrum-global-dimension-size-50);
  --spectrum-circleloader-large-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-circleloader-large-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-circleloader-large-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-circleloader-large-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-circleloader-large-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-circleloader-large-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-circleloader-large-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-circleloader-large-over-background-height: var(--spectrum-global-dimension-size-800);
  --spectrum-circleloader-large-over-background-width: var(--spectrum-global-dimension-size-800);
  --spectrum-circleloader-large-over-background-border-size: var(--spectrum-global-dimension-size-50);
  --spectrum-circleloader-large-over-background-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-circleloader-large-over-background-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-circleloader-medium-text-color: var(--spectrum-alias-text-color);
  --spectrum-circleloader-medium-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-circleloader-medium-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-circleloader-medium-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-circleloader-medium-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-circleloader-medium-height: var(--spectrum-global-dimension-size-400);
  --spectrum-circleloader-medium-width: var(--spectrum-global-dimension-size-400);
  --spectrum-circleloader-medium-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-circleloader-medium-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-circleloader-medium-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-circleloader-medium-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-circleloader-medium-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-circleloader-medium-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-circleloader-medium-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-circleloader-medium-over-background-height: var(--spectrum-global-dimension-size-400);
  --spectrum-circleloader-medium-over-background-width: var(--spectrum-global-dimension-size-400);
  --spectrum-circleloader-medium-over-background-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-circleloader-medium-over-background-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-circleloader-small-text-color: var(--spectrum-alias-text-color);
  --spectrum-circleloader-small-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-circleloader-small-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-circleloader-small-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-circleloader-small-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-circleloader-small-height: var(--spectrum-global-dimension-size-200);
  --spectrum-circleloader-small-width: var(--spectrum-global-dimension-size-200);
  --spectrum-circleloader-small-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-circleloader-small-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-circleloader-small-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-circleloader-small-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-circleloader-small-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-circleloader-small-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-circleloader-small-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-circleloader-small-over-background-height: var(--spectrum-global-dimension-size-200);
  --spectrum-circleloader-small-over-background-width: var(--spectrum-global-dimension-size-200);
  --spectrum-circleloader-small-over-background-spin-animation-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-circleloader-small-over-background-spin-animation-ease: var(--spectrum-global-animation-ease-in-out);
}
