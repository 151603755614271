.spectrum {
  --spectrum-statuslight-celery-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-celery-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-celery-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-celery-dot-color: var(--spectrum-global-color-celery-400);
  --spectrum-statuslight-celery-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-celery-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-celery-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-celery-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-celery-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-celery-text-transform: none;
  --spectrum-statuslight-celery-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-celery-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-celery-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-celery-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-chartreuse-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-chartreuse-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-chartreuse-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-chartreuse-dot-color: var(--spectrum-global-color-chartreuse-400);
  --spectrum-statuslight-chartreuse-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-chartreuse-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-chartreuse-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-chartreuse-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-chartreuse-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-chartreuse-text-transform: none;
  --spectrum-statuslight-chartreuse-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-chartreuse-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-chartreuse-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-chartreuse-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-dot-color-label-celery: var(--spectrum-global-color-celery-400);
  --spectrum-statuslight-dot-color-label-chartreuse: var(--spectrum-global-color-chartreuse-400);
  --spectrum-statuslight-dot-color-label-yellow: var(--spectrum-global-color-yellow-400);
  --spectrum-statuslight-dot-color-label-magenta: var(--spectrum-global-color-magenta-400);
  --spectrum-statuslight-dot-color-label-fuchsia: var(--spectrum-global-color-fuchsia-400);
  --spectrum-statuslight-dot-color-label-purple: var(--spectrum-global-color-purple-400);
  --spectrum-statuslight-dot-color-label-indigo: var(--spectrum-global-color-indigo-400);
  --spectrum-statuslight-dot-color-label-seafoam: var(--spectrum-global-color-seafoam-400);
  --spectrum-statuslight-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-text-transform: none;
  --spectrum-statuslight-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-fuchsia-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-fuchsia-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-fuchsia-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-fuchsia-dot-color: var(--spectrum-global-color-fuchsia-400);
  --spectrum-statuslight-fuchsia-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-fuchsia-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-fuchsia-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-fuchsia-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-fuchsia-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-fuchsia-text-transform: none;
  --spectrum-statuslight-fuchsia-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-fuchsia-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-fuchsia-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-fuchsia-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-indigo-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-indigo-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-indigo-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-indigo-dot-color: var(--spectrum-global-color-indigo-400);
  --spectrum-statuslight-indigo-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-indigo-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-indigo-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-indigo-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-indigo-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-indigo-text-transform: none;
  --spectrum-statuslight-indigo-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-indigo-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-indigo-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-indigo-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-info-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-info-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-info-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-info-dot-color: var(--spectrum-semantic-informative-color-status);
  --spectrum-statuslight-info-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-info-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-info-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-info-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-info-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-info-text-transform: none;
  --spectrum-statuslight-info-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-info-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-info-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-info-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-magenta-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-magenta-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-magenta-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-magenta-dot-color: var(--spectrum-global-color-magenta-400);
  --spectrum-statuslight-magenta-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-magenta-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-magenta-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-magenta-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-magenta-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-magenta-text-transform: none;
  --spectrum-statuslight-magenta-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-magenta-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-magenta-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-magenta-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-negative-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-negative-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-negative-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-negative-dot-color: var(--spectrum-semantic-negative-color-status);
  --spectrum-statuslight-negative-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-negative-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-negative-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-negative-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-negative-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-negative-text-transform: none;
  --spectrum-statuslight-negative-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-negative-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-negative-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-negative-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-neutral-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-statuslight-neutral-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-neutral-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-neutral-dot-color: var(--spectrum-global-color-gray-500);
  --spectrum-statuslight-neutral-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-neutral-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-statuslight-neutral-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-neutral-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-neutral-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-neutral-text-transform: none;
  --spectrum-statuslight-neutral-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-neutral-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-neutral-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-neutral-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-notice-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-notice-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-notice-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-notice-dot-color: var(--spectrum-semantic-notice-color-status);
  --spectrum-statuslight-notice-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-notice-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-notice-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-notice-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-notice-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-notice-text-transform: none;
  --spectrum-statuslight-notice-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-notice-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-notice-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-notice-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-positive-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-positive-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-positive-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-positive-dot-color: var(--spectrum-semantic-positive-color-status);
  --spectrum-statuslight-positive-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-positive-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-positive-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-positive-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-positive-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-positive-text-transform: none;
  --spectrum-statuslight-positive-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-positive-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-positive-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-positive-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-purple-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-purple-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-purple-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-purple-dot-color: var(--spectrum-global-color-purple-400);
  --spectrum-statuslight-purple-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-purple-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-purple-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-purple-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-purple-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-purple-text-transform: none;
  --spectrum-statuslight-purple-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-purple-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-purple-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-purple-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-seafoam-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-seafoam-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-seafoam-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-seafoam-dot-color: var(--spectrum-global-color-seafoam-400);
  --spectrum-statuslight-seafoam-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-seafoam-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-seafoam-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-seafoam-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-seafoam-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-seafoam-text-transform: none;
  --spectrum-statuslight-seafoam-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-seafoam-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-seafoam-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-seafoam-dot-size: var(--spectrum-global-dimension-size-100);
  --spectrum-statuslight-yellow-text-color: var(--spectrum-alias-text-color);
  --spectrum-statuslight-yellow-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-statuslight-yellow-dot-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-statuslight-yellow-dot-color: var(--spectrum-global-color-yellow-400);
  --spectrum-statuslight-yellow-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-statuslight-yellow-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-statuslight-yellow-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-statuslight-yellow-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-statuslight-yellow-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-statuslight-yellow-text-transform: none;
  --spectrum-statuslight-yellow-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-statuslight-yellow-height: var(--spectrum-alias-single-line-height);
  --spectrum-statuslight-yellow-text-gap: var(--spectrum-global-dimension-size-150);
  --spectrum-statuslight-yellow-dot-size: var(--spectrum-global-dimension-size-100);
}
