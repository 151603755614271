.spectrum {
  --spectrum-detail-article-text-color: var(--spectrum-alias-text-color);
  --spectrum-detail-article-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-detail-article-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-article-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-detail-article-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-article-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-detail-article-text-transform: none;
  --spectrum-detail-article-margin-top: 0px;
  --spectrum-detail-article-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-article-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-article-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-article-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-detail-article-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-detail-article-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-article-emphasis-text-font-weight: var(--spectrum-alias-article-body-text-font-weight);
  --spectrum-detail-article-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-article-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-detail-article-emphasis-text-transform: none;
  --spectrum-detail-article-emphasis-margin-top: 0px;
  --spectrum-detail-article-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-article-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-article-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-article-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-detail-article-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-detail-article-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-article-strong-text-font-weight: var(--spectrum-alias-article-detail-text-font-weight-strong);
  --spectrum-detail-article-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-article-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-detail-article-strong-text-transform: none;
  --spectrum-detail-article-strong-margin-top: 0px;
  --spectrum-detail-article-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-article-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-article-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-text-color: var(--spectrum-alias-text-color);
  --spectrum-detail-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-text-transform: uppercase;
  --spectrum-detail-margin-top: 0px;
  --spectrum-detail-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-detail-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-emphasis-text-transform: uppercase;
  --spectrum-detail-emphasis-margin-top: 0px;
  --spectrum-detail-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-detail-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-strong-text-font-weight: var(--spectrum-alias-detail-text-font-weight-strong);
  --spectrum-detail-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-strong-text-transform: uppercase;
  --spectrum-detail-strong-margin-top: 0px;
  --spectrum-detail-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-text-color: var(--spectrum-alias-text-color);
  --spectrum-detail-han-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-text-font-weight: var(--spectrum-alias-han-detail-text-font-weight);
  --spectrum-detail-han-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-detail-han-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-text-transform: none;
  --spectrum-detail-han-margin-top: 0px;
  --spectrum-detail-han-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-emphasis-text-color: var(--spectrum-alias-text-color);
  --spectrum-detail-han-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-emphasis-text-font-weight: var(--spectrum-alias-han-detail-text-font-weight-emphasis);
  --spectrum-detail-han-emphasis-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-detail-han-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-emphasis-text-transform: none;
  --spectrum-detail-han-emphasis-margin-top: 0px;
  --spectrum-detail-han-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-strong-text-color: var(--spectrum-alias-text-color);
  --spectrum-detail-han-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-strong-text-font-weight: var(--spectrum-alias-han-detail-text-font-weight-strong);
  --spectrum-detail-han-strong-text-line-height: var(--spectrum-alias-body-han-text-line-height);
  --spectrum-detail-han-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-strong-text-transform: none;
  --spectrum-detail-han-strong-margin-top: 0px;
  --spectrum-detail-han-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-l-margin-top: 0px;
  --spectrum-detail-han-l-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-l-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-l-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-han-l-text-transform: uppercase;
  --spectrum-detail-han-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-l-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-l-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-han-l-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-han-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-l-emphasis-margin-top: 0px;
  --spectrum-detail-han-l-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-l-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-l-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-l-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-detail-han-l-emphasis-text-transform: uppercase;
  --spectrum-detail-han-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-han-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-han-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-l-emphasis-strong-margin-top: 0px;
  --spectrum-detail-han-l-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-han-l-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-han-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-han-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-han-light-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-l-margin-top: 0px;
  --spectrum-detail-han-light-l-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-l-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-l-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-han-light-l-text-transform: uppercase;
  --spectrum-detail-han-light-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-l-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-l-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-han-light-l-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-han-light-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-l-emphasis-margin-top: 0px;
  --spectrum-detail-han-light-l-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-l-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-l-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-l-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-detail-han-light-l-emphasis-text-transform: uppercase;
  --spectrum-detail-han-light-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-han-light-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-han-light-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-l-emphasis-strong-margin-top: 0px;
  --spectrum-detail-han-light-l-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-l-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-l-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-han-light-l-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-han-light-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-han-light-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-han-light-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-l-strong-margin-top: 0px;
  --spectrum-detail-han-light-l-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-l-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-l-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-han-light-l-strong-text-transform: uppercase;
  --spectrum-detail-han-light-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-l-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-l-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-han-light-l-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-han-light-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-m-margin-top: 0px;
  --spectrum-detail-han-light-m-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-m-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-m-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-han-light-m-text-transform: uppercase;
  --spectrum-detail-han-light-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-m-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-m-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-han-light-m-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-han-light-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-m-emphasis-margin-top: 0px;
  --spectrum-detail-han-light-m-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-m-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-m-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-m-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-detail-han-light-m-emphasis-text-transform: uppercase;
  --spectrum-detail-han-light-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-han-light-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-han-light-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-m-emphasis-strong-margin-top: 0px;
  --spectrum-detail-han-light-m-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-m-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-han-light-m-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-han-light-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-han-light-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-han-light-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-m-strong-margin-top: 0px;
  --spectrum-detail-han-light-m-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-m-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-m-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-han-light-m-strong-text-transform: uppercase;
  --spectrum-detail-han-light-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-m-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-m-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-han-light-m-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-han-light-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-s-margin-top: 0px;
  --spectrum-detail-han-light-s-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-s-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-s-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-han-light-s-text-transform: uppercase;
  --spectrum-detail-han-light-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-s-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-s-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-light-s-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-light-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-s-emphasis-margin-top: 0px;
  --spectrum-detail-han-light-s-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-s-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-s-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-s-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-detail-han-light-s-emphasis-text-transform: uppercase;
  --spectrum-detail-han-light-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-s-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-light-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-light-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-s-emphasis-strong-margin-top: 0px;
  --spectrum-detail-han-light-s-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-s-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-han-light-s-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-han-light-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-s-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-light-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-light-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-s-strong-margin-top: 0px;
  --spectrum-detail-han-light-s-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-s-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-s-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-han-light-s-strong-text-transform: uppercase;
  --spectrum-detail-han-light-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-s-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-s-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-light-s-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-light-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-xl-margin-top: 0px;
  --spectrum-detail-han-light-xl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-xl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-xl-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-han-light-xl-text-transform: uppercase;
  --spectrum-detail-han-light-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-xl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-xl-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-han-light-xl-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-han-light-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-xl-emphasis-margin-top: 0px;
  --spectrum-detail-han-light-xl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-xl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-xl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-xl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-light-emphasis);
  --spectrum-detail-han-light-xl-emphasis-text-transform: uppercase;
  --spectrum-detail-han-light-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-han-light-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-han-light-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-xl-emphasis-strong-margin-top: 0px;
  --spectrum-detail-han-light-xl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-xl-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-han-light-xl-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-han-light-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-han-light-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-han-light-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-light-xl-strong-margin-top: 0px;
  --spectrum-detail-han-light-xl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-light-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-light-xl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-light-xl-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-han-light-xl-strong-text-transform: uppercase;
  --spectrum-detail-han-light-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-light-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-light-xl-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-han-light-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-han-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-l-strong-margin-top: 0px;
  --spectrum-detail-han-l-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-l-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-l-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-han-l-strong-text-transform: uppercase;
  --spectrum-detail-han-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-l-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-l-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-han-l-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-han-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-m-margin-top: 0px;
  --spectrum-detail-han-m-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-m-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-m-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-han-m-text-transform: uppercase;
  --spectrum-detail-han-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-m-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-m-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-han-m-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-han-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-m-emphasis-margin-top: 0px;
  --spectrum-detail-han-m-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-m-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-m-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-m-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-detail-han-m-emphasis-text-transform: uppercase;
  --spectrum-detail-han-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-han-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-han-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-m-emphasis-strong-margin-top: 0px;
  --spectrum-detail-han-m-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-m-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-han-m-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-han-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-han-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-han-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-m-strong-margin-top: 0px;
  --spectrum-detail-han-m-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-m-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-m-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-han-m-strong-text-transform: uppercase;
  --spectrum-detail-han-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-m-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-m-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-han-m-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-han-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-s-margin-top: 0px;
  --spectrum-detail-han-s-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-s-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-s-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-han-s-text-transform: uppercase;
  --spectrum-detail-han-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-s-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-s-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-s-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-s-emphasis-margin-top: 0px;
  --spectrum-detail-han-s-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-s-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-s-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-s-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-detail-han-s-emphasis-text-transform: uppercase;
  --spectrum-detail-han-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-s-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-s-emphasis-strong-margin-top: 0px;
  --spectrum-detail-han-s-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-s-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-han-s-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-han-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-s-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-s-strong-margin-top: 0px;
  --spectrum-detail-han-s-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-s-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-s-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-han-s-strong-text-transform: uppercase;
  --spectrum-detail-han-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-s-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-s-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-han-s-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-han-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-xl-margin-top: 0px;
  --spectrum-detail-han-xl-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-xl-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-xl-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-han-xl-text-transform: uppercase;
  --spectrum-detail-han-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-xl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-xl-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-han-xl-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-han-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-xl-emphasis-margin-top: 0px;
  --spectrum-detail-han-xl-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-xl-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-xl-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-xl-emphasis-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis);
  --spectrum-detail-han-xl-emphasis-text-transform: uppercase;
  --spectrum-detail-han-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-han-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-han-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-xl-emphasis-strong-margin-top: 0px;
  --spectrum-detail-han-xl-emphasis-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-xl-emphasis-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-han-xl-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-han-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-han-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-han-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-han-xl-strong-margin-top: 0px;
  --spectrum-detail-han-xl-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-detail-han-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-han-xl-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-detail-han-xl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-han-xl-strong-text-transform: uppercase;
  --spectrum-detail-han-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-detail-han-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-han-xl-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-han-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-l-margin-top: 0px;
  --spectrum-detail-l-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-l-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-l-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-l-text-transform: uppercase;
  --spectrum-detail-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-l-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-l-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-l-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-l-emphasis-margin-top: 0px;
  --spectrum-detail-l-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-l-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-l-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-l-emphasis-text-transform: uppercase;
  --spectrum-detail-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-l-emphasis-strong-margin-top: 0px;
  --spectrum-detail-l-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-l-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-l-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-light-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-l-margin-top: 0px;
  --spectrum-detail-light-l-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-light-l-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-l-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-light-l-text-transform: uppercase;
  --spectrum-detail-light-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-l-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-l-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-light-l-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-light-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-l-emphasis-margin-top: 0px;
  --spectrum-detail-light-l-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-light-l-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-l-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-light-l-emphasis-text-transform: uppercase;
  --spectrum-detail-light-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-light-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-light-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-l-emphasis-strong-margin-top: 0px;
  --spectrum-detail-light-l-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-light-l-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-l-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-light-l-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-light-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-light-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-light-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-l-strong-margin-top: 0px;
  --spectrum-detail-light-l-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-light-l-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-l-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-light-l-strong-text-transform: uppercase;
  --spectrum-detail-light-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-l-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-l-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-light-l-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-light-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-m-margin-top: 0px;
  --spectrum-detail-light-m-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-light-m-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-m-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-light-m-text-transform: uppercase;
  --spectrum-detail-light-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-m-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-m-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-light-m-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-light-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-m-emphasis-margin-top: 0px;
  --spectrum-detail-light-m-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-light-m-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-m-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-light-m-emphasis-text-transform: uppercase;
  --spectrum-detail-light-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-light-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-light-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-m-emphasis-strong-margin-top: 0px;
  --spectrum-detail-light-m-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-light-m-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-m-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-light-m-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-light-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-light-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-light-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-m-strong-margin-top: 0px;
  --spectrum-detail-light-m-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-light-m-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-m-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-light-m-strong-text-transform: uppercase;
  --spectrum-detail-light-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-m-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-m-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-light-m-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-light-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-s-margin-top: 0px;
  --spectrum-detail-light-s-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-light-s-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-s-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-light-s-text-transform: uppercase;
  --spectrum-detail-light-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-s-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-s-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-light-s-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-light-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-s-emphasis-margin-top: 0px;
  --spectrum-detail-light-s-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-light-s-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-s-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-light-s-emphasis-text-transform: uppercase;
  --spectrum-detail-light-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-s-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-light-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-light-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-s-emphasis-strong-margin-top: 0px;
  --spectrum-detail-light-s-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-light-s-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-s-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-light-s-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-light-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-s-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-light-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-light-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-s-strong-margin-top: 0px;
  --spectrum-detail-light-s-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-light-s-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-s-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-light-s-strong-text-transform: uppercase;
  --spectrum-detail-light-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-s-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-s-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-light-s-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-light-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-xl-margin-top: 0px;
  --spectrum-detail-light-xl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-light-xl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-xl-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-light-xl-text-transform: uppercase;
  --spectrum-detail-light-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-xl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-xl-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-light-xl-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-light-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-xl-emphasis-margin-top: 0px;
  --spectrum-detail-light-xl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-light-xl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-xl-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-light-xl-emphasis-text-transform: uppercase;
  --spectrum-detail-light-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-light-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-light-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-xl-emphasis-strong-margin-top: 0px;
  --spectrum-detail-light-xl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-light-xl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-xl-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-light-xl-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-light-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-light-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-light-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-light-xl-strong-margin-top: 0px;
  --spectrum-detail-light-xl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-light-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-light-xl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-light-xl-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-light-xl-strong-text-transform: uppercase;
  --spectrum-detail-light-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-light-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-light-xl-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-light-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-l-strong-margin-top: 0px;
  --spectrum-detail-l-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-l-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-l-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-l-strong-text-transform: uppercase;
  --spectrum-detail-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-l-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-l-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-l-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-m-margin-top: 0px;
  --spectrum-detail-m-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-m-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-m-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-m-text-transform: uppercase;
  --spectrum-detail-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-m-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-m-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-m-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-m-emphasis-margin-top: 0px;
  --spectrum-detail-m-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-m-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-m-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-m-emphasis-text-transform: uppercase;
  --spectrum-detail-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-m-emphasis-strong-margin-top: 0px;
  --spectrum-detail-m-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-m-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-m-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-m-strong-margin-top: 0px;
  --spectrum-detail-m-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-m-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-m-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-m-strong-text-transform: uppercase;
  --spectrum-detail-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-m-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-m-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-m-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-s-margin-top: 0px;
  --spectrum-detail-s-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-s-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-s-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-s-text-transform: uppercase;
  --spectrum-detail-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-s-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-s-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-s-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-s-emphasis-margin-top: 0px;
  --spectrum-detail-s-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-s-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-s-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-s-emphasis-text-transform: uppercase;
  --spectrum-detail-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-s-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-s-emphasis-strong-margin-top: 0px;
  --spectrum-detail-s-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-s-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-s-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-s-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-s-strong-margin-top: 0px;
  --spectrum-detail-s-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-s-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-s-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-s-strong-text-transform: uppercase;
  --spectrum-detail-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-s-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-s-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-s-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-xl-margin-top: 0px;
  --spectrum-detail-xl-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-xl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-xl-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-xl-text-transform: uppercase;
  --spectrum-detail-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-xl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-xl-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-xl-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-xl-emphasis-margin-top: 0px;
  --spectrum-detail-xl-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-xl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-xl-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-xl-emphasis-text-transform: uppercase;
  --spectrum-detail-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-xl-emphasis-strong-margin-top: 0px;
  --spectrum-detail-xl-emphasis-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-xl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-xl-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-xl-strong-margin-top: 0px;
  --spectrum-detail-xl-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-detail-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-xl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-xl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-xl-strong-text-transform: uppercase;
  --spectrum-detail-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-xl-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-serif-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-l-margin-top: 0px;
  --spectrum-detail-serif-l-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-l-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-l-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-serif-l-text-transform: uppercase;
  --spectrum-detail-serif-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-l-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-l-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-serif-l-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-serif-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-l-emphasis-margin-top: 0px;
  --spectrum-detail-serif-l-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-l-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-l-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-serif-l-emphasis-text-transform: uppercase;
  --spectrum-detail-serif-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-serif-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-serif-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-l-emphasis-strong-margin-top: 0px;
  --spectrum-detail-serif-l-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-l-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-l-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-serif-l-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-serif-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-serif-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-serif-light-l-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-l-margin-top: 0px;
  --spectrum-detail-serif-light-l-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-l-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-light-l-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-l-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-serif-light-l-text-transform: uppercase;
  --spectrum-detail-serif-light-l-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-l-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-l-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-serif-light-l-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-serif-light-l-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-l-emphasis-margin-top: 0px;
  --spectrum-detail-serif-light-l-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-l-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-light-l-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-l-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-serif-light-l-emphasis-text-transform: uppercase;
  --spectrum-detail-serif-light-l-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-l-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-l-emphasis-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-serif-light-l-emphasis-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-serif-light-l-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-l-emphasis-strong-margin-top: 0px;
  --spectrum-detail-serif-light-l-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-l-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-light-l-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-l-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-serif-light-l-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-serif-light-l-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-l-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-l-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-serif-light-l-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-serif-light-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-l-strong-margin-top: 0px;
  --spectrum-detail-serif-light-l-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-light-l-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-l-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-serif-light-l-strong-text-transform: uppercase;
  --spectrum-detail-serif-light-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-l-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-l-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-serif-light-l-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-serif-light-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-m-margin-top: 0px;
  --spectrum-detail-serif-light-m-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-light-m-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-m-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-serif-light-m-text-transform: uppercase;
  --spectrum-detail-serif-light-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-m-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-m-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-serif-light-m-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-serif-light-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-m-emphasis-margin-top: 0px;
  --spectrum-detail-serif-light-m-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-light-m-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-m-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-serif-light-m-emphasis-text-transform: uppercase;
  --spectrum-detail-serif-light-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-serif-light-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-serif-light-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-m-emphasis-strong-margin-top: 0px;
  --spectrum-detail-serif-light-m-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-light-m-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-m-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-serif-light-m-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-serif-light-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-serif-light-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-serif-light-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-m-strong-margin-top: 0px;
  --spectrum-detail-serif-light-m-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-light-m-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-m-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-serif-light-m-strong-text-transform: uppercase;
  --spectrum-detail-serif-light-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-m-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-m-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-serif-light-m-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-serif-light-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-s-margin-top: 0px;
  --spectrum-detail-serif-light-s-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-light-s-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-s-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-serif-light-s-text-transform: uppercase;
  --spectrum-detail-serif-light-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-s-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-s-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-serif-light-s-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-serif-light-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-s-emphasis-margin-top: 0px;
  --spectrum-detail-serif-light-s-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-light-s-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-s-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-serif-light-s-emphasis-text-transform: uppercase;
  --spectrum-detail-serif-light-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-s-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-serif-light-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-serif-light-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-s-emphasis-strong-margin-top: 0px;
  --spectrum-detail-serif-light-s-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-light-s-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-s-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-serif-light-s-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-serif-light-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-s-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-serif-light-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-serif-light-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-s-strong-margin-top: 0px;
  --spectrum-detail-serif-light-s-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-light-s-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-s-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-serif-light-s-strong-text-transform: uppercase;
  --spectrum-detail-serif-light-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-s-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-s-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-serif-light-s-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-serif-light-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-xl-margin-top: 0px;
  --spectrum-detail-serif-light-xl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-light-xl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-xl-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-serif-light-xl-text-transform: uppercase;
  --spectrum-detail-serif-light-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-xl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-xl-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-serif-light-xl-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-serif-light-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-xl-emphasis-margin-top: 0px;
  --spectrum-detail-serif-light-xl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-light-xl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-xl-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight-light);
  --spectrum-detail-serif-light-xl-emphasis-text-transform: uppercase;
  --spectrum-detail-serif-light-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-serif-light-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-serif-light-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-xl-emphasis-strong-margin-top: 0px;
  --spectrum-detail-serif-light-xl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-light-xl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-xl-emphasis-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-serif-light-xl-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-serif-light-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-serif-light-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-serif-light-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-light-xl-strong-margin-top: 0px;
  --spectrum-detail-serif-light-xl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-light-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-light-xl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-light-xl-strong-text-font-weight: var(--spectrum-alias-heading-text-font-weight-regular);
  --spectrum-detail-serif-light-xl-strong-text-transform: uppercase;
  --spectrum-detail-serif-light-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-light-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-light-xl-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-serif-light-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-serif-l-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-l-strong-margin-top: 0px;
  --spectrum-detail-serif-l-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-l-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-l-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-l-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-serif-l-strong-text-transform: uppercase;
  --spectrum-detail-serif-l-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-l-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-l-strong-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-detail-serif-l-strong-margin-bottom: var(--spectrum-global-dimension-size-125);
  --spectrum-detail-serif-m-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-m-margin-top: 0px;
  --spectrum-detail-serif-m-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-m-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-m-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-m-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-serif-m-text-transform: uppercase;
  --spectrum-detail-serif-m-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-m-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-m-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-serif-m-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-serif-m-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-m-emphasis-margin-top: 0px;
  --spectrum-detail-serif-m-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-m-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-m-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-m-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-serif-m-emphasis-text-transform: uppercase;
  --spectrum-detail-serif-m-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-m-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-m-emphasis-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-serif-m-emphasis-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-serif-m-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-m-emphasis-strong-margin-top: 0px;
  --spectrum-detail-serif-m-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-m-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-m-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-m-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-serif-m-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-serif-m-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-m-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-m-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-serif-m-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-serif-m-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-m-strong-margin-top: 0px;
  --spectrum-detail-serif-m-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-m-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-m-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-m-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-serif-m-strong-text-transform: uppercase;
  --spectrum-detail-serif-m-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-m-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-m-strong-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-detail-serif-m-strong-margin-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-detail-serif-s-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-s-margin-top: 0px;
  --spectrum-detail-serif-s-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-s-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-s-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-s-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-serif-s-text-transform: uppercase;
  --spectrum-detail-serif-s-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-s-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-s-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-serif-s-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-serif-s-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-s-emphasis-margin-top: 0px;
  --spectrum-detail-serif-s-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-s-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-s-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-s-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-serif-s-emphasis-text-transform: uppercase;
  --spectrum-detail-serif-s-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-s-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-s-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-serif-s-emphasis-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-serif-s-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-s-emphasis-strong-margin-top: 0px;
  --spectrum-detail-serif-s-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-s-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-s-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-s-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-serif-s-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-serif-s-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-s-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-s-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-serif-s-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-serif-s-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-s-strong-margin-top: 0px;
  --spectrum-detail-serif-s-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-s-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-s-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-s-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-serif-s-strong-text-transform: uppercase;
  --spectrum-detail-serif-s-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-s-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-s-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-detail-serif-s-strong-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-detail-serif-xl-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-xl-margin-top: 0px;
  --spectrum-detail-serif-xl-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-xl-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-xl-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-xl-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-serif-xl-text-transform: uppercase;
  --spectrum-detail-serif-xl-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-xl-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-xl-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-serif-xl-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-serif-xl-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-xl-emphasis-margin-top: 0px;
  --spectrum-detail-serif-xl-emphasis-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-xl-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-xl-emphasis-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-xl-emphasis-text-font-weight: var(--spectrum-alias-detail-text-font-weight);
  --spectrum-detail-serif-xl-emphasis-text-transform: uppercase;
  --spectrum-detail-serif-xl-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-xl-emphasis-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-xl-emphasis-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-serif-xl-emphasis-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-serif-xl-emphasis-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-xl-emphasis-strong-margin-top: 0px;
  --spectrum-detail-serif-xl-emphasis-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-xl-emphasis-strong-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-detail-serif-xl-emphasis-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-xl-emphasis-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-serif-xl-emphasis-strong-text-transform: uppercase;
  --spectrum-detail-serif-xl-emphasis-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-xl-emphasis-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-xl-emphasis-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-serif-xl-emphasis-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-detail-serif-xl-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-detail-serif-xl-strong-margin-top: 0px;
  --spectrum-detail-serif-xl-strong-text-font-family: var(--spectrum-alias-serif-text-font-family);
  --spectrum-detail-serif-xl-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-detail-serif-xl-strong-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-detail-serif-xl-strong-text-font-weight: var(--spectrum-global-font-weight-black);
  --spectrum-detail-serif-xl-strong-text-transform: uppercase;
  --spectrum-detail-serif-xl-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-detail-serif-xl-strong-margin-bottom-multiplier: var(--spectrum-alias-body-margin-bottom);
  --spectrum-detail-serif-xl-strong-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-detail-serif-xl-strong-margin-bottom: var(--spectrum-global-dimension-size-150);
}
