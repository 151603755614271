.spectrum {
  --spectrum-picker-placeholder-text-color: var(--spectrum-alias-placeholder-text-color);
  --spectrum-picker-text-color: var(--spectrum-alias-text-color);
  --spectrum-picker-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-picker-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-picker-border-color: var(--spectrum-alias-border-color);
  --spectrum-picker-placeholder-text-color-hover: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-picker-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-picker-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-picker-background-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-picker-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-picker-placeholder-text-color-key-focus: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-picker-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-picker-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-picker-background-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-picker-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-picker-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-picker-placeholder-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-picker-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-picker-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-picker-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-picker-border-color-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-picker-validation-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-picker-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-picker-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-picker-background-color-down: var(--spectrum-global-color-gray-200);
  --spectrum-picker-border-color-down: var(--spectrum-alias-border-color-down);
  --spectrum-picker-placeholder-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-picker-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-picker-background-color-mouse-focus: var(--spectrum-global-color-gray-200);
  --spectrum-picker-border-color-mouse-focus: var(--spectrum-alias-border-color-down);
  --spectrum-picker-icon-color-mouse-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-picker-placeholder-text-color-mouse-focus: var(--spectrum-global-color-gray-900);
  --spectrum-picker-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-picker-validation-icon-color-error: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-picker-validation-icon-color-error-hover: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-border-color-error-down: var(--spectrum-global-color-red-600);
  --spectrum-picker-validation-icon-color-error-down: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-picker-validation-icon-color-error-key-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-border-color-error-mouse-focus: var(--spectrum-global-color-red-500);
  --spectrum-picker-validation-icon-color-error-mouse-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-width: var(--spectrum-global-dimension-size-3000);
  --spectrum-picker-min-width: var(--spectrum-global-dimension-size-400);
  --spectrum-picker-popover-offset-y: var(--spectrum-global-dimension-size-75);
  --spectrum-picker-popover-offset-x: 0px;
  --spectrum-picker-popover-max-height: var(--spectrum-global-dimension-size-2400);
  --spectrum-picker-popover-max-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-picker-image-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-image-height: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-image-width: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-image-border-radius: var(--spectrum-alias-border-radius-small);
  --spectrum-picker-image-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-picker-placeholder-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-picker-placeholder-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-picker-placeholder-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-picker-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-picker-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-picker-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-picker-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-picker-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-picker-text-transform: none;
  --spectrum-picker-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-picker-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-picker-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-picker-border-size-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-picker-ui-icon-height: 6px;
  --spectrum-picker-ui-icon-width: var(--spectrum-global-dimension-static-size-125);
  --spectrum-picker-height: var(--spectrum-global-dimension-size-400);
  --spectrum-picker-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-picker-affixed-border-radius: 0;
  --spectrum-picker-label-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-label-gap-y: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-asterisk-size: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-asterisk-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-picker-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-icon-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-picker-success-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-success-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-error-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-quiet-placeholder-text-color: var(--spectrum-alias-placeholder-text-color);
  --spectrum-picker-quiet-text-color: var(--spectrum-alias-text-color);
  --spectrum-picker-quiet-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-picker-quiet-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-picker-quiet-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-picker-quiet-placeholder-text-color-hover: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-picker-quiet-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-picker-quiet-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-picker-quiet-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-picker-quiet-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-picker-quiet-placeholder-text-color-key-focus: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-picker-quiet-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-picker-quiet-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-picker-quiet-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-picker-quiet-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-picker-quiet-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-picker-quiet-placeholder-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-picker-quiet-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-picker-quiet-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-picker-quiet-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-picker-quiet-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-picker-quiet-validation-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-picker-quiet-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-picker-quiet-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-picker-quiet-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-picker-quiet-border-color-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-picker-quiet-placeholder-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-picker-quiet-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-picker-quiet-background-color-mouse-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-picker-quiet-border-color-mouse-focus: var(--spectrum-alias-border-color-down);
  --spectrum-picker-quiet-icon-color-mouse-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-picker-quiet-placeholder-text-color-mouse-focus: var(--spectrum-global-color-gray-900);
  --spectrum-picker-quiet-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-picker-quiet-validation-icon-color-error: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-quiet-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-picker-quiet-validation-icon-color-error-hover: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-quiet-border-color-error-down: var(--spectrum-global-color-red-600);
  --spectrum-picker-quiet-validation-icon-color-error-down: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-quiet-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-picker-quiet-validation-icon-color-error-key-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-quiet-border-color-error-mouse-focus: var(--spectrum-global-color-red-500);
  --spectrum-picker-quiet-validation-icon-color-error-mouse-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-quiet-width: var(--spectrum-global-dimension-size-3000);
  --spectrum-picker-quiet-popover-offset-y: 0px;
  --spectrum-picker-quiet-popover-offset-x: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-quiet-popover-max-height: var(--spectrum-global-dimension-size-2400);
  --spectrum-picker-quiet-popover-max-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-picker-quiet-touch-hit-x: var(--spectrum-alias-single-line-height);
  --spectrum-picker-quiet-image-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-quiet-image-height: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-quiet-image-width: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-quiet-image-border-radius: var(--spectrum-alias-border-radius-small);
  --spectrum-picker-quiet-image-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-picker-quiet-placeholder-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-picker-quiet-placeholder-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-picker-quiet-placeholder-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-picker-quiet-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-picker-quiet-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-picker-quiet-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-picker-quiet-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-picker-quiet-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-picker-quiet-text-transform: none;
  --spectrum-picker-quiet-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-picker-quiet-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-picker-quiet-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-picker-quiet-border-size-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-picker-quiet-ui-icon-height: 6px;
  --spectrum-picker-quiet-ui-icon-width: var(--spectrum-global-dimension-static-size-125);
  --spectrum-picker-quiet-height: var(--spectrum-global-dimension-size-400);
  --spectrum-picker-quiet-padding-x: 0px;
  --spectrum-picker-quiet-border-radius: 0px;
  --spectrum-picker-quiet-affixed-border-radius: 0;
  --spectrum-picker-quiet-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-quiet-label-gap-y: 0px;
  --spectrum-picker-quiet-asterisk-size: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-quiet-asterisk-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-picker-quiet-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-quiet-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-quiet-icon-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-quiet-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-picker-quiet-success-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-quiet-success-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-quiet-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-quiet-error-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-quiet-affixed-border-size: var(--spectrum-global-dimension-static-size-10);
  --spectrum-picker-quiet-cursor-hit-x: var(--spectrum-alias-single-line-height);
  --spectrum-picker-thumbnail-small-placeholder-text-color: var(--spectrum-alias-placeholder-text-color);
  --spectrum-picker-thumbnail-small-text-color: var(--spectrum-alias-text-color);
  --spectrum-picker-thumbnail-small-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-picker-thumbnail-small-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-picker-thumbnail-small-border-color: var(--spectrum-alias-border-color);
  --spectrum-picker-thumbnail-small-placeholder-text-color-hover: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-picker-thumbnail-small-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-picker-thumbnail-small-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-picker-thumbnail-small-background-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-picker-thumbnail-small-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-picker-thumbnail-small-placeholder-text-color-key-focus: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-picker-thumbnail-small-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-picker-thumbnail-small-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-picker-thumbnail-small-background-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-picker-thumbnail-small-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-picker-thumbnail-small-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-picker-thumbnail-small-placeholder-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-picker-thumbnail-small-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-picker-thumbnail-small-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-picker-thumbnail-small-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-picker-thumbnail-small-border-color-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-picker-thumbnail-small-validation-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-picker-thumbnail-small-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-picker-thumbnail-small-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-picker-thumbnail-small-background-color-down: var(--spectrum-global-color-gray-200);
  --spectrum-picker-thumbnail-small-border-color-down: var(--spectrum-alias-border-color-down);
  --spectrum-picker-thumbnail-small-placeholder-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-picker-thumbnail-small-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-picker-thumbnail-small-background-color-mouse-focus: var(--spectrum-global-color-gray-200);
  --spectrum-picker-thumbnail-small-border-color-mouse-focus: var(--spectrum-alias-border-color-down);
  --spectrum-picker-thumbnail-small-icon-color-mouse-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-picker-thumbnail-small-placeholder-text-color-mouse-focus: var(--spectrum-global-color-gray-900);
  --spectrum-picker-thumbnail-small-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-picker-thumbnail-small-validation-icon-color-error: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-thumbnail-small-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-picker-thumbnail-small-validation-icon-color-error-hover: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-thumbnail-small-border-color-error-down: var(--spectrum-global-color-red-600);
  --spectrum-picker-thumbnail-small-validation-icon-color-error-down: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-thumbnail-small-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-picker-thumbnail-small-validation-icon-color-error-key-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-thumbnail-small-border-color-error-mouse-focus: var(--spectrum-global-color-red-500);
  --spectrum-picker-thumbnail-small-validation-icon-color-error-mouse-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-picker-thumbnail-small-width: var(--spectrum-global-dimension-size-3000);
  --spectrum-picker-thumbnail-small-min-width: var(--spectrum-global-dimension-size-400);
  --spectrum-picker-thumbnail-small-popover-offset-y: var(--spectrum-global-dimension-size-75);
  --spectrum-picker-thumbnail-small-popover-offset-x: 0px;
  --spectrum-picker-thumbnail-small-popover-max-height: var(--spectrum-global-dimension-size-2400);
  --spectrum-picker-thumbnail-small-popover-max-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-picker-thumbnail-small-image-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-thumbnail-small-image-height: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-thumbnail-small-image-width: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-thumbnail-small-image-border-radius: var(--spectrum-alias-border-radius-small);
  --spectrum-picker-thumbnail-small-image-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-picker-thumbnail-small-placeholder-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-picker-thumbnail-small-placeholder-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-picker-thumbnail-small-placeholder-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-picker-thumbnail-small-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-picker-thumbnail-small-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-picker-thumbnail-small-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-picker-thumbnail-small-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-picker-thumbnail-small-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-picker-thumbnail-small-text-transform: none;
  --spectrum-picker-thumbnail-small-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-picker-thumbnail-small-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-picker-thumbnail-small-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-picker-thumbnail-small-border-size-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-picker-thumbnail-small-ui-icon-height: 6px;
  --spectrum-picker-thumbnail-small-ui-icon-width: var(--spectrum-global-dimension-static-size-125);
  --spectrum-picker-thumbnail-small-height: var(--spectrum-global-dimension-size-400);
  --spectrum-picker-thumbnail-small-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-thumbnail-small-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-picker-thumbnail-small-affixed-border-radius: 0;
  --spectrum-picker-thumbnail-small-label-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-thumbnail-small-label-gap-y: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-thumbnail-small-asterisk-size: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-thumbnail-small-asterisk-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-picker-thumbnail-small-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-thumbnail-small-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-picker-thumbnail-small-icon-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-thumbnail-small-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-picker-thumbnail-small-success-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-thumbnail-small-success-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-picker-thumbnail-small-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-thumbnail-small-error-icon-width: var(--spectrum-global-dimension-size-225);
}
