.spectrum {
  --spectrum-selectlist-thumbnail-small-option-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-thumbnail-small-option-text-color: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-small-option-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-small-image-opacity: 1;
  --spectrum-selectlist-thumbnail-small-divider-color: var(--spectrum-alias-border-color-extralight);
  --spectrum-selectlist-thumbnail-small-option-icon-color-hover: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-small-option-background-color-hover: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-thumbnail-small-option-text-color-hover: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-small-option-text-color-selected: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-small-option-background-color-selected: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-thumbnail-small-option-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-small-option-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-small-option-background-color-selected-hover: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-thumbnail-small-option-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-selectlist-thumbnail-small-option-icon-color-key-focus: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-small-option-background-color-key-focus: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-thumbnail-small-option-border-color-key-focus: var(--spectrum-global-color-blue-400);
  --spectrum-selectlist-thumbnail-small-option-text-color-key-focus: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-small-border-size-key-focus: var(--spectrum-global-dimension-static-size-25);
  --spectrum-selectlist-thumbnail-small-option-border-size-key-focus: var(--spectrum-global-dimension-static-size-25);
  --spectrum-selectlist-thumbnail-small-option-icon-color-down: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-small-option-background-color-down: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-thumbnail-small-option-text-color-down: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-small-option-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-thumbnail-small-option-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-selectlist-thumbnail-small-option-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-selectlist-thumbnail-small-image-opacity-disabled: 0.3;
  --spectrum-selectlist-thumbnail-small-option-focus-indicator-color: var(--spectrum-alias-border-color-focus);
  --spectrum-selectlist-thumbnail-small-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-thumbnail-small-image-border-color: var(--spectrum-alias-border-color-translucent-dark);
  --spectrum-selectlist-thumbnail-small-divider-size: var(--spectrum-alias-border-size-thick);
  --spectrum-selectlist-thumbnail-small-divider-padding: 3px;
  --spectrum-selectlist-thumbnail-small-option-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-selectlist-thumbnail-small-option-padding: var(--spectrum-global-dimension-static-size-150);
  --spectrum-selectlist-thumbnail-small-option-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-selectlist-thumbnail-small-option-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-small-option-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-small-option-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-selectlist-thumbnail-small-option-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-selectlist-thumbnail-small-option-baseline: var(--spectrum-global-dimension-static-font-size-75);
  --spectrum-selectlist-thumbnail-small-option-focus-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-selectlist-thumbnail-small-padding-left: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-small-padding-right: var(--spectrum-global-dimension-size-450);
  --spectrum-selectlist-thumbnail-small-option-icon-size: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-small-option-height: var(--spectrum-global-dimension-static-size-600);
  --spectrum-selectlist-thumbnail-small-option-icon-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-small-image-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-selectlist-thumbnail-small-image-height: var(--spectrum-global-dimension-size-225);
  --spectrum-selectlist-thumbnail-small-image-width: var(--spectrum-global-dimension-size-225);
  --spectrum-selectlist-thumbnail-small-image-border-radius: var(--spectrum-alias-border-radius-small);
  --spectrum-selectlist-thumbnail-small-image-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-selectlist-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-selectlist-option-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-option-text-color: var(--spectrum-alias-text-color);
  --spectrum-selectlist-option-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-divider-color: var(--spectrum-alias-border-color-extralight);
  --spectrum-selectlist-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-selectlist-option-icon-color-hover: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-option-background-color-hover: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-option-text-color-hover: var(--spectrum-alias-text-color);
  --spectrum-selectlist-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-selectlist-option-icon-color-down: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-option-background-color-down: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-option-text-color-down: var(--spectrum-alias-text-color);
  --spectrum-selectlist-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-selectlist-option-icon-color-key-focus: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-option-background-color-key-focus: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-option-border-color-key-focus: var(--spectrum-global-color-blue-400);
  --spectrum-selectlist-option-text-color-key-focus: var(--spectrum-alias-text-color);
  --spectrum-selectlist-border-size-key-focus: var(--spectrum-global-dimension-static-size-25);
  --spectrum-selectlist-option-border-size-key-focus: var(--spectrum-global-dimension-static-size-25);
  --spectrum-selectlist-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-selectlist-option-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-option-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-selectlist-option-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-selectlist-option-text-color-selected: var(--spectrum-alias-text-color);
  --spectrum-selectlist-option-background-color-selected: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-option-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-option-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-option-background-color-selected-hover: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-option-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-selectlist-option-focus-indicator-color: var(--spectrum-alias-border-color-focus);
  --spectrum-selectlist-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-selectlist-divider-size: var(--spectrum-alias-border-size-thick);
  --spectrum-selectlist-divider-padding: 3px;
  --spectrum-selectlist-option-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-selectlist-option-padding: var(--spectrum-global-dimension-static-size-150);
  --spectrum-selectlist-option-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-selectlist-option-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-option-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-selectlist-option-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-selectlist-option-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-selectlist-option-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-selectlist-option-focus-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-selectlist-padding-left: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-padding-right: var(--spectrum-global-dimension-size-450);
  --spectrum-selectlist-option-icon-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-option-baseline: var(--spectrum-global-dimension-static-font-size-75);
  --spectrum-selectlist-thumbnail-option-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-thumbnail-option-text-color: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-option-icon-color: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-divider-color: var(--spectrum-alias-border-color-extralight);
  --spectrum-selectlist-thumbnail-image-opacity: 1;
  --spectrum-selectlist-thumbnail-option-icon-color-hover: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-option-background-color-hover: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-thumbnail-option-text-color-hover: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-option-text-color-selected: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-option-background-color-selected: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-thumbnail-option-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-option-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-option-background-color-selected-hover: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-thumbnail-option-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-selectlist-thumbnail-option-icon-color-key-focus: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-option-background-color-key-focus: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-thumbnail-option-border-color-key-focus: var(--spectrum-global-color-blue-400);
  --spectrum-selectlist-thumbnail-option-text-color-key-focus: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-border-size-key-focus: var(--spectrum-global-dimension-static-size-25);
  --spectrum-selectlist-thumbnail-option-border-size-key-focus: var(--spectrum-global-dimension-static-size-25);
  --spectrum-selectlist-thumbnail-option-icon-color-down: var(--spectrum-alias-icon-color-selected);
  --spectrum-selectlist-thumbnail-option-background-color-down: var(--spectrum-alias-background-color-hover-overlay);
  --spectrum-selectlist-thumbnail-option-text-color-down: var(--spectrum-alias-text-color);
  --spectrum-selectlist-thumbnail-option-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-thumbnail-option-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-selectlist-thumbnail-option-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-selectlist-thumbnail-image-opacity-disabled: 0.3;
  --spectrum-selectlist-thumbnail-image-border-color: var(--spectrum-alias-border-color-translucent-dark);
  --spectrum-selectlist-thumbnail-option-focus-indicator-color: var(--spectrum-alias-border-color-focus);
  --spectrum-selectlist-thumbnail-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-selectlist-thumbnail-divider-size: var(--spectrum-alias-border-size-thick);
  --spectrum-selectlist-thumbnail-divider-padding: 3px;
  --spectrum-selectlist-thumbnail-option-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-selectlist-thumbnail-option-padding: var(--spectrum-global-dimension-static-size-150);
  --spectrum-selectlist-thumbnail-option-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-option-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-option-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-selectlist-thumbnail-option-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-selectlist-thumbnail-option-focus-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-selectlist-thumbnail-padding-left: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-padding-right: var(--spectrum-global-dimension-size-450);
  --spectrum-selectlist-thumbnail-option-icon-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-option-height: var(--spectrum-global-dimension-static-size-600);
  --spectrum-selectlist-thumbnail-option-baseline: var(--spectrum-global-dimension-static-font-size-75);
  --spectrum-selectlist-thumbnail-option-icon-size: var(--spectrum-global-dimension-size-150);
  --spectrum-selectlist-thumbnail-image-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-selectlist-thumbnail-image-height: var(--spectrum-global-dimension-size-500);
  --spectrum-selectlist-thumbnail-image-width: var(--spectrum-global-dimension-size-500);
  --spectrum-selectlist-thumbnail-image-border-radius: var(--spectrum-alias-border-radius-small);
  --spectrum-selectlist-thumbnail-image-border-size: var(--spectrum-alias-border-size-thin);
}
