.spectrum {
  --spectrum-fieldbutton-placeholder-text-color: var(--spectrum-alias-placeholder-text-color);
  --spectrum-fieldbutton-text-color: var(--spectrum-alias-text-color);
  --spectrum-fieldbutton-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-fieldbutton-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-fieldbutton-border-color: var(--spectrum-alias-border-color);
  --spectrum-fieldbutton-placeholder-text-color-hover: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-fieldbutton-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-fieldbutton-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-fieldbutton-background-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-fieldbutton-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-fieldbutton-placeholder-text-color-key-focus: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-fieldbutton-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-fieldbutton-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-fieldbutton-background-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-fieldbutton-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-fieldbutton-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-fieldbutton-placeholder-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-fieldbutton-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-fieldbutton-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-fieldbutton-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-fieldbutton-border-color-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-fieldbutton-validation-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-fieldbutton-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-fieldbutton-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-fieldbutton-background-color-down: var(--spectrum-global-color-gray-200);
  --spectrum-fieldbutton-border-color-down: var(--spectrum-alias-border-color-down);
  --spectrum-fieldbutton-placeholder-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-fieldbutton-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-fieldbutton-background-color-mouse-focus: var(--spectrum-global-color-gray-200);
  --spectrum-fieldbutton-border-color-mouse-focus: var(--spectrum-alias-border-color-down);
  --spectrum-fieldbutton-icon-color-mouse-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-fieldbutton-placeholder-text-color-mouse-focus: var(--spectrum-global-color-gray-900);
  --spectrum-fieldbutton-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-fieldbutton-validation-icon-color-error: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-fieldbutton-validation-icon-color-error-hover: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-border-color-error-down: var(--spectrum-global-color-red-600);
  --spectrum-fieldbutton-validation-icon-color-error-down: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-fieldbutton-validation-icon-color-error-key-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-border-color-error-mouse-focus: var(--spectrum-global-color-red-500);
  --spectrum-fieldbutton-validation-icon-color-error-mouse-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-placeholder-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-fieldbutton-placeholder-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-fieldbutton-placeholder-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-fieldbutton-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-fieldbutton-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-fieldbutton-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-fieldbutton-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-fieldbutton-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-fieldbutton-text-transform: none;
  --spectrum-fieldbutton-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-fieldbutton-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-fieldbutton-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-fieldbutton-border-size-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-fieldbutton-ui-icon-height: 6px;
  --spectrum-fieldbutton-ui-icon-width: var(--spectrum-global-dimension-static-size-125);
  --spectrum-fieldbutton-height: var(--spectrum-global-dimension-size-400);
  --spectrum-fieldbutton-min-width: var(--spectrum-global-dimension-size-400);
  --spectrum-fieldbutton-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-fieldbutton-affixed-border-radius: 0;
  --spectrum-fieldbutton-label-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-fieldbutton-label-gap-y: var(--spectrum-global-dimension-size-100);
  --spectrum-fieldbutton-asterisk-size: var(--spectrum-global-dimension-size-100);
  --spectrum-fieldbutton-asterisk-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-fieldbutton-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-fieldbutton-icon-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-fieldbutton-success-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-success-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-fieldbutton-error-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-fieldbutton-quiet-placeholder-text-color: var(--spectrum-alias-placeholder-text-color);
  --spectrum-fieldbutton-quiet-text-color: var(--spectrum-alias-text-color);
  --spectrum-fieldbutton-quiet-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-fieldbutton-quiet-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-fieldbutton-quiet-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-fieldbutton-quiet-placeholder-text-color-hover: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-fieldbutton-quiet-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-fieldbutton-quiet-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-fieldbutton-quiet-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-fieldbutton-quiet-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-fieldbutton-quiet-placeholder-text-color-key-focus: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-fieldbutton-quiet-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-fieldbutton-quiet-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-fieldbutton-quiet-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-fieldbutton-quiet-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-fieldbutton-quiet-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-fieldbutton-quiet-placeholder-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-fieldbutton-quiet-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-fieldbutton-quiet-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-fieldbutton-quiet-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-fieldbutton-quiet-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-fieldbutton-quiet-validation-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-fieldbutton-quiet-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-fieldbutton-quiet-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-fieldbutton-quiet-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-fieldbutton-quiet-border-color-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-fieldbutton-quiet-placeholder-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-fieldbutton-quiet-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-fieldbutton-quiet-background-color-mouse-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-fieldbutton-quiet-border-color-mouse-focus: var(--spectrum-alias-border-color-down);
  --spectrum-fieldbutton-quiet-icon-color-mouse-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-fieldbutton-quiet-placeholder-text-color-mouse-focus: var(--spectrum-global-color-gray-900);
  --spectrum-fieldbutton-quiet-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-fieldbutton-quiet-validation-icon-color-error: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-quiet-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-fieldbutton-quiet-validation-icon-color-error-hover: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-quiet-border-color-error-down: var(--spectrum-global-color-red-600);
  --spectrum-fieldbutton-quiet-validation-icon-color-error-down: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-quiet-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-fieldbutton-quiet-validation-icon-color-error-key-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-quiet-border-color-error-mouse-focus: var(--spectrum-global-color-red-500);
  --spectrum-fieldbutton-quiet-validation-icon-color-error-mouse-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-fieldbutton-quiet-placeholder-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-fieldbutton-quiet-placeholder-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-fieldbutton-quiet-placeholder-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-fieldbutton-quiet-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-fieldbutton-quiet-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-fieldbutton-quiet-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-fieldbutton-quiet-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-fieldbutton-quiet-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-fieldbutton-quiet-text-transform: none;
  --spectrum-fieldbutton-quiet-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-fieldbutton-quiet-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-fieldbutton-quiet-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-fieldbutton-quiet-border-size-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-fieldbutton-quiet-ui-icon-height: 6px;
  --spectrum-fieldbutton-quiet-ui-icon-width: var(--spectrum-global-dimension-static-size-125);
  --spectrum-fieldbutton-quiet-height: var(--spectrum-global-dimension-size-400);
  --spectrum-fieldbutton-quiet-padding-x: 0px;
  --spectrum-fieldbutton-quiet-border-radius: 0px;
  --spectrum-fieldbutton-quiet-affixed-border-radius: 0;
  --spectrum-fieldbutton-quiet-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-quiet-label-gap-y: 0px;
  --spectrum-fieldbutton-quiet-asterisk-size: var(--spectrum-global-dimension-size-100);
  --spectrum-fieldbutton-quiet-asterisk-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-fieldbutton-quiet-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-quiet-icon-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-fieldbutton-quiet-icon-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-quiet-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-fieldbutton-quiet-success-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-quiet-success-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-fieldbutton-quiet-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-fieldbutton-quiet-error-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-fieldbutton-quiet-affixed-border-size: var(--spectrum-global-dimension-static-size-10);
}
