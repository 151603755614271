.spectrum {
  --spectrum-barloader-large-text-color: var(--spectrum-alias-text-color);
  --spectrum-barloader-large-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-barloader-large-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-barloader-large-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-barloader-large-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-barloader-large-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-barloader-large-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-barloader-large-height: var(--spectrum-global-dimension-size-75);
  --spectrum-barloader-large-fill-border-radius: 0;
  --spectrum-barloader-large-label-gap-y: var(--spectrum-global-dimension-size-115);
  --spectrum-barloader-large-indeterminate-text-color: var(--spectrum-alias-text-color);
  --spectrum-barloader-large-indeterminate-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-barloader-large-indeterminate-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-barloader-large-indeterminate-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-barloader-large-indeterminate-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-barloader-large-indeterminate-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-barloader-large-indeterminate-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-barloader-large-indeterminate-height: var(--spectrum-global-dimension-size-75);
  --spectrum-barloader-large-indeterminate-fill-border-radius: 0;
  --spectrum-barloader-large-indeterminate-label-gap-y: var(--spectrum-global-dimension-size-115);
  --spectrum-barloader-large-indeterminate-fill-width: var(--spectrum-global-dimension-size-1700);
  --spectrum-barloader-large-indeterminate-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-barloader-large-indeterminate-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-barloader-large-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-barloader-large-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-barloader-large-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-barloader-large-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-barloader-large-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-barloader-large-over-background-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-barloader-large-over-background-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-barloader-large-over-background-height: var(--spectrum-global-dimension-size-75);
  --spectrum-barloader-large-over-background-fill-border-radius: 0;
  --spectrum-barloader-large-over-background-label-gap-y: var(--spectrum-global-dimension-size-115);
  --spectrum-barloader-small-text-color: var(--spectrum-alias-text-color);
  --spectrum-barloader-small-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-barloader-small-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-barloader-small-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-barloader-small-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-barloader-small-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-barloader-small-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-barloader-small-height: var(--spectrum-global-dimension-size-50);
  --spectrum-barloader-small-fill-border-radius: 0;
  --spectrum-barloader-small-label-gap-y: var(--spectrum-global-dimension-size-115);
  --spectrum-barloader-small-indeterminate-text-color: var(--spectrum-alias-text-color);
  --spectrum-barloader-small-indeterminate-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-barloader-small-indeterminate-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-barloader-small-indeterminate-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-barloader-small-indeterminate-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-barloader-small-indeterminate-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-barloader-small-indeterminate-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-barloader-small-indeterminate-height: var(--spectrum-global-dimension-size-50);
  --spectrum-barloader-small-indeterminate-fill-border-radius: 0;
  --spectrum-barloader-small-indeterminate-label-gap-y: var(--spectrum-global-dimension-size-115);
  --spectrum-barloader-small-indeterminate-fill-width: var(--spectrum-global-dimension-size-1700);
  --spectrum-barloader-small-indeterminate-animation-ease: var(--spectrum-global-animation-ease-in-out);
  --spectrum-barloader-small-indeterminate-duration: var(--spectrum-global-animation-duration-2000);
  --spectrum-barloader-small-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-barloader-small-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-barloader-small-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-barloader-small-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-barloader-small-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-barloader-small-over-background-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-barloader-small-over-background-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-barloader-small-over-background-height: var(--spectrum-global-dimension-size-50);
  --spectrum-barloader-small-over-background-fill-border-radius: 0;
  --spectrum-barloader-small-over-background-label-gap-y: var(--spectrum-global-dimension-size-115);
}
