.spectrum {
  --spectrum-rating-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-rating-focus-ring-color: var(--spectrum-alias-focus-color);
  --spectrum-rating-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-icon-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-rating-border-color-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-rating-border-color-key-focus: var(--spectrum-alias-focus-color);
  --spectrum-rating-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-rating-border-color-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-icon-color-selected: var(--spectrum-alias-icon-color);
  --spectrum-rating-border-color-selected-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-icon-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-rating-border-color-selected-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-icon-color-selected-down: var(--spectrum-alias-icon-color-down);
  --spectrum-rating-border-color-selected-key-focus: var(--spectrum-alias-focus-color);
  --spectrum-rating-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-rating-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-icon-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-rating-height: var(--spectrum-alias-single-line-height);
  --spectrum-rating-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-rating-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-rating-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-rating-icon-count: 5;
  --spectrum-rating-icon-spacing: var(--spectrum-global-dimension-size-75);
  --spectrum-rating-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-rating-indicator-height: var(--spectrum-global-dimension-size-25);
  --spectrum-rating-indicator-border-radius: var(--spectrum-alias-border-radius-small);
  --spectrum-rating-indicator-offset: var(--spectrum-alias-border-size-thick);
  --spectrum-rating-emphasized-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-emphasized-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-rating-emphasized-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-emphasized-icon-color-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-rating-emphasized-border-color-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-emphasized-icon-color-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-rating-emphasized-border-color-key-focus: var(--spectrum-alias-focus-color);
  --spectrum-rating-emphasized-icon-color-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-rating-emphasized-border-color-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-emphasized-icon-color-selected: var(--spectrum-alias-icon-color-selected);
  --spectrum-rating-emphasized-border-color-selected-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-emphasized-icon-color-selected-hover: var(--spectrum-alias-icon-color-selected-hover);
  --spectrum-rating-emphasized-border-color-selected-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-emphasized-icon-color-selected-down: var(--spectrum-alias-icon-color-selected-down);
  --spectrum-rating-emphasized-border-color-selected-key-focus: var(--spectrum-alias-focus-color);
  --spectrum-rating-emphasized-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-selected-focus);
  --spectrum-rating-emphasized-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-emphasized-icon-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-rating-emphasized-height: var(--spectrum-alias-single-line-height);
  --spectrum-rating-emphasized-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-rating-emphasized-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-rating-emphasized-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-rating-emphasized-icon-count: 5;
  --spectrum-rating-emphasized-icon-spacing: var(--spectrum-global-dimension-size-75);
  --spectrum-rating-emphasized-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-rating-emphasized-indicator-height: var(--spectrum-global-dimension-size-25);
  --spectrum-rating-emphasized-indicator-border-radius: var(--spectrum-alias-border-radius-small);
  --spectrum-rating-emphasized-indicator-offset: var(--spectrum-alias-border-size-thick);
  --spectrum-rating-quiet-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-quiet-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-rating-quiet-focus-ring-color: var(--spectrum-alias-focus-color);
  --spectrum-rating-quiet-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-quiet-icon-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-rating-quiet-border-color-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-quiet-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-rating-quiet-border-color-key-focus: var(--spectrum-alias-focus-color);
  --spectrum-rating-quiet-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-rating-quiet-border-color-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-quiet-icon-color-selected: var(--spectrum-alias-icon-color);
  --spectrum-rating-quiet-border-color-selected-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-quiet-icon-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-rating-quiet-border-color-selected-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-quiet-icon-color-selected-down: var(--spectrum-alias-icon-color-down);
  --spectrum-rating-quiet-border-color-selected-key-focus: var(--spectrum-alias-focus-color);
  --spectrum-rating-quiet-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-rating-quiet-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-rating-quiet-icon-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-rating-quiet-height: var(--spectrum-alias-single-line-height);
  --spectrum-rating-quiet-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-rating-quiet-focus-ring-size: var(--spectrum-alias-border-size-thick);
  --spectrum-rating-quiet-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-rating-quiet-icon-count: 5;
  --spectrum-rating-quiet-icon-spacing: var(--spectrum-global-dimension-size-75);
  --spectrum-rating-quiet-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-rating-quiet-indicator-height: var(--spectrum-global-dimension-size-25);
  --spectrum-rating-quiet-indicator-border-radius: var(--spectrum-alias-border-radius-small);
  --spectrum-rating-quiet-indicator-offset: var(--spectrum-alias-border-size-thick);
}
