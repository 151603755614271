.spectrum {
  --spectrum-actionbutton-border-size-selected: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-background-color-selected: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-border-color-selected: var(--spectrum-alias-border-color);
  --spectrum-actionbutton-text-color-selected: var(--spectrum-alias-text-color);
  --spectrum-actionbutton-hold-icon-color-selected: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-icon-color-selected: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-border-size-selected-hover: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-background-color-selected-hover: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-border-color-selected-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-actionbutton-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-hold-icon-color-selected-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-icon-color-selected-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-border-size-selected-down: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-background-color-selected-down: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-border-color-selected-down: var(--spectrum-alias-border-color-down);
  --spectrum-actionbutton-text-color-selected-down: var(--spectrum-alias-text-color-down);
  --spectrum-actionbutton-hold-icon-color-selected-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-icon-color-selected-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-border-size-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-actionbutton-background-color-selected-key-focus: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-border-color-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-actionbutton-text-color-selected-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-hold-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-border-size-selected-disabled: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-actionbutton-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-hold-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-background-color-selected-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-border-color-selected-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-actionbutton-text-color: var(--spectrum-alias-text-color);
  --spectrum-actionbutton-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-actionbutton-border-color: var(--spectrum-alias-border-color);
  --spectrum-actionbutton-hold-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-border-size-hover: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-background-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-actionbutton-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-actionbutton-hold-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-actionbutton-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-border-size-down: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-background-color-down: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-border-color-down: var(--spectrum-alias-border-color-down);
  --spectrum-actionbutton-hold-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-actionbutton-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-actionbutton-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-actionbutton-background-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-actionbutton-hold-icon-color-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-actionbutton-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-border-size-disabled: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-border-color-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-actionbutton-hold-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-actionbutton-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-actionbutton-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-actionbutton-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-actionbutton-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-actionbutton-text-transform: none;
  --spectrum-actionbutton-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-actionbutton-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-actionbutton-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-actionbutton-height: var(--spectrum-alias-single-line-height);
  --spectrum-actionbutton-min-width: var(--spectrum-global-dimension-size-400);
  --spectrum-actionbutton-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-actionbutton-text-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-actionbutton-icon-padding-x: var(--spectrum-global-dimension-size-85);
  --spectrum-actionbutton-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-actionbutton-hold-icon-padding-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-actionbutton-hold-icon-padding-right: var(--spectrum-global-dimension-size-40);
  --spectrum-actionbutton-hold-icon-size: var(--spectrum-global-dimension-size-65);
  --spectrum-actionbutton-emphasized-border-size-selected: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-emphasized-background-color-selected: var(--spectrum-semantic-cta-color-background-default);
  --spectrum-actionbutton-emphasized-border-color-selected: var(--spectrum-semantic-cta-color-background-default);
  --spectrum-actionbutton-emphasized-text-color-selected: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-hold-icon-color-selected: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-icon-color-selected: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-border-size-selected-hover: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-emphasized-background-color-selected-hover: var(--spectrum-semantic-cta-color-background-hover);
  --spectrum-actionbutton-emphasized-border-color-selected-hover: var(--spectrum-semantic-cta-color-background-hover);
  --spectrum-actionbutton-emphasized-text-color-selected-hover: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-hold-icon-color-selected-hover: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-icon-color-selected-hover: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-border-size-selected-down: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-emphasized-background-color-selected-down: var(--spectrum-semantic-cta-color-background-down);
  --spectrum-actionbutton-emphasized-border-color-selected-down: var(--spectrum-semantic-cta-color-background-down);
  --spectrum-actionbutton-emphasized-text-color-selected-down: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-hold-icon-color-selected-down: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-icon-color-selected-down: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-border-size-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-actionbutton-emphasized-background-color-selected-key-focus: var(--spectrum-semantic-cta-color-background-key-focus);
  --spectrum-actionbutton-emphasized-border-color-selected-key-focus: var(--spectrum-semantic-cta-color-background-key-focus);
  --spectrum-actionbutton-emphasized-text-color-selected-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-hold-icon-color-selected-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-icon-color-selected-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-emphasized-border-size-selected-disabled: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-emphasized-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-actionbutton-emphasized-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-emphasized-hold-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-emphasized-background-color-selected-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-emphasized-border-color-selected-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-actionbutton-emphasized-text-color: var(--spectrum-alias-text-color);
  --spectrum-actionbutton-emphasized-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-emphasized-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-emphasized-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-actionbutton-emphasized-border-color: var(--spectrum-alias-border-color);
  --spectrum-actionbutton-emphasized-hold-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-emphasized-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-emphasized-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-emphasized-border-size-hover: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-emphasized-background-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-actionbutton-emphasized-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-actionbutton-emphasized-hold-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-emphasized-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-actionbutton-emphasized-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-emphasized-border-size-down: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-emphasized-background-color-down: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-emphasized-border-color-down: var(--spectrum-alias-border-color-down);
  --spectrum-actionbutton-emphasized-hold-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-emphasized-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-emphasized-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-actionbutton-emphasized-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-actionbutton-emphasized-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-actionbutton-emphasized-background-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-actionbutton-emphasized-hold-icon-color-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-emphasized-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-emphasized-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-actionbutton-emphasized-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-emphasized-border-size-disabled: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-emphasized-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-emphasized-border-color-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-actionbutton-emphasized-hold-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-emphasized-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-actionbutton-emphasized-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-actionbutton-emphasized-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-actionbutton-emphasized-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-actionbutton-emphasized-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-actionbutton-emphasized-text-transform: none;
  --spectrum-actionbutton-emphasized-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-actionbutton-emphasized-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-actionbutton-emphasized-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-actionbutton-emphasized-height: var(--spectrum-alias-single-line-height);
  --spectrum-actionbutton-emphasized-min-width: var(--spectrum-global-dimension-size-400);
  --spectrum-actionbutton-emphasized-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-actionbutton-emphasized-text-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-actionbutton-emphasized-icon-padding-x: var(--spectrum-global-dimension-size-85);
  --spectrum-actionbutton-emphasized-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-actionbutton-emphasized-hold-icon-padding-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-actionbutton-emphasized-hold-icon-padding-right: var(--spectrum-global-dimension-size-40);
  --spectrum-actionbutton-emphasized-hold-icon-size: var(--spectrum-global-dimension-size-65);
  --spectrum-actionbutton-quiet-border-size-selected: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-background-color-selected: var(--spectrum-global-color-gray-300);
  --spectrum-actionbutton-quiet-border-color-selected: var(--spectrum-alias-border-color);
  --spectrum-actionbutton-quiet-text-color-selected: var(--spectrum-alias-text-color);
  --spectrum-actionbutton-quiet-hold-icon-color-selected: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-quiet-icon-color-selected: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-quiet-border-size-selected-hover: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-background-color-selected-hover: var(--spectrum-global-color-gray-300);
  --spectrum-actionbutton-quiet-border-color-selected-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-actionbutton-quiet-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-quiet-hold-icon-color-selected-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-icon-color-selected-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-border-size-selected-down: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-background-color-selected-down: var(--spectrum-global-color-gray-300);
  --spectrum-actionbutton-quiet-border-color-selected-down: var(--spectrum-alias-border-color-down);
  --spectrum-actionbutton-quiet-text-color-selected-down: var(--spectrum-alias-text-color-down);
  --spectrum-actionbutton-quiet-hold-icon-color-selected-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-quiet-icon-color-selected-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-quiet-border-size-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-actionbutton-quiet-background-color-selected-key-focus: var(--spectrum-global-color-gray-300);
  --spectrum-actionbutton-quiet-border-color-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-actionbutton-quiet-text-color-selected-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-quiet-hold-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-icon-color-selected-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-border-size-selected-disabled: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-actionbutton-quiet-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-quiet-hold-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-quiet-background-color-selected-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-quiet-border-color-selected-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-actionbutton-quiet-text-color: var(--spectrum-alias-text-color);
  --spectrum-actionbutton-quiet-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-quiet-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-actionbutton-quiet-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-actionbutton-quiet-hold-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-quiet-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-quiet-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-border-size-hover: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-actionbutton-quiet-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-actionbutton-quiet-hold-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-actionbutton-quiet-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-quiet-border-size-down: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-background-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-actionbutton-quiet-border-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-actionbutton-quiet-hold-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-quiet-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-quiet-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-actionbutton-quiet-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-actionbutton-quiet-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-actionbutton-quiet-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-actionbutton-quiet-hold-icon-color-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-quiet-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-actionbutton-quiet-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-quiet-border-size-disabled: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-actionbutton-quiet-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-actionbutton-quiet-hold-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-quiet-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-actionbutton-quiet-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-actionbutton-quiet-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-actionbutton-quiet-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-actionbutton-quiet-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-actionbutton-quiet-text-transform: none;
  --spectrum-actionbutton-quiet-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-actionbutton-quiet-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-actionbutton-quiet-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-actionbutton-quiet-height: var(--spectrum-alias-single-line-height);
  --spectrum-actionbutton-quiet-min-width: var(--spectrum-global-dimension-size-400);
  --spectrum-actionbutton-quiet-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-actionbutton-quiet-text-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-actionbutton-quiet-icon-padding-x: var(--spectrum-global-dimension-size-85);
  --spectrum-actionbutton-quiet-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-actionbutton-quiet-hold-icon-padding-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-actionbutton-quiet-hold-icon-padding-right: var(--spectrum-global-dimension-size-40);
  --spectrum-actionbutton-quiet-hold-icon-size: var(--spectrum-global-dimension-size-65);
  --spectrum-actionbutton-quiet-emphasized-border-size-selected: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-emphasized-background-color-selected: var(--spectrum-semantic-cta-color-background-default);
  --spectrum-actionbutton-quiet-emphasized-border-color-selected: var(--spectrum-semantic-cta-color-background-default);
  --spectrum-actionbutton-quiet-emphasized-text-color-selected: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color-selected: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-icon-color-selected: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-border-size-selected-hover: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-emphasized-background-color-selected-hover: var(--spectrum-semantic-cta-color-background-hover);
  --spectrum-actionbutton-quiet-emphasized-border-color-selected-hover: var(--spectrum-semantic-cta-color-background-hover);
  --spectrum-actionbutton-quiet-emphasized-text-color-selected-hover: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color-selected-hover: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-icon-color-selected-hover: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-border-size-selected-down: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-emphasized-background-color-selected-down: var(--spectrum-semantic-cta-color-background-down);
  --spectrum-actionbutton-quiet-emphasized-border-color-selected-down: var(--spectrum-semantic-cta-color-background-down);
  --spectrum-actionbutton-quiet-emphasized-text-color-selected-down: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color-selected-down: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-icon-color-selected-down: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-border-size-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-actionbutton-quiet-emphasized-background-color-selected-key-focus: var(--spectrum-semantic-cta-color-background-key-focus);
  --spectrum-actionbutton-quiet-emphasized-border-color-selected-key-focus: var(--spectrum-semantic-cta-color-background-key-focus);
  --spectrum-actionbutton-quiet-emphasized-text-color-selected-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color-selected-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-icon-color-selected-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-actionbutton-quiet-emphasized-border-size-selected-disabled: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-emphasized-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-actionbutton-quiet-emphasized-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color-selected-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-quiet-emphasized-background-color-selected-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-actionbutton-quiet-emphasized-border-color-selected-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-actionbutton-quiet-emphasized-text-color: var(--spectrum-alias-text-color);
  --spectrum-actionbutton-quiet-emphasized-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-quiet-emphasized-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-emphasized-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-actionbutton-quiet-emphasized-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-actionbutton-quiet-emphasized-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-quiet-emphasized-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-emphasized-border-size-hover: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-emphasized-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-actionbutton-quiet-emphasized-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-emphasized-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-actionbutton-quiet-emphasized-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-quiet-emphasized-border-size-down: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-emphasized-background-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-actionbutton-quiet-emphasized-border-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-actionbutton-quiet-emphasized-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-quiet-emphasized-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-actionbutton-quiet-emphasized-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-actionbutton-quiet-emphasized-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-actionbutton-quiet-emphasized-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-actionbutton-quiet-emphasized-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-actionbutton-quiet-emphasized-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-actionbutton-quiet-emphasized-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-quiet-emphasized-border-size-disabled: var(--spectrum-alias-border-size-thin);
  --spectrum-actionbutton-quiet-emphasized-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-actionbutton-quiet-emphasized-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-actionbutton-quiet-emphasized-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-actionbutton-quiet-emphasized-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-actionbutton-quiet-emphasized-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-actionbutton-quiet-emphasized-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-actionbutton-quiet-emphasized-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-actionbutton-quiet-emphasized-text-transform: none;
  --spectrum-actionbutton-quiet-emphasized-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-actionbutton-quiet-emphasized-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-actionbutton-quiet-emphasized-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-actionbutton-quiet-emphasized-height: var(--spectrum-alias-single-line-height);
  --spectrum-actionbutton-quiet-emphasized-min-width: var(--spectrum-global-dimension-size-400);
  --spectrum-actionbutton-quiet-emphasized-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-actionbutton-quiet-emphasized-text-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-actionbutton-quiet-emphasized-icon-padding-x: var(--spectrum-global-dimension-size-85);
  --spectrum-actionbutton-quiet-emphasized-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-padding-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-padding-right: var(--spectrum-global-dimension-size-40);
  --spectrum-actionbutton-quiet-emphasized-hold-icon-size: var(--spectrum-global-dimension-size-65);
}
