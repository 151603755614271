.spectrum {
  --spectrum-textfield-placeholder-text-color: var(--spectrum-alias-placeholder-text-color);
  --spectrum-textfield-text-color: var(--spectrum-alias-text-color);
  --spectrum-textfield-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-textfield-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-textfield-border-color: var(--spectrum-alias-border-color);
  --spectrum-textfield-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-textfield-placeholder-text-color-hover: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-textfield-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-textfield-background-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-textfield-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-textfield-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-textfield-placeholder-text-color-key-focus: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-textfield-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-textfield-background-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-textfield-icon-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-textfield-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-textfield-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-textfield-placeholder-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-textfield-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-textfield-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-textfield-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-textfield-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-textfield-validation-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-textfield-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-textfield-placeholder-text-color-down: var(--spectrum-alias-placeholder-text-color-down);
  --spectrum-textfield-background-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-textfield-border-color-down: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-textfield-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-textfield-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-textfield-placeholder-text-color-mouse-focus: var(--spectrum-alias-placeholder-text-color);
  --spectrum-textfield-background-color-mouse-focus: var(--spectrum-global-color-gray-50);
  --spectrum-textfield-border-color-mouse-focus: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-textfield-border-size-mouse-focus: var(--spectrum-alias-border-size-thin);
  --spectrum-textfield-icon-color-mouse-focus: var(--spectrum-global-color-gray-700);
  --spectrum-textfield-border-color-error: var(--spectrum-semantic-negative-color-default);
  --spectrum-textfield-validation-icon-color-error: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-border-color-error-hover: var(--spectrum-semantic-negative-color-state-hover);
  --spectrum-textfield-validation-icon-color-error-hover: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-border-color-error-down: var(--spectrum-semantic-negative-color-state-down);
  --spectrum-textfield-validation-icon-color-error-down: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-textfield-validation-icon-color-error-key-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-border-color-error-mouse-focus: var(--spectrum-semantic-negative-color-state-hover);
  --spectrum-textfield-validation-icon-color-error-mouse-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-icon-color-valid: var(--spectrum-global-color-green-400);
  --spectrum-textfield-border-color-valid-hover: var(--spectrum-global-color-gray-400);
  --spectrum-textfield-icon-color-valid-hover: var(--spectrum-global-color-green-400);
  --spectrum-textfield-border-color-valid-down: var(--spectrum-alias-border-color-down);
  --spectrum-textfield-icon-color-valid-down: var(--spectrum-global-color-green-400);
  --spectrum-textfield-border-color-valid-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-textfield-icon-color-valid-key-focus: var(--spectrum-global-color-green-400);
  --spectrum-textfield-border-color-valid-mouse-focus: var(--spectrum-alias-border-color-down);
  --spectrum-textfield-icon-color-valid-mouse-focus: var(--spectrum-global-color-green-400);
  --spectrum-textfield-placeholder-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-textfield-placeholder-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-textfield-placeholder-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-textfield-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-textfield-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-textfield-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-textfield-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-textfield-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-textfield-text-transform: none;
  --spectrum-textfield-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-textfield-height: var(--spectrum-alias-single-line-height);
  --spectrum-textfield-min-width: var(--spectrum-global-dimension-size-600);
  --spectrum-textfield-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-textfield-padding-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-textfield-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-textfield-label-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-textfield-label-gap-y: var(--spectrum-global-dimension-size-100);
  --spectrum-textfield-label-padding-top: var(--spectrum-global-dimension-size-100);
  --spectrum-textfield-label-padding-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-counter-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-textfield-asterisk-size: var(--spectrum-global-dimension-size-100);
  --spectrum-textfield-asterisk-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-textfield-icon-size: var(--spectrum-global-dimension-size-225);
  --spectrum-textfield-success-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-success-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-textfield-error-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-textfield-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-cursor-hit-x: var(--spectrum-global-dimension-size-50);
  --spectrum-textfield-icon-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-icon-margin-right: var(--spectrum-global-dimension-size-0);
  --spectrum-textfield-quiet-placeholder-text-color: var(--spectrum-alias-placeholder-text-color);
  --spectrum-textfield-quiet-text-color: var(--spectrum-alias-text-color);
  --spectrum-textfield-quiet-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-textfield-quiet-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-textfield-quiet-border-color: var(--spectrum-alias-border-color);
  --spectrum-textfield-quiet-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-textfield-quiet-placeholder-text-color-hover: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-textfield-quiet-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-textfield-quiet-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-textfield-quiet-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-textfield-quiet-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-textfield-quiet-placeholder-text-color-key-focus: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-textfield-quiet-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-textfield-quiet-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-textfield-quiet-icon-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-textfield-quiet-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-textfield-quiet-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-textfield-quiet-placeholder-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-textfield-quiet-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-textfield-quiet-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-textfield-quiet-border-color-disabled: var(--spectrum-alias-border-color-mid);
  --spectrum-textfield-quiet-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-textfield-quiet-validation-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-textfield-quiet-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-textfield-quiet-placeholder-text-color-down: var(--spectrum-alias-placeholder-text-color-down);
  --spectrum-textfield-quiet-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-textfield-quiet-border-color-down: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-textfield-quiet-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-textfield-quiet-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-textfield-quiet-placeholder-text-color-mouse-focus: var(--spectrum-alias-placeholder-text-color);
  --spectrum-textfield-quiet-background-color-mouse-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-textfield-quiet-border-color-mouse-focus: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-textfield-quiet-border-size-mouse-focus: var(--spectrum-alias-border-size-thin);
  --spectrum-textfield-quiet-icon-color-mouse-focus: var(--spectrum-global-color-gray-700);
  --spectrum-textfield-quiet-border-color-error: var(--spectrum-semantic-negative-color-default);
  --spectrum-textfield-quiet-validation-icon-color-error: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-quiet-border-color-error-hover: var(--spectrum-semantic-negative-color-state-hover);
  --spectrum-textfield-quiet-validation-icon-color-error-hover: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-quiet-border-color-error-down: var(--spectrum-semantic-negative-color-state-down);
  --spectrum-textfield-quiet-validation-icon-color-error-down: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-quiet-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-textfield-quiet-validation-icon-color-error-key-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-quiet-border-color-error-mouse-focus: var(--spectrum-semantic-negative-color-state-hover);
  --spectrum-textfield-quiet-validation-icon-color-error-mouse-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-textfield-quiet-icon-color-valid: var(--spectrum-global-color-green-400);
  --spectrum-textfield-quiet-border-color-valid-hover: var(--spectrum-global-color-gray-400);
  --spectrum-textfield-quiet-icon-color-valid-hover: var(--spectrum-global-color-green-400);
  --spectrum-textfield-quiet-border-color-valid-down: var(--spectrum-alias-border-color-down);
  --spectrum-textfield-quiet-icon-color-valid-down: var(--spectrum-global-color-green-400);
  --spectrum-textfield-quiet-border-color-valid-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-textfield-quiet-icon-color-valid-key-focus: var(--spectrum-global-color-green-400);
  --spectrum-textfield-quiet-border-color-valid-mouse-focus: var(--spectrum-alias-border-color-down);
  --spectrum-textfield-quiet-icon-color-valid-mouse-focus: var(--spectrum-global-color-green-400);
  --spectrum-textfield-quiet-placeholder-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-textfield-quiet-placeholder-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-textfield-quiet-placeholder-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-textfield-quiet-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-textfield-quiet-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-textfield-quiet-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-textfield-quiet-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-textfield-quiet-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-textfield-quiet-text-transform: none;
  --spectrum-textfield-quiet-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-textfield-quiet-height: var(--spectrum-alias-single-line-height);
  --spectrum-textfield-quiet-min-width: var(--spectrum-global-dimension-size-600);
  --spectrum-textfield-quiet-padding-x: 0;
  --spectrum-textfield-quiet-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-textfield-quiet-padding-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-textfield-quiet-border-radius: 0px;
  --spectrum-textfield-quiet-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-quiet-label-gap-y: 0;
  --spectrum-textfield-quiet-label-padding-top: var(--spectrum-global-dimension-size-100);
  --spectrum-textfield-quiet-label-padding-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-quiet-counter-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-textfield-quiet-asterisk-size: var(--spectrum-global-dimension-size-100);
  --spectrum-textfield-quiet-asterisk-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-textfield-quiet-icon-size: var(--spectrum-global-dimension-size-225);
  --spectrum-textfield-quiet-success-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-quiet-success-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-quiet-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-textfield-quiet-error-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-textfield-quiet-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-quiet-cursor-hit-x: var(--spectrum-global-dimension-size-50);
  --spectrum-textfield-quiet-icon-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-textfield-quiet-icon-margin-right: var(--spectrum-global-dimension-size-0);
  --spectrum-textfield-quiet-affixed-border-size: var(--spectrum-global-dimension-static-size-10);
}
