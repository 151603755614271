.spectrum {
  --spectrum-inlinealert-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-inlinealert-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-inlinealert-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-inlinealert-border-color: var(--spectrum-global-color-gray-600);
  --spectrum-inlinealert-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-inlinealert-content-margin-top: var(--spectrum-global-dimension-static-size-100);
  --spectrum-inlinealert-error-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-inlinealert-error-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-inlinealert-error-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-inlinealert-error-border-color: var(--spectrum-semantic-negative-color-border);
  --spectrum-inlinealert-error-icon-color: var(--spectrum-semantic-negative-color-icon);
  --spectrum-inlinealert-error-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-error-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-error-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-inlinealert-error-content-margin-top: var(--spectrum-global-dimension-static-size-100);
  --spectrum-inlinealert-info-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-inlinealert-info-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-inlinealert-info-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-inlinealert-info-border-color: var(--spectrum-semantic-informative-color-border);
  --spectrum-inlinealert-info-icon-color: var(--spectrum-semantic-informative-color-icon);
  --spectrum-inlinealert-info-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-info-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-info-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-inlinealert-info-content-margin-top: var(--spectrum-global-dimension-static-size-100);
  --spectrum-inlinealert-success-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-inlinealert-success-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-inlinealert-success-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-inlinealert-success-border-color: var(--spectrum-semantic-positive-color-border);
  --spectrum-inlinealert-success-icon-color: var(--spectrum-semantic-positive-color-icon);
  --spectrum-inlinealert-success-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-success-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-success-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-inlinealert-success-content-margin-top: var(--spectrum-global-dimension-static-size-100);
  --spectrum-inlinealert-warning-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-inlinealert-warning-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-inlinealert-warning-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-inlinealert-warning-border-color: var(--spectrum-semantic-notice-color-border);
  --spectrum-inlinealert-warning-icon-color: var(--spectrum-semantic-notice-color-icon);
  --spectrum-inlinealert-warning-padding-x: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-warning-padding-y: var(--spectrum-global-dimension-static-size-250);
  --spectrum-inlinealert-warning-min-width: var(--spectrum-global-dimension-static-size-4600);
  --spectrum-inlinealert-warning-content-margin-top: var(--spectrum-global-dimension-static-size-100);
}
