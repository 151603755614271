.spectrum {
  --spectrum-colorarea-border-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-colorarea-fill-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-colorarea-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-colorarea-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-colorarea-default-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorarea-default-height: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorarea-min-width: var(--spectrum-global-dimension-size-800);
  --spectrum-colorarea-min-height: var(--spectrum-global-dimension-size-800);
  --spectrum-colorarea-cursor-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-colorarea-cursor-hit-y: var(--spectrum-global-dimension-size-100);
  --spectrum-colorarea-touch-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-colorarea-touch-hit-y: var(--spectrum-global-dimension-size-100);
  --spectrum-colorarea-loupe-entry-animation-delay: 0ms;
  --spectrum-colorarea-loupe-entry-animation-duration: var(--spectrum-alias-loupe-entry-animation-duration);
  --spectrum-colorarea-loupe-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-colorarea-loupe-entry-animation-properties: opacity;
  --spectrum-colorarea-loupe-exit-animation-delay: 0ms;
  --spectrum-colorarea-loupe-exit-animation-duration: var(--spectrum-alias-loupe-exit-animation-duration);
  --spectrum-colorarea-loupe-exit-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-colorarea-loupe-exit-animation-properties: opacity;
}
