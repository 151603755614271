.spectrum {
  --spectrum-label-colored-gray-background-color: var(--spectrum-global-color-static-gray-700);
  --spectrum-label-colored-green-background-color: var(--spectrum-global-color-static-green-600);
  --spectrum-label-colored-blue-background-color: var(--spectrum-global-color-static-blue);
  --spectrum-label-colored-red-background-color: var(--spectrum-global-color-static-red-600);
  --spectrum-label-colored-seafoam-background-color: var(--spectrum-global-color-seafoam-400);
  --spectrum-label-colored-fuchsia-background-color: var(--spectrum-global-color-fuchsia-400);
  --spectrum-label-colored-orange-background-color: var(--spectrum-global-color-static-orange-600);
  --spectrum-label-colored-yellow-background-color: var(--spectrum-global-color-yellow-400);
  --spectrum-label-colored-text-color: var(--spectrum-global-color-static-white);
  --spectrum-label-colored-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-label-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-label-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-label-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-label-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-label-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-label-text-line-height: var(--spectrum-global-font-line-height-small);
  --spectrum-label-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-label-text-transform: none;
  --spectrum-label-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-label-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-label-over-background-text-color-disabled: var(--spectrum-global-color-static-white);
  --spectrum-label-over-background-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-label-over-background-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-label-over-background-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-label-over-background-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-label-large-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-label-large-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-label-large-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-label-large-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-label-large-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-label-large-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-label-large-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-label-large-text-transform: none;
  --spectrum-label-large-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-label-large-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-label-large-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-label-large-over-background-text-color-disabled: var(--spectrum-global-color-static-white);
  --spectrum-label-large-over-background-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-label-large-over-background-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-label-large-over-background-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-label-large-over-background-margin-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-label-large-over-background-text-line-height: var(--spectrum-alias-line-height-large);
}
