.spectrum {
  --spectrum-tag-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-tag-border-color: var(--spectrum-global-color-gray-600);
  --spectrum-tag-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-tag-icon-color: var(--spectrum-global-color-gray-600);
  --spectrum-tag-background-color-hover: var(--spectrum-global-color-gray-75);
  --spectrum-tag-border-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-background-color-down: var(--spectrum-global-color-gray-75);
  --spectrum-tag-border-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-tag-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-tag-icon-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-tag-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-tag-background-color-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-tag-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-text-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tag-icon-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tag-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-background-color-selected: var(--spectrum-global-color-gray-700);
  --spectrum-tag-border-color-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-text-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-icon-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-background-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-tag-border-color-selected-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-text-color-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-icon-color-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-background-color-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-tag-border-color-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-text-color-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-icon-color-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-background-color-selected-down: var(--spectrum-global-color-gray-800);
  --spectrum-tag-border-color-selected-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-text-color-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-icon-color-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-tag-icon-color-error: var(--spectrum-global-color-red-500);
  --spectrum-tag-text-color-error: var(--spectrum-global-color-gray-700);
  --spectrum-tag-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-tag-icon-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-tag-text-color-error-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-icon-color-error-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-tag-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-text-color-error-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tag-border-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-tag-icon-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-tag-text-color-error-down: var(--spectrum-global-color-gray-900);
  --spectrum-tag-background-color-error-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-border-color-error-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-text-color-error-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-icon-color-error-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-background-color-error-selected: var(--spectrum-global-color-gray-700);
  --spectrum-tag-border-color-error-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-icon-color-error-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-text-color-error-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-background-color-error-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-tag-border-color-error-selected-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-text-color-error-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-icon-color-error-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-background-color-error-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-tag-border-color-error-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-text-color-error-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-icon-color-error-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-background-color-error-selected-down: var(--spectrum-global-color-gray-800);
  --spectrum-tag-border-color-error-selected-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-text-color-error-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-icon-color-error-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-tag-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-tag-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tag-text-baseline: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-height: var(--spectrum-global-dimension-size-300);
  --spectrum-tag-icon-height: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-icon-width: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-tag-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-tag-padding-x: var(--spectrum-global-dimension-size-125);
  --spectrum-tag-avatar-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-icon-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-icon-size: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-deletable-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-tag-deletable-border-color: var(--spectrum-global-color-gray-600);
  --spectrum-tag-deletable-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-icon-color: var(--spectrum-global-color-gray-600);
  --spectrum-tag-deletable-background-color-hover: var(--spectrum-global-color-gray-75);
  --spectrum-tag-deletable-border-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-deletable-text-color-hover: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-deletable-background-color-down: var(--spectrum-global-color-gray-75);
  --spectrum-tag-deletable-border-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-tag-deletable-text-color-down: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-icon-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-tag-deletable-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-tag-deletable-background-color-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-tag-deletable-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-deletable-text-color-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-icon-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tag-deletable-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-deletable-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-deletable-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-deletable-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-deletable-background-color-selected: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-border-color-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-deletable-text-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-icon-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-background-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-tag-deletable-border-color-selected-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-deletable-text-color-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-icon-color-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-background-color-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-tag-deletable-border-color-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-deletable-text-color-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-icon-color-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-background-color-selected-down: var(--spectrum-global-color-gray-800);
  --spectrum-tag-deletable-border-color-selected-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-deletable-text-color-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-icon-color-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-tag-deletable-icon-color-error: var(--spectrum-global-color-red-500);
  --spectrum-tag-deletable-text-color-error: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-tag-deletable-icon-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-tag-deletable-text-color-error-hover: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-icon-color-error-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-tag-deletable-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-deletable-text-color-error-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-border-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-tag-deletable-icon-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-tag-deletable-text-color-error-down: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-background-color-error-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-deletable-border-color-error-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-deletable-text-color-error-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-deletable-icon-color-error-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-deletable-background-color-error-selected: var(--spectrum-global-color-gray-700);
  --spectrum-tag-deletable-border-color-error-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-deletable-icon-color-error-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-text-color-error-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-background-color-error-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-tag-deletable-border-color-error-selected-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-deletable-text-color-error-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-icon-color-error-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-background-color-error-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-tag-deletable-border-color-error-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-deletable-text-color-error-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-icon-color-error-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-background-color-error-selected-down: var(--spectrum-global-color-gray-800);
  --spectrum-tag-deletable-border-color-error-selected-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-deletable-text-color-error-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-icon-color-error-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-deletable-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-tag-deletable-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-tag-deletable-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tag-deletable-text-baseline: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-deletable-height: var(--spectrum-global-dimension-size-300);
  --spectrum-tag-deletable-icon-height: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-deletable-icon-width: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-deletable-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-tag-deletable-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-tag-deletable-padding-x: var(--spectrum-global-dimension-size-125);
  --spectrum-tag-deletable-avatar-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-deletable-icon-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-deletable-icon-size: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-removable-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-tag-removable-border-color: var(--spectrum-global-color-gray-600);
  --spectrum-tag-removable-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-icon-color: var(--spectrum-global-color-gray-600);
  --spectrum-tag-removable-background-color-hover: var(--spectrum-global-color-gray-75);
  --spectrum-tag-removable-border-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-removable-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-removable-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-removable-button-background-color-hover: var(--spectrum-global-color-gray-300);
  --spectrum-tag-removable-button-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-tag-removable-background-color-down: var(--spectrum-global-color-gray-75);
  --spectrum-tag-removable-border-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-tag-removable-text-color-down: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-icon-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-tag-removable-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-tag-removable-background-color-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-tag-removable-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-removable-text-color-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-icon-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tag-removable-button-background-color-key-focus: var(--spectrum-global-color-gray-600);
  --spectrum-tag-removable-button-icon-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-tag-removable-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-removable-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-removable-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-removable-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-removable-background-color-selected: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-border-color-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-removable-text-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-icon-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-button-icon-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-button-background-color-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-removable-background-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-tag-removable-border-color-selected-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-removable-text-color-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-icon-color-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-button-icon-color-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-button-background-color-selected-hover: var(--spectrum-global-color-gray-600);
  --spectrum-tag-removable-background-color-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-tag-removable-border-color-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-removable-text-color-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-icon-color-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-button-icon-color-selected-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-button-background-color-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-background-color-selected-down: var(--spectrum-global-color-gray-800);
  --spectrum-tag-removable-border-color-selected-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-removable-text-color-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-icon-color-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-button-icon-color-selected-down: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-button-background-color-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-tag-removable-icon-color-error: var(--spectrum-global-color-red-500);
  --spectrum-tag-removable-text-color-error: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-tag-removable-icon-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-tag-removable-text-color-error-hover: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-background-color-error-hover: var(--spectrum-global-color-gray-300);
  --spectrum-tag-removable-icon-color-error-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-tag-removable-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-removable-text-color-error-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-background-color-error-key-focus: var(--spectrum-global-color-gray-300);
  --spectrum-tag-removable-border-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-tag-removable-icon-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-tag-removable-text-color-error-down: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-background-color-error-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-removable-border-color-error-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-tag-removable-text-color-error-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-removable-icon-color-error-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-tag-removable-background-color-error-selected: var(--spectrum-global-color-gray-700);
  --spectrum-tag-removable-border-color-error-selected: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-removable-icon-color-error-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-text-color-error-selected: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-background-color-error-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-tag-removable-border-color-error-selected-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-removable-text-color-error-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-icon-color-error-selected-hover: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-background-color-error-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-tag-removable-border-color-error-selected-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-tag-removable-text-color-error-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-icon-color-error-selected-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-background-color-error-selected-down: var(--spectrum-global-color-gray-800);
  --spectrum-tag-removable-border-color-error-selected-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-tag-removable-text-color-error-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-icon-color-error-selected-down: var(--spectrum-global-color-gray-50);
  --spectrum-tag-removable-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-tag-removable-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-tag-removable-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-tag-removable-text-baseline: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-removable-height: var(--spectrum-global-dimension-size-300);
  --spectrum-tag-removable-icon-height: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-removable-icon-width: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-removable-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-tag-removable-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-tag-removable-padding-x: var(--spectrum-global-dimension-size-125);
  --spectrum-tag-removable-avatar-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-removable-icon-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tag-removable-icon-size: var(--spectrum-global-dimension-size-100);
}
