.spectrum {
  --spectrum-loader-bar-large-text-color: var(--spectrum-alias-text-color);
  --spectrum-loader-bar-large-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-loader-bar-large-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-loader-bar-large-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-bar-large-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-bar-large-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-loader-bar-large-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-loader-bar-large-height: var(--spectrum-global-dimension-size-75);
  --spectrum-loader-bar-large-fill-border-radius: 0;
  --spectrum-loader-bar-large-label-gap-y: var(--spectrum-global-dimension-size-115);
  --spectrum-loader-bar-large-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-bar-large-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-bar-large-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-bar-large-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-bar-large-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-loader-bar-large-over-background-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-loader-bar-large-over-background-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-loader-bar-large-over-background-height: var(--spectrum-global-dimension-size-75);
  --spectrum-loader-bar-large-over-background-fill-border-radius: 0;
  --spectrum-loader-bar-large-over-background-label-gap-y: var(--spectrum-global-dimension-size-115);
  --spectrum-loader-bar-small-text-color: var(--spectrum-alias-text-color);
  --spectrum-loader-bar-small-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-loader-bar-small-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-loader-bar-small-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-bar-small-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-bar-small-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-loader-bar-small-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-loader-bar-small-height: var(--spectrum-global-dimension-size-50);
  --spectrum-loader-bar-small-fill-border-radius: 0;
  --spectrum-loader-bar-small-label-gap-y: var(--spectrum-global-dimension-size-115);
  --spectrum-loader-bar-small-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-bar-small-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-bar-small-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-bar-small-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-bar-small-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-loader-bar-small-over-background-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-loader-bar-small-over-background-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-loader-bar-small-over-background-height: var(--spectrum-global-dimension-size-50);
  --spectrum-loader-bar-small-over-background-fill-border-radius: 0;
  --spectrum-loader-bar-small-over-background-label-gap-y: var(--spectrum-global-dimension-size-115);
  --spectrum-loader-circle-large-text-color: var(--spectrum-alias-text-color);
  --spectrum-loader-circle-large-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-loader-circle-large-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-loader-circle-large-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-circle-large-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-circle-large-height: var(--spectrum-global-dimension-size-800);
  --spectrum-loader-circle-large-width: var(--spectrum-global-dimension-size-800);
  --spectrum-loader-circle-large-border-size: var(--spectrum-global-dimension-size-50);
  --spectrum-loader-circle-large-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-circle-large-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-circle-large-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-circle-large-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-circle-large-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-loader-circle-large-over-background-height: var(--spectrum-global-dimension-size-800);
  --spectrum-loader-circle-large-over-background-width: var(--spectrum-global-dimension-size-800);
  --spectrum-loader-circle-large-over-background-border-size: var(--spectrum-global-dimension-size-50);
  --spectrum-loader-circle-medium-text-color: var(--spectrum-alias-text-color);
  --spectrum-loader-circle-medium-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-loader-circle-medium-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-loader-circle-medium-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-circle-medium-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-circle-medium-height: var(--spectrum-global-dimension-static-size-400);
  --spectrum-loader-circle-medium-width: var(--spectrum-global-dimension-static-size-400);
  --spectrum-loader-circle-medium-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-circle-medium-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-circle-medium-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-circle-medium-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-circle-medium-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-loader-circle-medium-over-background-height: var(--spectrum-global-dimension-static-size-400);
  --spectrum-loader-circle-medium-over-background-width: var(--spectrum-global-dimension-static-size-400);
  --spectrum-loader-circle-small-text-color: var(--spectrum-alias-text-color);
  --spectrum-loader-circle-small-track-color: var(--spectrum-alias-track-color-default);
  --spectrum-loader-circle-small-track-fill-color: var(--spectrum-global-color-blue-500);
  --spectrum-loader-circle-small-track-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-circle-small-track-fill-color-disabled: var(--spectrum-alias-track-color-disabled);
  --spectrum-loader-circle-small-height: var(--spectrum-global-dimension-static-size-200);
  --spectrum-loader-circle-small-width: var(--spectrum-global-dimension-static-size-200);
  --spectrum-loader-circle-small-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-circle-small-over-background-track-color: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-circle-small-over-background-track-fill-color: var(--spectrum-global-color-static-white);
  --spectrum-loader-circle-small-over-background-track-color-disabled: var(--spectrum-alias-track-color-over-background);
  --spectrum-loader-circle-small-over-background-track-fill-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-loader-circle-small-over-background-height: var(--spectrum-global-dimension-static-size-200);
  --spectrum-loader-circle-small-over-background-width: var(--spectrum-global-dimension-static-size-200);
}
