.spectrum {
  --spectrum-dragthumb-handle-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-dragthumb-handle-background-color-hover: var(--spectrum-global-color-gray-400);
  --spectrum-dragthumb-handle-background-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-dragthumb-width: var(--spectrum-global-dimension-static-size-150);
  --spectrum-dragthumb-height: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dragthumb-swatch-handle-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-dragthumb-swatch-handle-background-color-hover: var(--spectrum-global-color-gray-400);
  --spectrum-dragthumb-swatch-handle-background-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-dragthumb-swatch-width: var(--spectrum-global-dimension-static-size-150);
  --spectrum-dragthumb-swatch-height: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dragthumb-swatch-swatch-width: var(--spectrum-global-dimension-static-size-100);
  --spectrum-dragthumb-swatch-swatch-height: var(--spectrum-global-dimension-static-size-100);
}
