.spectrum {
  --spectrum-coachmark-indicator-ring-default-color: var(--spectrum-alias-focus-color);
  --spectrum-coachmark-indicator-ring-low-contrast-color: var(--spectrum-global-color-gray-50);
  --spectrum-coachmark-indicator-ring-high-contrast-color: var(--spectrum-global-color-gray-900);
  --spectrum-coachmark-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-coachmark-shadow-color: var(--spectrum-alias-dropshadow-color);
  --spectrum-coachmark-border-color: var(--spectrum-alias-border-color-dark);
  --spectrum-coachmark-title-text-color: var(--spectrum-alias-title-text-color);
  --spectrum-coachmark-content-text-color: var(--spectrum-alias-text-color);
  --spectrum-coachmark-step-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-coachmark-min-width: var(--spectrum-global-dimension-size-3400);
  --spectrum-coachmark-max-width: var(--spectrum-global-dimension-size-5000);
  --spectrum-coachmark-padding-x: var(--spectrum-global-dimension-size-300);
  --spectrum-coachmark-padding-y: var(--spectrum-global-dimension-size-300);
  --spectrum-coachmark-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-coachmark-title-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-coachmark-content-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-coachmark-indicator-gap: var(--spectrum-global-dimension-size-75);
  --spectrum-coachmark-step-margin-left: var(--spectrum-global-dimension-size-200);
  --spectrum-coachmark-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-coachmark-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-coachmark-title-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-coachmark-title-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-coachmark-content-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-coachmark-content-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-coachmark-content-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-coachmark-step-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-coachmark-step-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-coachmark-step-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-coachmark-indicator-ring-border-size: var(--spectrum-global-dimension-static-size-25);
  --spectrum-coachmark-animation-indicator-ring-duration: 3000ms;
  --spectrum-coachmark-animation-indicator-ring-ease: var(--spectrum-global-animation-linear);
  --spectrum-coachmark-animation-inticator-ring-outer-delay-multiple: -1;
  --spectrum-coachmark-animation-indicator-keyframe-0-opacity: 0;
  --spectrum-coachmark-animation-indicator-keyframe-50-scale: 1.5;
  --spectrum-coachmark-animation-indicator-keyframe-50-opacity: 1;
  --spectrum-coachmark-animation-indicator-keyframe-100-scale: 2;
  --spectrum-coachmark-animation-indicator-keyframe-100-opacity: 0;
  --spectrum-coachmark-shadow-blur: var(--spectrum-alias-dropshadow-blur);
  --spectrum-coachmark-shadow-offset-y: var(--spectrum-alias-dropshadow-offset-y);
  --spectrum-coachmark-indicator-ring-diameter: var(--spectrum-global-dimension-size-200);
  --spectrum-coachmark-animation-inticator-ring-inner-delay-multiple: -0.5;
  --spectrum-coachmark-animation-inticator-ring-center-delay-multiple: -0.66;
  --spectrum-coachmark-animation-indicator-keyframe-0-scale: 1;
  --spectrum-coachmark-indicator-entry-animation-delay: 0ms;
  --spectrum-coachmark-indicator-entry-animation-duration: var(--spectrum-global-animation-duration-800);
  --spectrum-coachmark-indicator-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-coachmark-indicator-entry-animation-properties: opacity;
  --spectrum-coachmark-entry-animation-delay: var(--spectrum-global-animation-duration-300);
  --spectrum-coachmark-entry-animation-duration: var(--spectrum-global-animation-duration-400);
  --spectrum-coachmark-entry-animation-distance: var(--spectrum-global-dimension-size-75);
  --spectrum-coachmark-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-coachmark-entry-animation-properties: opacity, distance;
  --spectrum-coachmark-indicator-exit-animation-delay: 0ms;
  --spectrum-coachmark-indicator-exit-animation-duration: var(--spectrum-global-animation-duration-800);
  --spectrum-coachmark-indicator-exit-animation-ease: var(--spectrum-global-animation-ease-in);
  --spectrum-coachmark-indicator-exit-animation-properties: opacity;
  --spectrum-coachmark-exit-animation-delay: var(--spectrum-global-animation-duration-100);
  --spectrum-coachmark-exit-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-coachmark-exit-animation-distance: var(--spectrum-global-dimension-size-75);
  --spectrum-coachmark-exit-animation-ease: var(--spectrum-global-animation-ease-in);
  --spectrum-coachmark-exit-animation-properties: opacity, distance;
  --spectrum-coachmark-quiet-indicator-ring-default-color: var(--spectrum-alias-focus-color);
  --spectrum-coachmark-quiet-indicator-ring-low-contrast-color: var(--spectrum-global-color-gray-50);
  --spectrum-coachmark-quiet-indicator-ring-high-contrast-color: var(--spectrum-global-color-gray-900);
  --spectrum-coachmark-quiet-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-coachmark-quiet-shadow-color: var(--spectrum-alias-dropshadow-color);
  --spectrum-coachmark-quiet-border-color: var(--spectrum-alias-border-color-dark);
  --spectrum-coachmark-quiet-title-text-color: var(--spectrum-alias-title-text-color);
  --spectrum-coachmark-quiet-content-text-color: var(--spectrum-alias-text-color);
  --spectrum-coachmark-quiet-step-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-coachmark-quiet-min-width: var(--spectrum-global-dimension-size-3400);
  --spectrum-coachmark-quiet-max-width: var(--spectrum-global-dimension-size-5000);
  --spectrum-coachmark-quiet-padding-x: var(--spectrum-global-dimension-size-300);
  --spectrum-coachmark-quiet-padding-y: var(--spectrum-global-dimension-size-300);
  --spectrum-coachmark-quiet-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-coachmark-quiet-title-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-coachmark-quiet-content-margin-bottom: var(--spectrum-global-dimension-size-200);
  --spectrum-coachmark-quiet-indicator-gap: var(--spectrum-global-dimension-size-75);
  --spectrum-coachmark-quiet-step-margin-left: var(--spectrum-global-dimension-size-200);
  --spectrum-coachmark-quiet-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-coachmark-quiet-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-coachmark-quiet-title-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-coachmark-quiet-title-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-coachmark-quiet-content-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-coachmark-quiet-content-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-coachmark-quiet-content-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-coachmark-quiet-step-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-coachmark-quiet-step-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-coachmark-quiet-step-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-coachmark-quiet-indicator-ring-border-size: var(--spectrum-global-dimension-static-size-25);
  --spectrum-coachmark-quiet-animation-indicator-ring-duration: 3000ms;
  --spectrum-coachmark-quiet-animation-indicator-ring-ease: var(--spectrum-global-animation-linear);
  --spectrum-coachmark-quiet-animation-inticator-ring-outer-delay-multiple: -1;
  --spectrum-coachmark-quiet-animation-indicator-keyframe-0-opacity: 0;
  --spectrum-coachmark-quiet-animation-indicator-keyframe-50-scale: 1.5;
  --spectrum-coachmark-quiet-animation-indicator-keyframe-50-opacity: 1;
  --spectrum-coachmark-quiet-animation-indicator-keyframe-100-scale: 2;
  --spectrum-coachmark-quiet-animation-indicator-keyframe-100-opacity: 0;
  --spectrum-coachmark-quiet-shadow-blur: var(--spectrum-alias-dropshadow-blur);
  --spectrum-coachmark-quiet-shadow-offset-y: var(--spectrum-alias-dropshadow-offset-y);
  --spectrum-coachmark-quiet-indicator-ring-diameter: var(--spectrum-global-dimension-size-100);
  --spectrum-coachmark-quiet-animation-inticator-ring-center-delay-multiple: -0.66;
  --spectrum-coachmark-quiet-animation-inticator-ring-inner-delay-multiple: -0.33;
  --spectrum-coachmark-quiet-animation-indicator-keyframe-0-scale: 0.8;
  --spectrum-coachmark-quiet-indicator-entry-animation-delay: 0ms;
  --spectrum-coachmark-quiet-indicator-entry-animation-duration: var(--spectrum-global-animation-duration-800);
  --spectrum-coachmark-quiet-indicator-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-coachmark-quiet-indicator-entry-animation-properties: opacity;
  --spectrum-coachmark-quiet-entry-animation-delay: var(--spectrum-global-animation-duration-300);
  --spectrum-coachmark-quiet-entry-animation-duration: var(--spectrum-global-animation-duration-400);
  --spectrum-coachmark-quiet-entry-animation-distance: var(--spectrum-global-dimension-size-75);
  --spectrum-coachmark-quiet-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-coachmark-quiet-entry-animation-properties: opacity, distance;
  --spectrum-coachmark-quiet-indicator-exit-animation-delay: 0ms;
  --spectrum-coachmark-quiet-indicator-exit-animation-duration: var(--spectrum-global-animation-duration-800);
  --spectrum-coachmark-quiet-indicator-exit-animation-ease: var(--spectrum-global-animation-ease-in);
  --spectrum-coachmark-quiet-indicator-exit-animation-properties: opacity;
  --spectrum-coachmark-quiet-exit-animation-delay: var(--spectrum-global-animation-duration-100);
  --spectrum-coachmark-quiet-exit-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-coachmark-quiet-exit-animation-distance: var(--spectrum-global-dimension-size-75);
  --spectrum-coachmark-quiet-exit-animation-ease: var(--spectrum-global-animation-ease-in);
  --spectrum-coachmark-quiet-exit-animation-properties: opacity, distance;
}
