.spectrum {
  --spectrum-tooltip-text-color: var(--spectrum-global-color-static-white);
  --spectrum-tooltip-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-tooltip-background-color: var(--spectrum-global-color-static-gray-700);
  --spectrum-tooltip-tip-color: var(--spectrum-global-color-static-gray-700);
  --spectrum-tooltip-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-tooltip-min-height: var(--spectrum-global-dimension-size-300);
  --spectrum-tooltip-padding-x: var(--spectrum-global-dimension-size-125);
  --spectrum-tooltip-padding-top: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-icon-size: var(--spectrum-global-dimension-size-175);
  --spectrum-tooltip-icon-margin-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-icon-margin-y: var(--spectrum-global-dimension-size-65);
  --spectrum-tooltip-tip-height: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-tip-width: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-tip-margin: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-text-baseline: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-tooltip-text-line-height: var(--spectrum-global-dimension-font-size-200);
  --spectrum-tooltip-entry-animation-delay: var(--spectrum-global-animation-duration-0);
  --spectrum-tooltip-entry-warmup-animation-delay: var(--spectrum-global-animation-duration-4000);
  --spectrum-tooltip-entry-animation-duration: var(--spectrum-global-animation-duration-200);
  --spectrum-tooltip-entry-animation-distance: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-tooltip-entry-animation-properties: opacity, distance;
  --spectrum-tooltip-exit-animation-delay: var(--spectrum-global-animation-duration-0);
  --spectrum-tooltip-exit-warmup-animation-delay: var(--spectrum-global-animation-duration-4000);
  --spectrum-tooltip-exit-animation-duration: var(--spectrum-global-animation-duration-200);
  --spectrum-tooltip-exit-animation-distance: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-exit-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-tooltip-exit-animation-properties: opacity, distance;
  --spectrum-tooltip-info-text-color: var(--spectrum-global-color-static-white);
  --spectrum-tooltip-info-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-tooltip-info-background-color: var(--spectrum-global-color-static-blue-700);
  --spectrum-tooltip-info-tip-color: var(--spectrum-global-color-static-blue-700);
  --spectrum-tooltip-info-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-tooltip-info-min-height: var(--spectrum-global-dimension-size-300);
  --spectrum-tooltip-info-padding-x: var(--spectrum-global-dimension-size-125);
  --spectrum-tooltip-info-padding-top: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-info-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-info-icon-size: var(--spectrum-global-dimension-size-175);
  --spectrum-tooltip-info-icon-margin-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-info-icon-margin-y: var(--spectrum-global-dimension-size-65);
  --spectrum-tooltip-info-tip-height: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-info-tip-width: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-info-tip-margin: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-info-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-info-text-baseline: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-info-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-tooltip-info-text-line-height: var(--spectrum-global-dimension-font-size-200);
  --spectrum-tooltip-info-entry-animation-delay: var(--spectrum-global-animation-duration-0);
  --spectrum-tooltip-info-entry-warmup-animation-delay: var(--spectrum-global-animation-duration-4000);
  --spectrum-tooltip-info-entry-animation-duration: var(--spectrum-global-animation-duration-200);
  --spectrum-tooltip-info-entry-animation-distance: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-info-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-tooltip-info-entry-animation-properties: opacity, distance;
  --spectrum-tooltip-info-exit-animation-delay: var(--spectrum-global-animation-duration-0);
  --spectrum-tooltip-info-exit-warmup-animation-delay: var(--spectrum-global-animation-duration-4000);
  --spectrum-tooltip-info-exit-animation-duration: var(--spectrum-global-animation-duration-200);
  --spectrum-tooltip-info-exit-animation-distance: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-info-exit-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-tooltip-info-exit-animation-properties: opacity, distance;
  --spectrum-tooltip-negative-text-color: var(--spectrum-global-color-static-white);
  --spectrum-tooltip-negative-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-tooltip-negative-background-color: var(--spectrum-global-color-static-red-700);
  --spectrum-tooltip-negative-tip-color: var(--spectrum-global-color-static-red-700);
  --spectrum-tooltip-negative-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-tooltip-negative-min-height: var(--spectrum-global-dimension-size-300);
  --spectrum-tooltip-negative-padding-x: var(--spectrum-global-dimension-size-125);
  --spectrum-tooltip-negative-padding-top: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-negative-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-negative-icon-size: var(--spectrum-global-dimension-size-175);
  --spectrum-tooltip-negative-icon-margin-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-negative-icon-margin-y: var(--spectrum-global-dimension-size-65);
  --spectrum-tooltip-negative-tip-height: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-negative-tip-width: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-negative-tip-margin: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-negative-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-negative-text-baseline: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-negative-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-tooltip-negative-text-line-height: var(--spectrum-global-dimension-font-size-200);
  --spectrum-tooltip-negative-entry-animation-delay: var(--spectrum-global-animation-duration-0);
  --spectrum-tooltip-negative-entry-warmup-animation-delay: var(--spectrum-global-animation-duration-4000);
  --spectrum-tooltip-negative-entry-animation-duration: var(--spectrum-global-animation-duration-200);
  --spectrum-tooltip-negative-entry-animation-distance: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-negative-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-tooltip-negative-entry-animation-properties: opacity, distance;
  --spectrum-tooltip-negative-exit-animation-delay: var(--spectrum-global-animation-duration-0);
  --spectrum-tooltip-negative-exit-warmup-animation-delay: var(--spectrum-global-animation-duration-4000);
  --spectrum-tooltip-negative-exit-animation-duration: var(--spectrum-global-animation-duration-200);
  --spectrum-tooltip-negative-exit-animation-distance: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-negative-exit-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-tooltip-negative-exit-animation-properties: opacity, distance;
  --spectrum-tooltip-background-color-neutral: var(--spectrum-global-color-static-gray-700);
  --spectrum-tooltip-tip-color-neutral: var(--spectrum-global-color-static-gray-700);
  --spectrum-tooltip-positive-text-color: var(--spectrum-global-color-static-white);
  --spectrum-tooltip-positive-icon-color: var(--spectrum-global-color-static-white);
  --spectrum-tooltip-positive-background-color: var(--spectrum-global-color-static-green-700);
  --spectrum-tooltip-positive-tip-color: var(--spectrum-global-color-static-green-700);
  --spectrum-tooltip-positive-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-tooltip-positive-min-height: var(--spectrum-global-dimension-size-300);
  --spectrum-tooltip-positive-padding-x: var(--spectrum-global-dimension-size-125);
  --spectrum-tooltip-positive-padding-top: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-positive-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-positive-icon-size: var(--spectrum-global-dimension-size-175);
  --spectrum-tooltip-positive-icon-margin-x: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-positive-icon-margin-y: var(--spectrum-global-dimension-size-65);
  --spectrum-tooltip-positive-tip-height: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-positive-tip-width: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-positive-tip-margin: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-positive-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-positive-text-baseline: var(--spectrum-global-dimension-size-100);
  --spectrum-tooltip-positive-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-tooltip-positive-text-line-height: var(--spectrum-global-dimension-font-size-200);
  --spectrum-tooltip-positive-entry-animation-delay: var(--spectrum-global-animation-duration-0);
  --spectrum-tooltip-positive-entry-warmup-animation-delay: var(--spectrum-global-animation-duration-4000);
  --spectrum-tooltip-positive-entry-animation-duration: var(--spectrum-global-animation-duration-200);
  --spectrum-tooltip-positive-entry-animation-distance: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-positive-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-tooltip-positive-entry-animation-properties: opacity, distance;
  --spectrum-tooltip-positive-exit-animation-delay: var(--spectrum-global-animation-duration-0);
  --spectrum-tooltip-positive-exit-warmup-animation-delay: var(--spectrum-global-animation-duration-4000);
  --spectrum-tooltip-positive-exit-animation-duration: var(--spectrum-global-animation-duration-200);
  --spectrum-tooltip-positive-exit-animation-distance: var(--spectrum-global-dimension-size-50);
  --spectrum-tooltip-positive-exit-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-tooltip-positive-exit-animation-properties: opacity, distance;
}
