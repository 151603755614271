.spectrum {
  --spectrum-search-placeholder-text-color: var(--spectrum-alias-placeholder-text-color);
  --spectrum-search-text-color: var(--spectrum-alias-text-color);
  --spectrum-search-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-search-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-search-border-color: var(--spectrum-alias-border-color);
  --spectrum-search-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-search-placeholder-text-color-hover: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-search-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-search-background-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-search-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-search-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-search-placeholder-text-color-key-focus: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-search-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-search-background-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-search-icon-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-search-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-search-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-search-placeholder-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-search-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-search-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-search-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-search-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-search-validation-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-search-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-search-placeholder-text-color-down: var(--spectrum-alias-placeholder-text-color-down);
  --spectrum-search-background-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-search-border-color-down: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-search-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-search-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-search-placeholder-text-color-mouse-focus: var(--spectrum-alias-placeholder-text-color);
  --spectrum-search-background-color-mouse-focus: var(--spectrum-global-color-gray-50);
  --spectrum-search-border-color-mouse-focus: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-search-border-size-mouse-focus: var(--spectrum-alias-border-size-thin);
  --spectrum-search-icon-color-mouse-focus: var(--spectrum-global-color-gray-700);
  --spectrum-search-border-color-error: var(--spectrum-semantic-negative-color-default);
  --spectrum-search-validation-icon-color-error: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-border-color-error-hover: var(--spectrum-semantic-negative-color-state-hover);
  --spectrum-search-validation-icon-color-error-hover: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-border-color-error-down: var(--spectrum-semantic-negative-color-state-down);
  --spectrum-search-validation-icon-color-error-down: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-search-validation-icon-color-error-key-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-border-color-error-mouse-focus: var(--spectrum-semantic-negative-color-state-hover);
  --spectrum-search-validation-icon-color-error-mouse-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-icon-color-valid: var(--spectrum-global-color-green-400);
  --spectrum-search-border-color-valid-hover: var(--spectrum-global-color-gray-400);
  --spectrum-search-icon-color-valid-hover: var(--spectrum-global-color-green-400);
  --spectrum-search-border-color-valid-down: var(--spectrum-alias-border-color-down);
  --spectrum-search-icon-color-valid-down: var(--spectrum-global-color-green-400);
  --spectrum-search-border-color-valid-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-search-icon-color-valid-key-focus: var(--spectrum-global-color-green-400);
  --spectrum-search-border-color-valid-mouse-focus: var(--spectrum-alias-border-color-down);
  --spectrum-search-icon-color-valid-mouse-focus: var(--spectrum-global-color-green-400);
  --spectrum-search-placeholder-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-search-placeholder-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-search-placeholder-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-search-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-search-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-search-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-search-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-search-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-search-text-transform: none;
  --spectrum-search-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-search-height: var(--spectrum-alias-single-line-height);
  --spectrum-search-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-search-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-search-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-search-padding-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-search-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-search-label-gap-x: var(--spectrum-global-dimension-size-100);
  --spectrum-search-label-gap-y: var(--spectrum-global-dimension-size-100);
  --spectrum-search-label-padding-top: var(--spectrum-global-dimension-size-100);
  --spectrum-search-label-padding-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-search-counter-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-search-asterisk-size: var(--spectrum-global-dimension-size-100);
  --spectrum-search-asterisk-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-search-icon-size: var(--spectrum-global-dimension-size-225);
  --spectrum-search-success-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-search-success-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-search-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-search-error-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-search-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-search-cursor-hit-x: var(--spectrum-global-dimension-size-50);
  --spectrum-search-icon-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-search-icon-margin-right: var(--spectrum-global-dimension-size-0);
  --spectrum-search-padding-left: var(--spectrum-global-dimension-size-450);
  --spectrum-search-padding-right: var(--spectrum-global-dimension-size-350);
  --spectrum-search-icon-width: var(--spectrum-global-dimension-static-size-200);
  --spectrum-search-icon-height: var(--spectrum-global-dimension-static-size-200);
  --spectrum-search-quiet-placeholder-text-color: var(--spectrum-alias-placeholder-text-color);
  --spectrum-search-quiet-text-color: var(--spectrum-alias-text-color);
  --spectrum-search-quiet-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-search-quiet-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-search-quiet-border-color: var(--spectrum-alias-border-color);
  --spectrum-search-quiet-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-search-quiet-placeholder-text-color-hover: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-search-quiet-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-search-quiet-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-search-quiet-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-search-quiet-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-search-quiet-placeholder-text-color-key-focus: var(--spectrum-alias-placeholder-text-color-hover);
  --spectrum-search-quiet-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-search-quiet-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-search-quiet-icon-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-search-quiet-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-search-quiet-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-search-quiet-placeholder-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-search-quiet-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-search-quiet-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-search-quiet-border-color-disabled: var(--spectrum-alias-border-color-mid);
  --spectrum-search-quiet-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-search-quiet-validation-icon-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-search-quiet-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-search-quiet-placeholder-text-color-down: var(--spectrum-alias-placeholder-text-color-down);
  --spectrum-search-quiet-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-search-quiet-border-color-down: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-search-quiet-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-search-quiet-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-search-quiet-placeholder-text-color-mouse-focus: var(--spectrum-alias-placeholder-text-color);
  --spectrum-search-quiet-background-color-mouse-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-search-quiet-border-color-mouse-focus: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-search-quiet-border-size-mouse-focus: var(--spectrum-alias-border-size-thin);
  --spectrum-search-quiet-icon-color-mouse-focus: var(--spectrum-global-color-gray-700);
  --spectrum-search-quiet-border-color-error: var(--spectrum-semantic-negative-color-default);
  --spectrum-search-quiet-validation-icon-color-error: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-quiet-border-color-error-hover: var(--spectrum-semantic-negative-color-state-hover);
  --spectrum-search-quiet-validation-icon-color-error-hover: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-quiet-border-color-error-down: var(--spectrum-semantic-negative-color-state-down);
  --spectrum-search-quiet-validation-icon-color-error-down: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-quiet-border-color-error-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-search-quiet-validation-icon-color-error-key-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-quiet-border-color-error-mouse-focus: var(--spectrum-semantic-negative-color-state-hover);
  --spectrum-search-quiet-validation-icon-color-error-mouse-focus: var(--spectrum-semantic-negative-color-icon);
  --spectrum-search-quiet-icon-color-valid: var(--spectrum-global-color-green-400);
  --spectrum-search-quiet-border-color-valid-hover: var(--spectrum-global-color-gray-400);
  --spectrum-search-quiet-icon-color-valid-hover: var(--spectrum-global-color-green-400);
  --spectrum-search-quiet-border-color-valid-down: var(--spectrum-alias-border-color-down);
  --spectrum-search-quiet-icon-color-valid-down: var(--spectrum-global-color-green-400);
  --spectrum-search-quiet-border-color-valid-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-search-quiet-icon-color-valid-key-focus: var(--spectrum-global-color-green-400);
  --spectrum-search-quiet-border-color-valid-mouse-focus: var(--spectrum-alias-border-color-down);
  --spectrum-search-quiet-icon-color-valid-mouse-focus: var(--spectrum-global-color-green-400);
  --spectrum-search-quiet-placeholder-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-search-quiet-placeholder-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-search-quiet-placeholder-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-search-quiet-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-search-quiet-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-search-quiet-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-search-quiet-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-search-quiet-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-search-quiet-text-transform: none;
  --spectrum-search-quiet-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-search-quiet-height: var(--spectrum-alias-single-line-height);
  --spectrum-search-quiet-min-width: var(--spectrum-global-dimension-size-600);
  --spectrum-search-quiet-padding-x: 0;
  --spectrum-search-quiet-padding-top: var(--spectrum-global-dimension-size-75);
  --spectrum-search-quiet-padding-bottom: var(--spectrum-global-dimension-size-115);
  --spectrum-search-quiet-border-radius: 0px;
  --spectrum-search-quiet-label-gap-x: var(--spectrum-global-dimension-size-150);
  --spectrum-search-quiet-label-gap-y: 0;
  --spectrum-search-quiet-label-padding-top: var(--spectrum-global-dimension-size-100);
  --spectrum-search-quiet-label-padding-bottom: var(--spectrum-global-dimension-size-150);
  --spectrum-search-quiet-counter-gap: var(--spectrum-global-dimension-size-100);
  --spectrum-search-quiet-asterisk-size: var(--spectrum-global-dimension-size-100);
  --spectrum-search-quiet-asterisk-gap: var(--spectrum-global-dimension-size-50);
  --spectrum-search-quiet-icon-size: var(--spectrum-global-dimension-size-225);
  --spectrum-search-quiet-success-icon-height: var(--spectrum-global-dimension-size-150);
  --spectrum-search-quiet-success-icon-width: var(--spectrum-global-dimension-size-150);
  --spectrum-search-quiet-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-search-quiet-error-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-search-quiet-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-search-quiet-cursor-hit-x: var(--spectrum-global-dimension-size-50);
  --spectrum-search-quiet-icon-margin-left: var(--spectrum-global-dimension-size-150);
  --spectrum-search-quiet-icon-margin-right: var(--spectrum-global-dimension-size-0);
  --spectrum-search-quiet-affixed-border-size: var(--spectrum-global-dimension-static-size-10);
  --spectrum-search-quiet-padding-left: var(--spectrum-global-dimension-size-300);
  --spectrum-search-quiet-padding-right: var(--spectrum-global-dimension-size-250);
}
