.spectrum {
  --spectrum-icon-alert-medium-width: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-alert-medium-height: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-alert-small-width: var(--spectrum-global-dimension-size-175);
  --spectrum-icon-alert-small-height: var(--spectrum-global-dimension-size-175);
  --spectrum-icon-arrow-down-small-width: var(--spectrum-global-dimension-size-100);
  --spectrum-icon-arrow-left-medium-width: var(--spectrum-global-dimension-size-175);
  --spectrum-icon-chevron-down-medium-height: var(--spectrum-global-dimension-size-75);
  --spectrum-icon-chevron-down-small-width: var(--spectrum-global-dimension-size-100);
  --spectrum-icon-chevron-down-small-height: var(--spectrum-global-dimension-size-75);
  --spectrum-icon-chevron-left-large-height: var(--spectrum-global-dimension-size-200);
  --spectrum-icon-chevron-left-medium-width: var(--spectrum-global-dimension-size-75);
  --spectrum-icon-chevron-right-large-height: var(--spectrum-global-dimension-size-200);
  --spectrum-icon-chevron-right-medium-width: var(--spectrum-global-dimension-size-75);
  --spectrum-icon-chevron-right-small-width: var(--spectrum-global-dimension-size-75);
  --spectrum-icon-chevron-right-small-height: var(--spectrum-global-dimension-size-100);
  --spectrum-icon-chevron-up-small-width: var(--spectrum-global-dimension-size-100);
  --spectrum-icon-chevron-up-small-height: var(--spectrum-global-dimension-size-75);
  --spectrum-icon-cornertriangle-width: var(--spectrum-global-dimension-size-65);
  --spectrum-icon-cornertriangle-height: var(--spectrum-global-dimension-size-65);
  --spectrum-icon-cross-medium-width: var(--spectrum-global-dimension-size-100);
  --spectrum-icon-cross-medium-height: var(--spectrum-global-dimension-size-100);
  --spectrum-icon-cross-small-width: var(--spectrum-global-dimension-size-100);
  --spectrum-icon-cross-small-height: var(--spectrum-global-dimension-size-100);
  --spectrum-icon-doublegripper-width: var(--spectrum-global-dimension-size-200);
  --spectrum-icon-doublegripper-height: var(--spectrum-global-dimension-size-50);
  --spectrum-icon-folderbreadcrumb-width: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-folderbreadcrumb-height: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-info-medium-width: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-info-medium-height: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-info-small-width: var(--spectrum-global-dimension-size-175);
  --spectrum-icon-info-small-height: var(--spectrum-global-dimension-size-175);
  --spectrum-icon-magnifier-width: var(--spectrum-global-dimension-size-200);
  --spectrum-icon-magnifier-height: var(--spectrum-global-dimension-size-200);
  --spectrum-icon-star-width: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-star-height: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-star-outline-width: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-star-outline-height: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-success-medium-width: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-success-medium-height: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-success-small-width: var(--spectrum-global-dimension-size-175);
  --spectrum-icon-success-small-height: var(--spectrum-global-dimension-size-175);
  --spectrum-icon-triplegripper-height: var(--spectrum-global-dimension-size-85);
  --spectrum-icon-workflow-medium-size: var(--spectrum-alias-workflow-icon-size);
}
