.spectrum {
  --spectrum-dialog-confirm-background-color: var(--spectrum-alias-background-color-default);
  --spectrum-dialog-confirm-overlay-background-color: var(--spectrum-alias-background-color-modal-overlay);
  --spectrum-dialog-confirm-icon-color: var(--spectrum-global-color-gray-900);
  --spectrum-dialog-confirm-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-dialog-confirm-description-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-dialog-confirm-width: 90%;
  --spectrum-dialog-confirm-min-width: var(--spectrum-global-dimension-static-size-3600);
  --spectrum-dialog-confirm-icon-margin-left: var(--spectrum-global-dimension-static-size-300);
  --spectrum-dialog-confirm-divider-margin-top: var(--spectrum-global-dimension-static-size-150);
  --spectrum-dialog-confirm-divider-margin-bottom: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-confirm-title-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-dialog-confirm-title-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-dialog-confirm-description-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-dialog-confirm-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-dialog-confirm-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-confirm-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-confirm-button-gap: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-confirm-buttongroup-gap-x: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-confirm-buttongroup-gap-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-confirm-description-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-dialog-confirm-background-entry-animation-delay: 0ms;
  --spectrum-dialog-confirm-background-entry-animation-duration: var(--spectrum-global-animation-duration-600);
  --spectrum-dialog-confirm-background-entry-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-confirm-background-entry-animation-properties: opacity;
  --spectrum-dialog-confirm-entry-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-confirm-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-dialog-confirm-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-dialog-confirm-entry-animation-distance: var(--spectrum-global-dimension-size-250);
  --spectrum-dialog-confirm-entry-animation-properties: opacity, distance;
  --spectrum-dialog-confirm-background-exit-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-confirm-background-exit-animation-duration: var(--spectrum-global-animation-duration-300);
  --spectrum-dialog-confirm-background-exit-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-confirm-background-exit-animation-properties: opacity;
  --spectrum-dialog-confirm-exit-animation-delay: 0ms;
  --spectrum-dialog-confirm-exit-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-dialog-confirm-exit-animation-ease: var(--spectrum-global-animation-ease-in);
  --spectrum-dialog-confirm-exit-animation-distance: 0;
  --spectrum-dialog-confirm-exit-animation-properties: opacity, distance;
  --spectrum-dialog-background-color: var(--spectrum-alias-background-color-default);
  --spectrum-dialog-underlay-background-color: var(--spectrum-alias-background-color-modal-overlay);
  --spectrum-dialog-icon-color: var(--spectrum-global-color-gray-900);
  --spectrum-dialog-rule-color: var(--spectrum-global-color-gray-300);
  --spectrum-dialog-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-dialog-content-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-dialog-width: 90%;
  --spectrum-dialog-min-width: var(--spectrum-global-dimension-static-size-3600);
  --spectrum-dialog-icon-margin-left: var(--spectrum-global-dimension-static-size-300);
  --spectrum-dialog-rule-height: var(--spectrum-global-dimension-static-size-25);
  --spectrum-dialog-rule-margin-top: var(--spectrum-global-dimension-static-size-150);
  --spectrum-dialog-rule-margin-bottom: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-rule-border-radius: var(--spectrum-global-dimension-static-size-10);
  --spectrum-dialog-divider-margin-top: var(--spectrum-global-dimension-static-size-150);
  --spectrum-dialog-divider-margin-bottom: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-title-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-dialog-title-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-dialog-content-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-dialog-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-dialog-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-button-gap: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-buttongroup-gap-x: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-buttongroup-gap-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-content-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-dialog-animation-distance: var(--spectrum-global-dimension-static-size-100);
  --spectrum-dialog-background-entry-animation-delay: 0ms;
  --spectrum-dialog-background-entry-animation-duration: var(--spectrum-global-animation-duration-600);
  --spectrum-dialog-background-entry-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-background-entry-animation-properties: opacity;
  --spectrum-dialog-entry-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-dialog-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-dialog-entry-animation-distance: var(--spectrum-global-dimension-size-250);
  --spectrum-dialog-entry-animation-properties: opacity, distance;
  --spectrum-dialog-background-exit-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-background-exit-animation-duration: var(--spectrum-global-animation-duration-300);
  --spectrum-dialog-background-exit-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-background-exit-animation-properties: opacity;
  --spectrum-dialog-exit-animation-delay: 0ms;
  --spectrum-dialog-exit-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-dialog-exit-animation-ease: var(--spectrum-global-animation-ease-in);
  --spectrum-dialog-exit-animation-distance: 0;
  --spectrum-dialog-exit-animation-properties: opacity, distance;
  --spectrum-dialog-destructive-background-color: var(--spectrum-alias-background-color-default);
  --spectrum-dialog-destructive-overlay-background-color: var(--spectrum-alias-background-color-modal-overlay);
  --spectrum-dialog-destructive-icon-color: var(--spectrum-global-color-gray-900);
  --spectrum-dialog-destructive-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-dialog-destructive-description-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-dialog-destructive-width: 90%;
  --spectrum-dialog-destructive-min-width: var(--spectrum-global-dimension-static-size-3600);
  --spectrum-dialog-destructive-icon-margin-left: var(--spectrum-global-dimension-static-size-300);
  --spectrum-dialog-destructive-divider-margin-top: var(--spectrum-global-dimension-static-size-150);
  --spectrum-dialog-destructive-divider-margin-bottom: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-destructive-title-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-dialog-destructive-title-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-dialog-destructive-description-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-dialog-destructive-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-dialog-destructive-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-destructive-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-destructive-button-gap: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-destructive-buttongroup-gap-x: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-destructive-buttongroup-gap-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-destructive-description-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-dialog-destructive-background-entry-animation-delay: 0ms;
  --spectrum-dialog-destructive-background-entry-animation-duration: var(--spectrum-global-animation-duration-600);
  --spectrum-dialog-destructive-background-entry-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-destructive-background-entry-animation-properties: opacity;
  --spectrum-dialog-destructive-entry-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-destructive-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-dialog-destructive-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-dialog-destructive-entry-animation-distance: var(--spectrum-global-dimension-size-250);
  --spectrum-dialog-destructive-entry-animation-properties: opacity, distance;
  --spectrum-dialog-destructive-background-exit-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-destructive-background-exit-animation-duration: var(--spectrum-global-animation-duration-300);
  --spectrum-dialog-destructive-background-exit-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-destructive-background-exit-animation-properties: opacity;
  --spectrum-dialog-destructive-exit-animation-delay: 0ms;
  --spectrum-dialog-destructive-exit-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-dialog-destructive-exit-animation-ease: var(--spectrum-global-animation-ease-in);
  --spectrum-dialog-destructive-exit-animation-distance: 0;
  --spectrum-dialog-destructive-exit-animation-properties: opacity, distance;
  --spectrum-dialog-error-icon-color: var(--spectrum-semantic-negative-color-icon);
  --spectrum-dialog-error-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-dialog-error-background-color: var(--spectrum-alias-background-color-default);
  --spectrum-dialog-error-overlay-background-color: var(--spectrum-alias-background-color-modal-overlay);
  --spectrum-dialog-error-description-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-dialog-error-width: 90%;
  --spectrum-dialog-error-min-width: var(--spectrum-global-dimension-static-size-3600);
  --spectrum-dialog-error-icon-margin-left: var(--spectrum-global-dimension-static-size-300);
  --spectrum-dialog-error-divider-margin-top: var(--spectrum-global-dimension-static-size-150);
  --spectrum-dialog-error-divider-margin-bottom: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-error-title-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-dialog-error-title-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-dialog-error-description-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-dialog-error-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-dialog-error-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-error-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-error-button-gap: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-error-buttongroup-gap-x: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-error-buttongroup-gap-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-error-description-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-dialog-error-background-entry-animation-delay: 0ms;
  --spectrum-dialog-error-background-entry-animation-duration: var(--spectrum-global-animation-duration-600);
  --spectrum-dialog-error-background-entry-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-error-background-entry-animation-properties: opacity;
  --spectrum-dialog-error-entry-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-error-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-dialog-error-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-dialog-error-entry-animation-distance: var(--spectrum-global-dimension-size-250);
  --spectrum-dialog-error-entry-animation-properties: opacity, distance;
  --spectrum-dialog-error-background-exit-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-error-background-exit-animation-duration: var(--spectrum-global-animation-duration-300);
  --spectrum-dialog-error-background-exit-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-error-background-exit-animation-properties: opacity;
  --spectrum-dialog-error-exit-animation-delay: 0ms;
  --spectrum-dialog-error-exit-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-dialog-error-exit-animation-ease: var(--spectrum-global-animation-ease-in);
  --spectrum-dialog-error-exit-animation-distance: 0;
  --spectrum-dialog-error-exit-animation-properties: opacity, distance;
  --spectrum-dialog-info-background-color: var(--spectrum-alias-background-color-default);
  --spectrum-dialog-info-overlay-background-color: var(--spectrum-alias-background-color-modal-overlay);
  --spectrum-dialog-info-icon-color: var(--spectrum-global-color-gray-900);
  --spectrum-dialog-info-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-dialog-info-description-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-dialog-info-width: 90%;
  --spectrum-dialog-info-min-width: var(--spectrum-global-dimension-static-size-3600);
  --spectrum-dialog-info-icon-margin-left: var(--spectrum-global-dimension-static-size-300);
  --spectrum-dialog-info-divider-margin-top: var(--spectrum-global-dimension-static-size-150);
  --spectrum-dialog-info-divider-margin-bottom: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-info-title-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-dialog-info-title-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-dialog-info-description-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-dialog-info-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-dialog-info-icon-height: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-info-icon-width: var(--spectrum-global-dimension-size-225);
  --spectrum-dialog-info-button-gap: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-info-buttongroup-gap-x: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-info-buttongroup-gap-y: var(--spectrum-global-dimension-static-size-200);
  --spectrum-dialog-info-description-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-dialog-info-background-entry-animation-delay: 0ms;
  --spectrum-dialog-info-background-entry-animation-duration: var(--spectrum-global-animation-duration-600);
  --spectrum-dialog-info-background-entry-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-info-background-entry-animation-properties: opacity;
  --spectrum-dialog-info-entry-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-info-entry-animation-duration: var(--spectrum-global-animation-duration-500);
  --spectrum-dialog-info-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-dialog-info-entry-animation-distance: var(--spectrum-global-dimension-size-250);
  --spectrum-dialog-info-entry-animation-properties: opacity, distance;
  --spectrum-dialog-info-background-exit-animation-delay: var(--spectrum-global-animation-duration-200);
  --spectrum-dialog-info-background-exit-animation-duration: var(--spectrum-global-animation-duration-300);
  --spectrum-dialog-info-background-exit-animation-ease: var(--spectrum-global-animation-linear);
  --spectrum-dialog-info-background-exit-animation-properties: opacity;
  --spectrum-dialog-info-exit-animation-delay: 0ms;
  --spectrum-dialog-info-exit-animation-duration: var(--spectrum-global-animation-duration-100);
  --spectrum-dialog-info-exit-animation-ease: var(--spectrum-global-animation-ease-in);
  --spectrum-dialog-info-exit-animation-distance: 0;
  --spectrum-dialog-info-exit-animation-properties: opacity, distance;
}
