.spectrum {
  --spectrum-colorslider-fill-color: none;
  --spectrum-colorslider-fill-color-hover: none;
  --spectrum-colorslider-fill-color-down: none;
  --spectrum-colorslider-fill-color-key-focus: none;
  --spectrum-colorslider-border-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-colorslider-fill-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-colorslider-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-colorslider-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-colorslider-colorhandle-cursor-hit-border-radius: var(--spectrum-global-dimension-size-0);
  --spectrum-colorslider-colorhandle-touch-hit-width: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorslider-colorhandle-touch-hit-height: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorslider-colorhandle-touch-hit-border-radius: var(--spectrum-global-dimension-size-0);
  --spectrum-colorslider-default-length: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorslider-min-length: var(--spectrum-global-dimension-size-1000);
  --spectrum-colorslider-height: var(--spectrum-global-dimension-size-300);
  --spectrum-colorslider-default-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorslider-min-width: var(--spectrum-global-dimension-size-1000);
  --spectrum-colorslider-cursor-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-colorslider-cursor-hit-y: var(--spectrum-global-dimension-size-0);
  --spectrum-colorslider-touch-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-colorslider-colorhandle-cursor-hit-width: var(--spectrum-global-dimension-size-200);
  --spectrum-colorslider-colorhandle-cursor-hit-height: var(--spectrum-global-dimension-size-300);
  --spectrum-colorslider-loupe-entry-animation-delay: 0ms;
  --spectrum-colorslider-loupe-entry-animation-duration: var(--spectrum-alias-loupe-entry-animation-duration);
  --spectrum-colorslider-loupe-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-colorslider-loupe-entry-animation-properties: opacity;
  --spectrum-colorslider-loupe-exit-animation-delay: 0ms;
  --spectrum-colorslider-loupe-exit-animation-duration: var(--spectrum-alias-loupe-exit-animation-duration);
  --spectrum-colorslider-loupe-exit-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-colorslider-loupe-exit-animation-properties: opacity;
  --spectrum-colorslider-vertical-fill-color: none;
  --spectrum-colorslider-vertical-fill-color-hover: none;
  --spectrum-colorslider-vertical-fill-color-down: none;
  --spectrum-colorslider-vertical-fill-color-key-focus: none;
  --spectrum-colorslider-vertical-border-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-colorslider-vertical-fill-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-colorslider-vertical-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-colorslider-vertical-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-colorslider-vertical-colorhandle-cursor-hit-border-radius: var(--spectrum-global-dimension-size-0);
  --spectrum-colorslider-vertical-colorhandle-touch-hit-width: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorslider-vertical-colorhandle-touch-hit-height: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorslider-vertical-colorhandle-touch-hit-border-radius: var(--spectrum-global-dimension-size-0);
  --spectrum-colorslider-vertical-default-length: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorslider-vertical-min-length: var(--spectrum-global-dimension-size-1000);
  --spectrum-colorslider-vertical-width: var(--spectrum-global-dimension-size-300);
  --spectrum-colorslider-vertical-default-height: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorslider-vertical-min-height: var(--spectrum-global-dimension-size-1000);
  --spectrum-colorslider-vertical-cursor-hit-x: var(--spectrum-global-dimension-size-0);
  --spectrum-colorslider-vertical-cursor-hit-y: var(--spectrum-global-dimension-size-100);
  --spectrum-colorslider-vertical-touch-hit-y: var(--spectrum-global-dimension-size-100);
  --spectrum-colorslider-vertical-colorhandle-cursor-hit-width: var(--spectrum-global-dimension-size-300);
  --spectrum-colorslider-vertical-colorhandle-cursor-hit-height: var(--spectrum-global-dimension-size-200);
  --spectrum-colorslider-vertical-loupe-entry-animation-delay: 0ms;
  --spectrum-colorslider-vertical-loupe-entry-animation-duration: var(--spectrum-alias-loupe-entry-animation-duration);
  --spectrum-colorslider-vertical-loupe-entry-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-colorslider-vertical-loupe-entry-animation-properties: opacity;
  --spectrum-colorslider-vertical-loupe-exit-animation-delay: 0ms;
  --spectrum-colorslider-vertical-loupe-exit-animation-duration: var(--spectrum-alias-loupe-exit-animation-duration);
  --spectrum-colorslider-vertical-loupe-exit-animation-ease: var(--spectrum-global-animation-ease-out);
  --spectrum-colorslider-vertical-loupe-exit-animation-properties: opacity;
}
