.spectrum {
  --spectrum-link-text-color: var(--spectrum-global-color-blue-600);
  --spectrum-link-underline-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-link-text-color-hover: var(--spectrum-global-color-blue-600);
  --spectrum-link-underline-color-hover: var(--spectrum-global-color-blue-600);
  --spectrum-link-text-color-down: var(--spectrum-global-color-blue-700);
  --spectrum-link-underline-color-down: var(--spectrum-global-color-blue-700);
  --spectrum-link-text-color-key-focus: var(--spectrum-alias-text-color-key-focus);
  --spectrum-link-underline-color-key-focus: var(--spectrum-alias-text-color-key-focus);
  --spectrum-link-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-link-underline-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-link-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-link-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-link-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-link-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-link-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-link-text-transform: none;
  --spectrum-link-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-link-underline-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-link-underline-size: var(--spectrum-alias-border-size-thin);
  --spectrum-link-over-background-text-color: var(--spectrum-global-color-static-white);
  --spectrum-link-over-background-underline-color: var(--spectrum-global-color-static-white);
  --spectrum-link-over-background-text-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-link-over-background-underline-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-link-over-background-text-color-down: var(--spectrum-global-color-static-white);
  --spectrum-link-over-background-underline-color-down: var(--spectrum-global-color-static-white);
  --spectrum-link-over-background-text-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-link-over-background-underline-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-link-over-background-text-color-mouse-focus: rgb(255, 255, 255);
  --spectrum-link-over-background-underline-color-mouse-focus: rgb(255, 255, 255);
  --spectrum-link-over-background-text-color-disabled: rgba(255,255,255,0.5);
  --spectrum-link-over-background-underline-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-link-over-background-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-link-over-background-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-link-over-background-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-link-over-background-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-link-over-background-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-link-over-background-text-transform: none;
  --spectrum-link-over-background-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-link-over-background-underline-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-link-over-background-underline-size: var(--spectrum-alias-border-size-thin);
  --spectrum-link-primary-text-color: var(--spectrum-global-color-blue-600);
  --spectrum-link-primary-underline-color: var(--spectrum-global-color-blue-600);
  --spectrum-link-primary-text-color-hover: var(--spectrum-global-color-blue-600);
  --spectrum-link-primary-underline-color-hover: var(--spectrum-global-color-blue-600);
  --spectrum-link-primary-text-color-down: var(--spectrum-global-color-blue-700);
  --spectrum-link-primary-underline-color-down: var(--spectrum-global-color-blue-700);
  --spectrum-link-primary-text-color-key-focus: var(--spectrum-alias-text-color-key-focus);
  --spectrum-link-primary-underline-color-key-focus: var(--spectrum-alias-text-color-key-focus);
  --spectrum-link-primary-text-color-mouse-focus: var(--spectrum-alias-text-color-mouse-focus);
  --spectrum-link-primary-underline-color-mouse-focus: var(--spectrum-alias-text-color-mouse-focus);
  --spectrum-link-primary-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-link-primary-underline-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-link-primary-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-link-primary-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-link-primary-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-link-primary-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-link-primary-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-link-primary-text-transform: none;
  --spectrum-link-primary-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-link-primary-underline-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-link-primary-underline-size: var(--spectrum-alias-border-size-thin);
  --spectrum-link-quiet-text-color: var(--spectrum-alias-text-color);
  --spectrum-link-quiet-underline-color: var(--spectrum-alias-text-color);
  --spectrum-link-quiet-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-underline-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-link-quiet-underline-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-link-quiet-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-underline-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-link-quiet-underline-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-link-quiet-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-link-quiet-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-link-quiet-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-link-quiet-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-link-quiet-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-link-quiet-text-transform: none;
  --spectrum-link-quiet-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-link-quiet-underline-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-link-quiet-underline-size: var(--spectrum-alias-border-size-thin);
  --spectrum-link-quiet-over-background-text-color: rgb(255, 255, 255);
  --spectrum-link-quiet-over-background-text-color-hover: rgb(255, 255, 255);
  --spectrum-link-quiet-over-background-text-color-down: rgb(255, 255, 255);
  --spectrum-link-quiet-over-background-text-color-key-focus: rgb(255, 255, 255);
  --spectrum-link-quiet-over-background-text-color-mouse-focus: rgb(255, 255, 255);
  --spectrum-link-quiet-over-background-text-color-disabled: rgba(255,255,255,0.5);
  --spectrum-link-quiet-over-background-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-link-quiet-over-background-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-link-quiet-over-background-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-link-quiet-over-background-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-link-quiet-over-background-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-link-quiet-over-background-text-transform: none;
  --spectrum-link-quiet-over-background-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-link-quiet-primary-text-color: var(--spectrum-global-color-blue-600);
  --spectrum-link-quiet-primary-text-color-hover: var(--spectrum-global-color-blue-600);
  --spectrum-link-quiet-primary-text-color-down: var(--spectrum-global-color-blue-700);
  --spectrum-link-quiet-primary-text-color-key-focus: var(--spectrum-alias-text-color-key-focus);
  --spectrum-link-quiet-primary-text-color-mouse-focus: var(--spectrum-alias-text-color-mouse-focus);
  --spectrum-link-quiet-primary-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-link-quiet-primary-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-link-quiet-primary-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-link-quiet-primary-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-link-quiet-primary-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-link-quiet-primary-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-link-quiet-primary-text-transform: none;
  --spectrum-link-quiet-primary-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-link-quiet-secondary-text-color: var(--spectrum-alias-text-color);
  --spectrum-link-quiet-secondary-underline-color: var(--spectrum-alias-text-color);
  --spectrum-link-quiet-secondary-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-secondary-underline-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-secondary-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-link-quiet-secondary-underline-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-link-quiet-secondary-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-secondary-underline-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-secondary-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-secondary-underline-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-quiet-secondary-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-link-quiet-secondary-underline-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-link-quiet-secondary-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-link-quiet-secondary-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-link-quiet-secondary-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-link-quiet-secondary-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-link-quiet-secondary-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-link-quiet-secondary-text-transform: none;
  --spectrum-link-quiet-secondary-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-link-quiet-secondary-underline-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-link-quiet-secondary-underline-size: var(--spectrum-alias-border-size-thin);
  --spectrum-link-secondary-text-color: var(--spectrum-alias-text-color);
  --spectrum-link-secondary-underline-color: var(--spectrum-alias-text-color);
  --spectrum-link-secondary-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-link-secondary-underline-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-link-secondary-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-link-secondary-underline-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-link-secondary-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-secondary-underline-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-secondary-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-secondary-underline-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-link-secondary-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-link-secondary-underline-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-link-secondary-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-link-secondary-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-link-secondary-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-link-secondary-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-link-secondary-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-link-secondary-text-transform: none;
  --spectrum-link-secondary-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-link-secondary-underline-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-link-secondary-underline-size: var(--spectrum-alias-border-size-thin);
}
