.spectrum {
  --spectrum-breadcrumb-compact-item-border-size: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-compact-item-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-compact-item-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-compact-item-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-breadcrumb-compact-item-underline-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-compact-item-border-size-hover: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-compact-item-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-compact-item-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-compact-item-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-compact-item-underline-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-compact-item-border-size-down: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-compact-item-border-color-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-compact-item-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-compact-item-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-breadcrumb-compact-item-underline-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-breadcrumb-compact-item-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-breadcrumb-compact-item-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-breadcrumb-compact-item-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-compact-item-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-compact-item-underline-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-compact-item-border-size-drop: var(--spectrum-alias-border-size-thick);
  --spectrum-breadcrumb-compact-item-border-color-drop: var(--spectrum-global-color-blue-500);
  --spectrum-breadcrumb-compact-item-background-color-drop: var(--spectrum-alias-highlight-selected);
  --spectrum-breadcrumb-compact-item-text-color-drop: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-compact-item-underline-color-drop: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-compact-item-border-size-disabled: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-compact-item-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-compact-item-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-compact-item-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-breadcrumb-compact-item-underline-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-compact-separator-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-breadcrumb-compact-title-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-breadcrumb-compact-margin-top: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-compact-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-compact-list-height: var(--spectrum-global-dimension-size-400);
  --spectrum-breadcrumb-compact-list-padding-top: var(--spectrum-global-dimension-static-size-25);
  --spectrum-breadcrumb-compact-list-padding-bottom: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-compact-separator-margin-y: var(--spectrum-global-dimension-size-130);
  --spectrum-breadcrumb-compact-separator-icon-width: var(--spectrum-global-dimension-size-75);
  --spectrum-breadcrumb-compact-separator-icon-height: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-compact-item-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-breadcrumb-compact-item-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-breadcrumb-compact-item-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-breadcrumb-compact-item-margin-left: var(--spectrum-global-dimension-static-size-10);
  --spectrum-breadcrumb-compact-item-margin-right: var(--spectrum-global-dimension-static-size-10);
  --spectrum-breadcrumb-compact-item-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-breadcrumb-compact-item-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-compact-title-margin-top: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-compact-title-margin-left: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-compact-title-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-breadcrumb-compact-title-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-breadcrumb-compact-title-text-font-weight: var(--spectrum-alias-body-text-font-weight-strong);
  --spectrum-breadcrumb-compact-title-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-compact-button-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-breadcrumb-compact-button-touch-hit-x: var(--spectrum-global-dimension-static-size-50);
  --spectrum-breadcrumb-compact-item-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-breadcrumb-compact-title-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-breadcrumb-item-border-size: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-item-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-item-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-item-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-breadcrumb-item-underline-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-ui-icon-color: var(--spectrum-global-color-gray-500);
  --spectrum-breadcrumb-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-breadcrumb-item-border-size-hover: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-item-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-item-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-item-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-item-underline-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-item-border-size-down: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-item-border-color-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-item-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-item-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-breadcrumb-item-underline-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-breadcrumb-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-breadcrumb-item-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-breadcrumb-item-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-breadcrumb-item-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-item-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-item-underline-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-item-border-size-drop: var(--spectrum-alias-border-size-thick);
  --spectrum-breadcrumb-item-border-color-drop: var(--spectrum-global-color-blue-500);
  --spectrum-breadcrumb-item-background-color-drop: var(--spectrum-alias-highlight-selected);
  --spectrum-breadcrumb-item-text-color-drop: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-item-underline-color-drop: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-item-border-size-disabled: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-item-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-item-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-item-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-breadcrumb-item-underline-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-separator-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-breadcrumb-title-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-breadcrumb-margin-top: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-list-height: var(--spectrum-global-dimension-size-400);
  --spectrum-breadcrumb-list-padding-top: var(--spectrum-global-dimension-static-size-25);
  --spectrum-breadcrumb-list-padding-bottom: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-separator-margin-y: var(--spectrum-global-dimension-size-130);
  --spectrum-breadcrumb-separator-icon-width: var(--spectrum-global-dimension-size-75);
  --spectrum-breadcrumb-separator-icon-height: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-item-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-breadcrumb-item-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-breadcrumb-item-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-breadcrumb-item-margin-left: var(--spectrum-global-dimension-static-size-10);
  --spectrum-breadcrumb-item-margin-right: var(--spectrum-global-dimension-static-size-10);
  --spectrum-breadcrumb-item-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-breadcrumb-item-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-title-margin-top: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-title-margin-left: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-title-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-breadcrumb-title-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-breadcrumb-title-text-font-weight: var(--spectrum-alias-body-text-font-weight-strong);
  --spectrum-breadcrumb-title-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-button-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-breadcrumb-button-touch-hit-x: var(--spectrum-global-dimension-static-size-50);
  --spectrum-breadcrumb-height: 32px;
  --spectrum-breadcrumb-ui-icon-width: var(--spectrum-global-dimension-size-75);
  --spectrum-breadcrumb-ui-icon-height: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-margin: 32px;
  --spectrum-breadcrumb-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-breadcrumb-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-breadcrumb-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-breadcrumb-item-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-breadcrumb-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-breadcrumb-multiline-item-border-size: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-multiline-item-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-multiline-item-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-multiline-item-text-color: var(--spectrum-alias-label-text-color);
  --spectrum-breadcrumb-multiline-item-underline-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-multiline-item-border-size-hover: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-multiline-item-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-multiline-item-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-multiline-item-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-multiline-item-underline-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-multiline-item-border-size-down: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-multiline-item-border-color-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-multiline-item-background-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-multiline-item-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-breadcrumb-multiline-item-underline-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-breadcrumb-multiline-item-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-breadcrumb-multiline-item-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-breadcrumb-multiline-item-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-multiline-item-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-multiline-item-underline-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-multiline-item-border-size-drop: var(--spectrum-alias-border-size-thick);
  --spectrum-breadcrumb-multiline-item-border-color-drop: var(--spectrum-global-color-blue-500);
  --spectrum-breadcrumb-multiline-item-background-color-drop: var(--spectrum-alias-highlight-selected);
  --spectrum-breadcrumb-multiline-item-text-color-drop: var(--spectrum-alias-text-color-hover);
  --spectrum-breadcrumb-multiline-item-underline-color-drop: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-multiline-item-border-size-disabled: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-multiline-item-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-multiline-item-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-breadcrumb-multiline-item-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-breadcrumb-multiline-item-underline-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-breadcrumb-multiline-separator-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-breadcrumb-multiline-title-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-breadcrumb-multiline-margin-top: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-multiline-margin-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-multiline-list-height: var(--spectrum-global-dimension-size-400);
  --spectrum-breadcrumb-multiline-list-padding-top: var(--spectrum-global-dimension-static-size-25);
  --spectrum-breadcrumb-multiline-list-padding-bottom: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-multiline-separator-margin-y: var(--spectrum-global-dimension-size-130);
  --spectrum-breadcrumb-multiline-separator-icon-width: var(--spectrum-global-dimension-size-75);
  --spectrum-breadcrumb-multiline-separator-icon-height: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-multiline-item-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-breadcrumb-multiline-item-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-breadcrumb-multiline-item-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-breadcrumb-multiline-item-margin-left: var(--spectrum-global-dimension-static-size-10);
  --spectrum-breadcrumb-multiline-item-margin-right: var(--spectrum-global-dimension-static-size-10);
  --spectrum-breadcrumb-multiline-item-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-breadcrumb-multiline-item-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-multiline-title-margin-top: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-multiline-title-margin-left: var(--spectrum-global-dimension-static-size-0);
  --spectrum-breadcrumb-multiline-title-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-breadcrumb-multiline-title-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-breadcrumb-multiline-title-text-font-weight: var(--spectrum-alias-body-text-font-weight-strong);
  --spectrum-breadcrumb-multiline-title-padding-x: var(--spectrum-global-dimension-size-100);
  --spectrum-breadcrumb-multiline-button-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-breadcrumb-multiline-button-touch-hit-x: var(--spectrum-global-dimension-static-size-50);
  --spectrum-breadcrumb-multiline-item-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-breadcrumb-multiline-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-breadcrumb-title-margin: 0.3em 0;
  --spectrum-breadcrumb-title-active-text-size: 28px;
  --spectrum-breadcrumb-title-active-text-font-weight: 300;
  --spectrum-breadcrumb-title-active-text-line-height: 32px;
}
