.spectrum {
  --spectrum-cyclebutton-text-color: var(--spectrum-alias-text-color);
  --spectrum-cyclebutton-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-cyclebutton-border-size: var(--spectrum-alias-border-size-thin);
  --spectrum-cyclebutton-background-color: var(--spectrum-alias-background-color-transparent);
  --spectrum-cyclebutton-border-color: var(--spectrum-alias-border-color-transparent);
  --spectrum-cyclebutton-hold-icon-color: var(--spectrum-alias-icon-color);
  --spectrum-cyclebutton-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-cyclebutton-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-cyclebutton-border-size-hover: var(--spectrum-alias-border-size-thin);
  --spectrum-cyclebutton-background-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-cyclebutton-border-color-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-cyclebutton-hold-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-cyclebutton-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-cyclebutton-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-cyclebutton-border-size-down: var(--spectrum-alias-border-size-thin);
  --spectrum-cyclebutton-background-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-cyclebutton-border-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-cyclebutton-hold-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-cyclebutton-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-cyclebutton-icon-color-key-focus: var(--spectrum-alias-icon-color-focus);
  --spectrum-cyclebutton-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-cyclebutton-border-color-key-focus: var(--spectrum-alias-border-color-focus);
  --spectrum-cyclebutton-background-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-cyclebutton-hold-icon-color-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-cyclebutton-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-cyclebutton-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-cyclebutton-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-cyclebutton-border-size-disabled: var(--spectrum-alias-border-size-thin);
  --spectrum-cyclebutton-background-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-cyclebutton-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-cyclebutton-hold-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-cyclebutton-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-cyclebutton-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-cyclebutton-text-font-weight: var(--spectrum-alias-body-text-font-weight);
  --spectrum-cyclebutton-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-cyclebutton-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-cyclebutton-text-transform: none;
  --spectrum-cyclebutton-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-cyclebutton-icon-size: var(--spectrum-alias-workflow-icon-size);
  --spectrum-cyclebutton-text-baseline: var(--spectrum-global-dimension-size-150);
  --spectrum-cyclebutton-height: var(--spectrum-alias-single-line-height);
  --spectrum-cyclebutton-min-width: var(--spectrum-global-dimension-size-400);
  --spectrum-cyclebutton-border-radius: var(--spectrum-alias-border-radius-regular);
  --spectrum-cyclebutton-text-padding-x: var(--spectrum-global-dimension-size-150);
  --spectrum-cyclebutton-icon-padding-x: var(--spectrum-global-dimension-size-85);
  --spectrum-cyclebutton-icon-padding-y: var(--spectrum-global-dimension-size-85);
  --spectrum-cyclebutton-hold-icon-padding-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-cyclebutton-hold-icon-padding-right: var(--spectrum-global-dimension-size-40);
  --spectrum-cyclebutton-hold-icon-size: var(--spectrum-global-dimension-size-65);
}
