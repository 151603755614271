.spectrum {
  --spectrum-subheading-article-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-subheading-article-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-subheading-article-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-subheading-article-text-font-weight: var(--spectrum-alias-article-subheading-text-font-weight);
  --spectrum-subheading-article-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-subheading-article-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-subheading-article-text-transform: none;
  --spectrum-subheading-article-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-subheading-article-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-subheading-article-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-subheading-article-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-subheading-article-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-subheading-article-emphasis-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-subheading-article-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-subheading-article-emphasis-text-font-weight: var(--spectrum-alias-article-subheading-text-font-weight);
  --spectrum-subheading-article-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-subheading-article-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-subheading-article-emphasis-text-transform: none;
  --spectrum-subheading-article-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-subheading-article-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-subheading-article-emphasis-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-subheading-article-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-subheading-article-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-subheading-article-strong-text-font-family: var(--spectrum-alias-article-text-font-family);
  --spectrum-subheading-article-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-subheading-article-strong-text-font-weight: var(--spectrum-alias-article-subheading-text-font-weight-strong);
  --spectrum-subheading-article-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-subheading-article-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-small);
  --spectrum-subheading-article-strong-text-transform: none;
  --spectrum-subheading-article-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-subheading-article-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-subheading-article-strong-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-subheading-article-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-subheading-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-subheading-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-subheading-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-subheading-text-font-weight: var(--spectrum-alias-subheading-text-font-weight);
  --spectrum-subheading-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-subheading-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-subheading-text-transform: uppercase;
  --spectrum-subheading-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-subheading-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-subheading-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-subheading-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-subheading-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-subheading-emphasis-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-subheading-emphasis-text-font-style: var(--spectrum-global-font-style-italic);
  --spectrum-subheading-emphasis-text-font-weight: var(--spectrum-alias-subheading-text-font-weight);
  --spectrum-subheading-emphasis-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-subheading-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-subheading-emphasis-text-transform: uppercase;
  --spectrum-subheading-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-subheading-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-subheading-emphasis-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-subheading-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-subheading-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-subheading-strong-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-subheading-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-subheading-strong-text-font-weight: var(--spectrum-alias-subheading-text-font-weight-strong);
  --spectrum-subheading-strong-text-line-height: var(--spectrum-alias-heading-text-line-height);
  --spectrum-subheading-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-medium);
  --spectrum-subheading-strong-text-transform: uppercase;
  --spectrum-subheading-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-subheading-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-subheading-strong-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-subheading-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-subheading-han-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-subheading-han-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-subheading-han-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-subheading-han-text-font-weight: var(--spectrum-alias-han-heading-text-font-weight-regular);
  --spectrum-subheading-han-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-subheading-han-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-subheading-han-text-transform: none;
  --spectrum-subheading-han-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-subheading-han-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-subheading-han-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-subheading-han-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-subheading-han-emphasis-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-subheading-han-emphasis-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-subheading-han-emphasis-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-subheading-han-emphasis-text-font-weight: var(--spectrum-alias-han-subheading-text-font-weight-emphasis);
  --spectrum-subheading-han-emphasis-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-subheading-han-emphasis-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-subheading-han-emphasis-text-transform: none;
  --spectrum-subheading-han-emphasis-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-subheading-han-emphasis-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-subheading-han-emphasis-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-subheading-han-emphasis-margin-bottom: var(--spectrum-global-dimension-size-40);
  --spectrum-subheading-han-strong-text-color: var(--spectrum-alias-heading-text-color);
  --spectrum-subheading-han-strong-text-font-family: var(--spectrum-alias-font-family-zh);
  --spectrum-subheading-han-strong-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-subheading-han-strong-text-font-weight: var(--spectrum-alias-han-subheading-text-font-weight-strong);
  --spectrum-subheading-han-strong-text-line-height: var(--spectrum-alias-heading-han-text-line-height);
  --spectrum-subheading-han-strong-text-letter-spacing: var(--spectrum-global-font-letter-spacing-han);
  --spectrum-subheading-han-strong-text-transform: none;
  --spectrum-subheading-han-strong-margin-bottom-multiplier: var(--spectrum-alias-heading-margin-bottom);
  --spectrum-subheading-han-strong-text-size: var(--spectrum-global-dimension-font-size-50);
  --spectrum-subheading-han-strong-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-subheading-han-strong-margin-bottom: var(--spectrum-global-dimension-size-40);
}
