.spectrum {
  --spectrum-colorhandle-inner-border-color: var(--spectrum-global-color-static-white);
  --spectrum-colorhandle-outer-border-color: var(--spectrum-alias-colorhandle-outer-border-color);
  --spectrum-colorhandle-fill-color: none;
  --spectrum-colorhandle-cursor-hit-width: var(--spectrum-global-dimension-size-300);
  --spectrum-colorhandle-cursor-hit-height: var(--spectrum-global-dimension-size-300);
  --spectrum-colorhandle-cursor-hit-border-radius: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-colorhandle-touch-hit-width: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorhandle-touch-hit-height: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorhandle-touch-hit-border-radius: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-colorhandle-loupe-inner-border-color: var(--spectrum-global-color-static-white);
  --spectrum-colorhandle-loupe-outer-border-color: rgba(50,50,50,0.1);
  --spectrum-colorhandle-loupe-shadow-color: var(--spectrum-alias-dropshadow-color);
  --spectrum-colorhandle-inner-border-color-hover: var(--spectrum-global-color-static-white);
  --spectrum-colorhandle-outer-border-color-hover: var(--spectrum-alias-colorhandle-outer-border-color);
  --spectrum-colorhandle-fill-color-hover: none;
  --spectrum-colorhandle-cursor-hit-width-hover: var(--spectrum-global-dimension-size-300);
  --spectrum-colorhandle-cursor-hit-height-hover: var(--spectrum-global-dimension-size-300);
  --spectrum-colorhandle-cursor-hit-border-radius-hover: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-colorhandle-touch-hit-width-hover: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorhandle-touch-hit-height-hover: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorhandle-touch-hit-border-radius-hover: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-colorhandle-inner-border-color-down: var(--spectrum-global-color-static-white);
  --spectrum-colorhandle-outer-border-color-down: var(--spectrum-alias-colorhandle-outer-border-color);
  --spectrum-colorhandle-fill-color-down: none;
  --spectrum-colorhandle-cursor-hit-width-down: var(--spectrum-global-dimension-size-300);
  --spectrum-colorhandle-cursor-hit-height-down: var(--spectrum-global-dimension-size-300);
  --spectrum-colorhandle-cursor-hit-border-radius-down: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-colorhandle-touch-hit-width-down: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorhandle-touch-hit-height-down: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorhandle-touch-hit-border-radius-down: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-colorhandle-inner-border-color-key-focus: var(--spectrum-global-color-static-white);
  --spectrum-colorhandle-outer-border-color-key-focus: var(--spectrum-alias-colorhandle-outer-border-color);
  --spectrum-colorhandle-fill-color-key-focus: none;
  --spectrum-colorhandle-cursor-hit-width-key-focus: var(--spectrum-global-dimension-size-400);
  --spectrum-colorhandle-cursor-hit-height-key-focus: var(--spectrum-global-dimension-size-400);
  --spectrum-colorhandle-cursor-hit-border-radius-key-focus: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-colorhandle-touch-hit-width-key-focus: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorhandle-touch-hit-height-key-focus: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorhandle-touch-hit-border-radius-key-focus: var(--spectrum-global-dimension-static-percent-50);
  --spectrum-colorhandle-inner-border-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-colorhandle-outer-border-color-disabled: rgba(0,0,0,0);
  --spectrum-colorhandle-fill-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-colorhandle-size: var(--spectrum-global-dimension-size-200);
  --spectrum-colorhandle-inner-border-size: var(--spectrum-global-dimension-static-size-25);
  --spectrum-colorhandle-outer-border-size: var(--spectrum-global-dimension-static-size-10);
  --spectrum-colorhandle-size-focus: var(--spectrum-global-dimension-size-400);
  --spectrum-colorhandle-loupe-width: var(--spectrum-global-dimension-static-size-600);
  --spectrum-colorhandle-loupe-height: var(--spectrum-global-dimension-static-size-800);
  --spectrum-colorhandle-loupe-inner-border-size: var(--spectrum-global-dimension-static-size-25);
  --spectrum-colorhandle-loupe-outer-border-size: var(--spectrum-global-dimension-static-size-10);
  --spectrum-colorhandle-loupe-shadow-offset-y: var(--spectrum-global-dimension-size-10);
  --spectrum-colorhandle-loupe-shadow-blur: var(--spectrum-global-dimension-size-50);
}
