.spectrum {
  --spectrum-switch-text-color: var(--spectrum-alias-text-color);
  --spectrum-switch-handle-border-color: var(--spectrum-global-color-gray-600);
  --spectrum-switch-handle-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color: var(--spectrum-global-color-gray-300);
  --spectrum-switch-text-gap: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-handle-border-color-hover: var(--spectrum-global-color-gray-700);
  --spectrum-switch-handle-background-color-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color-hover: var(--spectrum-global-color-gray-300);
  --spectrum-switch-text-gap-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-switch-handle-border-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-switch-handle-background-color-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-switch-text-gap-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-handle-border-color-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-switch-handle-background-color-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color-key-focus: var(--spectrum-global-color-gray-300);
  --spectrum-switch-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-handle-border-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-handle-background-color-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-switch-text-gap-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-selected: var(--spectrum-alias-text-color);
  --spectrum-switch-handle-border-color-selected: var(--spectrum-global-color-gray-700);
  --spectrum-switch-handle-background-color-selected: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size-selected: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color-selected: var(--spectrum-global-color-gray-700);
  --spectrum-switch-text-gap-selected: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-handle-border-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-switch-handle-background-color-selected-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size-selected-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-switch-text-gap-selected-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-selected-down: var(--spectrum-alias-text-color-down);
  --spectrum-switch-handle-border-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-switch-handle-background-color-selected-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size-selected-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-switch-text-gap-selected-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-selected-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-handle-border-color-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-switch-handle-background-color-selected-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-switch-focus-ring-gap-selected-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-focus-ring-size-selected-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-focus-ring-color-selected-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-track-color-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-handle-border-size-selected-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-handle-background-color-selected-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-text-gap-selected-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-error: var(--spectrum-global-color-red-600);
  --spectrum-switch-track-color-error: var(--spectrum-global-color-red-500);
  --spectrum-switch-handle-border-size-error: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-switch-handle-background-color-error: var(--spectrum-global-color-gray-75);
  --spectrum-switch-text-gap-error: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-error-hover: var(--spectrum-global-color-red-700);
  --spectrum-switch-track-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-handle-border-size-error-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-handle-background-color-error-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-text-gap-error-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-track-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-handle-border-size-error-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-handle-background-color-error-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-text-gap-error-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-error-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-switch-track-color-error-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-handle-border-size-error-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-error-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-handle-background-color-error-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-focus-ring-gap-error-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-focus-ring-size-error-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-focus-ring-color-error-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-text-color-error-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-handle-border-color-error-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-handle-background-color-error-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-handle-border-size-error-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-track-color-error-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-switch-text-gap-error-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-error-selected: var(--spectrum-global-color-red-600);
  --spectrum-switch-track-color-error-selected: var(--spectrum-global-color-red-500);
  --spectrum-switch-handle-border-size-error-selected: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-error-selected: var(--spectrum-global-color-red-500);
  --spectrum-switch-handle-background-color-error-selected: var(--spectrum-global-color-gray-75);
  --spectrum-switch-text-gap-error-selected: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-error-selected-hover: var(--spectrum-global-color-red-700);
  --spectrum-switch-track-color-error-selected-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-handle-border-size-error-selected-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-error-selected-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-handle-background-color-error-selected-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-text-gap-error-selected-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-error-selected-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-track-color-error-selected-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-handle-border-size-error-selected-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-error-selected-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-handle-background-color-error-selected-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-text-gap-error-selected-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-text-color-error-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-switch-track-color-error-selected-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-handle-border-size-error-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-error-selected-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-handle-background-color-error-selected-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-focus-ring-gap-error-selected-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-focus-ring-size-error-selected-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-focus-ring-color-error-selected-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-text-color-error-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-track-color-error-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-handle-border-size-error-selected-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-handle-border-color-error-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-handle-background-color-error-selected-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-text-gap-error-selected-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-track-color-disabled-selected: var(--spectrum-global-color-gray-400);
  --spectrum-switch-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-switch-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-switch-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-switch-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-switch-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-switch-text-transform: none;
  --spectrum-switch-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-switch-handle-size: var(--spectrum-global-dimension-size-175);
  --spectrum-switch-track-height: var(--spectrum-global-dimension-size-175);
  --spectrum-switch-height: var(--spectrum-global-dimension-size-400);
  --spectrum-switch-text-baseline: var(--spectrum-global-dimension-font-size-75);
  --spectrum-switch-cursor-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-switch-touch-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-switch-emphasized-text-color: var(--spectrum-alias-text-color);
  --spectrum-switch-emphasized-handle-border-color: var(--spectrum-global-color-gray-600);
  --spectrum-switch-emphasized-handle-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color: var(--spectrum-global-color-gray-300);
  --spectrum-switch-emphasized-text-gap: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-emphasized-handle-border-color-hover: var(--spectrum-global-color-gray-700);
  --spectrum-switch-emphasized-handle-background-color-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color-hover: var(--spectrum-global-color-gray-300);
  --spectrum-switch-emphasized-text-gap-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-switch-emphasized-handle-border-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-switch-emphasized-handle-background-color-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-switch-emphasized-text-gap-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-emphasized-handle-border-color-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-switch-emphasized-handle-background-color-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color-key-focus: var(--spectrum-global-color-gray-300);
  --spectrum-switch-emphasized-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-emphasized-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-emphasized-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-emphasized-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-emphasized-handle-border-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-emphasized-handle-background-color-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-switch-emphasized-text-gap-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-selected: var(--spectrum-alias-text-color);
  --spectrum-switch-emphasized-handle-border-color-selected: var(--spectrum-global-color-blue-500);
  --spectrum-switch-emphasized-handle-background-color-selected: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size-selected: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color-selected: var(--spectrum-global-color-blue-500);
  --spectrum-switch-emphasized-text-gap-selected: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-emphasized-handle-border-color-selected-hover: var(--spectrum-global-color-blue-600);
  --spectrum-switch-emphasized-handle-background-color-selected-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size-selected-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color-selected-hover: var(--spectrum-global-color-blue-600);
  --spectrum-switch-emphasized-text-gap-selected-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-selected-down: var(--spectrum-alias-text-color-down);
  --spectrum-switch-emphasized-handle-border-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-switch-emphasized-handle-background-color-selected-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size-selected-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-switch-emphasized-text-gap-selected-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-selected-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-emphasized-handle-border-color-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-switch-emphasized-handle-background-color-selected-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-switch-emphasized-focus-ring-gap-selected-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-emphasized-focus-ring-size-selected-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-emphasized-focus-ring-color-selected-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-emphasized-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-emphasized-track-color-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-emphasized-handle-border-size-selected-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-emphasized-handle-background-color-selected-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-text-gap-selected-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-error: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-track-color-error: var(--spectrum-global-color-red-500);
  --spectrum-switch-emphasized-handle-border-size-error: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-switch-emphasized-handle-background-color-error: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-text-gap-error: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-error-hover: var(--spectrum-global-color-red-700);
  --spectrum-switch-emphasized-track-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-handle-border-size-error-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-handle-background-color-error-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-text-gap-error-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-emphasized-track-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-emphasized-handle-border-size-error-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-emphasized-handle-background-color-error-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-text-gap-error-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-error-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-switch-emphasized-track-color-error-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-handle-border-size-error-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-error-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-handle-background-color-error-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-focus-ring-gap-error-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-emphasized-focus-ring-size-error-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-emphasized-focus-ring-color-error-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-emphasized-text-color-error-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-emphasized-handle-border-color-error-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-emphasized-handle-background-color-error-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-handle-border-size-error-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-track-color-error-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-switch-emphasized-text-gap-error-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-error-selected: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-track-color-error-selected: var(--spectrum-global-color-red-500);
  --spectrum-switch-emphasized-handle-border-size-error-selected: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-error-selected: var(--spectrum-global-color-red-500);
  --spectrum-switch-emphasized-handle-background-color-error-selected: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-text-gap-error-selected: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-error-selected-hover: var(--spectrum-global-color-red-700);
  --spectrum-switch-emphasized-track-color-error-selected-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-handle-border-size-error-selected-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-error-selected-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-handle-background-color-error-selected-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-text-gap-error-selected-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-error-selected-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-emphasized-track-color-error-selected-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-emphasized-handle-border-size-error-selected-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-error-selected-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-emphasized-handle-background-color-error-selected-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-text-gap-error-selected-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-text-color-error-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-switch-emphasized-track-color-error-selected-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-handle-border-size-error-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-error-selected-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-emphasized-handle-background-color-error-selected-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-focus-ring-gap-error-selected-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-emphasized-focus-ring-size-error-selected-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-emphasized-focus-ring-color-error-selected-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-emphasized-text-color-error-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-emphasized-track-color-error-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-emphasized-handle-border-size-error-selected-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-emphasized-handle-border-color-error-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-emphasized-handle-background-color-error-selected-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-emphasized-text-gap-error-selected-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-emphasized-track-color-disabled-selected: var(--spectrum-global-color-gray-400);
  --spectrum-switch-emphasized-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-switch-emphasized-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-switch-emphasized-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-switch-emphasized-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-switch-emphasized-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-switch-emphasized-text-transform: none;
  --spectrum-switch-emphasized-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-switch-emphasized-handle-size: var(--spectrum-global-dimension-size-175);
  --spectrum-switch-emphasized-track-height: var(--spectrum-global-dimension-size-175);
  --spectrum-switch-emphasized-height: var(--spectrum-global-dimension-size-400);
  --spectrum-switch-emphasized-text-baseline: var(--spectrum-global-dimension-font-size-75);
  --spectrum-switch-emphasized-cursor-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-switch-emphasized-touch-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-switch-quiet-text-color: var(--spectrum-alias-text-color);
  --spectrum-switch-quiet-handle-border-color: var(--spectrum-global-color-gray-600);
  --spectrum-switch-quiet-handle-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color: var(--spectrum-global-color-gray-300);
  --spectrum-switch-quiet-text-gap: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-quiet-handle-border-color-hover: var(--spectrum-global-color-gray-700);
  --spectrum-switch-quiet-handle-background-color-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color-hover: var(--spectrum-global-color-gray-300);
  --spectrum-switch-quiet-text-gap-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-switch-quiet-handle-border-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-switch-quiet-handle-background-color-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color-down: var(--spectrum-global-color-gray-300);
  --spectrum-switch-quiet-text-gap-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-quiet-handle-border-color-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-switch-quiet-handle-background-color-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color-key-focus: var(--spectrum-global-color-gray-300);
  --spectrum-switch-quiet-focus-ring-gap-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-quiet-focus-ring-size-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-quiet-focus-ring-color-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-quiet-text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-quiet-handle-border-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-quiet-handle-background-color-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-switch-quiet-text-gap-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-selected: var(--spectrum-alias-text-color);
  --spectrum-switch-quiet-handle-border-color-selected: var(--spectrum-global-color-gray-700);
  --spectrum-switch-quiet-handle-background-color-selected: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size-selected: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color-selected: var(--spectrum-global-color-gray-700);
  --spectrum-switch-quiet-text-gap-selected: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-selected-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-quiet-handle-border-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-switch-quiet-handle-background-color-selected-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size-selected-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-switch-quiet-text-gap-selected-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-selected-down: var(--spectrum-alias-text-color-down);
  --spectrum-switch-quiet-handle-border-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-switch-quiet-handle-background-color-selected-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size-selected-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-switch-quiet-text-gap-selected-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-selected-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-switch-quiet-handle-border-color-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-switch-quiet-handle-background-color-selected-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color-selected-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-switch-quiet-focus-ring-gap-selected-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-quiet-focus-ring-size-selected-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-quiet-focus-ring-color-selected-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-quiet-text-color-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-quiet-track-color-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-quiet-handle-border-size-selected-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-quiet-handle-background-color-selected-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-text-gap-selected-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-error: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-track-color-error: var(--spectrum-global-color-red-500);
  --spectrum-switch-quiet-handle-border-size-error: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-error: var(--spectrum-global-color-red-500);
  --spectrum-switch-quiet-handle-background-color-error: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-text-gap-error: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-error-hover: var(--spectrum-global-color-red-700);
  --spectrum-switch-quiet-track-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-handle-border-size-error-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-error-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-handle-background-color-error-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-text-gap-error-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-quiet-track-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-quiet-handle-border-size-error-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-quiet-handle-background-color-error-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-text-gap-error-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-error-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-switch-quiet-track-color-error-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-handle-border-size-error-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-error-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-handle-background-color-error-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-focus-ring-gap-error-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-quiet-focus-ring-size-error-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-quiet-focus-ring-color-error-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-quiet-text-color-error-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-quiet-handle-border-color-error-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-quiet-handle-background-color-error-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-handle-border-size-error-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-track-color-error-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-switch-quiet-text-gap-error-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-error-selected: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-track-color-error-selected: var(--spectrum-global-color-red-500);
  --spectrum-switch-quiet-handle-border-size-error-selected: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-error-selected: var(--spectrum-global-color-red-500);
  --spectrum-switch-quiet-handle-background-color-error-selected: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-text-gap-error-selected: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-error-selected-hover: var(--spectrum-global-color-red-700);
  --spectrum-switch-quiet-track-color-error-selected-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-handle-border-size-error-selected-hover: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-error-selected-hover: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-handle-background-color-error-selected-hover: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-text-gap-error-selected-hover: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-error-selected-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-quiet-track-color-error-selected-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-quiet-handle-border-size-error-selected-down: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-error-selected-down: var(--spectrum-global-color-red-700);
  --spectrum-switch-quiet-handle-background-color-error-selected-down: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-text-gap-error-selected-down: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-text-color-error-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-switch-quiet-track-color-error-selected-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-handle-border-size-error-selected-key-focus: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-error-selected-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-switch-quiet-handle-background-color-error-selected-key-focus: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-focus-ring-gap-error-selected-key-focus: var(--spectrum-alias-focus-ring-gap);
  --spectrum-switch-quiet-focus-ring-size-error-selected-key-focus: var(--spectrum-alias-focus-ring-size);
  --spectrum-switch-quiet-focus-ring-color-error-selected-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-switch-quiet-text-color-error-selected-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-switch-quiet-track-color-error-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-quiet-handle-border-size-error-selected-disabled: var(--spectrum-alias-border-size-thick);
  --spectrum-switch-quiet-handle-border-color-error-selected-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-switch-quiet-handle-background-color-error-selected-disabled: var(--spectrum-global-color-gray-75);
  --spectrum-switch-quiet-text-gap-error-selected-disabled: var(--spectrum-global-dimension-size-125);
  --spectrum-switch-quiet-track-color-disabled-selected: var(--spectrum-global-color-gray-400);
  --spectrum-switch-quiet-text-font-family: var(--spectrum-alias-body-text-font-family);
  --spectrum-switch-quiet-text-font-style: var(--spectrum-global-font-style-regular);
  --spectrum-switch-quiet-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-switch-quiet-text-line-height: var(--spectrum-alias-body-text-line-height);
  --spectrum-switch-quiet-text-letter-spacing: var(--spectrum-global-font-letter-spacing-none);
  --spectrum-switch-quiet-text-transform: none;
  --spectrum-switch-quiet-text-size: var(--spectrum-alias-font-size-default);
  --spectrum-switch-quiet-handle-size: var(--spectrum-global-dimension-size-175);
  --spectrum-switch-quiet-track-height: var(--spectrum-global-dimension-size-175);
  --spectrum-switch-quiet-height: var(--spectrum-global-dimension-size-400);
  --spectrum-switch-quiet-text-baseline: var(--spectrum-global-dimension-font-size-75);
  --spectrum-switch-quiet-cursor-hit-x: var(--spectrum-global-dimension-size-100);
  --spectrum-switch-quiet-touch-hit-x: var(--spectrum-global-dimension-size-100);
}
